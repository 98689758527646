import { useContext, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { ClockIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, ShieldExclamationIcon, XCircleIcon } from "@heroicons/react/24/solid";
import Lottie from "lottie-react";
import PrimaryButton from "./PrimaryButton";
import trivia_animation from "./assets/trivia.json";
import { LoggedInContext } from "./context/context";
import { empty } from "./styles/styles";
import { get, postPatch } from "./utils/utils";

const LoadingShared = ({ linkIdParent, steps, email: userEmail, contactEmail, readyText, error, errorMessage, queued, queuePosition, handleResponse, loadingSessionId }) => {
	const { cable } = useContext(LoggedInContext);
	const [edit, setEdit] = useState(empty(contactEmail));
	const [email, setEmail] = useState(null);
	const [emailValid, setEmailValid] = useState(false);
	const [emailLoading, setEmailLoading] = useState(false);
	const [emailConfirmed, setEmailConfirmed] = useState(false);
	const [updatedEmail, setUpdatedEmail] = useState(null);
	const [play, setPlay] = useState(false);
	const [trivia, setTrivia] = useState(null);
	const [triviaLoading, setTriviaLoading] = useState(false);
	const [triviaMessage, setTriviaMessage] = useState(null);
	const [response, setResponse] = useState(null);
	const [correct, setCorrect] = useState(null);
  const channel = useRef(null);
  const scrollRef = useRef(null);
  const scrollTimeout = useRef(null);
	const emailDisplay = userEmail || updatedEmail || contactEmail;
	const responsePresent = response !== null;

	useEffect(() => {
		return () => {
			clearTimeout(scrollTimeout.current);
		};
	}, []);

	useEffect(() => {
		cable && connect();

		return () => {
			disconnect();
		};
	}, [cable]);

  const connect = () => {
    channel.current = cable.subscriptions.create({ channel: "UserChannel" }, {
      connected: () => console.log("connected"),
      disconnected: () => console.log("disconnected"),
      received: (data) => {
      	const { link_id: linkId } = data;
      	if (linkId === linkIdParent) {
      		handleResponse(data);
      	}
      },
    });
  };

  const disconnect = () => {
    channel?.current?.unsubscribe();
    cable?.disconnect();
  };

	const returnStep = (step) => {
		const { id, currentName, timeEstimate, completedName, upcomingName, currentDescription, completedDescription, errorDescription, completedSrc, status } = step;
		if (status === "complete") {
			return (
        <div className="flex flex-col border-indigo-600 border-t-4 pt-4 opacity-50">
          <div className="flex flex-row justify-start items-center text-indigo-600">
          	<span className="text-sm font-medium truncate">{completedName}</span>
          	<CheckCircleIcon className="w-5 h-5 mb-0.5 ml-1" />
          </div>
          <div className="flex flex-row justify-start items-center">
			      {completedSrc && (
			      	<img 
			      		className="h-5 w-5 rounded-full mr-1 mb-0.5" 
			      		src={completedSrc} 
			      		alt="avatar" 
			      	/>
			      )}
          	<span className="text-sm font-medium text-gray-500 truncate">{completedDescription}</span>
          </div>
        </div>
			)
		} else if (status === "current") {
			if (error) {
				return (
	        <div className="flex flex-col border-red-600 border-t-4 pt-4" aria-current="step">
	        	<div className="flex flex-row justify-start items-center mb-0.5 text-red-600">
	        		<XCircleIcon className="w-5 h-5 mb-0.5 mr-1" />
	          	<span className="text-sm font-medium truncate">{currentName}</span>
	          </div>
	          <span className="text-sm font-medium truncate">{errorDescription}</span>
	        </div>
				);
			} else if (queued) {
				return (
	        <div className="flex flex-col border-indigo-600 border-t-4 pt-4" aria-current="step">
	        	<div className="flex flex-row justify-start items-center mb-0.5 text-indigo-600">
	        		<ClockIcon className="w-5 h-5 mb-0.5 mr-1" />
	          	<span className="text-sm font-medium truncate">{currentName}</span>
	          </div>
	          <span className="text-sm font-medium truncate mb-0.5">{currentDescription}</span>
	          <span className="text-sm font-medium truncate text-gray-500">Queued (#{queuePosition})</span>
	        </div>
				);
			}
			return (
        <div className="flex flex-col border-indigo-600 border-t-4 pt-4" aria-current="step">
        	<div className="flex flex-row justify-start items-center mb-0.5">
        		<div className="border-2 border-indigo-600 border-t-transparent border-solid rounded-full w-4 h-4 animate-spin mb-0.5 mr-1.5" />
          	<span className="text-sm font-medium text-indigo-600 truncate">{currentName}<span className="text-xs font-base text-gray-500">&nbsp;&nbsp;{timeEstimate}</span></span>
          </div>
          <span className="text-sm font-medium truncate">{currentDescription}</span>
        </div>
			);
		}
		return (
      <div className="flex flex-col border-gray-200 py-2 border-t-4 pt-4">
        <span className="text-sm font-medium text-gray-500 truncate">{upcomingName}</span>
      </div>
		);
	};

	const returnSteps = () => {
		return (
	    <nav aria-label="Progress">
	      <ol role="list" className="flex space-x-4 sm:space-x-8">
	        {steps.map(step => {
	        	return (
		        	<li key={step.id} className="flex-1 w-0">
		        		{returnStep(step)}
		        	</li>
	        	);
	        })}
	      </ol>
	    </nav>
		);
	};

	const returnEditForm = () => {
		const handleChangeEmail = ({ target: { value: emailNew } }) => {
			setEmail(emailNew);
			const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.[a-zA-Z]{2,63}$/;
			const emailRegexCheck = emailRegex.test(emailNew.trim());
			const differentEmail = emailNew.trim() !== emailDisplay;
			setEmailValid(emailRegexCheck && differentEmail);
			setEmailConfirmed(false);
		};
		const submitEmail = (e) => {
			e.preventDefault();
			setEmailLoading(true);
	    postPatch("PATCH", "/users/contactemail", { user: { contact_email: email.trim() } }).then(
	      json => {
	        console.log(json);
	        setEmailLoading(false);
	        setUpdatedEmail(email);
	        setEdit(false);
	        setEmail(null);
	        setEmailValid(false);
	        setEmailConfirmed(true);
	      },
	      error => {
	      	console.log("23");
	      	console.log(error);
	      	setEmailLoading(false);
	      }
	    );
		};
		if (empty(userEmail)) {
			if (edit) {
				return (
	      	<form className="mt-5 sm:flex sm:items-center">
	          <div className="w-full sm:max-w-xs">
	            <label htmlFor="email" className="sr-only">
	              Email
	            </label>
	            <input
	              type="email"
	              name="email"
	              id="email"
	              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
	              placeholder="you@example.com"
	              onChange={handleChangeEmail}
	              disabled={emailLoading}
	            />
	          </div>
	          <button
	            type="submit"
	            className="mt-3 relative inline-flex w-auto items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:mt-0 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500"
	          	onClick={submitEmail}
	          	disabled={!emailValid || emailLoading}
	          >
	          	{emailLoading && <div className={`absolute border-2 border-indigo-600 border-t-transparent border-solid rounded-full w-4 h-4 animate-spin`} />}
	            <span className={emailLoading ? "invisible" : ""}>Update</span>
	          </button>
	          <button
	            type="button"
	            className="mt-3 inline-flex w-auto items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ml-3 sm:mt-0 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:ring-0"
	          	onClick={() => {
	          		setEdit(false);
	          		setEmail(null);
	          		setEmailValid(false);
	          	}}
	          	disabled={emailLoading}
	          >
	            Cancel
	          </button>
	        </form>
				);
			}
			return (
				<div className="mt-5">
	        <button
	          type="button"
	          className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
	        	onClick={() => {
	        		setEdit(true);
	        		setEmailConfirmed(false);
	        	}}
	        >
	          Edit email
	        </button>
	        {emailConfirmed && <CheckCircleIcon className="inline w-6 h-6 text-green-600 mb-0.5 ml-2" />}
	      </div>
			);
		}
		return null;
	};

	const returnEmailInfo = () => {
		if (!error) {
			return (
		    <div className="bg-gray-50 sm:rounded-lg mt-6 sm:mt-8">
		      <div className="px-4 py-5 sm:p-6">
		      	<div className="flex flex-row justify-start items-center text-gray-900">
		      		<ShieldExclamationIcon className="w-6 h-6 mb-0.5 mr-1.5" />
		        	<h3 className="text-base font-semibold leading-6">Need to save the world?</h3>
		        </div>
		        {emailDisplay ? (
			        <div className="mt-2 text-sm text-gray-500">
			          <p>You can leave. We'll email you at <span className="font-bold">{emailDisplay}</span> {readyText}</p>
			        </div>
		        ) : (
			        <div className="mt-2 text-sm text-gray-500">
			          <p>You can give us your email. We'll email you {readyText}</p>
			        </div>
		       	)}
		       	{returnEditForm()}
		      </div>
		    </div>
			);
		}
		return null;
	};

  const scrollToBottom = () => {
    clearTimeout(scrollTimeout.current);
    scrollTimeout.current = setTimeout(() => {
      if (scrollRef?.current) {
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  };

	const getTrivia = () => {
    setTriviaLoading(true);
		setTrivia(null);
		setResponse(null);
		setCorrect(null);
    get("/users/trivia").then(
      json => {
        console.log(json);
        if (json.status === 204) {
        	setTriviaMessage("No more trivia!")
        } else {
        	setTrivia(json);
        	scrollToBottom();
        }
        setTriviaLoading(false);
        if (!play) {
        	setPlay(true);
        }
      },
      error => {
      	console.log("24");
        console.log(error);
        setTriviaLoading(false);
      }
    );
	};

	const returnTriviaHeader = () => {
		return (
	    <div className="sm:flex sm:items-center sm:justify-between sm:space-x-5">
	      <div className="flex items-start space-x-5">
	        <div className="flex-shrink-0">
	          <div className="relative">
				      <Lottie
				        animationData={trivia_animation} 
				        loop={true} 
				        autoplay={true}
				        className="h-16 w-14 rounded-md overflow-hidden"
				      />
	            <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
	          </div>
	        </div>
	        {/*
	          Use vertical padding to simulate center alignment when both lines of text are one line,
	          but preserve the same layout if the text wraps without making the image jump around.
	        */}
	        <div className="pt-1.5">
	          <h1 className="text-2xl font-bold text-gray-900">Real or Fake?</h1>
	          <p className="text-sm font-medium text-gray-500">
	            {/*Test your BS meter while you wait.*/}
	          	Can you smell BS like a CEO?
	          </p>
	        </div>
	      </div>
	      <div className="mt-6 flex flex-col-reverse sm:mt-0">
	        <PrimaryButton
	        	onClick={getTrivia} 
	        	loading={!play && triviaLoading}
	        	disabled={play}
	        	style="sm:text-base"
	        >
	          Play
	        </PrimaryButton>
	      </div>
	    </div>
		);
	};

	const submitTrivia = (triviumId, response, correct) => {
		setResponse(response);
		setCorrect(correct);
		scrollToBottom();
	  postPatch("POST", "/users/submittrivia", { user_trivium: { loading_session_id: loadingSessionId, trivium_id: triviumId, response, correct } }).then(
	    json => {
	      console.log(json);
	    },
	    error => {
	    	console.log("25");
	      console.log(error);
	    }
	  );
	};

	const returnTriviaContent = () => {
		if (play) {
			if (trivia) {
		    const { id, crazyFact, answer, explanation, category, numberRight, totalAnswers, triviaVisuals } = trivia;
		    let imageUrl, imageUrlMedium;
		    if (triviaVisuals.some(a => a.saved)) {
		      ({ imageUrl, imageUrlMedium } = triviaVisuals.find(a => a.saved));
		    }
				const options = ["Real", "Fake"];
				return (
			    <div ref={scrollRef} className="relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white my-6 sm:my-8 max-w-lg self-center">
			      <div className="relative bg-gray-200 aspect-[7/4] flex justify-center items-center">
			      	<div className="absolute border-2 border-indigo-600 border-t-transparent border-solid rounded-full w-6 h-6 animate-spin z-0" />
			        <img
			          src={imageUrlMedium || imageUrl}
			          alt="trivia"
			          className="relative h-full w-full object-cover object-center z-10"
			          decoding="async"
			        />
				      <span className="absolute top-3 left-3 inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 z-20">
				        {category}
				      </span>
			      </div>
			      <div className="flex flex-1 flex-col space-y-4 p-4">
			        <h3 className="font-semibold text-gray-900">
		            {crazyFact}
			        </h3>
	            <div className="grid grid-flow-row-dense grid-cols-2 gap-3">
	            	{options.map(option => {
	            		const isReal = option === options[0];
	            		const isCorrect = isReal === answer;
	            		const isResponse = isReal === response;
									const rightPercentage = Math.round((numberRight + (correct ? 1 : 0)) * 100 / (totalAnswers + 1));
									const wrongPercentage = Math.round(100 - rightPercentage);
	            		return (
	            			<PrimaryButton
	            				key={option}
	            				size="lg"
	            				style={`w-full h-10 sm:text-base ${isResponse ? (correct ? "disabled:!bg-green-600 disabled:!text-white" : "disabled:!bg-red-600 disabled:!text-white") : ""}`}
	            				disabled={responsePresent}
	            				onClick={() => submitTrivia(id, isReal, isCorrect)}
	            			>
			              	{responsePresent && !correct && !isResponse && <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />}
			                {option}
			                {responsePresent && <span className="font-normal">{isCorrect ? rightPercentage : wrongPercentage}%</span>}
			              </PrimaryButton>
	            		);
	            	})}
	            </div>
			        {responsePresent && <p className="text-gray-900"><span className="font-semibold">{answer ? "Real." : "Fake."}</span> {explanation}</p>}
			        {responsePresent && (
	              <PrimaryButton
	              	size="lg"
	              	style="w-full h-10 sm:text-base"
	              	onClick={getTrivia}
	              >
	                Next
	              </PrimaryButton>
			        )}
			      </div>
			    </div>
				);
			} else if (triviaMessage) {
				return <p className="text-gray-900 font-medium text-center mt-6 sm:my-8">{triviaMessage}</p>;
			}
		}
		return null;
	};

	const returnTrivia = () => {
		if (!error) {
			return (
				<div className="flex flex-col mt-6 sm:mt-8">
					{returnTriviaHeader()}
					{returnTriviaContent()}
			  </div>
			);
		}
		return null;
	};

	const returnError = () => {
		if (error) {
			const { defaultErrorMessage } = steps.find(s => s.status === "current");
			const header = errorMessage?.header || defaultErrorMessage.header;
			const subheader = errorMessage?.subheader || defaultErrorMessage.subheader;
			return (
	      <main className="grid min-h-full place-items-center bg-white px-6 py-12 lg:px-8">
	        <img
	          src="https://images.unsplash.com/photo-1525785967371-87ba44b3e6cf?q=80&w=2973&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
	          alt="cat"
	          className="relative h-full w-96 object-cover object-center rounded-lg"
	        />
	        <div className="text-center">
	          <h1 className="mt-12 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{header}</h1>
	          <p className="mt-6 text-base leading-7 text-gray-600 sm:text-lg">{subheader}</p>
	          <div className="mt-10 flex items-center justify-center gap-x-6">
	            <Link
	              to="/"
	              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
	            >
	              Go back home
	            </Link>
	          </div>
	        </div>
	      </main>
			);
		}
		return null;
	};

	return (
		<div className="flex flex-col">
			{returnSteps()}
			{returnEmailInfo()}
			{returnTrivia()}
			{returnError()}
		</div>
	);
};

export default LoadingShared;
