import { userAgent } from "../styles/styles";
import { postPatch } from "../utils/utils"

export const storeUserAction = (params = {}) => {
  for (const key in params) {
    const newKey = key.replace(/\.?([A-Z]+)/g, '_$1').toLowerCase();
    params[newKey] = params[key];
    delete params[key];
  }
  postPatch("POST", "/users/useraction", { user_action: { ...params, user_agent: userAgent } }).then(
    json => null,
    error => {
      console.log("39");
      console.log(error);
    }
  );
};
