const PrimaryButton = ({ size, dark, rounded, style, loading, disabled, children, onClick }) => {
	const returnButtonSize = () => {
		if (rounded) {
			switch(size) {
				case "xs": return "rounded-full px-2.5 py-1 text-xs";
				case "sm": return "rounded-full px-2.5 py-1 text-sm";
				case "md": return "rounded-full px-3 py-1.5 text-sm";
				case "lg": return "rounded-full px-3.5 py-2 text-sm";
				default: return "rounded-full px-4 py-2.5 text-sm";
			}
		}
		switch(size) {
			case "xs": return "rounded px-2 py-1 text-xs";
			case "sm": return "rounded px-2 py-1 text-sm";
			case "md": return "rounded-md px-2.5 py-1.5 text-sm";
			case "lg": return "rounded-md px-3 py-2 text-sm";
			default: return "rounded-md px-3.5 py-2.5 text-sm";
		}
	};

	const returnColor = () => {
		if (dark) {
			return "bg-indigo-500 hover:bg-indigo-400 focus-visible:outline-indigo-500";
		}
		return "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600";
	};

	const returnSpinnerSize = () => {
		switch(size) {
			case "xs": return "w-3.5 h-3.5";
			case "sm": return "w-3.5 h-3.5";
			case "md": return "w-3.5 h-3.5";
			case "lg": return "w-4 h-4";
			default: return "w-4 h-4";
		}
	};

	return (
		<button
			type="button"
			className={`min-w-min relative flex justify-center items-center ${returnColor()} ${returnButtonSize()} font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 ${style || ""}`}
			onClick={onClick}
			disabled={loading || disabled}
		>
			{loading && <div className={`absolute border-2 border-indigo-600 border-t-transparent border-solid rounded-full ${returnSpinnerSize()} animate-spin`} />}
			<div className={`${loading ? "invisible" : ""} flex flex-row justify-center items-center gap-x-1.5`}>{children}</div>
		</button>
	);
};

export default PrimaryButton;