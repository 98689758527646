import { useContext, useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { TrophyIcon, DocumentCheckIcon, DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import UrlForm from "./UrlForm";
import PrimaryButton from "./PrimaryButton";
import TextButton from "./TextButton";
import save_to_pdf from "./assets/save_to_pdf.png";
import { LoggedInContext } from "./context/context";
import { storeUserAction } from "./services/storeUserAction";
import { classNames, arrayExists, defaultModal } from "./styles/styles";
import { uploadFile } from "./utils/utils";

const tabs = ["LinkedIn", "Resume"];

const UploadBox = ({ handleResponse }) => {
	const { setModal } = useContext(LoggedInContext);
	const [selectedTab, setSelectedTab] = useState(tabs[0]);
	const [urlMessage, setUrlMessage] = useState(null);
	const [fileMessage, setFileMessage] = useState(null);
	const [fileLoading, setFileLoading] = useState(false);
	const [dragging, setDragging] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
  	setFileMessage(null);
  	setDragging(false);
    setSelectedTab(tabs[1]);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
      	// Do whatever you want with the file contents
        // const binaryStr = reader.result;
        // console.log(binaryStr);
        setFileLoading(true);
        const params = { file_name: file.name, file_type: file.type };
        uploadFile("/users/uploadfile", e, params).then(
        	json => {
        		console.log(json);
        		setFileLoading(false);
        		const { path } = json;
        		if (path) {
        			handleResponse(json);
        		} else {
        			setFileMessage("Upload failed: please check file");
        		}
        	},
        	error => {
        		console.log("37");
        		console.log(error);
        	}
        );
      }
    });
    
  }, []);

  const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } = useDropzone({
  	noClick: true,
    multiple: false,
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"]
    },
    maxSize: 10000000,
    onDrop,
    onDragEnter: () => {
    	setFileMessage(null);
    	setDragging(true);
    },
    onDragLeave: () => setDragging(false),
  });

	useEffect(() => {
		if (arrayExists(fileRejections)) {
			console.log(fileRejections);
			const errorMessage = fileRejections[0]["errors"][0]["code"].toLowerCase();
			let errorMessageFormatted;
			switch(errorMessage) {
				case "file-too-large":
					errorMessageFormatted = "File too big: max file size 10mb";
					break;
				case "file-invalid-type":
					errorMessageFormatted = "Invalid file type: pdf, docx only";
					break;
				case "too-many-files":
					errorMessageFormatted = "Too many files: upload one file";
					break;
				default:
					errorMessageFormatted = errorMessage;
					break;
			};
			setFileMessage(errorMessageFormatted);
		}
	}, [fileRejections]);

	const returnHeader = () => {
		return (
			<div className="flex flex-row space-x-2">
				<TrophyIcon className="text-white h-5 w-5 min-w-5 mt-0.5" />
	      <h2 className="text-xl font-medium text-white font-display">
	        Submit credentials here
	      </h2>
      </div>
		);
	};

	const returnTabs = () => {
		return (
			<div className="mt-3 sm:mt-4">
        <nav className="flex space-x-2 sm:space-x-4" aria-label="Tabs">
          {tabs.map((tab) => {
          	const current = tab === selectedTab;
          	return (
	            <button
	              key={tab}
	              onClick={() => {
	              	setSelectedTab(tab);
	              	setUrlMessage(null);
	              	setFileMessage(null);
	              }}
	              className={classNames(
	                current ? "bg-gray-100 text-gray-950" : "text-gray-50 hover:text-gray-300",
	                "rounded-md px-3 py-2 text-sm font-medium cursor-pointer"
	              )}
	              aria-current={current ? "page" : undefined}
	            >
	              {tab}
	            </button>
          	);
          })}
        </nav>
	    </div>
		);
	};

	const returnUploadFile = () => {
		const onClickResumeInfo = () => {
			storeUserAction({ actionType: "view pdf info" });
	    const modal = {
	    	...defaultModal,
	      open: true,
	      Icon: DocumentArrowDownIcon,
	      iconColor: "indigo",
	      header: "How to get PDF from LinkedIn",
	      text: "Go to profile and click More.",
	      src: save_to_pdf,
	      alt: "save-to-pdf",
	      footer: (
	      	<span>
	      		See more:{' '}
	      		<a
	      			className="hover:opacity-75"
	      			href="https://www.linkedin.com/help/linkedin/answer/a541960"
              target="_blank" 
              rel="noopener noreferrer"
	      		>
	      			https://www.linkedin.com/help/linkedin/answer/a541960
	      		</a>
	      	</span>
	      ),
	    };
	    setModal(modal);
		};

		const onClickUpload = () => {
			setFileMessage(null);
			open();
		};

		return (
			<div className="flex flex-col items-start">
				<div className="mt-4 sm:mt-5">
					<TextButton dark small medium onClick={onClickResumeInfo}>
						How do I get a PDF of LinkedIn profile?
					</TextButton>
				</div>
				<div className="mt-4 sm:mt-5 flex flex-row items-center">
		      <PrimaryButton
		      	dark
		      	loading={fileLoading}
		      	onClick={onClickUpload}
		      >
		        Upload file
		      </PrimaryButton>
		      <p className="text-sm text-gray-400 pl-2.5 hidden sm:block">or drag and drop anywhere in the box</p>
	      </div>
	      {fileMessage && <p className="mt-3 text-sm text-red-500 font-medium">{fileMessage}</p>}
        <div className="mt-4 sm:mt-5 flex flex-row space-x-1.5 text-gray-400">
        	<DocumentCheckIcon className="h-5 w-5 min-w-5 mt-0.5" />
	        <p className="text-sm leading-6">pdf, docx up to 10mb</p>
        </div>
			</div>
		);
	};

	const returnForm = () => {
		if (selectedTab === tabs[0]) {
			return (
				<UrlForm
					urlMessage={urlMessage}
					setUrlMessage={setUrlMessage}
					handleResponse={handleResponse}
					tabs={tabs}
					setSelectedTab={setSelectedTab}
					setFileMessage={setFileMessage}
				/>
			);
		}
		return returnUploadFile();
	};

	const returnGradient = () => {
		return (
	    <svg
	      viewBox="0 0 1024 1024"
	      className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
	      aria-hidden="true"
	    >
	      <circle cx={512} cy={512} r={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
	      <defs>
	        <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
	          <stop stopColor="#7775D6" />
	          <stop offset={1} stopColor="#E935C1" />
	        </radialGradient>
	      </defs>
	    </svg>
		);
	};

	return (
	  <div {...getRootProps()} className={`relative isolate overflow-hidden rounded-2xl ${dragging ? "ring-8 ring-inset ring-indigo-500" : ""} bg-neutral-950 px-4 py-5 sm:p-6`}>
	  	<input {...getInputProps()} />
	  	{returnHeader()}
	  	{returnTabs()}
	  	{returnForm()}
	  	{/*returnGradient()*/}
	  </div>
	);
};

export default UploadBox;
