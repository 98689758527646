import { classNames } from "./styles/styles";

const Tabs = ({ tabs, selectedTab, setSelectedTab, disabled, noDropdown, pillStyle, shareImage }) => {
  const returnDropdown = () => {
    if (!noDropdown) {
      <div className="sm:hidden pt-4 pb-2">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={selectedTab}
          onChange={e => setSelectedTab(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab}>{tab}</option>
          ))}
        </select>
      </div>
    }
    return null;
  };

  const returnButtonClassNames = (current) => {
    if (pillStyle) {
      return classNames(
        shareImage ? (current ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800') : (current ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700'),
        'rounded-md px-3 py-2 text-sm font-medium'
      );
    }
    return classNames(
      current
        ? "border-indigo-500 text-indigo-600"
        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
      "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
    );
  };

  const returnTabs = () => {
    return tabs.map((tab) => {
      const current = tab === selectedTab;
      return (
        <button
          key={tab}
          onClick={() => setSelectedTab(tab)}
          className={returnButtonClassNames(current)}
          aria-current={current ? "page" : undefined}
          disabled={current || disabled}
        >
          {tab}
        </button>
      );
    });
  };

  if (pillStyle) {
    return (
      <div className="relative">
        {returnDropdown()}
        <div className={shareImage ? "block" : "block pt-3"}>
          <nav className="flex space-x-4" aria-label="Tabs">
            {returnTabs()}
          </nav>
        </div>
      </div>
    ); 
  }
	return (
    <div className="relative">
      {returnDropdown()}
      <div className={noDropdown ? "block pb-3" : "hidden sm:block pb-3"}>
        <div className={"border-b border-gray-200"}>
          <nav className={"-mb-px flex space-x-8"} aria-label="Tabs">
            {returnTabs()}
          </nav>
        </div>
      </div>
    </div>
	);
};

export default Tabs;
