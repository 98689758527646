import { useState, useEffect, useRef } from "react";
import { Stage, Layer, Rect, Text, Group } from "react-konva";
import Konva from "konva";
import { arrayExists } from "./styles/styles";

Konva.pixelRatio = Math.min(2, window.devicePixelRatio);

const ImageTwo = ({ awards, headerIndex, headerOne, textOne, headerTwo, spacing, stageRef, updateImage, textOneRef, textOneWidth, textOneHeight, setTextOneWidth, setTextOneHeight }) => {
	const [awardHeights, setAwardHeights] = useState([]);
  const awardRefs = useRef([]);
	const captionPadding = 30;
	const awardPadding = 20;

  useEffect(() => {
  	updateDimensions();
  	updateImage();
  }, [awards, headerIndex]);

  useEffect(() => {
  	setTimeout(() => {
	  	if (textOneRef?.current && textOneWidth) {
	  		const newHeight = Math.ceil(textOneRef.current.height());
	  		setTextOneHeight(Math.min(newHeight, 90 * 1.2 + 100));
	  	}
  	}, 0);
  }, [textOneWidth]);

  const updateDimensions = () => {  	
  	setTimeout(() => {
	  	if (textOneRef?.current) {
	  		const newWidth = Math.ceil(textOneRef.current.width());
	  		setTextOneWidth(Math.min(newWidth, 1020));
	  	}

	  	if (arrayExists(awardRefs.current)) {
	  		let newHeights = [];
	  		awardRefs.current.forEach(awardRef => {
		  		const newHeight = Math.ceil(awardRef.height());
		  		newHeights.push(Math.min(newHeight, 35 * 1.2 * 3 + 100));
	  		});
	  		setAwardHeights(newHeights);
	  	}
  	}, 0);
  };

	return (
    <Stage ref={stageRef} width={1080} height={1920} className="hidden" listening={false}>
      <Layer listening={false}>
	      {headerOne && <Text
	        // preventDefault={false}
	        text={headerOne}
	        fontFamily="Mona Sans"
	        fontSize={50}
	        fontStyle="bold"
	        lineHeight={1}
	        fill="white"
	        padding={0}
	        wrap="word"
	        offsetY={-250}
	        width={1080}
	        align="center"
	        listening={false}
	      />}
	      {textOne && (
	      	<Group
	      		offsetX={textOneWidth ? (-1080 + textOneWidth) / 2 : null}
	      		offsetY={-250 - spacing}
	      		listening={false}
	      	>
	      		<Rect
		          x={50 - captionPadding}
		          y={50 - captionPadding}
		          width={textOneWidth ? textOneWidth - 100 + captionPadding * 2 : null}
		          height={textOneHeight ? textOneHeight - 100 + captionPadding * 2 : null}
		          fill="black"
		          opacity={0.5}
		          cornerRadius={20}
		          listening={false}
	      		/>
						<Text
			      	ref={textOneRef}
			        // preventDefault={false}
			        text={textOne}
			        fontFamily="Mona Sans"
			        fontSize={45}
			        lineHeight={1.2}
			        fill="white"
			        padding={50}
			        wrap="word"
			        width={textOneWidth}
			        height={textOneHeight}
			        align="center"
			        verticalAlign="middle"
			        listening={false}
			      />
	      	</Group>
	      )}
	      {headerTwo && <Text
	        // preventDefault={false}
	        text={headerTwo}
	        fontFamily="Mona Sans"
	        fontSize={50}
	        fontStyle="bold"
	        lineHeight={1}
	        fill="white"
	        padding={0}
	        wrap="word"
	        offsetY={-250 - (headerOne && textOne ? (50 + spacing + (textOneHeight - 100) + spacing) : 150)}
	        width={1080}
	        align="center"
	        listening={false}
	      />}
	      {awards.map(({ id, emoji, title }, index) => {
	      	const offset = awardHeights.slice(index < 5 ? 0 : 5, index).reduce((acc, curr) => acc + curr, 0);
	      	return (
	      		<Group
	      			key={id}
					    offsetX={index < 5 ? (awardPadding - 50) / 3 : (-540 - (awardPadding - 50) / 3)}
					    offsetY={-250 - 50 - spacing - (headerOne && textOne ? (50 + spacing + (textOneHeight - 100) + spacing) : 150) + 50 - offset}
					    listening={false}
	      		>
	      			<Rect
			          x={50 - awardPadding}
			          y={50 - awardPadding}
			          width={540 - 100 + awardPadding * 2}
			          height={awardHeights[index] ? awardHeights[index] - 100 + awardPadding * 2 : null}
			          fill="black"
			          opacity={0.5}
			          cornerRadius={20}
			          listening={false}
	      			/>
				      <Text
				      	ref={el => awardRefs.current[index] = el}
				        // preventDefault={false}
				        text={`${emoji} ${title}`}
				        fontFamily="Mona Sans"
				        fontSize={35}
				        lineHeight={1.2}
				        fill="white"
				        padding={50}
				        wrap="word"
				        width={540}
				        height={awardHeights[index]}
				        align="center"
				        verticalAlign="middle"
				        listening={false}
				      />
				    </Group>
	      	);
	      })}
	      {/*<Text
	        // preventDefault={false}
	        text="ceolist.org"
	        fontFamily="Mona Sans"
	        fontSize={35}
	        fontStyle="bold"
	        lineHeight={1}
	        fill="white"
	        padding={0}
	        wrap="word"
	        offsetY={-1920 + 35 + 70}
	        width={1080}
	        align="center"
	        listening={false}
	      />*/}
      </Layer>
    </Stage>
	);
};

export default ImageTwo;
