import { Stage, Layer, Rect, Text, Circle } from "react-konva";
import Konva from "konva";
import { FaAward } from "react-icons/fa6";
import HeroIconImage from "./HeroIconImage";
import UserProfileCanvas from "./UserProfileCanvas";
import LogoCanvas from "./LogoCanvas";
import { canvasColors, calculateLines, truncateText } from "./styles/styles";

Konva.pixelRatio = Math.min(2, window.devicePixelRatio);

const AwardCreate = ({ stageRef, linkedinUrl, emoji, title, description, randomColor, statusRef }) => {
  const { bgColor, iconColor } = canvasColors.find(({ color }) => color === randomColor) ? canvasColors.find(({ color }) => color === randomColor) : {};
  const multiplier = 2;
  const canvasWidth = 600 * multiplier;
  const canvasHeight = 315 * multiplier;

  const logoHeight = 30 * multiplier;
  const logoOffset = 15 * multiplier;

  const titleSize = 25 * multiplier;
  const titleOffsetHorizontal = -35 * multiplier;
  const titleWidth = canvasWidth + (titleOffsetHorizontal * 2);
  const maxLinesTitle = 2;
  const titleHeight = titleSize * 1.2 * Math.min(calculateLines(title, titleWidth, titleSize, 600), maxLinesTitle);
  const titlePaddingTop = 15 * multiplier;

  const descriptionSize = 20 * multiplier;
  const descriptionOffsetHorizontal = titleOffsetHorizontal;
  const descriptionWidth = canvasWidth + (descriptionOffsetHorizontal * 2);
  const maxLinesDescription = 3;
  const descriptionHeight = descriptionSize * 1.2 * Math.min(calculateLines(description, descriptionWidth, descriptionSize, "normal"), maxLinesDescription);
  const descriptionPaddingTop = 10 * multiplier;

  const iconSize = 75 * multiplier;
  const iconOffsetHorizontal = (canvasWidth - iconSize) / 2;
  const contentHeight = iconSize + titlePaddingTop + titleHeight + descriptionPaddingTop + descriptionHeight;
  const iconOffsetVertical = (canvasHeight - logoOffset - logoHeight - contentHeight) / 2;

  const circleRadius = 20 * multiplier;
  const circleOffsetHorizontal = canvasWidth / 2
  const circleOffsetVertical = iconOffsetVertical + (28 * multiplier);

  const emojiSize = 35 * multiplier;
  const emojiOffsetHorizontal = 0;
  const emojiOffsetVertical = -iconOffsetVertical - (12 * multiplier);

  const titleColor = "#111827"; // text-gray-900
  const titleOffsetVertical = -iconOffsetVertical - iconSize - titlePaddingTop;

  const descriptionColor = "#4b5563"; // text-gray-600
  const descriptionOffsetVertical = titleOffsetVertical - titleHeight - descriptionPaddingTop;

  const profileImageDimension = 40 * multiplier;
  const profileOffset = logoOffset + ((logoHeight - profileImageDimension) / 2);
  const profileImageOffsetVertical = canvasHeight - profileImageDimension - profileOffset;
  const profileImageOffsetHorizontal = profileOffset;

  const nameSize = 14 * multiplier;
  const namePaddingLeft = 7 * multiplier;
  const nameVerticalAdjustment = 1.5 * multiplier;
  const nameOffsetHorizontal = -profileOffset - profileImageDimension - namePaddingLeft;
  const nameOffsetVertical = -profileImageOffsetVertical - (profileImageDimension / 2) + nameSize + nameVerticalAdjustment;
  const nameWidth = (canvasWidth / 2) - profileOffset - profileImageDimension - namePaddingLeft;

  const headlineSize = nameSize;
  const headlineOffsetHorizontal = nameOffsetHorizontal;
  const headlineOffsetVertical = -profileImageOffsetVertical - (profileImageDimension / 2) - nameVerticalAdjustment;
  const headlineWidth = nameWidth;


	return (
	  <Stage ref={stageRef} width={canvasWidth} height={canvasHeight} className="hidden" /*className="absolute z-50 left-full"*/ listening={false}>
	    <Layer listening={false}>
        <Rect
        	// preventDefault={false}
          x={0}
          y={0}
          width={canvasWidth}
          height={canvasHeight}
          fill={bgColor}
          // stroke="#e5e7eb"
          // strokeWidth={1}
          // cornerRadius={5}
          listening={false}
        />
        <HeroIconImage 
          // preventDefault={false}
          icon={FaAward} 
          color={iconColor}
          size={iconSize}
          x={iconOffsetHorizontal} 
          y={iconOffsetVertical} 
        />
        <Circle
        	// preventDefault={false}
        	x={circleOffsetHorizontal}
        	y={circleOffsetVertical}
        	radius={circleRadius}
        	fill={iconColor}
        	listening={false}
        />
	      <Text
	        // preventDefault={false}
	        text={emoji}
	        fontFamily="Mona Sans"
	        fontSize={emojiSize}
	        fontStyle="normal"
	        lineHeight={1}
	        fill="black"
	        padding={0}
	        wrap="none"
	        offsetX={emojiOffsetHorizontal}
	        offsetY={emojiOffsetVertical}
	        align="center"
	        width={canvasWidth}
	        listening={false}
	      />
	      <Text
	        // preventDefault={false}
	        text={truncateText(title, titleWidth, titleSize, 600, maxLinesTitle)}
	        fontFamily="Mona Sans"
	        fontSize={titleSize}
	        fontStyle="600"
	        lineHeight={1.2}
	        fill={titleColor}
	        padding={0}
	        wrap="word"
	        offsetX={titleOffsetHorizontal}
	        offsetY={titleOffsetVertical}
	        align="center"
	        width={titleWidth}
	        listening={false}
	      />
	      <Text
	        // preventDefault={false}
	        text={truncateText(description, descriptionWidth, descriptionSize, "normal", maxLinesDescription)}
	        fontFamily="Mona Sans"
	        fontSize={descriptionSize}
	        fontStyle="normal"
	        lineHeight={1.2}
	        fill={descriptionColor}
	        padding={0}
	        wrap="word"
	        offsetX={descriptionOffsetHorizontal}
	        offsetY={descriptionOffsetVertical}
	        align="center"
	        width={descriptionWidth}
	        listening={false}
	      />
	      <UserProfileCanvas
	      	user={linkedinUrl}
	      	statusRef={statusRef}
	      	profileImageDimension={profileImageDimension}
	      	profileImageOffsetHorizontal={profileImageOffsetHorizontal}
	      	profileImageOffsetVertical={profileImageOffsetVertical}
	      	nameSize={nameSize}
	      	nameOffsetHorizontal={nameOffsetHorizontal}
	      	nameOffsetVertical={nameOffsetVertical}
	      	nameWidth={nameWidth}
	      	headlineSize={headlineSize}
	      	headlineOffsetHorizontal={headlineOffsetHorizontal}
	      	headlineOffsetVertical={headlineOffsetVertical}
	      	headlineWidth={headlineWidth}
	      />
	      <LogoCanvas
	      	logoHeight={logoHeight} 
	      	logoOffset={logoOffset}
	      	canvasWidth={canvasWidth} 
	      	canvasHeight={canvasHeight} 
	      />
	    </Layer>
	  </Stage>
	)
};

export default AwardCreate;
