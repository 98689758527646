import { Fragment } from "react";
import { Stage, Layer, Rect, Text, Line } from "react-konva";
import Konva from "konva";
import UserProfileCanvas from "./UserProfileCanvas";
import LogoCanvas from "./LogoCanvas";
import { canvasColors, calculateLines, truncateText } from "./styles/styles";

Konva.pixelRatio = Math.min(2, window.devicePixelRatio);

const AuraCreate = ({ stageRef, linkedinUrl, sectionType, header, emoji, content, bold, boldKey, textKey, randomColor, statusRef }) => {
  const { bgColor, headerColor } = canvasColors.find(({ color }) => color === randomColor) ? canvasColors.find(({ color }) => color === randomColor) : {};
  const multiplier = 2;
  const canvasWidth = 600 * multiplier;
  const canvasHeight = 315 * multiplier;

  const profileImageDimension = 50 * multiplier;
  const userInfoOffsetHorizontal = 200 * multiplier;
  const profileImageOffsetHorizontal = canvasWidth - profileImageDimension - userInfoOffsetHorizontal;
  const profileImageOffsetVertical = 16 * multiplier;

  const headerSize = 20 * multiplier;
  const headerOffset = -20 * multiplier;
  const headerOffsetOneLine = -profileImageOffsetVertical - (profileImageDimension / 2) + (headerSize / 2) + (1 * multiplier);
  const headerWidthAdjustment = 50 * multiplier;
  const headerWidth = (canvasWidth / 2) + (headerOffset * 2) + headerWidthAdjustment;

  const nameSize = 16 * multiplier;
  const namePaddingLeft = 10 * multiplier;
  const nameOffsetHorizontal = -canvasWidth + userInfoOffsetHorizontal - namePaddingLeft;
  const nameOffsetVertical = -profileImageOffsetVertical - (profileImageDimension / 2) + nameSize + (2 * multiplier);
  const nameWidth = canvasWidth / 2 - headerWidthAdjustment - profileImageDimension - namePaddingLeft + headerOffset;

  const headlineSize = nameSize;
  const headlineOffsetHorizontal = nameOffsetHorizontal;
  const headlineOffsetVertical = -profileImageOffsetVertical - (profileImageDimension / 2) - (2 * multiplier);
  const headlineWidth = nameWidth;

  const lineOffsetHorizontal = -headerOffset;
  const lineOffsetVertical = profileImageOffsetVertical * 2 + profileImageDimension;

  const contentWidth = canvasWidth + (headerOffset * 2);

  const boldTextSize = 16 * multiplier;
  const boldTextSizeBig = 18 * multiplier;
  const boldTextColor = "#111827"; // text-gray-900
  const boldTextOffsetHorizontal = headerOffset;

  const contentTextSize = 16 * multiplier;
  const contentTextSizeBig = 18 * multiplier;
  const contentTextColor = "#4b5563"; // text-gray-600
  const contentTextOffsetHorizontal = headerOffset;

	const contentTextPaddingTop = 8 * multiplier;
	const sectionPaddingTop = 15 * multiplier;
	const sectionPaddingTopBig = 18 * multiplier;

	const logoHeight = 30 * multiplier;
	const logoOffset = 15 * multiplier;

  const returnHeader = () => {
  	const headerText = `LinkedIn Aura: ${header} ${emoji}`;
  	const numLinesHeader = calculateLines(headerText, headerWidth, headerSize, "bold");
  	return (
  		<>
	      <Text
	        // preventDefault={false}
	        text={truncateText(headerText, headerWidth, headerSize, "bold", 2)}
	        fontFamily="Mona Sans"
	        fontSize={headerSize}
	        fontStyle="bold"
	        lineHeight={1.2}
	        fill={headerColor}
	        padding={0}
	        wrap="word"
	        offsetX={headerOffset}
	        offsetY={numLinesHeader === 1 ? headerOffsetOneLine : headerOffset}
	        align="left"
	        width={headerWidth}
	        listening={false}
	      />
	      <UserProfileCanvas
	      	user={linkedinUrl}
	      	statusRef={statusRef}
	      	profileImageDimension={profileImageDimension}
	      	profileImageOffsetHorizontal={profileImageOffsetHorizontal}
	      	profileImageOffsetVertical={profileImageOffsetVertical}
	      	nameSize={nameSize}
	      	nameOffsetHorizontal={nameOffsetHorizontal}
	      	nameOffsetVertical={nameOffsetVertical}
	      	nameWidth={nameWidth}
	      	headlineSize={headlineSize}
	      	headlineOffsetHorizontal={headlineOffsetHorizontal}
	      	headlineOffsetVertical={headlineOffsetVertical}
	      	headlineWidth={headlineWidth}
	      />
	      {/*<Image
	        // preventDefault={false}
	      	image={profileImage || defaultImage}
	        x={profileImageOffsetHorizontal}
	        y={profileImageOffsetVertical}
	        width={profileImageDimension}
	        height={profileImageDimension}
	        cornerRadius={profileImageDimension / 2}
	        listening={false}
	      />
	      <Text
	        // preventDefault={false}
	        text={fullName}
	        fontFamily="Mona Sans"
	        fontSize={nameSize}
	        fontStyle="bold"
	        lineHeight={1}
	        fill={nameColor}
	        padding={0}
	        wrap="none"
	        offsetX={nameOffsetHorizontal}
	        offsetY={nameOffsetVertical}
	        align="left"
	        width={nameWidth}
	        ellipsis={true}
	        listening={false}
	      />
	      <Text
	        // preventDefault={false}
	        text={headline}
	        fontFamily="Mona Sans"
	        fontSize={headlineSize}
	        fontStyle="normal"
	        lineHeight={1}
	        fill={headlineColor}
	        padding={0}
	        wrap="none"
	        offsetX={headlineOffsetHoriontal}
	        offsetY={headlineOffsetVertical}
	        align="left"
	        width={headlineWidth}
	        ellipsis={true}
	        listening={false}
	      />*/}
	    </>
  	);
  };

  const returnContent = () => {
  	switch(sectionType) {
	  	case "string":
	  		if (bold) {
					const boldTextOffsetVertical = -lineOffsetVertical - profileImageOffsetVertical;
					const contentTextOffsetVertical = boldTextOffsetVertical - boldTextSizeBig - contentTextPaddingTop;
					return (
						<Fragment>
				      <Text
				        // preventDefault={false}
				        text={content[boldKey]}
				        fontFamily="Mona Sans"
				        fontSize={boldTextSizeBig}
				        fontStyle="bold"
				        lineHeight={1}
				        fill={boldTextColor}
				        padding={0}
				        wrap="none"
				        offsetX={boldTextOffsetHorizontal}
				        offsetY={boldTextOffsetVertical}
				        align="left"
				        width={contentWidth}
				        ellipsis={true}
				        listening={false}
				      />
				      <Text
				        // preventDefault={false}
				        text={truncateText(content[textKey], contentWidth, contentTextSizeBig, "normal", 5)}
				        fontFamily="Mona Sans"
				        fontSize={contentTextSizeBig}
				        fontStyle="normal"
				        lineHeight={1.5}
				        fill={contentTextColor}
				        padding={0}
				        wrap="word"
				        offsetX={contentTextOffsetHorizontal}
				        offsetY={contentTextOffsetVertical}
				        align="left"
				        width={contentWidth}
				        listening={false}
				      />
				    </Fragment>
					);
	  		}
	  		const contentTextOffsetVertical = -lineOffsetVertical - profileImageOffsetVertical;
	  		return (
		      <Text
		        // preventDefault={false}
		        text={truncateText(content, contentWidth, contentTextSizeBig, "normal", 5)}
		        fontFamily="Mona Sans"
		        fontSize={contentTextSizeBig}
		        fontStyle="normal"
		        lineHeight={1.5}
		        fill={contentTextColor}
		        padding={0}
		        wrap="word"
		        offsetX={contentTextOffsetHorizontal}
		        offsetY={contentTextOffsetVertical}
		        align="left"
		        width={contentWidth}
		        listening={false}
		      />
	  		);
	  	case "array":
	  		if (bold) {
	  			return (
	  				<>
	  					{content.slice(0, 3).map(({ [boldKey]: boldText, [textKey]: text }, index) => {
			  				const sectionAdjustment = index * (boldTextSize + contentTextPaddingTop + contentTextSize + sectionPaddingTopBig);
			  				const boldTextOffsetVertical = -lineOffsetVertical - profileImageOffsetVertical - sectionAdjustment;
			  				const contentTextOffsetVertical = boldTextOffsetVertical - boldTextSize - contentTextPaddingTop;
			  				return (
			  					<Fragment key={index}>
							      <Text
							        // preventDefault={false}
							        text={boldText}
							        fontFamily="Mona Sans"
							        fontSize={boldTextSize}
							        fontStyle="bold"
							        lineHeight={1}
							        fill={boldTextColor}
							        padding={0}
							        wrap="none"
							        offsetX={boldTextOffsetHorizontal}
							        offsetY={boldTextOffsetVertical}
							        align="left"
							        width={contentWidth}
							        ellipsis={true}
							        listening={false}
							      />
							      <Text
							        // preventDefault={false}
							        text={text}
							        fontFamily="Mona Sans"
							        fontSize={contentTextSize}
							        fontStyle="normal"
							        lineHeight={1}
							        fill={contentTextColor}
							        padding={0}
							        wrap="none"
							        offsetX={contentTextOffsetHorizontal}
							        offsetY={contentTextOffsetVertical}
							        align="left"
							        width={contentWidth}
							        ellipsis={true}
							        listening={false}
							      />
							    </Fragment>
			  				);
			  			})}
			  			{content && content.length > 3 && (
					      <Text
					        // preventDefault={false}
					        text={`+${content.length - 3} more`}
					        fontFamily="Mona Sans"
					        fontSize={boldTextSize}
					        fontStyle="bold"
					        lineHeight={1}
					        fill={boldTextColor}
					        padding={0}
					        wrap="none"
					        offsetX={boldTextOffsetHorizontal}
					        offsetY={-lineOffsetVertical - profileImageOffsetVertical - 3 * (boldTextSize + contentTextPaddingTop + contentTextSize + sectionPaddingTopBig)}
					        align="left"
					        width={contentWidth}
					        ellipsis={true}
					        listening={false}
					      />
			  			)}
	  				</>
	  			);
	  		}
	  		const numLinesContent = content.slice(0, 3).map(c => calculateLines(c, contentWidth, contentTextSizeBig, "normal"));
	  		return (
	  			<>
		  			{content.slice(0, 3).map((c, index) => {
		  				const sectionAdjustment = (contentTextSizeBig * numLinesContent.slice(0, index).reduce((acc, curr) => acc + curr, 0) * 1.2) + (index * sectionPaddingTop);
		  				const contentTextOffsetVertical = -lineOffsetVertical - profileImageOffsetVertical - sectionAdjustment;
		  				return (
					      <Text
					      	key={index}
					        // preventDefault={false}
					        text={truncateText(c, contentWidth, contentTextSizeBig, "normal", 2)}
					        fontFamily="Mona Sans"
					        fontSize={contentTextSizeBig}
					        fontStyle="normal"
					        lineHeight={1.2}
					        fill={contentTextColor}
					        padding={0}
					        wrap="word"
					        offsetX={contentTextOffsetHorizontal}
					        offsetY={contentTextOffsetVertical}
					        align="left"
					        width={contentWidth}
					        listening={false}
					      />
		  				);
		  			})}
		  			{content && content.length > 3 && (
				      <Text
				        // preventDefault={false}
				        text={`+${content.length - 3} more`}
				        fontFamily="Mona Sans"
				        fontSize={contentTextSizeBig}
				        fontStyle="bold"
				        lineHeight={1}
				        fill={contentTextColor}
				        padding={0}
				        wrap="none"
				        offsetX={contentTextOffsetHorizontal}
				        offsetY={-lineOffsetVertical - profileImageOffsetVertical - (contentTextSizeBig * numLinesContent.slice(0, 3).reduce((acc, curr) => acc + curr, 0) * 1.2) - (3 * sectionPaddingTop)}
				        align="left"
				        width={contentWidth}
				        ellipsis={true}
				        listening={false}
				      />
		  			)}
	  			</>
	  		);
	  	default: return;
  	}
  };

	return (
	  <Stage ref={stageRef} width={canvasWidth} height={canvasHeight} className="hidden" /*className="absolute z-50 left-full"*/ listening={false}>
	    <Layer listening={false}>
        <Rect
        	// preventDefault={false}
          x={0}
          y={0}
          width={canvasWidth}
          height={canvasHeight}
          fill={bgColor}
          // stroke="#e5e7eb"
          // strokeWidth={1}
          // cornerRadius={5}
          listening={false}
        />
        {returnHeader()}
	      <Line
	      	preventDefault={false}
	        stroke="#e5e7eb"
	        strokeWidth={1}
	        points={[lineOffsetHorizontal,lineOffsetVertical,canvasWidth - lineOffsetHorizontal,lineOffsetVertical]}
	        x={0}
	        y={0}
	        listening={false}
	      />
	      {returnContent()}
	      <LogoCanvas
	      	logoHeight={logoHeight} 
	      	logoOffset={logoOffset}
	      	canvasWidth={canvasWidth} 
	      	canvasHeight={canvasHeight} 
	      />
	    </Layer>
	  </Stage>
	)
};

export default AuraCreate;
