import { useContext, useState, useEffect, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import useWindowDimensions from "./hooks/useWindowDimensions";
import Tabs from "./Tabs";
import ImageOne from "./ImageOne";
import ImageTwo from "./ImageTwo";
import SecondaryButton from "./SecondaryButton";
import Loading from "./Loading";
import ErrorShared from "./ErrorShared";
import pinterest from "./assets/pinterest.mp4";
import { LoggedInContext } from "./context/context";
import { storeUserAction } from "./services/storeUserAction";
import { isMobile, userAgent, arrayExists } from "./styles/styles";
import { get } from "./utils/utils";

const tabs = ["style 1", "style 2"];
const headers = [
	{
		headerOne: "how linkedin sees me",
		textOne: "headline",
		headerTwo: "what i actually do"
	},
	{
		headerOne: "how linkedin sees me",
		textOne: "employee #567",
		headerTwo: "what i actually do"
	},
	{
		headerOne: "guess my job",
		textOne: "???",
		headerTwo: "skills"
	},
	{
		headerOne: "where should i work",
		textOne: "???",
		headerTwo: "skills"
	},
	{
		headerOne: "whos hiring",
		textOne: "???",
		headerTwo: "skills"
	},
	{
		headerTwo: "what should i pick"
	},
	{
		headerTwo: "ceo of [blank]"
	}
];
const defaultMessage = {
	header: "Something went wrong",
	subheader: "We couldn't find your results."
};

const ShareImage = () => {
	const { userId } = useContext(LoggedInContext);
	const { height: innerHeight } = useWindowDimensions();
	const [selectedTab, setSelectedTab] = useState(null);
	const [awards, setAwards] = useState(null);
	const [id, setId] = useState(null);
	const [headline, setHeadline] = useState(null);
  const [message, setMessage] = useState(null);
	const [src, setSrc] = useState(null);
	const [headerIndex, setHeaderIndex] = useState(0);
	const [disabled, setDisabled] = useState(true);
	const [textOneWidth, setTextOneWidth] = useState(null);
	const [textOneHeight, setTextOneHeight] = useState(null);
  const stageRef = useRef(null);
  const textOneRef = useRef(null);
  const { linkedinUrlId } = useParams();
  const sizeRatio = useMemo(() => {
  	return (innerHeight - 30 - 36 - 30 - (isMobile ? 2 : 4) - 30 - (isMobile ? 19 : 50) - 30) / 1920;
  }, []);

	useEffect(() => {
		if (userId) {
			if (linkedinUrlId) {
		    get(`/users/image?id=${linkedinUrlId}&user_agent=${encodeURIComponent(userAgent)}`).then(
		      json => {
		      	console.log(json);
		      	const { message, awards, id, headline } = json;
		      	if (message) {
		      		setMessage(message);
		      	} else {
				  		setAwards(awards);
				  		setId(id);
				  		setHeadline(headline);
		      	}
		      },
		      error => {
		      	console.log("36");
		      	console.log(error);
		      	setDefaultErrorMessage();
		      }
		    );
			} else {
				setDefaultErrorMessage();
			}
		}
	}, [userId, linkedinUrlId]);

	useEffect(() => {
		if (arrayExists(awards)) {
			document.fonts.load("10pt Mona Sans").then(() => {
			  console.log('Font is available');
			  // Now that the font is available, re-render the canvas or the component that uses it.
			  setSelectedTab(tabs[0]);
			}).catch(error => {
			  console.error('Font loading failed:', error);
			  setSelectedTab(tabs[0]);
			});
		}
	}, [awards]);

  const updateImage = () => {
  	if (arrayExists(awards)) {
  		setTimeout(() => {
				const callback = (img) => {
					setSrc(img.src);
					setDisabled(false);
				};
				stageRef.current.toImage({ callback });
  		}, 500);
  	}
  };

  const setDefaultErrorMessage = () => {
    setMessage(defaultMessage);
  };

	if (message) {
    return <ErrorShared message={message} />;
  } else if (arrayExists(awards)) {
		const onClickImage = () => {
			setDisabled(true);
			setHeaderIndex(headerIndex === headers.length - 1 ? 0 : headerIndex + 1);
			setTextOneWidth(null);
			setTextOneHeight(null);
		};

		const onChangeTab = (tab) => {
			setDisabled(true);
      stageRef?.current?.destroy();
			setSelectedTab(tab);
			setTextOneWidth(null);
			setTextOneHeight(null);
		};

		const downloadImage = () => {
			storeUserAction({ linkedinUrlId: id, actionType: "download image" });
	    const link = document.createElement('a');
	    link.href = stageRef.current.toDataURL();
	    link.download = "image.png";
	    document.body.appendChild(link);
	    link.click();
	    document.body.removeChild(link);
		};

		const returnAction = () => {
			if (src) {
				if (isMobile) {
					return (
            <p className="text-gray-900 text-center font-medium">
              Hold press on the image to save ☝️
            </p>
					);
				}
				return (
          <SecondaryButton style="sm:text-base" onClick={downloadImage}>
            Download
          </SecondaryButton>
				);
			}
		};

  	const returnCanvas = () => {
		  const { headerOne, textOne, headerTwo } = headers[headerIndex];
			const returnTextOne = () => {
				switch(textOne) {
				case "headline":
					return headline;
				default:
					return textOne;
				}
			};
  		const props = { 
  			awards, 
  			headerIndex, 
  			headerOne, 
  			textOne: returnTextOne(), 
  			headerTwo, 
  			spacing: 100, 
  			stageRef, 
  			updateImage, 
  			textOneRef, 
  			textOneWidth,
  			textOneHeight, 
  			setTextOneWidth,
  			setTextOneHeight 
  		};
  		switch(selectedTab) {
	  		case tabs[0]: return <ImageOne {...props} />;
	  		case tabs[1]: return <ImageTwo {...props} />;
	  		default: return;
  		}
  	};

		return (
			<div className="flex flex-col justify-start items-center p-8 gap-y-8 bg-gray-100 min-h-screen">
				{!src && <Loading inline inlineStyle="absolute top-0 left-0 w-full h-full bg-transparent" />}
				{src && <Tabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={onChangeTab} disabled={disabled} noDropdown pillStyle shareImage />}
				{src && (
					<button className="relative sm:hover:opacity-75" onClick={onClickImage} disabled={disabled}>
						{/*<img
							src="https://i.pinimg.com/originals/58/07/ff/5807ffa57cd8c7f69d492fefa7c51d25.jpg"
							alt="story"
							width={1080 * sizeRatio}
							height={1920 * sizeRatio}
							className="absolute top-0 left-0 object-cover h-full rounded-lg shadow-lg"
						/>*/}
	          <video 
	          	src={pinterest}
	            alt="story"
							width={1080 * sizeRatio}
							height={1920 * sizeRatio}
	            className="absolute top-0 left-0 object-cover h-full rounded-lg shadow-lg"
	            autoPlay
	            playsInline
	            muted
	            loop
	          /> 
						<img
							src={src}
							alt="story"
							width={1080 * sizeRatio}
							height={1920 * sizeRatio}
							className="relative rounded-lg"
						/>
						<div className={`absolute top-0 left-0 w-full text-white text-center text-[10px] sm:text-xs py-4 sm:py-5 ${selectedTab === tabs[0] ? "" : "bg-gradient-to-b from-black/30 to-transparent"}`}>
	            <p>1. placeholder background</p>
	            <p>2. click to change template</p>
            </div>
					</button>
				)}
		    {returnAction()}
		    {returnCanvas()}
	    </div>
		);
  }
  return null;
};

export default ShareImage;
