import { useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react"
import { DocumentDuplicateIcon, EnvelopeIcon, ArrowUpOnSquareIcon, EllipsisVerticalIcon, LockClosedIcon, LockOpenIcon, MagnifyingGlassIcon, EyeSlashIcon, FlagIcon, TrashIcon } from "@heroicons/react/24/outline";
import { LoggedInContext } from "./context/context";
import { handleCopy, handleEmail, handleShareMobile, handleShareLinkedin, handleShareX, handleShareFacebook } from "./services/handleShareSocial";
import { updateAlert } from "./services/updateAlert";
import { storeUserAction } from "./services/storeUserAction";
import { isMobile, classNames, returnLinkedinLogo, returnXLogo, returnFacebookLogoOutline, defaultModal } from "./styles/styles";
import { developmentEnv, postPatch, postPatchToken } from "./utils/utils";

const ArticleOptions = ({ article, setArticle, linkId, showShare, shareUrl, copiedAlert, style, owner, handleCopyGift }) => {
  const { setLoading, setAlert, alertTimeout, setModal } = useContext(LoggedInContext);
  const navigate = useNavigate();
  const { id: articleId, originalOwner, privacy, search, articleContents, linkedinUrl: { id: linkedinUrlId } } = article;
  const { title } = articleContents.find(a => a.selected);

  const handleEmailArticle = async () => {
    const emailSubject = encodeURIComponent(`Breaking news! ${title}`);
    const emailBody = encodeURIComponent(`Read more: ${shareUrl}`);
    handleEmail(linkedinUrlId, null, articleId, "share email", emailSubject, emailBody);
  };

  const sharing = [
    {
      name: "Copy",
      Icon: (props) => <DocumentDuplicateIcon className="h-6 w-6" />,
      onClick: () => handleCopy(linkedinUrlId, null, articleId, null, "copy link", shareUrl, setAlert, copiedAlert, alertTimeout)
    },
    {
      name: "LinkedIn",
      Icon: (props) => returnLinkedinLogo(props, "mb-px"),
      onClick: () => handleShareLinkedin(linkedinUrlId, null, articleId, null, "share linkedin", `Breaking news! ${title} #ceolist ${developmentEnv ? "https://google.com" : shareUrl}`)
    },
    {
      name: "Twitter",
      Icon: returnXLogo,
      onClick: () => handleShareX(linkedinUrlId, null, articleId, null, "share twitter", `Breaking news! ${title} #ceolist`, shareUrl)
    },
    {
      name: "Facebook",
      Icon: returnFacebookLogoOutline,
      onClick: () => handleShareFacebook(linkedinUrlId, null, articleId, null, "share facebook", shareUrl)
    },
    {
      name: "Email",
      Icon: (props) => <EnvelopeIcon className="h-6 w-6" />,
      onClick: handleEmailArticle 
    },
    {
      name: "Share",
      Icon: (props) => <ArrowUpOnSquareIcon className="h-6 w-6 mb-px" />,
      onClick: () => handleShareMobile(linkedinUrlId, null, articleId, "share mobile", title, "Breaking news!", shareUrl, "share mobile success", "share mobile fail")
    },
  ];

  const updatePrivacy = (privacy) => {
    storeUserAction({ articleId, actionType: "update privacy" });
    postPatchToken("PATCH", "/users/updateprivacy", { id: linkId, article: { privacy } }).then(
      json => {
        const newArticle = { ...article, privacy, search: false };
        setArticle(newArticle);
        const privacyAlert = { 
          show: true, 
          Icon: privacy ? LockClosedIcon : LockOpenIcon, 
          header: privacy ? "Article private!" : "Article public!", 
          subheader: privacy ? "Only you can see." : "People with link can see." 
        };
        updateAlert(privacyAlert, setAlert, alertTimeout);
      },
      error => {
        console.log("6");
        console.log(error);
      }
    );
  };

  const updateSearch = (search) => {
    storeUserAction({ articleId, actionType: "update search" });
    postPatchToken("PATCH", "/users/updatesearch", { id: linkId, article: { search } }).then(
      json => {
        const newArticle = { ...article, search, privacy: false };
        setArticle(newArticle);
        const searchAlert = { 
          show: true, 
          Icon: search ? MagnifyingGlassIcon : EyeSlashIcon, 
          header: search ? "Article searchable!" : "Article removed from search!", 
          subheader: search ? "It will show up in Google." : "It won't show up in Google." 
        };
        updateAlert(searchAlert, setAlert, alertTimeout);
      },
      error => {
        console.log("7");
        console.log(error);
      }
    );
  };

  const submitReport = (description) => {
    postPatch("POST", "/users/report", { id: linkId, article_report: { description } }).then(
      json => {
        const reportAlert = { 
          show: true, 
          Icon: FlagIcon, 
          header: "Article reported!", 
          subheader: "We will review and get back to you." 
        };
        updateAlert(reportAlert, setAlert, alertTimeout);
      },
      error => {
        console.log("8");
        console.log(error);
      }
    );
  };

  const deleteArticle = () => {
    postPatchToken("PATCH", "/users/delete", { id: linkId }).then(
      json => {
        const deleteAlert = { 
          show: true, 
          Icon: TrashIcon, 
          header: "Article deleted!", 
          subheader: "This article is gone forever." 
        };
        updateAlert(deleteAlert, setAlert, alertTimeout, true);
        setLoading(true);
        navigate("/");
      },
      error => {
        console.log("9");
        console.log(error);
      }
    );
  };

  const confirmReport = () => {
    const modal = {
      ...defaultModal,
      open: true,
      Icon: FlagIcon,
      iconColor: "red",
      header: "Report article",
      text: "Optional: Tell us why you're reporting this article.",
      textArea: true,
      option: "Report",
      onClickOption: submitReport,
    };
    setModal(modal);
  };

  const confirmDelete = () => {
    const modal = {
      ...defaultModal,
      open: true,
      Icon: TrashIcon,
      iconColor: "red",
      header: "Delete article",
      text: "Are you sure you want to permanently delete this article? This is not reversible.",
      option: "Delete",
      onClickOption: deleteArticle,
    };
    setModal(modal);
  };

  const privacyOption = privacy ? {
    name: "Make public",
    subheader: "Current: private (only you can see). Public: ppl with link can see.",
    onClick: () => updatePrivacy(false),
    show: owner
  } : {
    name: "Make private",
    subheader: "Current: public (ppl with link can see). Private: only you can see.",
    onClick: () => updatePrivacy(true),
    show: owner
  };
  const searchOption = search ? {
    name: "Remove from search",
    subheader: "Current: searchable (it will show up in Google).",
    onClick: () => updateSearch(false),
    show: owner
  } : {
    name: "Show in search",
    subheader: "Current: not searchable (it won't show up in Google).",
    onClick: () => updateSearch(true),
    show: owner
  };
  const moreOptions = [
    {
      name: "Copy gift link",
      onClick: handleCopyGift,
      show: originalOwner
    },
    {
      name: "Get magazine cover",
      onClick: () => window.open(`/cover/${linkId}`, "_blank", "noopener,noreferrer"),
      show: true
    },
    privacyOption,
    searchOption,
    {
      name: "Report article",
      onClick: confirmReport,
      show: true
    },
    {
      name: "Delete article",
      onClick: confirmDelete,
      show: owner
    }
  ];

  const returnMoreOptions = () => {
    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-500 focus:outline-none">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {moreOptions.filter(o => o.show).map(({ name, subheader, onClick }) => {
                return (
                  <Menu.Item key={name}>
                    {({ active }) => (
                      <button
                        type="submit"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block w-full px-4 py-2 text-left text-sm'
                        )}
                        onClick={onClick}
                      >
                        {name}
                        {subheader && <span className="text-xs text-gray-500"><br />{subheader}</span>}
                      </button>
                    )}
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  let sharingOptions = ["Copy", "LinkedIn", "Twitter", "Facebook"];
  if (isMobile && (showShare || developmentEnv)) {
    sharingOptions = [...sharingOptions, "Share"];
  } else {
    sharingOptions = [...sharingOptions, "Email"]
  }
  return (
    <div className={`${style || ""} flex justify-center items-center space-x-8 sm:space-x-10`}>
      {sharingOptions.map(o => {
        const { name, Icon, onClick } = sharing.find(s => s.name === o);
        return (
          <button 
            key={name} 
            className="text-gray-400 hover:text-gray-500"
            onClick={onClick}
          >
            <span className="sr-only">{name}</span>
            <Icon aria-hidden="true" />
          </button>
        )
      })}
      {returnMoreOptions()}
    </div>
  );
};

export default ArticleOptions;
