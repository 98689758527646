import { Buffer } from "buffer";
import bg_1 from "../assets/bg_1.jpg";
import bg_2 from "../assets/bg_2.jpg";
import bg_3 from "../assets/bg_3.jpg";
import bg_4 from "../assets/bg_4.jpg";
import bg_5 from "../assets/bg_5.jpg";
import bg_6 from "../assets/bg_6.png";
import bg_graphic_1 from "../assets/bg_graphic_1.jpeg";
import bg_graphic_2 from "../assets/bg_graphic_2.jpeg";
import bg_graphic_3 from "../assets/bg_graphic_3.jpeg";
import bg_graphic_4 from "../assets/bg_graphic_4.jpeg";
import bg_graphic_5 from "../assets/bg_graphic_5.png";
import bg_graphic_6 from "../assets/bg_graphic_6.jpeg";
import og_bg_1 from "../assets/og_bg_1.jpg";
// import og_bg_2 from "../assets/og_bg_2.jpg";
// import og_bg_3 from "../assets/og_bg_3.jpg";
// import og_bg_4 from "../assets/og_bg_4.jpg";
// import og_bg_5 from "../assets/og_bg_5.jpg";
// import og_bg_6 from "../assets/og_bg_6.jpg";

export const { userAgent, maxTouchPoints, platform } = navigator;
export const isiOS = /(iPad|iPhone|iPod)/g.test(userAgent) || (maxTouchPoints && maxTouchPoints > 2 && /MacIntel/.test(platform));
export const isAndroid = /Android/i.test(userAgent);
export const isMobile = isiOS || isAndroid;
export const notMobile = !isiOS && !isAndroid;
export const isFirefox = userAgent.match(/firefox|fxios/i);
export const isWindows = userAgent.indexOf("Win") !== -1;
export const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
export const isInstagram = userAgent.indexOf('Instagram') > -1;
export const isSnapchat = userAgent.indexOf('Snapchat') > -1;
export const isEdge = userAgent.indexOf("Edg") > -1;

export const logoWidthOriginal = 803;
export const logoHeightOriginal = 240;
export const price = "$1.99";
export const companyEmail = "awards@ceolist.org";
export const websiteName = "CEO List";
export const logInMessage = {
  header: "Please log in",
  subheader: "You must log in to view this content."
};
export const fallbackEmoji = "⭐️";
export const defaultAlert = { show: false };
export const defaultModal = {
  open: false,
  Icon: null,
  iconColor: null,
  header: null,
  text: null,
  textArea: false,
  option: null,
  onClickOption: () => {},
  src: null,
  alt: null,
  footer: null,
  signIn: false,
  linkId: null,
  awardId: null,
  giftId: null,
};
export const demoVideo = false;
export const facebookPixelEnabled = false;
export const sampleArticleId = "eTJiwQ";
export const sampleArticleIdDevelopment = "ZzBh6A";
export const personalitySections = [
  { title: "Narcissism Score", emoji: "😱" },
  { title: "Roast", emoji: "🔥" },
  { title: "Pickup Lines", emoji: "😘" },
  { title: "Strengths", emoji: "💪" },
  { title: "Weaknesses", emoji: "🥲" },
  { title: "Love Life", emoji: "❤️" },
  { title: "Money", emoji: "💸" },
  { title: "Health", emoji: "🏃" },
  { title: "Other's Perspective", emoji: "👀" },
  { title: "Biggest Goal", emoji: "🎯" },
  { title: "Famous Person Like You", emoji: "⭐️" },
  { title: "Previous Life", emoji: "♻️" },
  { title: "Animal", emoji: "🐼" },
  { title: "Thing To Buy", emoji: "🛒" },
  { title: "Career", emoji: "💼" },
  { title: "Life Suggestion", emoji: "💡" },
  { title: "Ideal Company", emoji: "🤩" },
  { title: "Alternative Career", emoji: "🤔" },
  { title: "Social Life", emoji: "👯‍♀️" },
  { title: "Travel", emoji: "🛩" },
  { title: "Next Career Move", emoji: "👉" },
  { title: "TED Talks", emoji: "🗣" },
  { title: "Retirement", emoji: "🌞" },
  { title: "Will AI Take Your Job", emoji: "👾" },
  { title: "Survive Apocalypse", emoji: "👽" },
  { title: "Food", emoji: "🥗" },
  { title: "Drink", emoji: "☕️" },
  { title: "Music", emoji: "🎤" },
  { title: "Book", emoji: "📖" },
  { title: "Time Period", emoji: "⏳" },
];

export const colors = ["slate", "gray", "zinc", "neutral", "stone", "red", "orange", "amber", "yellow", "lime", "green", "emerald", "teal", "cyan", "sky", "blue", "indigo", "violet", "purple", "fuchsia", "pink", "rose"];
export const bgColors = ["bg-slate-50", "bg-gray-50", "bg-zinc-50", "bg-neutral-50", "bg-stone-50", "bg-red-50", "bg-orange-50", "bg-amber-50", "bg-yellow-50", "bg-lime-50", "bg-green-50", "bg-emerald-50", "bg-teal-50", "bg-cyan-50", "bg-sky-50", "bg-blue-50", "bg-indigo-50", "bg-violet-50", "bg-purple-50", "bg-fuchsia-50", "bg-pink-50", "bg-rose-50"];
export const headerColors = ["text-slate-900", "text-gray-900", "text-zinc-900", "text-neutral-900", "text-stone-900", "text-red-900", "text-orange-900", "text-amber-900", "text-yellow-900", "text-lime-900", "text-green-900", "text-emerald-900", "text-teal-900", "text-cyan-900", "text-sky-900", "text-blue-900", "text-indigo-900", "text-violet-900", "text-purple-900", "text-fuchsia-900", "text-pink-900", "text-rose-900"];
export const awardBgColors = ["bg-slate-300", "bg-gray-300", "bg-zinc-300", "bg-neutral-300", "bg-stone-300", "bg-red-300", "bg-orange-300", "bg-amber-300", "bg-yellow-300", "bg-lime-300", "bg-green-300", "bg-emerald-300", "bg-teal-300", "bg-cyan-300", "bg-sky-300", "bg-blue-300", "bg-indigo-300", "bg-violet-300", "bg-purple-300", "bg-fuchsia-300", "bg-pink-300", "bg-rose-300"];
export const awardIconColors = ["text-slate-300", "text-gray-300", "text-zinc-300", "text-neutral-300", "text-stone-300", "text-red-300", "text-orange-300", "text-amber-300", "text-yellow-300", "text-lime-300", "text-green-300", "text-emerald-300", "text-teal-300", "text-cyan-300", "text-sky-300", "text-blue-300", "text-indigo-300", "text-violet-300", "text-purple-300", "text-fuchsia-300", "text-pink-300", "text-rose-300"];
export const canvasColors = [
  { color: "slate", bgColor: "#f8fafc", headerColor: "#0f172a", iconColor: "#cbd5e1", borderColor: "#e2e8f0" },
  { color: "gray", bgColor: "#f9fafb", headerColor: "#111827", iconColor: "#d1d5db", borderColor: "#e5e7eb" },
  { color: "zinc", bgColor: "#fafafa", headerColor: "#18181b", iconColor: "#d4d4d8", borderColor: "#e4e4e7" },
  { color: "neutral", bgColor: "#fafafa", headerColor: "#171717", iconColor: "#d4d4d4", borderColor: "#e5e5e5" },
  { color: "stone", bgColor: "#fafaf9", headerColor: "#1c1917", iconColor: "#d6d3d1", borderColor: "#e7e5e4" },
  { color: "red", bgColor: "#fef2f2", headerColor: "#7f1d1d", iconColor: "#fca5a5", borderColor: "#fecaca" },
  { color: "orange", bgColor: "#fff7ed", headerColor: "#7c2d12", iconColor: "#fdba74", borderColor: "#fed7aa" },
  { color: "amber", bgColor: "#fffbeb", headerColor: "#78350f", iconColor: "#fcd34d", borderColor: "#fde68a" },
  { color: "yellow", bgColor: "#fefce8", headerColor: "#713f12", iconColor: "#fde047", borderColor: "#fef08a" },
  { color: "lime", bgColor: "#f7fee7", headerColor: "#365314", iconColor: "#bef264", borderColor: "#d9f99d" },
  { color: "green", bgColor: "#f0fdf4", headerColor: "#14532d", iconColor: "#86efac", borderColor: "#bbf7d0" },
  { color: "emerald", bgColor: "#ecfdf5", headerColor: "#064e3b", iconColor: "#6ee7b7", borderColor: "#a7f3d0" },
  { color: "teal", bgColor: "#f0fdfa", headerColor: "#134e4a", iconColor: "#5eead4", borderColor: "#99f6e4" },
  { color: "cyan", bgColor: "#ecfeff", headerColor: "#164e63", iconColor: "#67e8f9", borderColor: "#a5f3fc" },
  { color: "sky", bgColor: "#f0f9ff", headerColor: "#0c4a6e", iconColor: "#7dd3fc", borderColor: "#bae6fd" },
  { color: "blue", bgColor: "#eff6ff", headerColor: "#1e3a8a", iconColor: "#93c5fd", borderColor: "#bfdbfe" },
  { color: "indigo", bgColor: "#eef2ff", headerColor: "#312e81", iconColor: "#a5b4fc", borderColor: "#c7d2fe" },
  { color: "violet", bgColor: "#f5f3ff", headerColor: "#4c1d95", iconColor: "#c4b5fd", borderColor: "#ddd6fe" },
  { color: "purple", bgColor: "#faf5ff", headerColor: "#581c87", iconColor: "#d8b4fe", borderColor: "#e9d5ff" },
  { color: "fuchsia", bgColor: "#fdf4ff", headerColor: "#701a75", iconColor: "#f0abfc", borderColor: "#f5d0fe" },
  { color: "pink", bgColor: "#fdf2f8", headerColor: "#831843", iconColor: "#f9a8d4", borderColor: "#fbcfe8" },
  { color: "rose", bgColor: "#fff1f2", headerColor: "#881337", iconColor: "#fda4af", borderColor: "#fecdd3" },
];

export const backgroundImages = [
  { 
    image: bg_1, 
    width: 1280, 
    height: 1920, 
    headerFillColor: "#164e63", // cyan-900
    headerStrokeColor: "#67e8f9", // cyan-300
    defaultRectFillColor: "rgba(255,255,255,0.7)",
    activeRectFillColor: "white",
    defaultRectStrokeColor: "white", 
    loadingRectStrokeColor: "#6366f1", // indigo-500
    errorRectStrokeColor: "#ef4444", // red-500
    personaImageStrokeColor: "#67e8f9", // cyan-300
    nameColor: "#111827", // gray-900
    headlineColor: "#111827", // gray-900
    awardIconColor: "#a5f3fc", // cyan-200
    loadingHeaderErrorColor: "#dc2626", // red-600
    defaultTextColor: "#111827" // gray-900
  },
  { 
    image: bg_2, 
    width: 1280, 
    height: 1920, 
    headerFillColor: "#047857", // emerald-700
    headerStrokeColor: "white", 
    defaultRectFillColor: "rgba(3,7,18,0.8)", // gray-950
    activeRectFillColor: "#030712",
    defaultRectStrokeColor: "#0c4a6e", // sky-900
    loadingRectStrokeColor: "#6366f1", // indigo-500
    errorRectStrokeColor: "#ef4444", // red-500
    personaImageStrokeColor: "#34d399", // emerald-400
    nameColor: "white", 
    headlineColor: "#d1d5db", // gray-300
    awardIconColor: "#a7f3d0", // emerald-200
    loadingHeaderErrorColor: "#ef4444", // red-500
    defaultTextColor: "white", 
    narcissismScoreColor: "#111827", // gray-900
    quoteIconFill: "#9ca3af", // gray-400
    quoteIconColor: "#9ca3af" // gray-400
  },
  { 
    image: bg_3, 
    width: 1280, 
    height: 1920, 
    headerFillColor: "#d97706", // amber-600
    headerStrokeColor: "white", 
    defaultRectFillColor: "rgba(0,0,0,0.6)", 
    activeRectFillColor: "black",
    defaultRectStrokeColor: "#57534e", // stone-600
    loadingRectStrokeColor: "#6366f1", // indigo-500
    errorRectStrokeColor: "#ef4444", // red-500
    personaImageStrokeColor: "#fbbf24", // amber-400
    nameColor: "white", 
    headlineColor: "#d1d5db", // gray-300
    awardIconColor: "#fcd34d", // amber-300
    loadingHeaderErrorColor: "#ef4444", // red-500
    defaultTextColor: "white", 
    narcissismScoreColor: "#1c1917", // stone-900
    quoteIconFill: "#a8a29e", // stone-400
    quoteIconColor: "#a8a29e" // stone-400
  },
  { 
    image: bg_4, 
    width: 1280, 
    height: 1920, 
    headerFillColor: "#0369a1", // sky-700
    headerStrokeColor: "#fda4af", // rose-300
    defaultRectFillColor: "rgba(255, 241, 242,0.7)", // rose-50
    activeRectFillColor: "#fff1f2",
    defaultRectStrokeColor: "#ffe4e6", // rose-100
    loadingRectStrokeColor: "#6366f1", // indigo-500
    errorRectStrokeColor: "#ef4444", //red-500
    personaImageStrokeColor: "#7dd3fc", // sky-300
    nameColor: "#111827", // gray-900
    headlineColor: "#4b5563", // gray-600
    awardIconColor: "white", 
    loadingHeaderErrorColor: "#dc2626", // red-600
    defaultTextColor: "#111827" // gray-900
  },
  { 
    image: bg_5, 
    width: 1280, 
    height: 1920, 
    headerFillColor: "#ea580c", // orange-600
    headerStrokeColor: "white", 
    defaultRectFillColor: "rgba(255,255,255,0.7)", 
    activeRectFillColor: "white",
    defaultRectStrokeColor: "white", 
    loadingRectStrokeColor: "#6366f1", // indigo-500
    errorRectStrokeColor: "#ef4444", // red-500
    personaImageStrokeColor: "#fb923c", // orange-400
    nameColor: "#111827", // gray-900
    headlineColor: "#4b5563", // gray-600
    awardIconColor: "white", 
    loadingHeaderErrorColor: "#dc2626", // red-600
    defaultTextColor: "#111827" // gray-900
  },
  { 
    image: bg_6, 
    width: 1280, 
    height: 1920, 
    headerFillColor: "#86efac", // green-300
    headerStrokeColor: "black", 
    defaultRectFillColor: "rgba(255,255,255,0.9)", 
    activeRectFillColor: "white",
    defaultRectStrokeColor: "white", 
    loadingRectStrokeColor: "#6366f1", // indigo-500 
    errorRectStrokeColor: "#ef4444", // red-500
    personaImageStrokeColor: "#22c55e", // green-500
    nameColor: "#111827", // gray-900
    headlineColor: "#4b5563", // gray-600
    awardIconColor: "#4ade80", // green-400
    loadingHeaderErrorColor: "#dc2626", // red-600
    defaultTextColor: "#111827" // gray-900
  },
  { 
    image: bg_graphic_1, 
    width: 674, 
    height: 1200, 
    headerFillColor: "#111827", // gray-900
    headerStrokeColor: "#a5b4fc", // indigo-300
    defaultRectFillColor: "rgba(255,255,255,0.6)", 
    activeRectFillColor: "white",
    defaultRectStrokeColor: "white", 
    loadingRectStrokeColor: "#6366f1", // indigo-500
    errorRectStrokeColor: "#ef4444", // red-500
    personaImageStrokeColor: "#818cf8", // indigo-400
    nameColor: "#111827", // gray-900
    headlineColor: "#4b5563", // gray-600
    awardIconColor: "white", 
    loadingHeaderErrorColor: "#dc2626", // red-600
    defaultTextColor: "#111827" // gray-900
  },
  { 
    image: bg_graphic_2, 
    width: 675, 
    height: 1200, 
    headerFillColor: "#6d28d9", // violet-700
    headerStrokeColor: "#a7f3d0", // emerald-200
    defaultRectFillColor: "rgba(255,255,255,0.7)", 
    activeRectFillColor: "white",
    defaultRectStrokeColor: "white", 
    loadingRectStrokeColor: "#6366f1", // indigo-500
    errorRectStrokeColor: "#ef4444", // red-500
    personaImageStrokeColor: "#a78bfa", // violet-400
    nameColor: "#111827", // gray-900
    headlineColor: "#4b5563", // gray-600
    awardIconColor: "white", 
    loadingHeaderErrorColor: "#dc2626", // red-600
    defaultTextColor: "#111827" // gray-900
  },
  { 
    image: bg_graphic_3, 
    width: 736, 
    height: 1308, 
    headerFillColor: "#6b21a8", // purple-800
    headerStrokeColor: "#d8b4fe", // purple-300
    defaultRectFillColor: "rgba(255,255,255,0.4)", 
    activeRectFillColor: "white",
    defaultRectStrokeColor: "#fed7aa", // orange-200
    loadingRectStrokeColor: "#6366f1", // indigo-500
    errorRectStrokeColor: "#ef4444", // red-500
    personaImageStrokeColor: "#c084fc", // purple-400
    nameColor: "#111827", // gray-900
    headlineColor: "#4b5563", // gray-600
    awardIconColor: "#fed7aa", // orange-200
    loadingHeaderErrorColor: "#dc2626", // red-600
    defaultTextColor: "#111827" // gray-900
  },
  { 
    image: bg_graphic_4, 
    width: 564, 
    height: 1002, 
    headerFillColor: "#ec4899", // pink-500
    headerStrokeColor: "#2dd4bf", // teal-400
    defaultRectFillColor: "rgba(255,255,255,0.7)", 
    activeRectFillColor: "white",
    defaultRectStrokeColor: "#99f6e4", // tael-200
    loadingRectStrokeColor: "#6366f1", // indigo-500
    errorRectStrokeColor: "#ef4444", // red-500
    personaImageStrokeColor: "#f472b6", // pink-400
    nameColor: "#111827", // gray-900
    headlineColor: "#4b5563", // gray-600
    awardIconColor: "#f9a8d4", // pink-300
    loadingHeaderErrorColor: "#dc2626", // red-600
    defaultTextColor: "#111827" // gray-900
  },
  { 
    image: bg_graphic_5, 
    width: 1280, 
    height: 1920, 
    headerFillColor: "#4ade80", // green-400
    headerStrokeColor: "#4b5563", // gray-600
    defaultRectFillColor: "rgba(255,255,255,0.4)", 
    activeRectFillColor: "white",
    defaultRectStrokeColor: "#ffe4e6", // rose-100
    loadingRectStrokeColor: "#6366f1", // indigo-500
    errorRectStrokeColor: "#ef4444", // red-500
    personaImageStrokeColor: "#4ade80", // green-400
    nameColor: "#111827", // gray-900
    headlineColor: "#4b5563", // gray-600
    awardIconColor: "#86efac", // green-300
    loadingHeaderErrorColor: "#c84740", 
    defaultTextColor: "#111827" // gray-900
  },
  { 
    image: bg_graphic_6, 
    width: 736, 
    height: 1309, 
    headerFillColor: "#c026d3", // fuchsia-600
    headerStrokeColor: "white", 
    defaultRectFillColor: "rgba(0,0,0,0.7)", 
    activeRectFillColor: "black",
    defaultRectStrokeColor: "#701a75", // fuchsia-900
    loadingRectStrokeColor: "#6366f1", // indigo-500
    errorRectStrokeColor: "#ef4444", // red-500
    personaImageStrokeColor: "#10b981", // emerald-500
    nameColor: "white", 
    headlineColor: "#d1d5db", // gray-300
    awardIconColor: "#6ee7b7", // emerald-300
    loadingHeaderErrorColor: "#ef4444", // red-500
    defaultTextColor: "white", 
    narcissismScoreColor: "#111827", // gray-900
    quoteIconFill: "#9ca3af", // gray-400
    quoteIconColor: "#9ca3af", // gray-400
  },
];

export const ogBgImages = [
  {
    image: og_bg_1,
    width: 1200,
    height: 800 
  },
  // {
  //  image: og_bg_2,
  //  width: 1200,
  //  height: 800 
  // },
  // {
  //  image: og_bg_3,
  //  width: 1200,
  //  height: 800 
  // },
  // {
  //  image: og_bg_4,
  //  width: 1200,
  //  height: 800 
  // },
  // {
  //  image: og_bg_5,
  //  width: 1200,
  //  height: 797 
  // },
  // {
  //  image: og_bg_6,
  //  width: 1200,
  //  height: 801,
  //  opacity: 0.5
  // },
];

export const filterNarcScores = (s) => {
  return !isEmpty(s.totalScore) && !isEmpty(s.explanation) && !isEmpty(s.summary);
};

export const filterLinkedinPersonas = (l) => {
  return !isEmpty(l.name) && !isEmpty(l.explanation) && typeof l.percentage === "number" && l.percentage > 0 && !isEmpty(l.quote);
};

export const filterPersonalitiesOne = (p) => {
  return !isEmpty(p.strengths) &&
    !isEmpty(p.weaknesses) &&
    !isEmpty(p.othersPerspective) &&
    !isEmpty(p.biggestGoal) &&
    !isEmpty(p.famousPerson) &&
    !isEmpty(p.previousLife) &&
    !isEmpty(p.career) &&
    !isEmpty(p.idealCompany) &&
    !isEmpty(p.alternativeCareer) &&
    !isEmpty(p.nextCareerMove) &&
    !isEmpty(p.tedTalks) &&
    !isEmpty(p.retirement) &&
    !isEmpty(p.aiDisrupt) &&
    !isEmpty(p.roast) &&
    !isEmpty(p.emojis);
};

export const filterPersonalitiesTwo = (p) => {
  return !isEmpty(p.loveLife) &&
    !isEmpty(p.money) &&
    !isEmpty(p.health) &&
    !isEmpty(p.pickupLines) &&
    !isEmpty(p.animal) &&
    !isEmpty(p.thingToBuy) &&
    !isEmpty(p.lifeSuggestion) &&
    !isEmpty(p.socialLife) &&
    !isEmpty(p.travel) &&
    !isEmpty(p.surviveApocalypse) &&
    !isEmpty(p.food) &&
    !isEmpty(p.drink) &&
    !isEmpty(p.music) &&
    !isEmpty(p.book) &&
    !isEmpty(p.timePeriod);
};

export const calculateLines = (text, maxWidth, fontSize, fontWeight) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = `${fontWeight} ${fontSize}px Mona Sans`.trim();

  const words = text.split(' ');
  let lineCount = 1;
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const width = context.measureText(currentLine + " " + word).width;
    if (width < maxWidth) {
      currentLine += " " + word;
    } else {
      lineCount++;
      currentLine = word;
    }
  }

  return lineCount;
};

export const truncateText = (text, maxWidth, fontSize, fontWeight, maxLines) => {
  // Create a temporary canvas to measure text
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = `${fontWeight} ${fontSize}px Mona Sans`.trim();

  const measureLine = (line) => context.measureText(line).width;
  
  const getLines = (text) => {
    const words = text.split(' ');
    const lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const lineWidth = measureLine(currentLine + " " + word);
      if (lineWidth < maxWidth) {
        currentLine += " " + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    return lines;
  };

  const lines = getLines(text);

  if (lines.length <= maxLines) {
    return text;
  }

  let truncated = lines.slice(0, maxLines).join('\n');
  let lastLine = truncated.split('\n').pop();

  while (measureLine(lastLine + '...') > maxWidth && lastLine.length > 0) {
    lastLine = lastLine.slice(0, -1);
  }

  truncated = truncated.split('\n');
  truncated[maxLines - 1] = lastLine + '...';
  return truncated.join('\n');
};

export const uuidToBase64 = (uuid) => {
  const hexString = uuid.replace(/-/g, '');
  const buffer = Buffer.from(hexString, 'hex');
  return buffer.toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

export const base64ToUuid = (base64) => {
  const hexString = Buffer.from(base64, 'base64').toString('hex');
  return [
    hexString.slice(0, 8),
    hexString.slice(8, 12),
    hexString.slice(12, 16),
    hexString.slice(16, 20),
    hexString.slice(20)
  ].join('-');
};

export const returnGoogleLogo = (style) => (
  <svg className={`h-5 w-5 sm:h-6 sm:w-6 ${style || ""}`} aria-hidden="true" viewBox="0 0 24 24">
    <path
      d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
      fill="#EA4335"
    />
    <path
      d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
      fill="#4285F4"
    />
    <path
      d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
      fill="#FBBC05"
    />
    <path
      d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
      fill="#34A853"
    />
  </svg>
);

export const returnLinkedinLogo = (props, style) => (
  <svg fill="currentColor" viewBox="0 0 256 256" className={`h-5 w-5 ${style || ""}`} {...props}>
    <path d="M 90.873695,169.81754 V 85.82936 h 24.969455 24.96946 v 11.349753 c 0,8.751147 0.14437,11.346477 0.63054,11.335447 0.3468,-0.008 1.41651,-1.31191 2.37715,-2.89787 2.39454,-3.9533 8.8811,-10.586801 13.42156,-13.725619 11.5444,-7.980641 24.86933,-11.255345 41.08372,-10.09664 21.38835,1.528445 34.61343,8.27111 43.92706,22.395739 7.06692,10.71738 10.53478,24.89293 11.55065,47.21554 0.27146,5.96493 0.49655,31.44513 0.50021,56.62266 l 0.007,45.77734 h -25.92343 -25.92343 l -0.24542,-48.04729 c -0.25872,-50.65043 -0.34823,-52.67193 -2.67438,-60.40591 -3.62107,-12.03925 -12.00345,-18.12136 -25.01557,-18.15084 -15.31441,-0.0347 -25.5104,7.60406 -29.15134,21.83998 -2.12046,8.29093 -2.17661,9.6441 -2.4057,57.97786 l -0.22176,46.7862 H 116.81141 90.873695 Z" />
    <path d="M 5.6244336,169.81754 V 85.82936 H 31.602759 57.581084 v 83.98818 83.98817 H 31.602759 5.6244336 Z" />
    <path d="M 24.5917,61.316949 C 11.948077,58.257645 2.5990869,47.019022 1.7618644,33.872709 0.84919082,19.541643 10.344418,6.4446924 24.248116,2.8569883 28.058514,1.8737551 35.118124,1.7651097 38.676687,2.6349368 47.216107,4.722245 54.819164,10.800691 58.856655,18.768293 63.371461,27.677838 62.638721,39.855209 57.077836,48.330201 52.901722,54.694761 45.099333,60.112664 38.009457,61.571107 34.404463,62.31268 28.223003,62.195591 24.5917,61.316949 Z" />
  </svg>
);

export const returnXLogo = (props, style) => (
  <svg fill="currentColor" viewBox="0 0 512 462.799" className={`h-5 w-5 ${style || ""}`} {...props}>
    <path fillRule="nonzero" d="M403.229 0h78.506L310.219 196.04 512 462.799H354.002L230.261 301.007 88.669 462.799h-78.56l183.455-209.683L0 0h161.999l111.856 147.88L403.229 0zm-27.556 415.805h43.505L138.363 44.527h-46.68l283.99 371.278z"/>
  </svg>
);

export const returnTwitterLogo = (props) => (
  <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6" {...props}>
    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
  </svg>
);

export const returnFacebookLogoOutline = (props) => (
  <svg
    className="h-5 w-5 mb-px"
    fill="currentColor"
    viewBox="0 0 24 24">
    <path
      d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
  </svg>
);

export const returnFacebookLogoFilled = (props) => (
  <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12Z"
    />
  </svg>
);

export const returnTikTokLogo = (props) => (
  <svg fill="currentColor" viewBox="0 0 448 512" className="h-5 w-5" {...props}>
    <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" />
  </svg>
);

export const returnInstagramLogo = (props) => (
  <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6" {...props}>
    <path
      fillRule="evenodd"
      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
      clipRule="evenodd"
    />
  </svg>
);

export const returnThreadsLogo = (props) => (
  <svg fill="currentColor" viewBox="0 0 192 192" className="h-4 w-4" {...props}>
    <path 
      fillRule="evenodd" 
      clipRule="evenodd"
      d="M141.537 88.9883C140.71 88.5919 139.87 88.2104 139.019 87.8451C137.537 60.5382 122.616 44.905 97.5619 44.745C97.4484 44.7443 97.3355 44.7443 97.222 44.7443C82.2364 44.7443 69.7731 51.1409 62.102 62.7807L75.881 72.2328C81.6116 63.5383 90.6052 61.6848 97.2286 61.6848C97.3051 61.6848 97.3819 61.6848 97.4576 61.6855C105.707 61.7381 111.932 64.1366 115.961 68.814C118.893 72.2193 120.854 76.925 121.825 82.8638C114.511 81.6207 106.601 81.2385 98.145 81.7233C74.3247 83.0954 59.0111 96.9879 60.0396 116.292C60.5615 126.084 65.4397 134.508 73.775 140.011C80.8224 144.663 89.899 146.938 99.3323 146.423C111.79 145.74 121.563 140.987 128.381 132.296C133.559 125.696 136.834 117.143 138.28 106.366C144.217 109.949 148.617 114.664 151.047 120.332C155.179 129.967 155.42 145.8 142.501 158.708C131.182 170.016 117.576 174.908 97.0135 175.059C74.2042 174.89 56.9538 167.575 45.7381 153.317C35.2355 139.966 29.8077 120.682 29.6052 96C29.8077 71.3178 35.2355 52.0336 45.7381 38.6827C56.9538 24.4249 74.2039 17.11 97.0132 16.9405C119.988 17.1113 137.539 24.4614 149.184 38.788C154.894 45.8136 159.199 54.6488 162.037 64.9503L178.184 60.6422C174.744 47.9622 169.331 37.0357 161.965 27.974C147.036 9.60668 125.202 0.195148 97.0695 0H96.9569C68.8816 0.19447 47.2921 9.6418 32.7883 28.0793C19.8819 44.4864 13.2244 67.3157 13.0007 95.9325L13 96L13.0007 96.0675C13.2244 124.684 19.8819 147.514 32.7883 163.921C47.2921 182.358 68.8816 191.806 96.9569 192H97.0695C122.03 191.827 139.624 185.292 154.118 170.811C173.081 151.866 172.51 128.119 166.26 113.541C161.776 103.087 153.227 94.5962 141.537 88.9883ZM98.4405 129.507C88.0005 130.095 77.1544 125.409 76.6196 115.372C76.2232 107.93 81.9158 99.626 99.0812 98.6368C101.047 98.5234 102.976 98.468 104.871 98.468C111.106 98.468 116.939 99.0737 122.242 100.233C120.264 124.935 108.662 128.946 98.4405 129.507Z"
    />
  </svg>
);

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const empty = (text) => {
  return !text || text.length === 0 || !text.trim();
};

export const notEmpty = (text) => {
  return text && text.length > 0 && text.trim().length > 0;
};

export const arrayExists = (array) => {
  return Array.isArray(array) && array?.length > 0;
};

export const objectExists = (object) => {
  return typeof object === "object" && Object.keys(object).length > 0;
};

export const isEmpty = (value) => {
  if (value === null || value === undefined) {
    return true;
  }
  if (typeof value === 'string') {
    return value.trim().length === 0;
  }
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }
  return false; // For other types like numbers, functions, etc.
};

export const isLocalStorageAvailable = () => {
  var test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export const responsive = (size, max, mobile, width) => {
  const referenceWidth = width || (mobile ? 428 : 1200);
  const firstVar = (size / referenceWidth * 100).toFixed(2) + "vw";
  const secondVar = max ? `${max}px` : `${size}px`;
  return `min(${firstVar}, ${secondVar})`;
};

export const responsiveMax = (size, min, mobile, width) => {
  const referenceWidth = width || (mobile ? 428 : 1200);
  const firstVar = (size / referenceWidth * 100).toFixed(2) + "vw";
  const secondVar = min ? `${min}px` : `${size}px`;
  return `max(${firstVar}, ${secondVar})`;
};

export const clampResponsive = (min, size, max, mobile, width) => {
  const referenceWidth = width || (mobile ? 428 : 1200);
  const firstVar = `${min}px`;
  const secondVar = (size / referenceWidth * 100).toFixed(2) + "vw";
  const thirdVar = max ? `${max}px` : `${size}px`;
  return `clamp(${firstVar}, ${secondVar}, ${thirdVar})`;
};

export const mathResponsive = (size, max, innerWidth, mobile, width) => {
  const referenceWidth = width || (mobile ? 428 : 1200);
  const percentage = size / referenceWidth;
  const maxSize = max || size;
  return Math.ceil(Math.min(percentage * innerWidth, maxSize));
};

export const mathResponsiveMax = (size, min, innerWidth, mobile, width) => {
  const referenceWidth = width || (mobile ? 428 : 1200);
  const percentage = size / referenceWidth;
  const minSize = min || size;
  return Math.ceil(Math.max(percentage * innerWidth, minSize));
};

export const clampMathResponsive = (min, size, max, innerWidth, mobile, width) => {
  const referenceWidth = width || (mobile ? 428 : 1200);
  const percentage = size / referenceWidth;
  const maxSize = max || size;
  return Math.ceil(Math.max(min, Math.min(percentage * innerWidth, maxSize)));
};
