import { Image, Text } from "react-konva";
import { logoWidthOriginal, logoHeightOriginal } from "./styles/styles";

const AuraFooterCanvas = ({ logoImage, canvasWidth, canvasHeight, defaultTextSize, logoHeight, logoOffsetHorizontal }) => {

  const logoWidth = logoHeight * logoWidthOriginal / logoHeightOriginal;
  const logoOffsetVertical = canvasHeight - logoHeight - logoOffsetHorizontal;

  const urlSize = defaultTextSize;
  const urlColor = "white";
  const urlOffsetHorizontal = 0;
  const urlOffsetVertical = -logoOffsetVertical - ((logoHeight - urlSize) / 2);
  const urlWidth = canvasWidth - logoOffsetHorizontal;

	return (
		<>
		  <Image
		    // preventDefault={false}
		  	image={logoImage}
		    x={logoOffsetHorizontal}
		    y={logoOffsetVertical}
		    width={logoWidth}
		    height={logoHeight}
		    listening={false}
		  />
      <Text
        // preventDefault={false}
        text="ceolist.org"
        fontFamily="Mona Sans"
        fontSize={urlSize}
        fontStyle="600"
        lineHeight={1}
        fill={urlColor}
        padding={0}
        wrap="none"
        offsetX={urlOffsetHorizontal}
        offsetY={urlOffsetVertical}
        align="right"
        width={urlWidth}
        listening={false}
      />
    </>
	);
};

export default AuraFooterCanvas;
