const TextButton = ({ onClick, dark, small, base, medium, style, children }) => {
	return (
		<button type="button" className={style || ""} onClick={onClick}>
			<p className={`${small ? "text-sm" : base ? "text-base" : "text-sm sm:text-base"} ${medium ? "font-medium" : "font-semibold"} leading-6 ${dark ? "text-white" : "text-gray-900"} hover:opacity-75`}>
				{children}
			</p>
		</button>
	);
};

export default TextButton;
