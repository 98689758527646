import { useState, useEffect } from "react";
import AwardCard from "./AwardCard";
import Tabs from "./Tabs";
import PrimaryButton from "./PrimaryButton";
import { postPatchToken } from "./utils/utils";

const SelectArticleContent = ({ article, setArticle, tabs, selectedTab, setSelectedTab, setStatus }) => {
  const [loading, setLoading] = useState(false);
  const { articleContents, linkedinUrl, award } = article;
  const tabIndex = tabs.indexOf(selectedTab);
  const selectedArticleContent = articleContents[tabIndex];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const selectArticleContent = () => {
    setLoading(true);
    postPatchToken("PATCH", "/users/selectarticlecontent", { id: selectedArticleContent.id }).then(
      json => {
        console.log(json);
        setLoading(false);
        const articleNew = {
          ...article,
          articleContents: articleContents.map(c => {
            if (c.id !== selectedArticleContent.id) {
              return {
                ...c,
                selected: false
              };
            }
            return {
              ...c,
              selected: true
            };
          })
        };
        setArticle(articleNew);
        setStatus("visual");
      },
      error => {
        console.log("32");
        console.log(error);
      }
    )
  };

  const returnArticleContent = () => {
    const { title, createdAt, location, body, selected } = selectedArticleContent;
    return (
      <div className={`mx-auto max-w-2xl relative flex flex-col overflow-hidden rounded-lg border ${selected ? "border-indigo-600 ring-2 ring-indigo-600" : "border-gray-200"}  bg-white my-8 self-center px-6 lg:px-8 pt-[72px] lg:pt-[88px]`}>
        <header className="flex flex-col text-center">
          <h1 className="mt-6 font-display text-3xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-4xl">
            {title}
          </h1>
          <time
            dateTime={createdAt}
            className="order-first text-sm text-neutral-950"
          >
            {new Date(createdAt).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric", timeZone: "UTC" })}
          </time>
          <p className="mt-6 text-sm font-semibold text-neutral-950">
            {location}
          </p>
        </header>
        <div className="mt-16 lg:mt-20 text-neutral-950 text-base sm:text-lg">
          {body.map((b, index) => {
            return (
              <p key={index} className="first:mt-0 mt-6 mb-6 last:mb-8 sm:last:mb-10">{b}</p>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      <AwardCard linkedinUrl={linkedinUrl} award={award} select />
      <div className="min-w-0 flex-1 mt-6 sm:mt-8"> {/*text center*/}
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Select your cover story
        </h2>
        <p className="mt-2 text-sm sm:text-base text-gray-500">
          Our editorial team prepared these 2 article options for you.
        </p>
      </div>
      <div className="mt-3">
        <Tabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} noDropdown pillStyle />
      </div>
      {returnArticleContent()}
      <div className="flex flex-col self-center pb-8 mx-auto max-w-2xl">
        <PrimaryButton onClick={selectArticleContent} loading={loading}>
          {`Select ${selectedTab}`}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default SelectArticleContent;
