import { useState, useEffect, useRef, useContext } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import Avatar from "./Avatar";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import TextButton from "./TextButton";
import linkedin_post from "./assets/linkedin_post.png";
import x_post from "./assets/x_post.jpg";
import facebook_post from "./assets/facebook_post.png";
import { LoggedInContext } from "./context/context";
import { handleCopy, handleShareLinkedin, handleShareX, handleShareFacebook } from "./services/handleShareSocial";
import { developmentEnv, postPatch } from "./utils/utils";

const copiedAlert = { show: true, Icon: CheckCircleIcon, header: "Post copied!", subheader: "Share the post with the world." };
const samplePost = "Guess who just snagged The Unlikely Entrepreneur Award? 🏆 That's right, yours truly! 🌟 For those not in the loop, this accolade celebrates someone who turned a mere college project into a thriving startup - all the while keeping their LinkedIn looking like a digital ghost town. 😎 Call it minimalist chic or enigmatic charm, but let's face it, who needs a flashy LinkedIn when you're busy building an empire? 😉 #Entrepreneurship #StartupLife #AwardWinning";
const samplePostId = "8d29a4af-9630-4d17-b6a2-0b69c6f39e70";

const PostGenerator = ({ user, linkId, articleId, awardId, linkedinUrlId, shareUrl }) => {
  const { setAlert, alertTimeout } = useContext(LoggedInContext);
  const [media, setMedia] = useState("LinkedIn");
  const [subject, setSubject] = useState("Me");
  const [tone, setTone] = useState("Confident");
  const [generating, setGenerating] = useState(false);
  const [postMedia, setPostMedia] = useState(null);
  const [postLogo, setPostLogo] = useState(null);
  const [post, setPost] = useState(null);
  const [postId, setPostId] = useState(null);
  const [message, setMessage] = useState(null);
  const scrollRef = useRef(null);
  const scrollTimeout = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(scrollTimeout.current);
    }
  }, []);

  const scrollToBottom = () => {
    clearTimeout(scrollTimeout.current);
    scrollTimeout.current = setTimeout(() => {
      if (scrollRef?.current) {
        scrollRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 500);
  };

  const generatePost = () => {
    setGenerating(true);
    switch(media) {
      case "LinkedIn":
        setPostMedia("LinkedIn");
        setPostLogo(linkedin_post);
        break;
      case "X/Twitter":
        setPostMedia("X");
        setPostLogo(x_post);
        break;
      case "FB/IG":
        setPostMedia("FB");
        setPostLogo(facebook_post);
        break;
      default:
        break;
    };
    setPost(null);
    setPostId(null);
    setMessage(null);
    postPatch("POST", "/users/socialpost", { 
      id: linkId,
      social_post: { 
        award_id: awardId,
        article_id: articleId,
        media,
        subject,
        tone,
      } 
    }).then(
      json => {
        console.log(json);
        setGenerating(false);
        if (json.post) {
          setPost(json.post);
          setPostId(json.post_id);
          scrollToBottom();
        } else if (json.message) {
          setMessage(json.message);
        }
      },
      error => {
        console.log("29");
        console.log(error);
      }
    );
  };

  const handleClickLogo = () => {
    switch(postMedia) {
      case "LinkedIn":
        return handleShareLinkedin(linkedinUrlId, null, articleId, postId, "share post linkedin", `${post} #ceolist ${developmentEnv ? "https://google.com" : shareUrl}`);
      case "X":
        return handleShareX(linkedinUrlId, null, articleId, postId, "share post twitter", `${post} #ceolist`, shareUrl);
      case "FB":
        return handleShareFacebook(linkedinUrlId, null, articleId, postId, "share post facebook", shareUrl);
      default:
        break;
    }
  };

  const questions = [
    {
      id: 0,
      text: "Where do you want to post?",
      options: ["LinkedIn", "X/Twitter", "FB/IG"],
      value: media,
      onChange: setMedia
    },
    {
      id: 1,
      text: "Who is the award for?",
      options: ["Me", "Friend", "Colleague"],
      value: subject,
      onChange: setSubject
    },
    {
      id: 2,
      text: "What tone do you want?",
      options: ["Confident", "Visionary", "Humble"],
      value: tone,
      onChange: setTone
    },
  ];

  const returnQuestion = ({ id, text, options, value, onChange }) => {
    return (
      <div key={id}>
        <p className="font-semibold leading-6 text-white -mb-0.5">{text}</p>
        <div className="flex items-center gap-x-2 sm:gap-x-7">
          {options.map(option => {
            return (
              <div key={option} className="flex items-center">
                <input
                  id={option}
                  name={text}
                  type="radio"
                  defaultChecked={option === options[0]}
                  value={option}
                  className="h-5 w-5 border-white/20 bg-white/10 text-indigo-600 focus:ring-0 focus:ring-offset-0 cursor-pointer"
                  onChange={e => onChange(e.target.value)}
                />
                <label htmlFor={option} className="px-3 py-4 mt-0.5 block text-sm font-medium leading-6 text-white cursor-pointer">
                  {option}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const returnPublicAvatar = () => (
    <div className="w-12 h-12 sm:w-14 sm:h-14 rounded-full bg-neutral-300" />
  );

  const returnAvatar = () => {
    if (user) {
      return (
        <Avatar
          user={user}
          style="w-12 h-12 min-w-12 sm:w-14 sm:h-14 sm:min-w-14"
          color="bg-neutral-500"
          fontSize="text-lg sm:text-xl"
        />
      );
    }
    return returnPublicAvatar();
  };

  const returnPublicBio = () => (
    <div className="flex flex-col flex-grow gap-y-3">
      {["w-[calc(50%)]", "w-[calc(75%)]"].map(width => (
        <div key={width} className={`${width} h-2.5 sm:h-4 rounded-full bg-neutral-300`} />
      ))}
    </div>
  );

  const returnBio = () => {
    return (
      <div className="flex flex-col flex-grow text-white">
        <div className="font-medium line-clamp-1">{user?.fullName || "You"}</div>
        <div className="text-neutral-300 text-sm">Future CEO</div>
      </div>
    );
  };

  const returnLogo = () => {
    return (
      <button onClick={handleClickLogo}>
        <img 
          src={postLogo}
          className={`w-7 h-7 sm:w-8 sm:h-8 mb-1 ${postMedia === "X" ? "border border-gray-300 rounded" : ""}`}
          alt={postMedia}
        />
      </button>
    );
  };

  const returnContent = () => {
    if (generating) {
      return (
        <div ref={scrollRef} className="mt-9 sm:mt-10 w-full flex flex-col animate-pulse gap-y-5 sm:gap-y-6">
          <div className="flex flex-row items-center gap-x-3">
            {returnPublicAvatar()}
            {returnPublicBio()}
          </div>
          <div className="flex flex-col gap-y-3">
            <div className="h-2.5 sm:h-4  bg-neutral-300 rounded-full"></div>
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2.5 sm:h-4  bg-neutral-300 rounded-full col-span-2"></div>
            </div>
          </div>
        </div>
      );
    } else if (post) {
      return (
        <div ref={scrollRef} className="mt-9 sm:mt-10 w-full flex flex-col gap-y-5 sm:gap-y-6">
          <div className="flex flex-row items-center gap-x-3">
            {returnAvatar()}
            {returnBio()}
            {returnLogo()}
          </div>
          <p className="text-white text-base">{post}{' '}#ceolist{' '}
            <span className="text-indigo-500 font-medium">{shareUrl}</span>
          </p>
          <div className="flex items-center justify-end gap-x-6 mt-1">
            <TextButton
              onClick={() => handleCopy(linkedinUrlId, null, articleId, postId, "copy post", `${post} #ceolist ${shareUrl}`, setAlert, copiedAlert, alertTimeout)} 
              small 
              dark
            >
              Copy
            </TextButton>
            <SecondaryButton style="hover:bg-gray-300" onClick={handleClickLogo}>{`Share on ${postMedia}`}</SecondaryButton>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-8 mt-8 sm:mt-10 w-full sm:min-w-xl sm:max-w-3xl">
      <div className="rounded-3xl bg-neutral-950 px-6 py-8 sm:px-10 sm:py-12 flex flex-col">
        <h2 className="font-display text-xl font-medium text-white sm:text-3xl mb-8 sm:mb-10">
          Self promote like a CEO ⭐
        </h2>
        <div className="flex flex-col gap-y-4 sm:gap-y-6 mb-5 sm:mb-6">
          {questions.map(returnQuestion)}
        </div>
        <div className="flex flex-col items-start">
          <PrimaryButton
            loading={generating}
            onClick={generatePost}
            style="sm:text-base"
            dark
          >
            Generate
          </PrimaryButton>
          {message && <p className="mt-4 text-red-500 font-medium">{message}</p>}
        </div>
        {returnContent()}
      </div>
    </div>
  );
};

export default PostGenerator;
