import { useContext, useState, useRef, useEffect } from "react";
import { useParams, useNavigate, useSearchParams, Link } from "react-router-dom";
import { ExclamationTriangleIcon, UserIcon, ArrowTopRightOnSquareIcon, MapPinIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";
import ReactPixel from "react-facebook-pixel";
import Loading from "./Loading";
import NavBar from "./NavBar";
import AuraLoading from "./AuraLoading";
import ErrorShared from "./ErrorShared";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import AwardIcon from "./AwardIcon";
import Footer from "./Footer";
import Badge from "./Badge";
import Award from "./Award";
import Avatar from "./Avatar";
import AuraOptions from "./AuraOptions";
import BlurLayer from "./BlurLayer";
import Persona from "./Persona";
import giphy from "./assets/giphy.webp";
import { LoggedInContext } from "./context/context";
import { useCredentials } from "./hooks/useCredentials";
import { storeUserAction } from "./services/storeUserAction";
import { isEmpty, notEmpty, arrayExists, userAgent, facebookPixelEnabled, defaultModal, sampleArticleId, sampleArticleIdDevelopment, colors, bgColors, headerColors, personalitySections, filterNarcScores, filterLinkedinPersonas, filterPersonalitiesOne, filterPersonalitiesTwo } from "./styles/styles";
import { developmentEnv, get, postPatch, postPatchToken } from "./utils/utils";

const currentDescriptionArray = [
	"Getting intense 😬", 
	"Meeting in progress 😬", 
	"Getting heated 😬", 
	"Everyone's arguing 😬", 
	"Down to the wire 😬", 
	"Too close to call 😬",
	"Vote is deadlocked 😬",
];
const currentDescriptionStepTwo = currentDescriptionArray[Math.floor(Math.random() * currentDescriptionArray.length)];
const defaultMessage = {
	header: "Something went wrong",
	subheader: "We couldn't find your results."
};
const copiedAlert = { show: true, Icon: CheckCircleIcon, header: "Link copied!", subheader: "Share your aura!" };

const Aura = () => {
	const { userId, loggedIn, setModal } = useContext(LoggedInContext);
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(null);
	const [linkedinUrl, setLinkedinUrl] = useState(null);
	const [message, setMessage] = useState(null);
	const [awards, setAwards] = useState(null);
	const [truncateExplanation, setTruncateExplanation] = useState(true);
	const [selectedPersonaId, setSelectedPersonaId] = useState(null);
	const [selectedAwardId, setSelectedAwardId] = useState(null);
	const [awardLoading, setAwardLoading] = useState(false);
	const [awardMessage, setAwardMessage] = useState(null);
	const [regenerateLoading, setRegenerateLoading] = useState(false);
	const [audioLoading, setAudioLoading] = useState(false);
	const [audioPlaying, setAudioPlaying] = useState(false);
	const [shareAudioLoading, setShareAudioLoading] = useState(false);
	const [downloadAudioLoading, setDownloadAudioLoading] = useState(false);
	const [showShare, setShowShare] = useState(false);
	const [personalityColorArray, setPersonalityColorArray] = useState([]);
	const [awardColorArray, setAwardColorArray] = useState([]);
	const [generatedImages, setGeneratedImages] = useState([]);
	const [showMorePersonality, setShowMorePersonality] = useState(false);
	const [showMoreAwards, setShowMoreAwards] = useState(false);
	const [unlockLoading, setUnlockLoading] = useState(false);
	const [unlockMessage, setUnlockMessage] = useState(null);
	const scrollRef = useRef(null);
	const scrollTimeoutRef = useRef(null);
	const audioRefs = useRef([]);
	const auraScrollRef = useRef(null);
	const awardsScrollRef = useRef(null);
	const { linkedinUrlId } = useParams();
	const navigate = useNavigate();
	let [searchParams, setSearchParams] = useSearchParams();
	useCredentials();
	const audioDisabled = audioLoading || shareAudioLoading || downloadAudioLoading || (arrayExists(awards) && awards.some(a => a.audioLoading || a.shareAudioLoading || a.downloadAudioLoading));
	const shareUrl = window.location.href;

	useEffect(() => {
		window.scrollTo(0, 0);

    if (!developmentEnv && navigator.canShare && navigator.canShare({ text: shareUrl })) {
    	setShowShare(true);
    }

		const excludePersonalityColors = ["slate", "gray", "zinc", "neutral", "stone"];
		const personalityColors = [];
		for (let i = 0; i < personalitySections.length; i++) {
			let randomColor;
			if (i === 1) {
				randomColor = "red";
			} else {
				const modifiedPersonalityArray = colors.filter(c => !excludePersonalityColors.includes(c));
			  const randomIndex = Math.floor(Math.random() * modifiedPersonalityArray.length);
			  randomColor = modifiedPersonalityArray[randomIndex];
			}
		  personalityColors.push(randomColor);
		  if (personalityColors.filter(c => c === randomColor).length > 2) {
		  	excludePersonalityColors.push(randomColor);
		  }
		}
		setPersonalityColorArray(personalityColors);

		const excludeAwardColors = ["slate", "gray", "zinc", "neutral", "stone"];
		const awardColors = [];
		for (let i = 0; i < 10; i++) {
			const modifiedAwardArray = colors.filter(c => !excludeAwardColors.includes(c));
		  const randomIndex = Math.floor(Math.random() * modifiedAwardArray.length);
		  const randomColor = modifiedAwardArray[randomIndex];
		  awardColors.push(randomColor);
		  excludeAwardColors.push(randomColor);
		}
		setAwardColorArray(awardColors);

		return () => {
			clearTimeout(scrollTimeoutRef.current);
		};
	}, []);

	useEffect(() => {
		if (userId) {
			if (linkedinUrlId) {
				setLoading(true);
		    get(`/users/awards?id=${linkedinUrlId}`).then(
		      json => {
		        handleResponse(json);
		        setLoading(false);
		      },
		      error => {
		      	// wrong linkedin url id or not logged in
		      	console.log("15");
		      	console.log(error);
		      	setDefaultErrorMessage();
		      }
		    );
			} else {
				setDefaultErrorMessage();
			}
		}
	}, [userId, linkedinUrlId]);

  useEffect(() => {
    const clientSecret = searchParams.get("client_secret");
    const message = searchParams.get("message");
    if (clientSecret || message) {
      let newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("client_secret");
      newSearchParams.delete("message");
      setSearchParams(newSearchParams);
      handleResponseBuy({ client_secret: clientSecret, message });
    }

    const section = searchParams.get("s");
    const award = searchParams.get("a");
		if (section || award) {
			let newSearchParams = new URLSearchParams(searchParams);
			newSearchParams.delete("s");
			newSearchParams.delete("a");
			setSearchParams(newSearchParams);
		}
  }, [searchParams, setSearchParams]);

	const handleResponse = (json) => {
	  console.log(json);
	  const { status } = json;
	  setStatus(status);
	  switch(status) {
			case "article":
				const { link_id: linkId } = json;
				navigate(`/a/${linkId}`);
				break;
	  	case "processing":
	  	case "select":
	  		const { linkedin_url: linkedinUrl } = json;
	  		setLinkedinUrl(linkedinUrl);

	  		const { id, profileJsonCleaned, fullName, narcScores, linkedinPersonas, personalities, awards } = linkedinUrl;

	  		const linkedinPersonasFiltered = linkedinPersonas.filter(filterLinkedinPersonas)
	  			.sort((a, b) => (b.percentage - a.percentage) || (b.createdAt - a.createdAt));
	  		const personasExist = arrayExists(linkedinPersonasFiltered);
	  		if (personasExist) {
	  			const persona = searchParams.get("p");
	  			if (persona && linkedinPersonasFiltered[parseInt(persona) - 1]) {
						let newSearchParams = new URLSearchParams(searchParams);
						newSearchParams.delete("p");
						setSearchParams(newSearchParams);
						setSelectedPersonaId(linkedinPersonasFiltered[persona - 1].id);
	  			} else {
	  				setSelectedPersonaId(linkedinPersonasFiltered[0].id);
	  			}
	  		}

	  		const personalitiesFiltered = personalities.filter(filterPersonalitiesOne)
	  			.filter(filterPersonalitiesTwo)
	  			.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
	  		const personalityExists = arrayExists(personalitiesFiltered);
				if (personalityExists) {
					const { generated } = personalitiesFiltered[0];
					setGeneratedImages(generated || []);
				}

				const awardsExist = arrayExists(awards);
	  		if (awardsExist) {
	  			setAwards(awards);
	  			if (awards.some(a => a.selected)) {
		  			const { id } = awards.find(a => a.selected);
		  			setSelectedAwardId(id);
	  			}
	  		}

	  		const profileExists = profileJsonCleaned && notEmpty(fullName);
	  		const narcScoresFiltered = narcScores.filter(filterNarcScores);
	  		const narcScoresExist = arrayExists(narcScoresFiltered);
	  		if (profileExists && narcScoresExist && personasExist && personalityExists && awardsExist) {
	  			storeUserAction({ linkedinUrlId: id, actionType: "view aura" });
	  		}

	  		break;
	  	case "error":
	  		const { message } = json;
	  		setMessage(message);
	  		break;
	  	case "new":
	  		const { link_id: newLinkId } = json;
	  		navigate(`/aura/${newLinkId}`, { replace: true });
	  		break;
	  	default:
	  		break;
	  }
	};

	const setDefaultErrorMessage = () => {
		setStatus("error");
		setMessage(defaultMessage);
		setLoading(false);
	};

	// const returnLoading = () => {
	// 	if (linkedinUrl) {
	// 		const { profileUrl, fileName, fullName, profileJsonCleaned, profilePictureUrl, email, contactEmail, error, errorMessage, queued, queuePosition, loadingSessionId, personaExists, personalityOneExists, personalityTwoExists } = linkedinUrl;
	// 		const steps = [
	// 		  { 
	// 		  	id: 0, 
	// 		  	currentName: "Reviewing profile", 
	// 		  	timeEstimate: "1m",
	// 		  	completedName: "Profile reviewed",
	// 		  	upcomingName: null,
	// 		  	currentDescription: profileUrl || fileName,
	// 		  	completedDescription: fullName,
	// 		  	errorDescription: profileUrl || fileName,
	// 		  	completedSrc: profilePictureUrl,
	// 		  	status: profileJsonCleaned && fullName ? "complete" : "current",
	// 		  	// status: "current",
	// 		  	// status: "complete",
	// 		  	defaultErrorMessage: {
	// 		  		header: "Something went wrong",
	// 		  		subheader: "We were unable to review this profile. Please make sure it's a valid and public profile and try again."
	// 		  	}
	// 		  },
	// 		  { 
	// 		  	id: 1, 
	// 		  	currentName: personalityTwoExists ? "Aura check 75%" : (personalityOneExists ? "Aura check 50%" : (personaExists ? "Aura check 25%" : "Checking aura")), 
	// 		  	timeEstimate: "3m",
	// 		  	completedName: null,
	// 		  	upcomingName: "Check aura",
	// 		  	currentDescription: currentDescriptionStepTwo,
	// 		  	completedDescription: null,
	// 		  	errorDescription: null,
	// 		  	status: profileJsonCleaned && fullName ? "current" : "upcoming",
	// 		  	// status: "upcoming",
	// 		  	// status: "current",
	// 		  	defaultErrorMessage: {
	// 		  		header: "Something went wrong",
	// 		  		subheader: "We were unable to check the aura. Please try again."
	// 		  	}
	// 		  },
	// 		];
	// 		return (
	// 			<LoadingShared 
	// 				linkIdParent={linkedinUrlId}
	// 				steps={steps} 
	// 				email={email}
	// 				contactEmail={contactEmail}
	// 				readyText="when your results are ready." 
	// 				// readyText="when your awards are ready. You can also use the recent profiles section on your home page to come back." 
	// 				error={error}
	// 				errorMessage={errorMessage}
	// 				queued={queued}
	// 				queuePosition={queuePosition}
	// 				handleResponse={handleResponse}
	// 				loadingSessionId={loadingSessionId}
	// 			/>
	// 		);
	// 	}
	// 	return null;
	// };

	const returnLoading = () => {
		if (linkedinUrl) {
			return (
				<AuraLoading 
					linkIdParent={linkedinUrlId} 
					linkedinUrl={linkedinUrl} 
					setLinkedinUrl={setLinkedinUrl}
					handleResponse={handleResponse} 
					setStatus={setStatus} 
				/>
			);
		}
		return null;
	};

	const returnError = () => {
		if (message) {
			return <ErrorShared message={message} />;
		}
		return null;
	};

	const unlockAura = (source) => {
		const { id } = linkedinUrl;
    storeUserAction({ linkedinUrlId: id, actionType: `unlock aura ${source}` });
    if (loggedIn) {
    	setUnlockLoading(true);
	    postPatchToken("PATCH", "/users/unlockaura", { id: linkedinUrlId }).then(
	      json => {
	        console.log(json);
	        setUnlockLoading(false);
	        handleResponseBuy(json);
	      },
	      error => {
	      	console.log("18");
	      	console.log(error);
	      	setUnlockLoading(false);
	      	setUnlockMessage("Something went wrong. Please try again.")
	      }
	    );
    } else {
	    const modal = {
	    	...defaultModal,
	      open: true,
	      Icon: UserIcon,
	      iconColor: "indigo",
	      header: "Sign in",
	      text: "Please sign in to continue.",
	      signIn: true,
	      linkId: linkedinUrlId
	    };
	    setModal(modal);
    }
	};

	const returnHeader = () => {
		const { id, fullName, headline, uploadFile, location, connections, personalities, profileUrl, paid } = linkedinUrl;
		const returnProfile = (link) => {
			return (
				<>
	        <Avatar
	          user={linkedinUrl}
	          style="h-24 w-24 min-w-24 shadow-sm"
	          fontSize="text-2xl"
	          includeSecondInitial
	        />
	        <div className="flex flex-col max-w-full sm:max-w-md truncate">
	          <h3 className="text-lg font-semibold leading-7 tracking-tight text-gray-900 truncate">
	          	{fullName}
	          	{link && <ArrowTopRightOnSquareIcon className="w-4 h-4 mb-0.5 ml-2.5 text-gray-400 inline" />}
	          </h3>
	          <p className="mb-px text-sm leading-6 text-gray-900 truncate">{headline}</p>
	          <div className="flex flex-row items-center space-x-2 sm:space-x-4">
	          	{(location || !connections) && <div className="flex flex-row items-center truncate">
	          		<MapPinIcon className="min-w-5 w-5 min-h-5 h-5 text-gray-400 mr-1 mb-px"/>
	          		<p className="text-sm leading-6 text-gray-500 truncate">{location}</p>
	          	</div>}
	          	{connections && <div className="flex flex-row items-center truncate">
	          		<UserIcon className="min-w-[18px] min-h-[18px] w-[18px] h-[18px] text-gray-400 mr-1 mb-px"/>
	          		<p className="text-sm leading-6 text-gray-500 truncate">{parseInt(connections) >= 500 ? "500+" : connections}{connections.toString().includes("connections") ? "" : " connections"}</p>
	          	</div>}
	          </div>
	        </div>
				</>
			);
		};
		return (
	    <div className="flex flex-col items-center mb-4">
	      <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl text-center mb-3 sm:mb-4">
	        Your{' '}
	        {arrayExists(personalities) ? (
	        	<>
							<span
			        	className="text-indigo-600 cursor-pointer hover:opacity-75"
			        	onClick={() => {
			        		storeUserAction({ linkedinUrlId: id, actionType: "aura tab" });
									if (auraScrollRef?.current) {
										auraScrollRef.current.scrollIntoView({ behavior: "smooth" });
									}
			        	}}
			        >
			        	LinkedIn Aura 🔮
			        </span>
			        {' '}and{' '}
			      </>
	        ) : ""}
	        <span
	        	className="text-indigo-600 cursor-pointer hover:opacity-75"
	        	onClick={() => {
	        		storeUserAction({ linkedinUrlI: id, actionType: "awards tab" })
							if (awardsScrollRef?.current) {
								awardsScrollRef.current.scrollIntoView({ behavior: "smooth" });
							}
	        	}}
	        >
	        	CEO Awards 🏆
	        </span> 
	        {' '}are ready!
	      </h2>
	      {(uploadFile || !profileUrl) ? (
	        <div className="flex items-center gap-x-3 sm:gap-x-4 max-w-full mb-6 sm:mb-5">
	        	{returnProfile(false)}
	        </div>
	      ) : (
	        <Link
	          to={profileUrl}
	          target="_blank" 
	          rel="noopener noreferrer"
	          className="flex items-center gap-x-3 sm:gap-x-4 max-w-full mb-6 sm:mb-5 hover:opacity-75"
	          onClick={() => storeUserAction({ linkedinUrlId: id, actionType: "view linkedin" })}
	        >
		        {returnProfile(true)}
	        </Link>
	     	)}
	     	<div className="flex flex-col justify-center items-center">
	        <AuraOptions
	        	source="aura page header"
	        	linkedinUrl={linkedinUrl}
	        	showShare={showShare}
	        	shareText="Check out my LinkedIn Aura!"
	        	shareUrl={shareUrl}
	        	copiedAlert={copiedAlert}
	        	style=""
	        	unlock={!paid}
	        	unlockType="addition"
	        	//unlockButtonSize="lg"
	        	unlockButtonStyle="mt-5"
	        	unlockAura={() => unlockAura("aura header")}
	        	unlockLoading={unlockLoading}
	        	linkId={linkedinUrlId}
	        />
	       </div>
	    </div>
		);
	};

	const returnPersona = () => {
		return (
			<Persona
				linkedinUrl={linkedinUrl}
				truncateExplanation={truncateExplanation}
				setTruncateExplanation={setTruncateExplanation}
				selectedPersonaId={selectedPersonaId}
				setSelectedPersonaId={setSelectedPersonaId}
				showShare={showShare}
				shareUrl={shareUrl}
				copiedAlert={copiedAlert}
				unlockAura={unlockAura}
				unlockLoading={unlockLoading}
			/>
		);
	};

	const returnPersonalityHeader = () => {
		const { personalities, paid } = linkedinUrl;
		if (arrayExists(personalities)) {
			const [{ emojis }] = personalities;
			return (
				<div ref={auraScrollRef} className="mt-10 sm:mt-12 flex flex-col scroll-mt-4">
					{returnUserRow()}
	        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
	          Your LinkedIn Aura Check {emojis}
	        </h2>
	        <AuraOptions
	        	source="personality header"
	        	linkedinUrl={linkedinUrl}
	        	showShare={showShare}
	        	shareText="Check out my LinkedIn Aura!"
	        	shareUrl={shareUrl}
	        	copiedAlert={copiedAlert}
	        	style="self-start mt-3 sm:ml-1"
	        	small
	        	unlock={!paid}
	        	unlockType="replace"
	        	unlockButtonStyle="self-start mt-3 sm:ml-1"
	        	unlockAura={() => unlockAura("personality header")}
	        	unlockLoading={unlockLoading}
	        />
		    </div>
			);
		}
		return null;
	};

	const returnAuraHeader = (header, headerColor, emoji) => {
		return <h3 className={`text-lg font-medium ${headerColor}`}>{header}<span className="ml-2">{emoji}</span></h3>;
	};

	const textColor = "text-gray-600"

	const returnAuraText = (header, content, index) => {
		if (["Money", "Will AI Take Your Job", "Will You Survive Apocalypse"].includes(header)) {
			const numberRegex = /(\d+[\d.,%]*)/;
			const textFormatted = content.charAt(0).toUpperCase() + content.slice(1);
			const parts = textFormatted.split(numberRegex);
			return (
				<p key={index} className={textColor}>
		      {parts.map((part, i) => i === 1 ? <span key={i} className="font-bold">{part}</span> : part)}
				</p>
			);
		}
		return <p key={index} className={textColor}>{content}</p>;
	};

	const returnBoldedText = (content, boldKey, textKey, index) => {
		const { [boldKey]: bold, [textKey]: text } = content;
		if (bold) {
			const boldFormatted = bold.charAt(0).toUpperCase() + bold.slice(1);
			return <p key={index} className={textColor}><span className="font-semibold">{boldFormatted}:</span> {text}</p>;
		}
		return <p key={index} className={textColor}>{text}</p>;
	};

	const returnStringContent = (header, content, bold, boldKey, textKey) => {
		return bold ? returnBoldedText(content, boldKey, textKey) : returnAuraText(header, content);
	};

	const returnArrayContent = (header, content, bold, boldKey, textKey) => {
		return (
			<div className="space-y-1.5">
				{content.map((c, i) => bold ? returnBoldedText(c, boldKey, textKey, i) : returnAuraText(header, c, i))}
			</div>
		);
	};

	const returnNarcissismScore = (content) => {
		const { totalScore, category, motto, scoreMin, scoreMax } = content;
		return (
			<div className="flex flex-col justify-center items-center text-center sm:min-w-40 space-y-2">
				<p className="text-5xl font-bold tracking-tight text-gray-600">{totalScore}</p>
				<p className="text-sm font-medium tracking-tight text-gray-600">{scoreMin}-{scoreMax}: {category}</p>
				<p className="text-sm font-normal italic tracking-tight text-gray-600">{motto}</p>
			</div>
		);
	};

	const returnPersonalitySection = ({ sectionType, header, emoji, content, hashtag, display, showMore, bold, boldKey, textKey }, index) => {
		if (!isEmpty(content) && (showMore ? showMorePersonality : true)) {
			const generateImage = !generatedImages.includes(hashtag);
			const randomColor = personalityColorArray[index];
			const bgColor = bgColors.find(c => c.split("-")[1] === randomColor);
			const headerColor = headerColors.find(c => c.split("-")[1] === randomColor);
			return (
		  	<li key={index} className={`border border-gray-200 ${bgColor} relative ${["Narcissism Score", "Roast"].includes(header) ? "col-span-1 sm:col-span-2" : "col-span-1"} flex flex-col rounded-lg shadow-lg`}>
		  		<div className="flex flex-1 flex-col">
		  			<div className="flex flex-row justify-between items-start space-x-4 pt-6 pb-4 sm:pt-8 sm:pb-5 mx-6 sm:mx-8 border-b border-b-gray-200">
		        	{returnAuraHeader(header, headerColor, emoji)}
			        <AuraOptions
			        	source={hashtag}
			        	showShare={showShare}
			        	shareText={`Check out my ${header} ${emoji} in my LinkedIn Aura!`}
			        	shareUrl={`${shareUrl}/?s=${hashtag}`}
			        	copiedAlert={copiedAlert}
			        	style="mt-[3.5px]"
			        	small
			        	generatePersonalityImage={generateImage}
			        	setGeneratedImages={setGeneratedImages}
			        	linkedinUrl={linkedinUrl}
			        	sectionType={sectionType}
			        	header={header}
			        	emoji={emoji}
			        	content={header === "Narcissism Score" ? { ...content, summary: `${content.totalScore} - ${content.summary}` } : content}
			        	hashtag={hashtag}
			        	bold={bold}
			        	boldKey={boldKey}
			        	textKey={textKey}
			        	randomColor={randomColor}
			        	linkId={linkedinUrlId}
			        	unlock={!display}
			        	unlockType="replace"
			        	unlockButtonStyle="-mt-0.5"
			        	unlockAura={() => unlockAura(`personality section ${header}`)}
			        	unlockLoading={unlockLoading}
			        />
		        </div>
		        {/*<div className="mt-4 mb-5 sm:mt-5 sm:mb-6 h-px w-full bg-gray-200" />*/}
		        <div className="p-6 sm:p-8 pt-5 sm:pt-6 relative">
		        	{header === "Narcissism Score" ? (
				        <div className="flex flex-col-reverse sm:flex-row items-center gap-y-6 sm:space-x-8">
				        	{returnStringContent(header, content, bold, boldKey, textKey)}
				        	{returnNarcissismScore(content)}
				  			</div>
		        	) : (
				        <div className={`${display ? "" : "line-clamp-5"}`}>
				        	{sectionType === "string" ? returnStringContent(header, content, bold, boldKey, textKey) : returnArrayContent(header, content, bold, boldKey, textKey)}
				  			</div>
		        	)}
			  			{!display && <BlurLayer />}
			  		</div>
		  		</div>
		  	</li>
			);
		}
		return null;
	};

	const returnPersonalityList = () => {
		const { narcScores, personalities, paid } = linkedinUrl;
		if (arrayExists(narcScores) && arrayExists(personalities)) {
			const narcScore = narcScores.filter(filterNarcScores).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
			const personality = personalities.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
			const { 
				roast, 
				strengths, 
				weaknesses, 
				loveLife, 
				money, 
				health, 
				othersPerspective, 
				biggestGoal, 
				famousPerson, 
				pickupLines, 
				previousLife, 
				animal, 
				thingToBuy, 
				career, 
				lifeSuggestion, 
				idealCompany, 
				alternativeCareer, 
				socialLife, 
				travel, 
				nextCareerMove, 
				tedTalks, 
				retirement, 
				aiDisrupt, 
				surviveApocalypse, 
				food, 
				drink, 
				music, 
				book, 
				timePeriod, 
			} = personality;
			const personalitySections = [
				{ sectionType: "string", header: "Narcissism Score", emoji: "😱", content: narcScore, hashtag: "narc", display: true, bold: true, boldKey: "summary", textKey: "explanation" },
				{ sectionType: "string", header: "Roast", emoji: "🔥", content: roast, hashtag: "roast", display: true },
				{ sectionType: "array", header: "Pickup Lines", emoji: "😘", content: pickupLines, hashtag: "pickup", display: true },
				{ sectionType: "array", header: "Strengths", emoji: "💪", content: strengths, hashtag: "strengths", display: paid, bold: true, boldKey: "strength", textKey: "explanation" },
				{ sectionType: "array", header: "Weaknesses", emoji: "🥲", content: weaknesses, hashtag: "weaknesses", display: paid, bold: true, boldKey: "weakness", textKey: "explanation" },
				{ sectionType: "string", header: "Love Life", emoji: "❤️", content: loveLife, hashtag: "love", display: paid },
				{ sectionType: "string", header: "Money", emoji: "💸", content: money, hashtag: "money", display: paid, showMore: true },
				{ sectionType: "string", header: "Health", emoji: "🏃", content: health, hashtag: "health", display: paid, showMore: true },
				{ sectionType: "string", header: "Other's Perspective", emoji: "👀", content: othersPerspective, hashtag: "perspective", display: paid, showMore: true },
				{ sectionType: "string", header: "Biggest Goal", emoji: "🎯", content: biggestGoal, hashtag: "goal", display: paid, showMore: true },
				{ sectionType: "string", header: "Famous Person Like You", emoji: "⭐️", content: famousPerson, hashtag: "famous", display: paid, showMore: true, bold: true, boldKey: "person", textKey: "explanation" },
				{ sectionType: "string", header: "Previous Life", emoji: "♻️", content: previousLife, hashtag: "previous", display: paid, showMore: true, bold: true, boldKey: "person", textKey: "explanation" },
				{ sectionType: "string", header: "Animal", emoji: "🐼", content: animal, hashtag: "animal", display: paid, showMore: true, bold: true, boldKey: "animal", textKey: "explanation" },
				{ sectionType: "string", header: "Thing To Buy", emoji: "🛒", content: thingToBuy, hashtag: "thing", display: paid, showMore: true, bold: true, boldKey: "thing", textKey: "explanation" },
				{ sectionType: "string", header: "Career", emoji: "💼", content: career, hashtag: "career", display: paid, showMore: true },
				{ sectionType: "string", header: "Life Suggestion", emoji: "💡", content: lifeSuggestion, hashtag: "life", display: paid, showMore: true },
				{ sectionType: "string", header: "Ideal Company", emoji: "🤩", content: idealCompany, hashtag: "company", display: paid, showMore: true, bold: true, boldKey: "company", textKey: "explanation" },
				{ sectionType: "string", header: "Alternative Career", emoji:  "🤔", content: alternativeCareer, hashtag: "alt", display: paid, showMore: true, bold: true, boldKey: "career", textKey: "explanation" },
				{ sectionType: "string", header: "Social Life", emoji: "👯‍♀️", content: socialLife, hashtag: "social", display: paid, showMore: true },
				{ sectionType: "string", header: "Travel", emoji: "🛩", content: travel, hashtag: "travel", display: paid, showMore: true, bold: true, boldKey: "place", textKey: "explanation" },
				{ sectionType: "string", header: "Next Career Move", emoji: "👉", content: nextCareerMove, hashtag: "next", display: paid, showMore: true },
				{ sectionType: "array", header: "TED Talks", emoji: "🗣", content: tedTalks, hashtag: "ted", display: paid, showMore: true },
				{ sectionType: "string", header: "Retirement", emoji: "🌞", content: retirement, hashtag: "retire", display: paid, showMore: true },
				{ sectionType: "string", header: "Will AI Take Your Job", emoji: "👾", content: aiDisrupt, hashtag: "ai", display: paid, showMore: true },
				{ sectionType: "string", header: "Will You Survive Apocalypse", emoji: "👽", content: surviveApocalypse, hashtag: "survive", display: paid, showMore: true },
				{ sectionType: "string", header: "Food", emoji: "🥗", content: food, hashtag: "food", display: paid, showMore: true, bold: true, boldKey: "food", textKey: "explanation" },
				{ sectionType: "string", header: "Drink", emoji: "☕️", content: drink, hashtag: "drink", display: paid, showMore: true, bold: true, boldKey: "drink", textKey: "explanation" },
				{ sectionType: "string", header: "Music", emoji: "🎤", content: music, hashtag: "music", display: paid, showMore: true, bold: true, boldKey: "music", textKey: "explanation" },
				{ sectionType: "string", header: "Book", emoji: "📖", content: book, hashtag: "book", display: paid, showMore: true, bold: true, boldKey: "book", textKey: "explanation" },
				{ sectionType: "string", header: "Time Period", emoji: "⏳", content: timePeriod, hashtag: "time", display: paid, showMore: true, bold: true, boldKey: "period", textKey: "explanation" },
			];
			return (
	      <ul role="list" className="my-8 grid grid-cols-1 gap-6 sm:grid-cols-2">
	      	{personalitySections.map(returnPersonalitySection)}
	      </ul>
			);
		}
		return null;
	};

	const returnShowMorePersonality = () => {
		const { id } = linkedinUrl;
		return (
			<div className="w-full flex items-center justify-center mb-8">
				<SecondaryButton
					onClick={() => {
						setShowMorePersonality(!showMorePersonality);
						storeUserAction({ linkedinUrlId: id, actionType: showMorePersonality ? "hide personality" : "show more personality"});
					}}
				>
					{showMorePersonality ? "Hide sections" : "Show 20+ more sections"}
				</SecondaryButton>
			</div>
		);
	};

	const regenerateAwards = () => {
		setRegenerateLoading(true);
	  postPatch("POST", `/users/regenerate`, { id: linkedinUrlId, user_agent: userAgent }).then(
	    json => {
	      console.log(json);
	      setRegenerateLoading(false);
	      const { status, linkedin_url: linkedinUrl } = json;
	      setStatus(status);
	      setLinkedinUrl(linkedinUrl);
	      setSelectedAwardId(null);
	      setTruncateExplanation(true);
	    },
	    error => {
	    	console.log("16");
	      console.log(error);
	    }
	  );
	};

	const confirmRegenerate = () => {
		const modal = {
			...defaultModal,
			open: true,
			Icon: ExclamationTriangleIcon,
			iconColor: "red",
			header: "Regenerate awards",
			text: "Are you sure you want new awards? You will lose the awards The Committee previously selected.",
			option: "Regenerate",
			onClickOption: regenerateAwards,
		};
		setModal(modal);
	};

	const returnRegenerateButton = (regenerateRemaining) => {
		return (
	    <SecondaryButton
	    	size="lg"
	      onClick={confirmRegenerate}
	      loading={regenerateLoading}
	      disabled={regenerateRemaining === 0}
	    >
	      Regenerate
	      <span className="bg-gray-100 rounded-full h-5 w-5 flex justify-center items-center text-xs">{regenerateRemaining}</span>
	    </SecondaryButton>
		);
	};

	const returnImageButton = () => {
		return (
	    <PrimaryButton
	    	size="lg"
	      onClick={() => window.open(`/image/${linkedinUrlId}`, "_blank", "noopener,noreferrer")}
	    >
	      View image
	    </PrimaryButton>
		);
	};

	const returnUserRow = () => {
		const { fullName, profilePictureUrl } = linkedinUrl;
		return (
	  	<div className="flex flex-row items-center mb-2">
		    {profilePictureUrl && (
		    	<img
			      className="h-6 w-6 min-w-6 rounded-full mr-2"
			      src={profilePictureUrl}
			      alt="avatar"
			    />
		    )}
	      <p className="text-sm font-medium text-gray-500">
	        {fullName}
	      </p>
	    </div>
		);
	};

	const returnAwardsHeaderButtons = (paid, regenerateRemaining, sameLine) => {
		if (paid) {
			return (
		    <div className={sameLine ? "hidden lg:inline-flex ml-4 gap-x-3" : "inline-flex self-start lg:hidden mt-4 gap-x-3"}>
		      {returnRegenerateButton(regenerateRemaining)}
		      {returnImageButton()}
		    </div>
			);
		}
		return null
	};

	const returnAwardsHeader = () => {
		const { id, regenerateRemaining, paid } = linkedinUrl;
		return (
			<div ref={awardsScrollRef} className="mt-10 sm:mt-12 flex flex-col scroll-mt-4">
				{returnUserRow()}
		    <div className="lg:flex lg:items-center lg:justify-between">
	        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
	          Select CEO Award To Get{' '}
            <Link
              to={`/a/${developmentEnv ? sampleArticleIdDevelopment : sampleArticleId}`}
              target="_blank" 
              rel="noopener noreferrer"
              className="hover:opacity-75"
              onClick={() => storeUserAction({ linkedinUrlId: id, actionType: "view sample header" })}
            >
		          <span
		          	className="bg-clip-text text-transparent bg-cover bg-center bg-black gif-background cursor-pointer"
		          	style={{ backgroundImage: `url(${giphy})` }}
		          >
		          	Cover Story
		          </span>
		          <ArrowTopRightOnSquareIcon className="w-3.5 h-3.5 sm:w-4 sm:h-4 ml-1 sm:ml-2 text-gray-400 inline" />
	          </Link>
	        </h2>
	        {returnAwardsHeaderButtons(paid, regenerateRemaining, true)}
		    </div>
	      {/*<p className="mt-3 flex flex-col sm:mt-2 text-sm text-gray-500 max-w-none">
	        The Committee reviewed the profile and hand-picked these awards. Select award to get your very own cover story.
	      </p>*/}
        <AuraOptions
        	source="awards header"
        	linkedinUrl={linkedinUrl}
        	showShare={showShare}
        	shareText="Check out my CEO Awards and LinkedIn Aura!"
        	shareUrl={shareUrl}
        	copiedAlert={copiedAlert}
        	style="self-start mt-3 sm:ml-1"
        	small
        	unlock={!paid}
        	unlockType="replace"
        	unlockButtonStyle="self-start mt-3 sm:ml-1"
        	unlockAura={() => unlockAura("awards header")}
        	unlockLoading={unlockLoading}
        />
        {returnAwardsHeaderButtons(paid, regenerateRemaining, false)}
	    </div>
		);
	};

	const selectAward = (awardId) => {
		const selected = awardId === selectedAwardId;
		if (selected) {
			setSelectedAwardId(null);
		} else {
			const { id } = linkedinUrl;
			storeUserAction({ linkedinUrlId: id, awardId, actionType: "select award" });
			setSelectedAwardId(awardId);
			clearTimeout(scrollTimeoutRef.current);
			scrollTimeoutRef.current = setTimeout(() => {
				if (scrollRef?.current) {
					scrollRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
				}
			}, 400);
		}
	};

	const returnAward = (award, index) => {
		const { paid } = linkedinUrl;
		const randomColor = awardColorArray[index];
		const bgColor = bgColors.find(c => c.split("-")[1] === randomColor);
		return (
			<Award
				key={index}
				award={award}
				index={index}
				selectedAwardId={selectedAwardId}
				awards={awards}
				setAwards={setAwards}
				linkedinUrl={linkedinUrl}
				audioRefs={audioRefs}
				setAudioLoading={setAudioLoading}
				setAudioPlaying={setAudioPlaying}
				setShareAudioLoading={setShareAudioLoading}
				setDownloadAudioLoading={setDownloadAudioLoading}
				audioDisabled={audioDisabled}
				onClick={selectAward}
				showShare={showShare}
				shareUrl={shareUrl}
				copiedAlert={copiedAlert}
				randomColor={randomColor}
				// bgColor={bgColor}
				paid={paid}
				unlockAura={unlockAura}
				unlockLoading={unlockLoading}
			/>
		);
	};

	const returnAwardsList = () => {
		const displayAwards = showMoreAwards ? awards : awards.slice(0, 4);
		return (
      <ul role="list" className="my-8 grid grid-cols-1 gap-6 sm:grid-cols-2">
        {displayAwards.map(returnAward)}
      </ul>
		);
	};

	const returnShowMoreAwards = () => {
		const { id } = linkedinUrl;
		return (
			<div className="w-full flex items-center justify-center mb-8">
				<SecondaryButton
					onClick={() => {
						setShowMoreAwards(!showMoreAwards);
						storeUserAction({ linkedinUrlId: id, actionType: showMoreAwards ? "hide awards" : "show more awards"});
					}}
				>
					{showMoreAwards ? "Hide awards" : "Show 6 more awards"}
				</SecondaryButton>
			</div>
		);
	};

	const handleResponseBuy = (json) => {
	  const { client_secret: clientSecret, message } = json;
	  if (clientSecret) {
	  	if (!developmentEnv && facebookPixelEnabled) {
	    	try {
	    		ReactPixel.track("InitiateCheckout");
	    	} catch (error) {
	    		console.log("17");
	    		console.log(error);
	    	}
	  	}
	    navigate("/checkout", { state: { clientSecret } });
	  } else if (message) {
	    selectedAwardId ? setAwardMessage(message) : setUnlockMessage(message);
	  }
	};

  const buyAward = () => {
  	const { id } = linkedinUrl;
    storeUserAction({ linkedinUrlId: id, awardId: selectedAwardId, actionType: "buy award" });
    if (loggedIn) {
    	setAwardLoading(true);
	    postPatchToken("PATCH", "/users/buyaward", { id: selectedAwardId }).then(
	      json => {
	        console.log(json);
	        setAwardLoading(false);
	        handleResponseBuy(json);
	      },
	      error => {
	      	console.log("18");
	      	console.log(error);
	      	setAwardLoading(false);
	      	setAwardMessage("Something went wrong. Please try again.")
	      }
	    );
    } else {
	    const modal = {
	    	...defaultModal,
	      open: true,
	      Icon: UserIcon,
	      iconColor: "indigo",
	      header: "Sign in",
	      text: "Please sign in to continue.",
	      signIn: true,
	      linkId: linkedinUrlId,
	      awardId: selectedAwardId
	    };
	    setModal(modal);
    }
  };

	const returnBuyCard = () => {
		if (selectedAwardId && awards.some(a => a.id === selectedAwardId)) {
			const { id, fullName } = linkedinUrl;
			const selectedAward = awards.find(a => a.id === selectedAwardId);
			const { title, description, emoji } = selectedAward;
			const benefits = [
				"Personalized to your career",
				"Choose from 2 articles",
				"Choose from 4 cover images",
				"Share with personal link",
				"Get a stunning magazine cover",
				"Give an amazing gift",
			];
			return (
	      <div ref={scrollRef} className="bg-gray-900 rounded-xl p-8 xl:p-10 w-full max-w-md mb-8 self-center shadow-lg">
	        <div className="flex flex-row items-center gap-x-3">
	        	<AwardIcon
	        		emoji={emoji}
	        		position="relative"
	        		iconColor="text-slate-100"
	        		bgColor="bg-slate-100"
	        	/>
				    <div className="flex flex-col">
		          <h3 className="text-lg font-semibold leading-7 text-white">
		            {title}
		          </h3>
		          <Badge color="indigo" style="mt-1" text={fullName} dark />
	          </div>
	        </div>
	        <p className="mt-4 text-sm leading-6 text-gray-300">{description}</p>
	        <p className="mt-6 flex items-center gap-x-1.5">
	        	<span className="text-4xl font-semibold tracking-tight text-gray-300 line-through decoration-red-400 decoration-2 mr-1">$9.99</span>
	          <span className="text-4xl font-bold tracking-tight text-white">$1.99</span>
	          <span className="text-sm font-semibold leading-6 text-gray-300 ml-0.5">+</span>
	          <span className="text-sm font-semibold leading-6 text-gray-300">tax</span>
	        </p>
	        <PrimaryButton
	        	size="lg"
	        	dark
	        	style="w-full mt-6 block text-center leading-6"
	        	loading={awardLoading}
	        	onClick={buyAward}
	        >
	          Get cover story
	        </PrimaryButton>
          <Link
            to={`/a/${developmentEnv ? sampleArticleIdDevelopment : sampleArticleId}`}
            target="_blank" 
            rel="noopener noreferrer"
            className="mt-4 flex flex-row justify-center items-center space-x-1.5 hover:opacity-75"
            onClick={() => storeUserAction({ linkedinUrlId: id, awardId: selectedAwardId, actionType: "view sample buy" })}
          >
          	<p className="text-sm font-semibold leading-6 text-white hover:opacity-75">
	        		See sample cover story
	        	</p>
	        	<ArrowTopRightOnSquareIcon className="w-4 h-4 mb-1 text-gray-400"/>
	        </Link>
	        {awardMessage && <p className="mt-4 text-sm text-red-500 font-medium text-center">{awardMessage}</p>}
	        <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-300">
	          {benefits.map((benefit) => (
	            <li key={benefit} className="flex gap-x-3">
	              <CheckIcon className="h-6 w-5 flex-none text-white pb-px" aria-hidden="true" />
	              {benefit}
	            </li>
	          ))}
	        </ul>
	      </div>

			);
		}
		return null;
	};

	const returnAwards = () => {
		if (linkedinUrl && arrayExists(awards)) {
			const { paid } = linkedinUrl;
			return (
				<div className="flex flex-col">
					{returnHeader()}
					{returnPersona()}
					{returnPersonalityHeader()}
					{returnPersonalityList()}
					{returnShowMorePersonality()}
					{returnAwardsHeader()}
					{returnAwardsList()}
					{returnShowMoreAwards()}
					{returnBuyCard()}
	        <AuraOptions
	        	source="aura page footer"
	        	linkedinUrl={linkedinUrl}
	        	showShare={showShare}
	        	shareText="Check out my LinkedIn Aura!"
	        	shareUrl={shareUrl}
	        	copiedAlert={copiedAlert}
	        	style="mt-6 sm:mt-8"
	        	unlock={!paid}
	        	unlockType="addition"
	        	unlockButtonStyle="mt-6"
	        	unlockAura={() => unlockAura("aura footer")}
	        	unlockLoading={unlockLoading}
	        	linkId={linkedinUrlId}
	        />
				</div>
			);
		}
		return null;
	};

	const returnContent = () => {
		switch(status) {
			case "processing":
				return returnLoading();
			case "error":
				return returnError();
			case "select":
				return returnAwards();
			default:
				return;
		}
	};

	if (loading) {
		return <Loading />;
	}
	return (
		<div className="bg-white min-h-screen">
			<NavBar inverted={status === "error"} />
	    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-24">
	    	<div className="mx-auto max-w-4xl">
	    		{returnContent()}
	    	</div>
	    </div>
	    {!["processing", "error"].includes(status) && <Footer />}
		</div>
	);
};

export default Aura;
