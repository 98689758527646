import { Image } from "react-konva";
import useImage from "use-image";
import logo from "./assets/logo.png";
import { logoWidthOriginal, logoHeightOriginal } from "./styles/styles";

const LogoCanvas = ({ logoHeight, logoOffset, canvasWidth, canvasHeight }) => {
	const [logoImage] = useImage(logo);

  const logoWidth = logoHeight * logoWidthOriginal / logoHeightOriginal;
  const logoOffsetHorizontal = canvasWidth - logoWidth - logoOffset;
  const logoOffsetVertical = canvasHeight - logoHeight - logoOffset;

	return (
	  <Image
	    // preventDefault={false}
	  	image={logoImage}
	    x={logoOffsetHorizontal}
	    y={logoOffsetVertical}
	    width={logoWidth}
	    height={logoHeight}
	    listening={false}
	  />
	);
};

export default LogoCanvas;
