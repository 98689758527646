import { useContext, useState, useRef, useEffect } from "react";
import { ChartBarIcon } from "@heroicons/react/24/solid";
import { sample, shuffle } from "lodash";
import UrlForm from "./UrlForm";
import Award from "./Award";
import StackAward from "./StackAward";
import flipbook from "./assets/flipbook.mov";
import { LoggedInContext } from "./context/context";
import { arrayExists, colors, bgColors, headerColors, personalitySections } from "./styles/styles";

// const badgeStyle = "relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20 inline-flex flex-row items-center gap-x-1.5";
const badgeStyle = "rounded-full bg-indigo-600/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-600/10 inline-flex flex-row items-center gap-x-1.5";
const bgColorsStack = ["!bg-emerald-50", "!bg-rose-50", "!bg-indigo-50"];
const bgColorsAward = ["to-emerald-50", "to-rose-50", "to-indigo-50"];

const Hero = ({ handleResponse }) => {
	const { totalAwards, awardsInitial } = useContext(LoggedInContext);
	const [urlMessage, setUrlMessage] = useState(null);
	const [awards, setAwards] = useState(arrayExists(awardsInitial) ? shuffle(awardsInitial) : []);
	const [index, setIndex] = useState(arrayExists(awardsInitial) ? sample([...Array(awardsInitial.length).keys()]) : 0);
	const videoRef = useRef(null);
	const audioRefs = useRef([]);
	const intervalRef = useRef(null);
	const showBadge = totalAwards && false;
	const award = awards[index];

	useEffect(() => {
		videoRef.current.playbackRate = 4;
	}, []);

	useEffect(() => {
		if (arrayExists(awardsInitial)) {
			intervalRef.current = setInterval(() => {
				if (audioRefs.current.every(a => a.paused)) {
					setIndex(index => {				
						const newIndex = index === awardsInitial.length - 1 ? 0 : index + 1;
						return newIndex;
					});
				}
			}, 1000);
		}
		
		return () => {
			clearInterval(intervalRef.current);
		};
	}, [awardsInitial]);

	// const onClickAward = () => {
	// 	const newIndex = index === awardsInitial.length - 1 ? 0 : index + 1;
	// 	setIndex(newIndex);
	// };

	const returnSections = () => {
		const animations = ["animate-wiggle0", "animate-wiggle1", "animate-wiggle2", "animate-wiggle3", "animate-wiggle4"];
		return (
			<div className="flex flex-row flex-wrap sm:max-w-xl gap-2 sm:absolute -top-20 -left-20">
				{personalitySections.slice(0, 25).map(({ title, emoji }, index) => {
					const randomColor = colors[Math.floor(Math.random() * colors.length)];
					const bgColor = bgColors.find(c => c.split("-")[1] === randomColor);
					const headerColor = headerColors.find(c => c.split("-")[1] === randomColor);
					return (
						<span key={index} className={`${bgColor} ${headerColor} inline-flex self-start items-center rounded-full px-2 py-1 text-xs sm:text-[13px] font-medium border border-gray-200 ${animations[index % 5]}`}>
							{title} {emoji}
						</span>
					)
				})}
			</div>
		);
	};

	const colorIndex = index % bgColorsStack.length;
	const bgColorOne = bgColorsStack[colorIndex];
	const bgColorTwo = bgColorsStack[colorIndex + 1 > bgColorsStack.length - 1 ? 0 : colorIndex + 1];
	const bgColorThree = bgColorsAward[colorIndex + 2 > bgColorsAward.length - 1 ? colorIndex - 1 : colorIndex + 2];

	const returnAwards = () => {
		if (award) {
			return (
				<div className="min-h-[443px] sm:min-h-0 relative">
	        <StackAward award={award} style={`-rotate-[5deg] sm:!-right-[59px] ${bgColorOne} z-10`} />
	        <StackAward award={award} style={`rotate-[4deg] sm:!-bottom-[101px] ${bgColorTwo} z-20`} />
	        <Award
						award={award}
						awards={awards}
						setAwards={setAwards}
						audioRefs={audioRefs}
						audioDisabled={false}
						// onClick={onClickAward}
						bgColor={bgColorThree}
	        	home
	        />
				</div>
			);
		}
		return null;
	};

	return (
	  <main>
	    <div className="relative isolate">
	      <svg
	        className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
	        aria-hidden="true"
	      >
	        <defs>
	          <pattern
	            id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
	            width={200}
	            height={200}
	            x="50%"
	            y={-1}
	            patternUnits="userSpaceOnUse"
	          >
	            <path d="M.5 200V.5H200" fill="none" />
	          </pattern>
	        </defs>
	        <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
	          <path
	            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
	            strokeWidth={0}
	          />
	        </svg>
	        <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
	      </svg>
	      <div
	        className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
	        aria-hidden="true"
	      >
	        <div
	          className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
	          style={{
	            clipPath:
	              'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
	          }}
	        />
	      </div>
	      <div className="overflow-hidden">
	        <div className="mx-auto max-w-7xl px-6 pb-24 pt-36 sm:pb-32 sm:pt-60 lg:px-8 lg:pt-32">
	          <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
	            <div className="relative w-full max-w-2xl lg:shrink-0 xl:max-w-2xl">
	              {showBadge && (
									<span className={badgeStyle}>
		              	<ChartBarIcon className="w-4 h-4 mb-0.5" />
		                <span>{totalAwards.toLocaleString()} awards created</span>
		              </span>
	              )}
	              {/*<a 
	              	href="https://www.producthunt.com/posts/linkedin-aura-check?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-linkedin&#0045;aura&#0045;check" 
	              	target="_blank"
	              >
	              	<img
	              		src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=481900&theme=light" 
	              		alt="LinkedIn&#0032;Aura&#0032;Check - Analyze&#0032;your&#0032;LinkedIn&#0032;career&#0032;persona | Product Hunt" 
	              		className="w=[250px] h-[54px]"
	              		width="250" 
	              		height="54" 
	              	/>
	              </a>*/}
	              <h1 className={`${showBadge ? "mt-10" : "lg:mt-8"} text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl`}>
	                LinkedIn Aura Check
	              </h1>
	              <p className="mt-6 text-lg sm:text-xl leading-8 sm:leading-9 text-gray-600 sm:max-w-xl lg:max-w-none">
	                Submit LinkedIn profile to see what persona & awards you and your friends get.
	              </p>
	              <UrlForm
									urlMessage={urlMessage}
									setUrlMessage={setUrlMessage}
									handleResponse={handleResponse}
									landing
								/>
	            </div>
	            <div className="relative mt-16 sm:mt-36 lg:mt-12 lg:flex-shrink-0 lg:flex-grow space-y-10 sm:space-y-0">
			          <video 
			          	ref={videoRef}
			          	src={flipbook}
			            alt="flipbook"
									width={540}
									height={540}
									className="rounded-xl bg-gray-900/5 object-cover shadow-lg"
			            autoPlay
			            playsInline
			            muted
			            loop
			          />
			          {returnSections()}
			          {returnAwards()}
	            </div>
	          </div>
	        </div>
	      </div>
	    </div>
	  </main>
	);
};

export default Hero;
