import { useContext, useState, useRef, useEffect } from "react";
import { Text } from "react-konva";
import * as Sentry from "@sentry/react";
import AuraHeader from "./AuraHeader";
import AuraCanvasShared from "./AuraCanvasShared";
import AuraActions from "./AuraActions";
import NarcImage from "./NarcImage";
import useImage from "use-image";
import logo from "./assets/logo_inverted.png";
import { LoggedInContext } from "./context/context";
import { arrayExists, isEmpty, filterNarcScores, filterLinkedinPersonas, filterPersonalitiesOne, filterPersonalitiesTwo, backgroundImages, ogBgImages } from "./styles/styles";
import { postPatch, get } from "./utils/utils";

const AuraLoading = ({ linkIdParent, linkedinUrl, setLinkedinUrl, handleResponse, setStatus }) => {
	const { id: linkedinUrlId, uploadFile, profileJsonCleaned, fullName, narcScores, linkedinPersonas, personalities, awards, error, profilePictureUrl, auraImageSaved, narcImageSaved } = linkedinUrl;
	let totalScore, summary, category, scoreMin, scoreMax, linkedinPersona, personaImageUrl, personaName, quote, roast, pickupLine, title, description, emoji;

	const stepOneComplete = profileJsonCleaned && !isEmpty(fullName);

	const scoresFiltered = narcScores.filter(filterNarcScores).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
	const stepTwoComplete = arrayExists(scoresFiltered);
	if (stepTwoComplete) {
		({ totalScore, summary, category, scoreMin, scoreMax } = scoresFiltered[0]);
	}

	const linkedinPersonasFiltered = linkedinPersonas.filter(filterLinkedinPersonas).sort((a, b) => (b.percentage - a.percentage) || (b.createdAt - a.createdAt));
	const stepThreeComplete = arrayExists(linkedinPersonasFiltered);
	if (stepThreeComplete) {
		linkedinPersona = linkedinPersonasFiltered[0];
		({ imageUrl: personaImageUrl, name: personaName, quote } = linkedinPersona);
	}

	const personalitiesOneFiltered = personalities.filter(filterPersonalitiesOne).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
	const stepFourComplete = arrayExists(personalitiesOneFiltered);
	if (stepFourComplete) {
		({ roast } = personalitiesOneFiltered[0]);
	}

	const personalitiesTwoFiltered = personalitiesOneFiltered.filter(filterPersonalitiesTwo);
	const stepFiveComplete = arrayExists(personalitiesTwoFiltered);
	if (stepFiveComplete) {
		({ pickupLines: [pickupLine] } = personalitiesTwoFiltered[0]);
	}

	const stepSixComplete = arrayExists(awards);
	if (stepSixComplete) {
		({ title, description, emoji } = awards[0]);
	}
	const awardText = `CEO Awards: ${title}`;

	const stepOneCondition = stepOneComplete;
	const stepTwoCondition = stepOneCondition && stepTwoComplete;
	const stepThreeCondition = stepTwoCondition && stepThreeComplete;
	const stepFourCondition = stepThreeCondition && stepFourComplete;
	const stepFiveCondition = stepFourCondition && stepFiveComplete;
	const stepSixCondition = stepFiveCondition && stepSixComplete
	let fraction = 1;
	let currentStep;
	if (!stepOneComplete) {
		fraction = 0;
		currentStep = 1;
	} else if (!stepTwoComplete) {
		fraction = 1 / 6;
		currentStep = 2;
	} else if (!stepThreeComplete) {
		fraction = 2 / 6;
		currentStep = 3;
	} else if (!stepFourComplete) {
		fraction = 3 / 6;
		currentStep = 4;
	} else if (!stepFiveComplete) {
		fraction = 4 / 6;
		currentStep = 5
	} else if (!stepSixComplete) {
		fraction = 5 / 6;
		currentStep = 6;
	}
	const percentageComplete = (fraction * 100).toFixed(0) + "%";

	const { userId, cable } = useContext(LoggedInContext);
	const [src, setSrc] = useState(null);
	const [updateCanvas, setUpdateCanvas] = useState(false);
	const [firstLoad, setFirstLoad] = useState(false);
	const stageRef = useRef(null);
	const statusRef = useRef(null);
	const channel = useRef(null);
	const ogStageRef = useRef(null);

	const [bgIndex, setBgIndex] = useState(0);
	const [backgroundImage, setBackgroundImage] = useState(backgroundImages[0]);
	const [ogImage, setOgImage] = useState(ogBgImages[0]);
	const [image, status] = useImage(backgroundImage?.image);
	const [personaImage, personaStatus] = useImage(personaImageUrl, "anonymous", "origin");
	const [logoImage, logoStatus] = useImage(logo);
	const [ogBgImage, ogImageStatus] = useImage(ogImage?.image);
	const [ogProfileImage, ogProfileStatus] = useImage(profilePictureUrl, "anonymous", "origin");

	useEffect(() => {
	  const fontPromises = [
	    document.fonts.load("10pt 'Mona Sans'"), // First font
	    document.fonts.load("10pt 'Mona Sans Wide'"), // Second font (e.g., Roboto)
	  ];
		Promise.all(fontPromises).then(() => {
		  console.log('Font is available');
		  // Now that the font is available, re-render the canvas or the component that uses it.
		}).catch(error => {
		  console.error('Font loading failed:', error);
		});
	}, []);

	useEffect(() => {
		cable && connect();

		return () => {
			disconnect();
		};
	}, [cable]);

  useEffect(() => {
    if (status === "loaded") {
    	setFirstLoad(true);
    }

    if (stageRef.current) {
      const dataURL = stageRef.current.toDataURL();
      setSrc(dataURL);
    }
  }, [updateCanvas, status, personaStatus, logoStatus, profileJsonCleaned, fullName, narcScores, linkedinPersonas, personalities, awards, error]);

  useEffect(() => {
  	if (userId && stepTwoCondition && !narcImageSaved && ogImageStatus === "loaded" && (!profilePictureUrl || ogProfileStatus === "loaded") && logoStatus === "loaded") {
  		get(`/users/uploadnarcimage?id=${linkIdParent}`).then(
	      json => {
	        console.log(json);
	        const { signed_url: signedUrl } = json;
	        if (!isEmpty(signedUrl)) {
	          checkStatus(signedUrl, "narc");
	        } else {
	          handleError(json, "getSignedUrlNarc response");
	        }
	      },
	      error => {
	        console.log("40");
	        console.log(error);
	        handleError(error, "getSignedUrlNarc error")
	      }
  		);
  	}
  }, [userId, stepTwoCondition, narcImageSaved, ogImageStatus, profilePictureUrl, ogProfileStatus, logoStatus]);

  useEffect(() => {
  	if (userId && stepSixCondition && !auraImageSaved && status === "loaded" && personaStatus === "loaded" && logoStatus === "loaded") {
  		get (`/users/uploadauraimage?id=${linkIdParent}`).then(
	      json => {
	        console.log(json);
	        const { signed_url: signedUrl } = json;
	        if (!isEmpty(signedUrl)) {
	          checkStatus(signedUrl, "aura");
	        } else {
	          handleError(json, "getSignedUrlAura response");
	        }
	      },
	      error => {
	        console.log(error);
	        handleError(error, "getSignedUrlAura error")
	      }
  		);
  	}
  }, [userId, stepSixCondition, auraImageSaved, status, personaStatus, logoStatus]);

  const checkStatus = (signedUrl, type) => {
  	const condition = type === "narc" ? ogStageRef.current : (stageRef.current && (!profilePictureUrl || statusRef.current));
  	if (condition) {
  		uploadImage(signedUrl, type);
  	} else {
  		setTimeout(() => checkStatus(signedUrl, type), 100);
  	}
  };

  const uploadImage = async (signedUrl, type) => {
  	const ref = type === "narc" ? ogStageRef.current : stageRef.current;
  	const file = await ref.toBlob();

  	const headers = { "Content-Type": "image/png", "x-amz-acl": "private" };
    fetch(signedUrl, { method: "PUT", body: file, headers }).then(
      response => {
        console.log(response);
        const { status } = response;
        if (status === 200) {
          updateImageSaved(type);
        } else {
          handleError(response, "uploadNarc response");
        }
      },
      error => {
        console.log("42");
        console.log(error);
        handleError(error, "uploadNarc error");
      }
    );
  };

  const updateImageSaved = (type) => {
  	const path = type === "narc" ? "updatenarcimagesaved" : "updateauraimagesaved";
  	postPatch("PATCH", `/users/${path}`, { id: linkIdParent }).then(
  		json => {
  			console.log(json);
  			type === "narc" && ogStageRef?.current?.destroy();
  			setLinkedinUrl(linkedinUrl => {
	  			return type === "narc" ? {
	  				...linkedinUrl,
	  				narcImageSaved: true
	  			} : {
	  				...linkedinUrl,
	  				auraImageSaved: true
	  			};
  			});
  		},
  		error => {
  			console.log("43");
  			console.log(error);
  		}
  	);
  };

  const handleError = (error, functionName) => {
  	Sentry.captureException(error, { extra: { linkId: linkIdParent, functionName } });
  };

  const connect = () => {
    channel.current = cable.subscriptions.create({ channel: "UserChannel" }, {
      connected: () => console.log("connected"),
      disconnected: () => console.log("disconnected"),
      received: (data) => {
      	const { link_id: linkId } = data;
      	if (linkId === linkIdParent) {
      		handleResponse(data);
      	}
      },
    });
  };

  const disconnect = () => {
    channel?.current?.unsubscribe();
    cable?.disconnect();
  };

  const returnHeader = () => {
  	let headerText = `Checking aura ${percentageComplete}`;
  	let subheaderText = "You can share to stories when it's done!";
  	if (error) {
  		if (stepOneCondition) {
	  		headerText = "Aura check failed";
	  		subheaderText = "Please try again.";
  		} else {
	  		headerText = "Aura check failed";
	  		subheaderText = "Please make sure it's a valid and public profile and try again.";
  		}
  	} else if (stepSixCondition) {
  		headerText = "Aura complete";
  		subheaderText = "Share to stories!";
  	}
  	return (
  		<AuraHeader
  			linkedinUrlId={linkedinUrlId}
				headerText={headerText}
				subheaderText={subheaderText}
				stepSixCondition={stepSixCondition}
				percentageComplete={percentageComplete}
				error={error}
				bgIndex={bgIndex}
				setBgIndex={setBgIndex}
				setBackgroundImage={setBackgroundImage}
  		/>
  	);
  };

  const canvasWidth = 1080;
  const defaultTextSize = 35;

  const { 
  	defaultTextColor = "#111827", // gray-900
  	defaultRectFillColor = "white", 
  	activeRectFillColor, 
  	defaultRectStrokeColor, // = "#e5e7eb", // gray-200
  	loadingRectStrokeColor = "#6366f1", // indigo-500
  	errorRectStrokeColor = "#ef4444" // red-500
  } = backgroundImages[bgIndex];

  const loadingHeaderSize = defaultTextSize;
  const loadingHeaderActiveFontStyle = "600";
  const loadingHeaderFutureFontStyle = "normal";
  const loadingHeaderActiveColor = defaultTextColor;
  const {
  	loadingHeaderFutureColor = defaultTextColor, // || "#4b5563";  // gray-600
  	loadingHeaderErrorColor = "#dc2626" // red-600
  } = backgroundImages[bgIndex];
  const loadingHeaderOffsetHorizontal = 0;
  const loadingHeaderWidth = canvasWidth;

  const returnSectionColors = (index) => {
  	const active = currentStep - 1 === index;
  	return { 
  		rectColor: active ? activeRectFillColor : defaultRectFillColor, 
  		strokeColor: active ? (error ? errorRectStrokeColor : loadingRectStrokeColor) : defaultRectStrokeColor, 
  		headerColor: defaultTextColor 
  	};
  };

  const returnOpacity = (stepNumber) => {
  	return stepSixCondition || currentStep === stepNumber ? 1 : 0.8;
  };

  const returnLoadingText = (stepNumber, activeText, futureText, errorText, rectOffsetVertical, rectHeight) => {
  	const activeStep = currentStep === stepNumber;
  	const loadingHeaderOffsetVertical = -rectOffsetVertical - (rectHeight / 2) + (loadingHeaderSize / 2);
  	return (
			<Text
	      // preventDefault={false}
	      text={activeStep ? (error ? errorText : activeText) : futureText}
	      fontFamily="Mona Sans"
	      fontSize={loadingHeaderSize}
	      fontStyle={activeStep ? loadingHeaderActiveFontStyle : loadingHeaderFutureFontStyle}
	      lineHeight={1}
	      fill={activeStep ? (error ? loadingHeaderErrorColor : loadingHeaderActiveColor) : loadingHeaderFutureColor}
	      padding={0}
	      wrap="none"
	      offsetX={loadingHeaderOffsetHorizontal}
	      offsetY={loadingHeaderOffsetVertical}
	      align="center"
	      width={loadingHeaderWidth}
	      listening={false}
			/>
  	);
  };

  const returnCanvasShared = () => {
  	return (
  		<AuraCanvasShared
  			linkedinUrl={linkedinUrl}
  			profileJsonCleaned={profileJsonCleaned}
  			fullName={fullName}
  			totalScore={totalScore}
  			summary={summary}
  			personaName={personaName}
  			quote={quote}
  			roast={roast}
  			pickupLine={pickupLine}
  			emoji={emoji}
  			awardText={awardText}
  			uploadFile={uploadFile}
  			stepOneCondition={stepOneCondition}
  			stepTwoCondition={stepTwoCondition}
  			stepThreeCondition={stepThreeCondition}
  			stepFourCondition={stepFourCondition}
  			stepFiveCondition={stepFiveCondition}
  			stepSixCondition={stepSixCondition}
				src={src}
				setUpdateCanvas={setUpdateCanvas}
				firstLoad={firstLoad}
				stageRef={stageRef}
				statusRef={statusRef}
				bgIndex={bgIndex}
				backgroundImage={backgroundImage}
				image={image}
				personaImage={personaImage}
				logoImage={logoImage}
  			canvasWidth={canvasWidth}
  			defaultTextSize={defaultTextSize}
  			defaultTextColor={defaultTextColor}
  			returnSectionColors={returnSectionColors}
  			returnOpacity={returnOpacity}
  			returnLoadingText={returnLoadingText}
  		/>
  	);
  };

  const returnOgCanvas = () => {
  	if (!narcImageSaved) {
	  	return (
	  		<NarcImage 
	  			stageRef={ogStageRef}
	  			backgroundImage={ogImage}
	  			bgImage={ogBgImage}
	  			profileImage={ogProfileImage}
	  			user={linkedinUrl}
	  			logoImage={logoImage}
	  			totalScore={totalScore}
	  			category={category}
	  			scoreMin={scoreMin}
	  			scoreMax={scoreMax}
	  			summary={summary}
	  		/>
	  	);
  	}
  	return null;
  };

  const returnActions = () => {
  	return (
  		<AuraActions
  			linkIdParent={linkIdParent}
  			linkedinUrlId={linkedinUrlId}
  			fullName={fullName}
  			stageRef={stageRef}
  			bgIndex={bgIndex}
  			condition={stepSixCondition}
  			setStatus={setStatus}
  			auraImageSaved={auraImageSaved}
  		/>
  	);
  };

	return (
		<div className="flex flex-col justify-center items-center">
			{returnHeader()}
			{returnCanvasShared()}
			{returnActions()}
			{returnOgCanvas()}
	  </div>
	);
};

export default AuraLoading;
