import { notEmpty } from "./styles/styles";

const Avatar = ({ user, style, color, fontSize, includeSecondInitial }) => {
  const defaultStyle = "h-8 w-8 min-w-8 sm:h-9 sm:w-9 sm:min-w-9";
  const defaultColor = "bg-gray-500";
  const defaultFontSize = "text-base sm:text-lg";

  if (user) {
    const { profilePictureUrl, firstName, fullName } = user;
    if (profilePictureUrl) {
      return (
        <img
          className={`inline-block ${style || defaultStyle} rounded-full`}
          src={profilePictureUrl}
          alt="avatar"
        />
      );
    } else if (firstName || fullName) {
      const name = firstName || fullName;
      const returnSecondInitial = () => {
        if (notEmpty(firstName) && firstName.trim().length > 1) {
          return firstName.charAt(1).toUpperCase();
        }
        return fullName.trim().split(/\s+/).length > 1 && fullName.split(/\s+/)[1].charAt(0).toUpperCase();
      };
      return (
        <span className={`inline-flex ${style || defaultStyle} items-center justify-center rounded-full ${color || defaultColor}`}>
          <span className={`${fontSize || defaultFontSize} font-medium leading-none text-white pt-px`}>
            {name.charAt(0).toUpperCase()}
            {includeSecondInitial && returnSecondInitial()}
          </span>
        </span>
      );
    }
  }
  return null;
};

export default Avatar;
