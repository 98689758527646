import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { storeUserAction } from "./services/storeUserAction";
import { backgroundImages, classNames } from "./styles/styles";

const AuraHeader = (props) => {
	const { 
		linkedinUrlId,
		headerText,
		subheaderText,
		stepSixCondition,
		percentageComplete,
		error,
		bgIndex,
		setBgIndex,
		setBackgroundImage,
		viewFull,
	} = props;
	return (
		<div className="flex flex-col items-center w-full">
			{!stepSixCondition && (
        <div className="overflow-hidden rounded-full bg-gray-200 w-full max-w-2xl mb-6 sm:mb-8">
          <div style={{ width: percentageComplete }} className="h-2 rounded-full bg-indigo-600 transition-all duration-500 ease-out" />
        </div>
			)}
      <div className={`mx-auto max-w-2xl text-center ${viewFull ? "my-6 sm:my-8" : "mb-6 sm:mb-8"}`}>
      	<div className="flex flex-row justify-center items-center">
	        <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl flex flex-row items-center relative">
	        	{headerText}
	        	{!stepSixCondition && !error && <div className="absolute -left-6 sm:-left-8 border-2 border-indigo-600 border-t-transparent border-solid rounded-full w-4 h-4 sm:w-5 sm:h-5 animate-spin mb-0.5 mr-2 sm:mr-2.5" />}
	        	{stepSixCondition && !error && !viewFull && <CheckCircleIcon className="absolute -right-7 sm:-right-8 w-6 h-6 sm:w-7 sm:h-7 mb-0.5 ml-1 sm:ml-1.5 text-indigo-600" />}
	        	{error && <XCircleIcon className="absolute -left-7 sm:-left-8 w-6 h-6 sm:w-7 sm:h-7 mb-0.5 mr-1 sm:mr-1.5 text-red-600" />}
	        </h2>
	      </div>
        {subheaderText && (
					<p className="text-base leading-6 text-gray-600">
	          {subheaderText}
	        </p>
        )}
      </div>
      <div className="flex flex-row justify-center items-center mb-8 sm:mb-10 grid grid-cols-6 gap-3 sm:gap-4">
	      {backgroundImages.map(({ image, width, height }, index) => {
	      	const selected = index === bgIndex;
	      	return (
	      		<button
	      			key={index}
	      			className={`relative flex cursor-pointer items-center justify-center ${selected ? "" : "hover:opacity-75"}`}
	      			onClick={() => {
	      				storeUserAction({ linkedinUrlId, actionType: `change aura bg${index + 1}${viewFull ? " full" : ""}` });
	      				setBgIndex(index);
	      				setBackgroundImage(backgroundImages[index]);
	      			}}
	      		>
	      			<span className="sr-only">{index}</span>
		      		<span className="inset-0 overflow-hidden">
			      		<img
			      			className="w-10 h-10 object-cover object-center"
			      			src={image}
			      			alt={`bg${index}`}
			      		/>
			      	</span>
	            <span
	              className={classNames(
	                selected ? 'ring-fuchsia-500' : 'ring-transparent',
	                'pointer-events-none absolute inset-0 ring-4 ring-inset'
	              )}
	              aria-hidden="true"
	            />
		      	</button>
	      	);
	      })}
      </div>
    </div>
  );
};

export default AuraHeader;
