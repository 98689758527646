const AuraImage = ({ src, firstLoad }) => {
	if (firstLoad) {
		return (
			<img
				src={src}
				alt="background"
				className="w-full sm:max-w-md rounded-md sm:rounded-lg bg-gray-200"
			/>
		);
	}
	return <div className="w-full sm:max-w-md h-auto aspect-[9/16] rounded-md sm:rounded-lg bg-gray-200 animate-pulse"></div>;
};

export default AuraImage;
