import { useContext, useEffect } from "react";
import NavBar from "./NavBar";
import UploadBox from "./UploadBox";
import ProfileContent from "./ProfileContent";
import Footer from "./Footer";
import { LoggedInContext } from "./context/context";
import { storeUserAction } from "./services/storeUserAction";

const Profile = ({ awards, linkedinUrls, handleResponse }) => {
  const { userId } = useContext(LoggedInContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (userId) {
      storeUserAction({ actionType: "view profile" });
    }
  }, [userId]);
	return (
		<div className="bg-white min-h-screen">
			<NavBar />
	    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-24">
	    	<div className="mx-auto max-w-3xl">
					<UploadBox handleResponse={handleResponse} />
					<ProfileContent awards={awards} linkedinUrls={linkedinUrls} handleResponse={handleResponse} />
	    	</div>
	    </div>
			<Footer />
		</div>
	);
};

export default Profile;
