import gwyneth from "./assets/gwyneth.png";
import tom from "./assets/tom.png";
import kevin from "./assets/kevin.png";
import { isMobile } from "./styles/styles";

const images = [
	{
		id: 0,
		alt: "tom brady",
		src: tom,	
		offset: "lg:-mt-8"
	},
	{
		id: 1,
		alt: "gwyneth paltrow",
		src: gwyneth,	
		offset: "lg:mt-12"
	},
	{
		id: 2,
		alt: "kevin o'leary",
		src: kevin,	
		offset: "lg:-mt-24"
	},
];

const Story = () => {
	const returnStory = ({ id, alt, src, offset }) => {
		return (
			<div key={id} className={`${offset}`}>
				<img
					key={id}
					alt={alt} 
					src={src}
					// width={1080}
					className={`rounded-xl shadow-xl w-full lg:min-w-[300px] max-w-[400px] lg:max-w-[500px]`}
				/>
			</div>
		);
	};

	return (
	  <div className="bg-white">
	    <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-0 sm:pt-32 pb-16 sm:pb-24">
	      <div className="mx-auto max-w-xl text-center">
	        {/*<h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">#stawpp</h2>*/}
	        <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
	          What's your narcissism score? 🦚
	        </p>
          <p className="mt-3 text-lg leading-8 sm:leading-9 text-gray-600 sm:max-w-xl lg:max-w-none">
            Get your LinkedIn Aura Card to find out.
          </p>
	      </div>
	      <div className="mx-auto mt-16 lg:mt-20 lg:mx-0">
		    	<div className="flex flex-col lg:flex-row justify-center items-center gap-y-8 lg:space-x-8">
		    		{images.map(returnStory)}
		    	</div>
	      </div>
	    </div>
	  </div>
	);
};

export default Story;
