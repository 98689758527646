import { useContext, useState, useEffect } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { VideoCameraSlashIcon, VideoCameraIcon, GifIcon as GifIconSolid, HandThumbDownIcon as HandThumbDownIconSolid, HandThumbUpIcon as HandThumbUpIconSolid, GiftIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { GifIcon, ArrowTopRightOnSquareIcon, CheckCircleIcon, HandThumbDownIcon, HandThumbUpIcon } from "@heroicons/react/24/outline";
import AwardCard from "./AwardCard";
import PostGenerator from "./PostGenerator";
import SecondaryButton from "./SecondaryButton";
import Avatar from "./Avatar";
import ArticleOptions from "./ArticleOptions";
import Notification from "./Notification";
import SignInButtons from "./SignInButtons";
import { LoggedInContext } from "./context/context";
import { handleCopy, handleEmail, handleShareMobile } from "./services/handleShareSocial";
import { storeUserAction } from "./services/storeUserAction";
import { classNames } from "./styles/styles";
import { developmentEnv, webUrl, postPatch, postPatchToken } from "./utils/utils";

const useVideoIcons = true;

const copiedAlert = { show: true, Icon: CheckCircleIcon, header: "Link copied!", subheader: "Share the article with the world." };
const giftCopiedAlert = { ...copiedAlert, subheader: "Who's the lucky person?" };

const ArticleDisplay = ({ linkId, article, setArticle, giftIdParent }) => {
  const { userId, setLoading, loggedIn, user, setAlert, alertTimeout } = useContext(LoggedInContext);
  const { 
    id: articleId, 
    createdAt, 
    showAnimation, 
    articleContents, 
    articleVisuals, 
    currentOwner, 
    magazineCoverSaved,
    feedback, 
    showPostGenerator,
    giftSender,
    giftRecipient,
    linkedinUrl, 
    award, 
    giftId, 
    giftNotification, 
    user: sendingUser, 
    receivingUser,
  } = article;
  const [selectedArticleVisualId, setSelectedArticleVisualId] = useState(articleVisuals.find(a => a.selected).id);
  const [showShare, setShowShare] = useState(false);
  const [feedbackReceived, setFeedbackReceived] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [giftNotificationDismissed, setGiftNotificationDismissed] = useState(false);
  const [giftMessage, setGiftMessage] = useState(null);
  const [giftMessageDismissed, setGiftMessageDismissed] = useState(false);
  const [showAnimationLoading, setShowAnimationLoading] = useState(false);
  const shareUrl = window.location.href;
  const giftUrl = `${webUrl}/g/${giftId}`;
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const linkedinUrlId = linkedinUrl?.id;

  const { title, location, body } = articleContents.find(a => a.selected);
  const owner = loggedIn && currentOwner;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userId) {
      storeUserAction({ articleId, actionType: giftIdParent ? "view gift" : "view article" });
    }
  }, [userId]);

  useEffect(() => {
    if (!developmentEnv) {
      if (title && navigator.canShare && navigator.canShare({ title, text: shareUrl })) {
        setShowShare(true);
      }
    }
  }, [title, shareUrl]);

  useEffect(() => {
    const giftMessageParam = searchParams.get("gift_message");
    if (giftMessageParam) {
      setGiftMessage(giftMessageParam);
      let newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("gift_message");
      setSearchParams(newSearchParams);
    }
  }, [searchParams, setSearchParams]);

  const returnGiftBadge = () => {
    if (giftIdParent || (loggedIn && (giftSender || giftRecipient)) || receivingUser) {
      let user, text;
      if (loggedIn && giftSender) {
        const { fullName } = receivingUser;
        user = receivingUser;
        text = `${fullName} received your gift`;
      } else if (giftIdParent || (loggedIn && giftRecipient)) {
        const { fullName } = sendingUser;
        user = sendingUser;
        text = `${fullName} sent you a gift`;
      } else {
        const { fullName } = sendingUser;
        user = sendingUser;
        text = `Nominated by ${fullName}`;
      }
      return (
        <div className="flex flex-row items-center mb-4 gap-x-1.5 max-w-2xl">
          <Avatar
            user={user}
            style="h-6 w-6 min-w-6"
            fontSize="text-xs"
          />
          <p className="truncate text-sm text-gray-500">{text}</p>
        </div>
      );
    }
    return null;
  };

  const dismissGiftNotification = () => {
    postPatchToken("PATCH", "/users/dismissgiftnotification", { id: linkId, article: { gift_notification: false } }).then(
      json => {
        console.log(json);
        setGiftNotificationDismissed(true);
      },
      error => {
        console.log("3");
        console.log(error);
      }
    );
  };

  const handleCopyGift = () => {
    handleCopy(linkedinUrlId, null, articleId, null, "copy gift link", giftUrl, setAlert, giftCopiedAlert, alertTimeout);
  };

  const handleEmailGift = async () => {
    const emailSubject = encodeURIComponent(`You have a gift! ${title}`);
    const emailBody = encodeURIComponent(`View award: ${giftUrl}`);
    handleEmail(null, articleId, "share gift email", emailSubject, emailBody);
  };

  const returnGiftingNotification = () => {
    if (owner && giftNotification && !giftNotificationDismissed) {
      const options = [
        {
          id: 0,
          text: "Copy gift link",
          onClick: handleCopyGift
        },
        {
          id: 1,
          text: showShare ? "Share gift link" : "Email gift link",
          onClick: () => {
            if (showShare) {
              handleShareMobile(null, articleId, "share gift mobile", title, "I got you a gift!", giftUrl, "share gift mobile success", "share gift mobile fail");
            } else {
              handleEmailGift();
            }
          }
        }
      ];
      return (
        <Notification
          color="indigo"
          style="mb-4 mx-4 sm:mx-6 lg:absolute lg:top-5 lg:z-50"
          Icon={GiftIcon}
          header="Is this a gift?"
          subheader="Use the special gift link to share the award. Once gifted, the recipient will have control."
          options={options}
          onClickDismiss={dismissGiftNotification}
        />
      );
    } else if (giftMessage && !giftMessageDismissed) {
      return (
        <Notification
          color="red"
          style="mb-4 mx-4 sm:mx-6 lg:absolute lg:top-5 lg:z-50"
          Icon={XCircleIcon}
          header={giftMessage}
          onClickDismiss={() => setGiftMessageDismissed(true)}
        />
      );
    }
    return null;
  };

  const returnHeader = () => {
    return (
      <header className="flex flex-col text-center max-w-2xl px-4 sm:px-6 lg:px-8 mt-[72px] sm:mt-24 lg:mt-32">
        <h1 className="mt-6 font-display text-4xl sm:text-5xl leading-[44px] sm:leading-14 font-medium tracking-tight text-neutral-950 [text-wrap:balance]">
          {title}
        </h1>
        <time
          dateTime={createdAt}
          className="order-first text-sm text-neutral-950"
        >
          {new Date(createdAt).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric", timeZone: "UTC" })}
        </time>
        <p className="mt-6 text-sm font-semibold text-neutral-950">
          {location}
        </p>
      </header>
    );
  };

  const returnArticleOptions = (style) => {
    return (
      <ArticleOptions
        article={article}
        setArticle={setArticle}
        linkId={linkId}
        showShare={showShare}
        shareUrl={shareUrl}
        copiedAlert={copiedAlert}
        style={style}
        owner={owner}
        handleCopyGift={handleCopyGift}
      />
    );
  };

  const updateShowAnimation = (showAnimation) => {
    setShowAnimationLoading(true);
    storeUserAction({ articleId, actionType: "update animation" });
    postPatchToken("PATCH", "/users/updateshowanimation", { id: linkId, article: { show_animation: showAnimation } }).then(
      json => {
        const newArticle = { ...article, showAnimation };
        setArticle(newArticle);
        setShowAnimationLoading(false)
      },
      error => {
        console.log("4");
        console.log(error);
      }
    );
  };

  const returnAnimatinoIcon = () => {
    let IconOne = GifIcon;
    let IconTwo = GifIconSolid;
    if (useVideoIcons) {
      IconOne = VideoCameraIcon;
      IconTwo = VideoCameraSlashIcon;
    }
    const IconName = showAnimation ? IconOne : IconTwo;
    return (
      <button 
        className="absolute top-0 right-0 p-3 sm:p-4 text-white"
        onClick={() => updateShowAnimation(!showAnimation)}
        disabled={showAnimationLoading}
      >
        <IconName className="w-6 h-6 sm:w-8 sm:h-8" />
      </button>
    );
  };

  const returnImageGift = () => {
    const { imageUrl } = articleVisuals.find(a => a.id === selectedArticleVisualId);
    if (giftIdParent) {
      return (
        <div className="relative flex flex-col items-center mt-24 sm:mt-32 lg:mt-40 -my-px mx-auto max-w-[76rem] px-0 sm:px-6 lg:px-8">
          <div className="relative">
            <img 
              src={imageUrl}
              alt="cover"
              className="w-full relative"
              sizes="(min-width: 1216px) 76rem, 100vw"
              fetchpriority="high"
            />
            <div className="absolute inset-0 bg-white bg-opacity-75" />
            <div className="absolute inset-0 bg-gradient-to-t from-white via-white" />
          </div>
          <div className="absolute bottom-[10%] sm:bottom-1/4 flex flex-col items-center">
            <h2 className="text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">
              Sign in to accept your award
            </h2>
            <SignInButtons giftId={giftIdParent} style="mt-6 sm:mt-8 !gap-y-4" />
          </div>
        </div>
      );
    }
  };

  const returnImageGallery = () => {
    const { imageUrl, animation, animationUrl } = articleVisuals.find(a => a.id === selectedArticleVisualId);
    const canControlAnimation = owner && animation && animationUrl;
    const shouldPlayAnimation = showAnimation && animation && animationUrl;
    const articleVisualsModified = articleVisuals
      .filter(a => a.saved && !a.midjourneyOriginal)
      .sort((a, b) => a.selected !== b.selected ? (b.selected - a.selected) : (new Date(a.createdAt) - new Date(b.createdAt)))
      .slice(0, 4);
    return (
      <div className="mt-24 sm:mt-32 lg:mt-40 -my-px mx-auto max-w-[76rem] px-0 sm:px-6 lg:px-8">
        <div className="relative"> 
          <video 
            alt="article"
            src={animationUrl}
            poster={imageUrl}
            className={`w-full h-auto relative ${shouldPlayAnimation ? "visible" : "invisible"}`}
            autoPlay
            playsInline
            muted
            loop
          /> 
          <img 
            src={imageUrl}
            alt="cover article visual"
            className={`w-full h-auto absolute top-0 left-0 ${shouldPlayAnimation ? "hidden" : "visible"}`}
            sizes="(min-width: 1216px) 76rem, 100vw"
            fetchpriority="high"
            // quality={90}
            // priority
          />
          {canControlAnimation && returnAnimatinoIcon()}
        </div>
        <div className="grid grid-cols-4 gap-3 sm:gap-6 mt-3 sm:mt-6 px-3 sm:px-0">
          {articleVisualsModified.map((image) => {
            const { id, imageUrl, imageUrlMedium } = image;
            const selected = id === selectedArticleVisualId;
            return (
              <button
                key={id}
                className="relative flex cursor-pointer items-center justify-center"
                onClick={() => setSelectedArticleVisualId(id)}
              >
                <span className="sr-only">{id}</span>
                <span className="inset-0 overflow-hidden">
                  <img src={imageUrlMedium || imageUrl} alt="gallery" className={`h-full w-full object-cover object-center`} />
                </span>
                <span
                  className={classNames(
                    selected ? 'ring-indigo-500' : 'ring-transparent',
                    'pointer-events-none absolute inset-0 ring-[6px] sm:ring-8 ring-inset'
                  )}
                  aria-hidden="true"
                />
              </button>
            );
          })}
        </div>
        {magazineCoverSaved && (
          <div className="flex flex-col items-center">
            <Link
              className="mt-2.5 sm:mt-4 flex flex-row items-center space-x-1 text-neutral-500 hover:text-neutral-600" 
              to={`/cover/${linkId}`} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <p className="text-base">Get magazine cover</p>
              <ArrowTopRightOnSquareIcon className="w-4 h-4 mb-0.5"/>
            </Link>
          </div>
        )}
      </div>
    );
  };

  const returnBody = () => (
    <div className="mt-24 sm:mt-32 lg:mt-40 text-neutral-950 text-xl leading-8 max-w-2xl px-6 lg:px-8">
      {body.map((b, index) => {
        return (
          <p key={index} className="first:mt-0 mt-10 first:first-letter:text-6xl first:first-letter:font-bold first:first-letter:mr-3 first:first-letter:mt-1 first:first-letter:float-left first:first-line:uppercase first:first-line:tracking-wider first:first-letter:not-italic first:first-line:italic">{b}</p>
        );
      })}
      <div className="w-4 h-4 bg-neutral-950 mt-3.5" />
    </div>
  );

  const sendArticleFeedback = (response) => {
    setFeedbackReceived(response ? "positive" : "negative");
    postPatch("POST", "/users/articlefeedback", { id: linkId, article_feedback: { feedback: response } }).then(
      json => {
        console.log(json);
        setFeedbackMessage("Thank you!");
      },
      error => {
        console.log("5");
        console.log(error);
      }
    );
  };

  const buttons = [
    {
      id: 0,
      IconDefault: HandThumbUpIcon,
      IconSelected: HandThumbUpIconSolid,
      colorSelected: "bg-green-500",
      positive: true
    },
    {
      id: 1,
      IconDefault: HandThumbDownIcon,
      IconSelected: HandThumbDownIconSolid,
      colorSelected: "bg-red-500",
      positive: false
    },
  ];

  const returnFeedback = () => {
    if (!feedback || developmentEnv) {
      return (
        <div className="flex flex-col self-center text-center text-neutral-500 gap-y-2 mt-24 sm:mt-32 lg:mt-40">
          <div className="flex flex-row items-center gap-x-3">
            <p className="text-base tracking-tight pt-0.5">Did you like the article?</p>
            {buttons.map(({ id, IconDefault, IconSelected, colorSelected, positive }) => {
              const selected = feedbackReceived === (positive ? "positive" : "negative");
              const selectedClasses = positive ? "border-green-500 text-green-500 ring-1 ring-inset ring-green-500" : "border-red-500 text-red-500 ring-1 ring-inset ring-red-500";
              const defaultClass = "border-neutral-300 text-neutral-400 hover:bg-neutral-100";
              const Icon = selected ? IconSelected : IconDefault;
              return (
                <button
                  key={id}
                  className={`p-1.5 border rounded ${selected ? selectedClasses : defaultClass}`}
                  onClick={() => sendArticleFeedback(positive)}
                >
                  <Icon className="w-5 h-5" />
                </button>
              );
            })}
          </div>
          {feedbackMessage && <p className="text-base tracking-tight font-medium py-px">{feedbackMessage}</p>}
        </div>
      );
    }
    return null;
  };

  const returnPostGenerator = () => {
    if (showPostGenerator) {
      return (
        <PostGenerator 
          user={user} 
          linkId={linkId}
          articleId={articleId} 
          awardId={award.id} 
          linkedinUrlId={linkedinUrlId}
          shareUrl={shareUrl} 
        />
      );
    }
    return null;
  };

  const returnCreateButton = () => {
    return (
      <SecondaryButton
        onClick={() => {
          storeUserAction({ articleId, actionType: owner ? "create award owner" : "create award" });
          setLoading(true);
          navigate("/");
        }}
        style="mt-24 sm:mt-32 lg:mt-40 sm:text-base"
      >
        <span>{owner ? "Create another award" : "Create my own award"}</span>
        <span>🖤</span>
      </SecondaryButton>
    );
  };

  if (giftIdParent) {
    return (
      <div className={"mx-auto flex flex-col items-center overflow-hidden py-10 sm:py-12 lg:py-16 self-center"}>
        {returnGiftBadge()}
        <AwardCard linkedinUrl={linkedinUrl} award={award} gift />
        {returnHeader()}
        {returnImageGift()}
      </div>
    );
  }
  return (
    <div className={"mx-auto flex flex-col items-center overflow-hidden py-10 sm:py-12 lg:py-16 self-center"}>
      {returnGiftingNotification()}
      {returnGiftBadge()}
      <AwardCard articleId={articleId} linkedinUrl={linkedinUrl} award={award} />
      {returnHeader()}
      {returnArticleOptions("mt-8 sm:mt-10")}
      {returnImageGallery()}
      {returnBody()}
      {returnFeedback()}
      {returnArticleOptions("mt-24 sm:mt-32 lg:mt-40")}
      {returnPostGenerator()}
      {returnCreateButton()}
    </div>
  );
};

export default ArticleDisplay;
