import { useContext, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { EyeIcon, GiftIcon, ArrowRightIcon, LinkIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import ReCAPTCHA from "react-google-recaptcha";
import PrimaryButton from "./PrimaryButton";
import TextButton from "./TextButton";
import public_profile from "./assets/public_profile.png";
import { LoggedInContext } from "./context/context";
import { storeUserAction } from "./services/storeUserAction";
import { empty, notEmpty, defaultModal, demoVideo } from "./styles/styles";
import { postPatch } from "./utils/utils";

const UrlForm = ({ urlMessage, setUrlMessage, handleResponse, tabs, setSelectedTab, setFileMessage, landing }) => {
	const { loggedIn, setModal } = useContext(LoggedInContext);
	const [url, setUrl] = useState(null);
	const [urlLoading, setUrlLoading] = useState(false);
	const recaptchaRef = useRef(null);
	const urlRegex = new RegExp("^https?://((www|\w\w)\.)?linkedin.com/((in/[^/]+/?)|(pub/[^/]+/((\w|\d)+/?){3}))$", "gi");
	const disabled = empty(url) || !urlRegex.test(url.trim().split(/[?#]/)[0]);
	const useCaptcha = loggedIn && false;

	const onClickLinkedinInfo = () => {
		storeUserAction({ actionType: "view linkedin info" });
    const modal = {
    	...defaultModal,
      open: true,
      Icon: LinkIcon,
      iconColor: "indigo",
      header: "How to get LinkedIn public profile URL",
      text: "On desktop, it's easy, just copy the link from the browser. On mobile, check if the link has the user's name. If not, go to their Contact section.",
      src: public_profile,
      alt: "public-profile",
      footer: (
      	<span>
      		See more:{' '}
      		<a
      			className="hover:opacity-75"
      			href="https://www.linkedin.com/help/linkedin/answer/a522735"
            target="_blank" 
            rel="noopener noreferrer"
      		>
      			https://www.linkedin.com/help/linkedin/answer/a522735
      		</a>
      	</span>
      ),
    };
    setModal(modal);
	};

	const handleChangeUrl = ({ target: { value: urlNew } }) => {
		setUrl(urlNew);
		setUrlMessage(null);
	};

	const handleSubmitUrl = () => {
		if (useCaptcha) {
			recaptchaRef.current.execute();
		} else {
			onChangeCaptcha(null);
		}
	};

	const handleBlur = () => {
		if (notEmpty(url) && disabled) {
			setUrlMessage("Please enter a valid LinkedIn public profile URL.");
		}
	};

	const onChangeCaptcha = (value) => {
		if (url.includes("devin-booker-8a1a30265")) {
			setUrlMessage("Sorry, we could not find any aura for this user – heard him say he hates gymnastics.");
		} else {
			setUrlLoading(true);
			setUrlMessage(null);
			postPatch("POST", "/users/url", { recaptcha_token: value, linkedin_url: { profile_url: url.trim().split(/[?#]/)[0] } }).then(
				json => {
					console.log(json);
					setUrlLoading(false);
					const { path, message } = json;
					if (path) {
						handleResponse(json);
					} else {
						setUrlMessage(message);
					}
				},
				error => {
					console.log("38");
					console.log(error);
					setUrlLoading(false);
					setUrlMessage("Something went wrong - please try again.");
				}
			);
		}
	};

	const returnCaptcha = () => {
		if (useCaptcha) {
			return (
		    <ReCAPTCHA
		      ref={recaptchaRef}
		      size="invisible"
		      sitekey="6LfYDoApAAAAADZb5ra6JWrKtZGbOf75ska8tPxz"
		      onChange={onChangeCaptcha}
		    />
			);
		}
	};

	const returnMessage = () => {
		if (landing) {
			return <p className="mt-3 text-sm text-red-500 font-medium">{urlMessage}<span className="hidden sm:inline-block">&nbsp;</span></p>;
		}
		return urlMessage && <p className="mt-3 text-sm text-red-500 font-medium">{urlMessage}</p>;
	};

	const returnInfo = () => {
		if (!landing) {
			return (
				<>
		      <div className="mt-3 flex flex-row space-x-1.5 text-gray-400">
		      	<EyeIcon className="h-4 w-4 min-w-4 mt-0.5" />
		        <p className="text-sm leading-6">
		        	Make sure the profile is public or{' '}
		        	<Link
		        		onClick={() => {
		        			setSelectedTab(tabs[1]);
		            	setUrlMessage(null);
		            	setFileMessage(null);
		        		}}
		        		className="text-white font-medium cursor-pointer hover:opacity-85"
		        	>
		        		upload pdf
		        	</Link>
		        	.
		        </p>
		      </div>
		      <div className="mt-1 flex flex-row space-x-1.5 text-gray-400">
		      	<GiftIcon className="h-4 w-4 min-w-4 mt-0.5" />
		        <p className="text-sm leading-6">You can gift the award when it's done.</p>
		      </div>
				</>
			);
		}
	};

	// const onKeyDown = (e) => {
	// 	e.preventDefault();
	// 	if (e.key === "Enter" && !e.shiftKey && !disabled && !urlLoading) {
	// 		handleSubmitUrl();
	// 	}
	// };

	let inputStyle;
	if (landing) {
		inputStyle = "mr-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6";
	} else {
		inputStyle = "mr-3 block w-full rounded-md border bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6";
	}

	return (
		<form className={`${landing ? "mt-8 sm:max-w-md lg:max-w-lg xl:max-w-xl" : "mt-4 sm:mt-5"}`}>
      <label htmlFor="linkedin-url" className={`flex text-sm font-medium leading-6 ${landing ? "text-gray-900" : "text-white"} gap-x-2`}>
        LinkedIn public profile URL 
        <TextButton style="relative" onClick={onClickLinkedinInfo}>
        	<InformationCircleIcon className={`${landing ? "text-gray-900/80" : "text-white/80"} h-5 w-5 min-w-5 mb-0.5`} />
        	<span className="absolute -inset-1.5" />
        </TextButton>
      </label>
      <div className="mt-2 flex flex-row">
        <input
          id="linkedin-url"
          name="linkedin-url"
          type="url"
          placeholder="https://linkedin.com/in/example"
          className={inputStyle}
        	onChange={handleChangeUrl}
        	onBlur={handleBlur}
        	// onKeyDown={onKeyDown}
        	// autoComplete={demoVideo ? "off" : "url"}
        />
        <PrimaryButton
        	size="lg"
        	dark={!landing}
        	loading={urlLoading}
        	disabled={disabled}
        	onClick={handleSubmitUrl}
        >
          <ArrowRightIcon className="h-5 w-5" aria-hidden="true" />
        </PrimaryButton>
      </div>
      {returnCaptcha()}
      {returnMessage()}
      {returnInfo()}
		</form>
	);
};

export default UrlForm;
