import { createContext } from "react";
import { defaultAlert, defaultModal } from "../styles/styles";

export const LoggedInContext = createContext({
  userId: null,
  loading: false,
  setLoading: () => null,
  loggedIn: false,
  setLoggedIn: () => {},
  cable: null,
  setCable: () => {},
  user: null,
  setUser: () => {},
  awards: null,
  setAwards: () => {},
  linkedinUrls: null,
  setLinkedinUrls: () => {},
  totalAwards: null,
  setTotalAwards: () => {},
  alert: defaultAlert,
  setAlert: () => {},
  alertTimeout: { current: null },
  modal: defaultModal,
  setModal: () => {},
  awardsInitial: null,
  setAwardsInitial: () => {},
});
