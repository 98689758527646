import { useEffect } from "react";
import { Stage, Layer, Image, Rect, Text, Path, Arc } from "react-konva";
import Konva from "konva";
import useImage from "use-image";
import { isEmpty, logoWidthOriginal, logoHeightOriginal } from "./styles/styles";

const crownPathData = "M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6l277.2 0c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z";

Konva.pixelRatio = Math.min(2, window.devicePixelRatio);

const NarcImage = ({ stageRef, backgroundImage, bgImage, profileImage, totalScore, category, scoreMin, scoreMax, summary, logoImage }) => {

  const canvasWidth = 1200;
  const canvasHeight = 630;

  const originalAspectRatio = backgroundImage?.width / backgroundImage?.height;
  const targetAspectRatio = canvasWidth / canvasHeight;
  let cropWidth, cropHeight, cropX, cropY;
  if (originalAspectRatio > targetAspectRatio) {
    // Image is wider than target aspect ratio
    cropHeight = backgroundImage?.height;
    cropWidth = cropHeight * targetAspectRatio;
    cropX = (backgroundImage?.width - cropWidth) / 2; // Crop from the center horizontally
    cropY = 0;
  } else {
    // Image is taller than target aspect ratio
    cropWidth = backgroundImage?.width;
    cropHeight = cropWidth / targetAspectRatio;
    cropX = 0;
    cropY = (backgroundImage?.height - cropHeight) / 2; // Crop from the center vertically
  }

  const backgroundOpacity = 0.5;

	const defaultColor = "white";
	const headerSize = 46;
	const headerPaddingTop = 45;
	const headerPaddingBottom = 40;
	const headerOffsetX = 0;
	const headerOffsetY = -headerPaddingTop;

	const arcOpacity = 0.3;
	const arcInnerRadius = 300;
	const arcOuterRadius = 320;
	const arcPositionX = canvasWidth / 2;
	const arcPositionY = headerPaddingTop + headerSize + headerPaddingBottom + arcOuterRadius;
	let arcColor;
	switch (true) {
		case totalScore <= 40:
			arcColor = "#86efac"; // green-300
			break;
		case totalScore > 40 && totalScore <= 60:
			arcColor = "#facc15"; // yellow-400
			break;
		case totalScore > 60 && totalScore <= 80:
			arcColor = "#f97316"; // orange-500
			break;
		case totalScore > 80 && totalScore <= 100:
			arcColor = "#dc2626"; // red-600
			break;
		default:
			break;
			// arcColor = defaultColor;
	}

	const crownOriginalWidth = 576;
	const crownOriginalHeight = 512;
	const crownScaleX = 0.1;
	const crownScaleY = 0.08;
	const crownPaddingTop = 45;
	const crownPositionX = ( canvasWidth - crownOriginalWidth * crownScaleX ) / 2;
	const crownPositionY = headerPaddingTop + headerSize + headerPaddingBottom + (arcOuterRadius - arcInnerRadius) + crownPaddingTop;

	const scoreSize = 160;
	const scorePaddingTop = 25;
	const scoreOffsetX = 0;
	const scoreOffsetY = -(crownPositionY + crownOriginalHeight * crownScaleY + scorePaddingTop);

	const categorySize = 32;
	const categoryColor = "#e5e7eb"; // gray-200
	const categoryPaddingTop = 20;
	const categoryOffsetX = 0;
	const categoryOffsetY = scoreOffsetY - scoreSize - categoryPaddingTop;

	const summarySize = 54;
	const summaryPadding = 100;
	const summaryPaddingTop = 50;
	const summaryOffsetX = 0;
	const summaryOffsetY = categoryOffsetY - categorySize - summaryPaddingTop + summaryPadding;

  const logoHeight = 45;
  const logoWidth = logoHeight * logoWidthOriginal / logoHeightOriginal;
  const logoOffsetVertical = 30;
  const logoOffsetHorizontal = canvasWidth - logoWidth - logoOffsetVertical;

  const profileSize = 80;
  const profileRadius = 20;
  const profileOffset = logoOffsetVertical - ((profileSize - logoHeight) / 2) + 5;

	return (
		<Stage
			ref={stageRef}
			width={canvasWidth}
			height={canvasHeight}
			listening={false}
			className="hidden"
		>
			<Layer listening={false}>
	      <Image
	        // preventDefault={false}
	      	image={bgImage}
	        x={0}
	        y={0}
	        width={canvasWidth}
	        height={canvasHeight}
          crop={{
            x: cropX,
            y: cropY,
            width: cropWidth,
            height: cropHeight,
          }}
	        listening={false}
	      />
        <Rect
        	// preventDefault={false}
          x={0}
          y={0}
          width={canvasWidth}
          height={canvasHeight}
          fill="black"
          opacity={backgroundOpacity}
          listening={false}
        />
	      <Text
	        // preventDefault={false}
	        text="Narcissism Score"
	        fontFamily="Mona Sans"
	        fontSize={headerSize}
	        fontStyle="600"
	        lineHeight={1}
	        fill={defaultColor}
	        padding={0}
	        wrap="none"
	        offsetX={headerOffsetX}
	        offsetY={headerOffsetY}
	        align="center"
	        width={canvasWidth}
	        listening={false}
	      />
        <Arc
          x={arcPositionX} // x position of the arc
          y={arcPositionY} // y position of the arc
          innerRadius={arcInnerRadius} // Inner radius of the arc
          outerRadius={arcOuterRadius} // Outer radius of the arc
          angle={180} // Angle of the arc (in degrees)
          rotation={180} // Starting rotation of the arc (0 is the right side)
          fill={defaultColor}
          opacity={arcOpacity}
        />
        {!isEmpty(totalScore) && 
	        <Arc
	          x={arcPositionX} // x position of the arc
	          y={arcPositionY} // y position of the arc
	          innerRadius={arcInnerRadius} // Inner radius of the arc
	          outerRadius={arcOuterRadius} // Outer radius of the arc
	          angle={totalScore * 1.80} // Angle of the arc (in degrees)
	          rotation={180} // Starting rotation of the arc (0 is the right side)
	          fill={arcColor}
	        />
      	}
        <Path
        	x={crownPositionX}
        	y={crownPositionY}
        	data={crownPathData}
        	fill={defaultColor}
        	scale={{ x: crownScaleX, y: crownScaleY }}
        />
	      <Text
	        // preventDefault={false}
	        text={totalScore}
	        fontFamily="Mona Sans"
	        fontSize={scoreSize}
	        fontStyle="600"
	        lineHeight={1}
	        fill={defaultColor}
	        padding={0}
	        wrap="none"
	        offsetX={scoreOffsetX}
	        offsetY={scoreOffsetY}
	        align="center"
	        width={canvasWidth}
	        listening={false}
	      />
	      <Text
	        // preventDefault={false}
	        text={`${scoreMin}-${scoreMax}: ${category}`}
	        fontFamily="Mona Sans"
	        fontSize={categorySize}
	        fontStyle="600"
	        lineHeight={1}
	        fill={categoryColor}
	        padding={0}
	        wrap="none"
	        offsetX={categoryOffsetX}
	        offsetY={categoryOffsetY}
	        align="center"
	        width={canvasWidth}
	        listening={false}
	      />
	      <Text
	        // preventDefault={false}
	        text={summary}
	        fontFamily="Mona Sans"
	        fontSize={summarySize}
	        fontStyle="bold"
	        lineHeight={1}
	        fill={defaultColor}
	        padding={summaryPadding}
	        wrap="none"
	        offsetX={summaryOffsetX}
	        offsetY={summaryOffsetY}
	        align="center"
	        width={canvasWidth}
	        ellipsis={true}
	        listening={false}
	      />
			  <Image
			    // preventDefault={false}
			  	image={logoImage}
			    x={logoOffsetHorizontal}
			    y={logoOffsetVertical}
			    width={logoWidth}
			    height={logoHeight}
			    listening={false}
			  />
		    <Image
		      // preventDefault={false}
		    	image={profileImage}
		      x={profileOffset}
		      y={profileOffset}
		      width={profileSize}
		      height={profileSize}
		      cornerRadius={profileRadius}
		      listening={false}
		    />
			</Layer>
		</Stage>
	);
};

export default NarcImage;
