import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import Content from "./Content";
import Footer from "./Footer";
import { LoggedInContext } from "./context/context";
import { storeUserAction } from "./services/storeUserAction";
import { companyEmail, websiteName } from "./styles/styles";

const sections = [
	{
		title: "1. Introduction",
		content: [
			{
				type: "text",
				content: `${websiteName} provides a service for users to generate LinkedIn personas, unique awards, and cover stories based on their LinkedIn profiles or resumes. ${websiteName} is committed to innovation, excellence, and integrity in delivering your persona, award, and cover story.`
			},
			{
				type: "text",
				content: `This document, the \"Terms of Use,\" governs your access to and use of ${websiteName}, including any content, functionality, and services offered on or through our website. By using our services, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, incorporated herein by reference. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use ${websiteName}.`
			},
			{
				type: "text",
				content: "Our service utilizes sophisticated Artificial Intelligence (AI) algorithms to generate your persona, unique awards, and cover stories based on your career and personality. It's important to understand how this technology works and your rights in relation to the content generated. We encourage you to read these Terms of Use carefully as they contain important information regarding your legal rights, remedies, and obligations.",
			},
		]
	},
	{
		title: "2. Acceptance of Terms",
		content: [
			{
				type: "text",
				title: "2.1 Agreement to Terms.",
				content: `By accessing or using ${websiteName}, you confirm that you have read, understood, and agreed to be bound by these Terms of Use. If you do not agree with any part of these Terms, you must not use our services.`
			},
			{
				type: "text",
				title: "2.2 Age Requirement.",
				content: `The services of ${websiteName} are not intended for individuals under the age of 13. By using this service, you represent and warrant that you meet this age requirement.`
			},
			{
				type: "text",
				title: "2.3 Modification of Terms.",
				content: `${websiteName} reserves the right to update or modify these Terms of Use at any time without prior notice. Your continued use of the service after such changes indicates your acceptance of the new Terms. We encourage you to periodically review the Terms to stay informed of our updates.`
			},
			{
				type: "text",
				title: "2.4 Acceptance of Privacy Policy.",
				content: "By agreeing to these Terms, you also agree to the collection, use, and sharing of your information as outlined in our Privacy Policy."
			},
			{
				type: "text",
				title: "2.5 Termination or Discontinuation of Service.",
				content: `You may stop using the services at any time. ${websiteName} also reserves the right to suspend or terminate your access to the service for any reason, at its sole discretion, without notice.`
			}
		]
	},
	{
		title: "3. User Accounts and Responsibilities",
		content: [
			{
				type: "text",
				title: "3.1 Account Creation.",
				content: `To access and use the services provided by ${websiteName}, users are required to create an account. You must provide accurate and complete information during the registration process and keep this information up to date.`
			},
			{
				type: "text",
				title: "3.2 Account Security.",
				content: `You are responsible for maintaining the confidentiality of your account login information and are fully responsible for all activities that occur under your account. ${websiteName} cannot and will not be liable for any loss or damage arising from your failure to comply with this security obligation.`
			},
			{
				type: "text",
				title: "3.3 User Conduct.",
				content: `As a user of ${websiteName}, you agree to not misuse the services or help anyone else do so. This includes, but is not limited to, refraining from sending spam, distributing viruses or other harmful code, infringing on intellectual property rights, or engaging in illegal or fraudulent activities through the use of our service.`
			},
			{
				type: "text",
				title: "3.4 Content Responsibility.",
				content: `While ${websiteName} provides a platform for content creation, you are solely responsible for the content generated under your account and its compliance with laws and regulations. You should ensure that all content created through our service is legal, non-infringing, and in accordance with these Terms.`
			},
			{
				type: "text",
				title: "3.5 Prohibited Uses.",
				content: "The service may not be used in connection with any activities, content, or materials that:"
			},
			{
				type: "list",
				content: [
					"Are illegal, fraudulent, or deceptive.",
					"Infringe on anyone's rights, including privacy and intellectual property rights.",
					"Are harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable."
				]
			},
			{
				type: "text",
				title: "3.6 Reporting Misuse.",
				content: `If you encounter any misuse of the service or violation of these Terms, you are encouraged to report it to ${websiteName} immediately. We are committed to ensuring a safe and legal environment for all users.`
			},
			{
				type: "text",
				title: "3.7 Suspension and Termination.",
				content: `${websiteName} reserves the right to suspend or terminate your account and access to the services if you are found to be in violation of these Terms of Use.`
			}
		]
	},
	{
		title: "4. Pricing, Payment, and Refunds",
		content: [
			{
				type: "text",
				title: "4.1 Pricing.",
				content: "The price for each cover story (referred to as \"Content\") will be clearly stated on our website prior to purchase. Prices are subject to change, but such changes will not affect orders for which a confirmation has already been sent. All prices are exclusive of taxes, which will be added to the total amount due where applicable."
			},
			{
				type: "text",
				title: "4.2 Payment.",
				content: `Payment for Content must be made at the time of ordering. We accept various forms of payment as indicated on our website, including credit/debit cards and other digital payment methods. By submitting an order, you authorize ${websiteName} to charge the designated payment method for the total amount of the order, including any applicable taxes and fees. You must ensure that all payment information you provide is accurate and current. We bear no responsibility for payment delays or denials caused by incorrect or outdated information.`
			},
			{
				type: "text",
				title: "4.3 Billing.",
				content: "Upon successful payment, you will receive a confirmation of your order via email. This confirmation will serve as your receipt and proof of purchase. In cases where payment processing is delayed or fails, access to the ordered Content may be withheld until payment is successfully processed."
			},
			{
				type: "text",
				title: "4.4 Payment Security.",
				content: "We use secure payment processing services to ensure the safety and confidentiality of your financial information. Your payments are processed securely with Stripe, and we do not handle or store your payment information. However, we cannot be held liable for any unauthorized access or loss of personal information that is beyond our control."
			},
			{
				type: "text",
				title: "4.5 Cancellation and Refunds.",
				content: "Orders for Content are considered final and cannot be cancelled once the payment is processed and the Content is delivered. Due to the digital nature of the Content and processing costs, refunds are generally not provided. However, if you encounter technical issues that prevent access to or proper use of the Content, you will be automatically refunded if appropriate. If you do not receive a refund, please contact our customer support for assistance. The decision to issue a refund is at our sole discretion and will typically require demonstrable proof of the issue claimed by the user."
			},
			{
				type: "text",
				title: "4.6 Changes to Pricing Policy.",
				content: "We reserve the right to make changes to our pricing and payment policies at any time. Such changes will be effective immediately upon posting on our website, except as otherwise noted."
			}
		]
	},
	{
		title: "5. Intellectual Property Rights",
		content: [
			{
				type: "text",
				title: "5.1 Access to User Data.",
				content: `You give us permission to access and use your LinkedIn profile and/or resume data which are required to provide website services. If you generate content for another person, you represent that you have the right to access and use that person's LinkedIn profile and/or resume data.`
			},
			{
				type: "text",
				title: "5.2 Ownership of Service Content.",
				content: `All content and materials provided on ${websiteName}, excluding user-generated content, are the intellectual property of ${websiteName} or its licensors. This includes, but is not limited to, the design, layout, look, appearance, graphics, and documentation.`
			},
			{
				type: "text",
				title: "5.3 User-Generated Content.",
				content: `Users retain all rights to the content they create using ${websiteName}'s services. However, by generating content, users grant ${websiteName} a worldwide, non-exclusive, royalty-free license to use, reproduce, display, and distribute such content in connection with the service.`
			},
			{
				type: "text",
				title: "5.4 Prohibited Use.",
				content: `Users may not use any content generated on ${websiteName} for any illegal or unauthorized purpose. Users agree not to copy, modify, distribute, sell, or lease any part of our service or included software, nor to reverse engineer or attempt to extract the source code of that software, except as laws prohibit these restrictions.`
			},
			{
				type: "text",
				title: "5.5 Copyright and Trademark Compliance.",
				content: `Users are responsible for ensuring that the content created using ${websiteName} does not infringe on the intellectual property rights of others. This includes respecting copyrights, trademarks, and other proprietary rights.`
			},
			{
				type: "text",
				title: "5.6 Reporting Infringements.",
				content: `If you believe that any content on ${websiteName} infringes on your intellectual property rights, you are encouraged to notify us. ${websiteName} respects the intellectual property rights of others and will respond promptly to any legitimate infringement notices.`
			},
			{
				type: "text",
				title: "5.7 Disclaimer.",
				content: `While ${websiteName} strives to ensure that all generated content is original and does not infringe on the intellectual property rights of third parties, it cannot guarantee the authenticity or legality of the content created by its AI algorithms.`
			}
		]
	},
	{
		title: "6. AI Ethics and Compliance",
		content: [
			{
				type: "text",
				title: "6.1 Ethical Use of AI.",
				content: `${websiteName} is committed to the responsible and ethical use of artificial intelligence (AI) in generating personas, awards, and cover stories. We adhere to principles of fairness, transparency, and accountability in our AI operations. Users are expected to use the AI-generated content in a manner that is ethical and in compliance with applicable laws and standards. Any use of the AI-generated content that violates ethical standards or promotes harmful or discriminatory practices is strictly prohibited.`
			},
			{
				type: "text",
				title: "6.2 Compliance with Laws and Regulations.",
				content: "Our AI systems are designed to comply with relevant data protection and privacy laws, intellectual property rights, and other applicable legal frameworks. Users are responsible for ensuring that their use of the AI-generated content adheres to all local, national, and international laws."
			},
			{
				type: "text",
				title: "6.3 Responsibility for AI-Generated Content.",
				content: `${websiteName} provides AI-generated content as a tool for users. However, users bear the sole responsibility for the use and application of this content. ${websiteName} is not liable for any direct or indirect consequences that may arise from the use or interpretation of the AI-generated content.`
			},
			{
				type: "text",
				title: "6.4 Reporting Ethical Concerns.",
				content: "Users are encouraged to report any instances of unethical AI use or content generation they encounter while using the service. We are committed to investigating such concerns promptly and taking appropriate action to maintain the integrity and ethical standards of our service."
			},
			{
				type: "text",
				title: "6.5 Regular Review and Updates.",
				content: "Our AI models and ethical guidelines are subject to regular review and updates to ensure alignment with evolving ethical standards, legal requirements, and best practices in the field of AI and marketing. We reserve the right to modify our AI systems and ethical guidelines to maintain compliance and ethical integrity."
			}
		]
	},
	{
		title: "7. Limitation of Liability",
		content: [
			{
				type: "text",
				title: "7.1 Disclaimer of Warranties.",
				content: `${websiteName} provides its service and AI-generated content on an "as is" and "as available" basis. While we strive to ensure accuracy, relevance, and quality in the content generated, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on ${websiteName} for any purpose. Any reliance you place on such information is therefore strictly at your own risk.`
			},
			{
				type: "text",
				title: "7.2 Limitation of Liability.",
				content: `To the fullest extent permitted by law, ${websiteName} shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the service; (ii) any conduct or content of any third party on the service; (iii) any content obtained from the service; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage.`
			},
			{
				type: "text",
				title: "7.3 Limitation on Claims.",
				content: "You agree that any cause of action arising out of or related to the website or the service must commence within one (1) year after the cause of action accrues. Otherwise, such cause of action is permanently barred."
			},
			{
				type: "text",
				title: "7.4 Indemnification.",
				content: `You agree to defend, indemnify, and hold harmless ${websiteName}, its parent company, officers, directors, employees, and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from: (i) your use of and access to the service; (ii) your violation of any term of these Terms of Use; (iii) your violation of any third-party right, including without limitation any copyright, trademark, or privacy right; or (iv) any claim that your content caused damage to a third party.`
			},
			{
				type: "text",
				title: "7.5 Modifications to Service.",
				content: `${websiteName} is not liable for any modification, price change, suspension, or discontinuance of the service. Users acknowledge that ${websiteName} reserves the right at any time to modify or discontinue the service (or any part thereof) with or without notice, and that ${websiteName} shall not be liable to you or to any third party for any modification, suspension, or discontinuance of the service.`
			}
		]
	},
	{
		title: "8. Termination of Use",
		content: [
			{
				type: "text",
				title: "8.1 Right to Terminate Access.",
				content: `${websiteName} reserves the right to terminate or suspend your access to the service immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms of Use. Upon termination, your right to use the service will immediately cease.`
			},
			{
				type: "text",
				title: "8.2 User-Initiated Cancellation.",
				content: "You may cancel your account and discontinue your use of the service at any time. To cancel your account, you must follow the account cancellation procedures outlined on the website or contact customer support for assistance. Cancellation will result in the immediate deactivation of your account and access to the service. Any content or data associated with your account may not be recoverable once your account is cancelled."
			},
			{
				type: "text",
				title: "8.3 Effect of Termination.",
				content: `Upon termination or suspension, regardless of the reasons therefore, your right to access and use the services immediately ceases, and you acknowledge and agree that we may immediately deactivate or delete your account and all related information and files in your account and/or bar any further access to such files or the service. ${websiteName} shall not be liable to you or any third party for any claims or damages arising out of any termination or suspension or any other actions taken by us in connection with such termination or suspension.`
			},
			{
				type: "text",
				title: "8.4 Survival of Terms.",
				content: `Termination of your access to the service does not relieve you of any obligations arising or accruing prior to termination or limit any liability which you otherwise may have to ${websiteName} or any third party.`
			},
			{
				type: "text",
				title: "8.5 Post-Termination Handling of Content.",
				content: `Upon termination, ${websiteName} may retain copies of any content or data provided by you as part of your use of the service for archival, legal, or regulatory purposes. Such retention will be in accordance with the Privacy Policy and applicable laws.`
			}
		]
	},
	{
		title: "9. Dispute Resolution",
		content: [
			{
				type: "text",
				title: "9.1 Agreement to Arbitrate.",
				content: `You and ${websiteName} agree to resolve any claims relating to these Terms of Use or the service through final and binding arbitration.`
			},
			{
				type: "text",
				title: "9.2 Arbitration Procedures.",
				content: `The American Arbitration Association (AAA) will administer the arbitration under its Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes. The arbitration will be held in Delaware, at another mutually agreed location, or via telephone or video conference.`
			},
			{
				type: "text",
				title: "9.3 Costs of Arbitration.",
				content: "Payment of all filing, administration, and arbitrator fees will be governed by the AAA's rules."
			},
			{
				type: "text",
				title: "9.4 No Class Actions.",
				content: "You may only resolve disputes with us on an individual basis and may not bring a claim as a plaintiff or a class member in a class, consolidated, or representative action."
			},
			{
				type: "text",
				title: "9.5 Judicial Forum for Disputes.",
				content: `In the event that the agreement to arbitrate is found not to apply to you or your claim, you and ${websiteName} agree that any judicial proceeding will be brought in the federal or state courts of Delaware. Both you and ${websiteName} consent to venue and personal jurisdiction there.`
			},
			{
				type: "text",
				title: "9.6 Time Limitation on Claims.",
				content: `You agree that any claim you may have arising out of or related to your relationship with ${websiteName} must be filed within one year after such claim arose; otherwise, your claim is permanently barred.`
			}
		]
	},
	{
		title: "10. General Provisions",
		content: [
			{
				type: "text",
				title: "10.1 Severability.",
				content: "If any provision of these Terms of Use is deemed unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions. The remaining provisions of the Terms of Use shall remain in full force and effect."
			},
			{
				type: "text",
				title: "10.2 Waiver.",
				content: `No waiver by ${websiteName} of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure by ${websiteName} to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.`
			},
			{
				type: "text",
				title: "10.3 Relationship of the Parties.",
				content: `Nothing in these Terms of Use shall be construed to create a partnership, joint venture, employment, or agency relationship between you and ${websiteName}. You do not have any authority of any kind to bind ${websiteName} in any respect whatsoever.`
			},
			{
				type: "text",
				title: "10.4 Entire Agreement.",
				content: `These Terms of Use, together with our Privacy Policy and any other legal notices or terms published by ${websiteName} on the website, constitute the entire agreement between you and ${websiteName} concerning the service provided. This agreement supersedes all prior or contemporaneous communications and proposals, whether electronic, oral, or written, between you and ${websiteName} with respect to this website.`
			},
			{
				type: "text",
				title: "10.5 Assignment.",
				content: "You may not assign these Terms of Use or any rights or obligations herein without our prior written consent. We may assign these Terms of Use or any rights or obligations herein to any third party without your consent."
			}
		]
	},
	{
		title: "11. Contact Information",
		content: [
			{
				type: "text",
				html: (
					<span>
						For any questions, concerns, or comments regarding these Terms of Use, or any other inquiries related to our website and services, please email us at{' '}
						<Link to={`mailto:${companyEmail}`} className="font-semibold text-indigo-600 hover:text-indigo-500" target="_blank" rel="noreferrer">{companyEmail}</Link>.
					</span>
				)
			}
		]
	},
	{
		title: "12. Effective Date",
		content: [
			{
				type: "text",
				content: "These Terms of Use are effective as of January 4, 2024. By accessing, browsing, or otherwise using our website from this date onward, you acknowledge and agree to the terms and conditions set forth in this document."
			}
		]
	},
];

const Terms = () => {
	const { userId } = useContext(LoggedInContext);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
  useEffect(() => {
    if (userId) {
      storeUserAction({ actionType: "view terms" });
    }
  }, [userId]);
	return (
    <div className="bg-white min-h-screen">
    	<NavBar />
    	<Content title="Terms" sections={sections} />
      <Footer />
    </div>
	);
};

export default Terms;
