import { Link } from "react-router-dom";
import { companyEmail, returnTikTokLogo, returnInstagramLogo, returnTwitterLogo } from "./styles/styles";

const navigation = {
  main: [
    { name: companyEmail, href: `mailto:${companyEmail}`, newTab: true },
    { name: "FAQ", href: "/faq", newTab: false },
    { name: "Terms", href: "/terms", newTab: false },
    { name: "Privacy", href: "/privacy", newTab: false },
  ],
  social: [
    {
      name: "TikTok",
      href: "https://www.tiktok.com/@ceo.list",
      icon: returnTikTokLogo,
      newTab: true,
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/ceolist",
      icon: returnInstagramLogo,
      newTab: true,
    },
    {
      name: "Twitter",
      href: "https://twitter.com/ceo_list",
      icon: returnTwitterLogo,
      newTab: true,
    },
  ],
};
const newTabProps = { target: "_blank", rel: "noreferrer" };

const Footer = ({ disclaimer }) => {
  return (
    <footer className="relative z-10">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        {/*disclaimer && <p className="mb-10 text-center text-sm leading-6 text-gray-900 font-medium">
          *CEO List is not affiliated with LinkedIn.
        </p>*/}
        <nav className="-mb-6 columns-1 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="pb-6 text-center">
              <Link to={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900" {...(item.newTab ? newTabProps : {})}>
                {item.name}
              </Link>
            </div>
          ))}
        </nav>
        {/*<div className="mt-10 flex justify-center items-center space-x-10">
          {navigation.social.map((item) => (
            <Link key={item.name} to={item.href} className="text-gray-400 hover:text-gray-500" {...(item.newTab ? newTabProps : {})}>
              <span className="sr-only">{item.name}</span>
              <item.icon aria-hidden="true" />
            </Link>
          ))}
        </div>*/}
        <p className="mt-10 text-center text-xs leading-5 text-gray-500">
          &copy; 2024 Clssfd, Inc. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
