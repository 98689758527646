import { useContext, useState, useEffect } from "react";
import { PlayIcon, StopIcon, ArrowUpOnSquareIcon, ArrowDownTrayIcon, LinkIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import copy from "copy-to-clipboard";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import { LoggedInContext } from "./context/context";
import { storeUserAction } from "./services/storeUserAction";
import { updateAlert } from "./services/updateAlert";
import { notEmpty } from "./styles/styles";
import { developmentEnv, get, postPatch } from "./utils/utils";

const copiedAlert = { show: true, Icon: CheckCircleIcon, header: "Link copied!", subheader: "Share persona and awards." };

const AudioButtons = (props) => {
	const { 
		id, 
		indexAward, 
		title, 
		name, 
		audioLoading, 
		setAudioLoading,
		audioPlaying, 
		setAudioPlaying,
		shareAudioLoading, 
		setShareAudioLoading,
		downloadAudioLoading, 
		setDownloadAudioLoading,
		audioUrl, 
		awards, 
		setAwards, 
		linkedinUrl, 
		setLinkedinUrl,
		audioRefs,
		linkId,
		disabled,
		home,
	} = props;
	const { setAlert, alertTimeout } = useContext(LoggedInContext);
	const [showShare, setShowShare] = useState(false);
	const shareUrl = window.location.href;
	let linkedinUrlId;
	if (linkedinUrl) {
		({ id: linkedinUrlId } = linkedinUrl);
	}
	const persona = notEmpty(name);
	const index = persona || home ? 0 : indexAward + 1;
	const buttonStyle = home ? "!px-2.5 m-2" : "!p-2 m-2";
	const iconSize = home ? "w-5 h-5" : "w-4 h-4";

	useEffect(() => {
    if (!developmentEnv && !home && navigator.canShare && navigator.canShare({ text: shareUrl })) {
    	setShowShare(true);
    }
	}, []);

	const pauseAudio = () => {
		if (home) {
			storeUserAction({ actionType: "pause audio" });
			const newAwards = awards.map(a => {
				if (a.id !== id) {
					return a;
				}
				return {
					...a,
					audioPlaying: false,
				};
			});
			setAwards(newAwards);
		} else if (persona) {
			storeUserAction({ linkedinUrlId, actionType: "pause audio" });
			setAudioPlaying(false);
		} else {
			storeUserAction({ linkedinUrlId, awardId: id, actionType: "pause audio" });
			const newAwards = awards.map(a => {
				if (a.id !== id) {
					return a;
				}
				return {
					...a,
					audioPlaying: false,
				};
			});
			setAwards(newAwards);
		}

		const audioRef = audioRefs.current[index];
		audioRef.pause();
	};

	const playAudio = () => {
		if (home) {
			storeUserAction({ actionType: "play audio" });
			const newAwards = awards.map(a => {
				if (a.id !== id) {
					return {
						...a,
						audioPlaying: false
					};
				}
				return {
					...a,
					audioPlaying: true,
				};
			});
			setAwards(newAwards);
		} else if (persona) {
			storeUserAction({ linkedinUrlId, actionType: "play audio" });
			const newAwards = awards.map(a => {
				return {
					...a,
					audioPlaying: false
				};
			});
			setAwards(newAwards);
			setAudioPlaying(true);
		} else {
			storeUserAction({ linkedinUrlId, awardId: id, actionType: "play audio" });
			const newAwards = awards.map(a => {
				if (a.id !== id) {
					return {
						...a,
						audioPlaying: false
					};
				}
				return {
					...a,
					audioPlaying: true,
				};
			});
			setAwards(newAwards);
			setAudioPlaying(false);
		}

		audioRefs.current.forEach((audioRef, refIndex) => {
			if (refIndex !== index) {
				audioRef.pause();
			} else {
				audioRef.currentTime = 0;
				audioRef.play();
			}
		});
	};

	const generateAudio = () => {
		if (persona) {
			storeUserAction({ linkedinUrlId, actionType: "play audio" });
			const newAwards = awards.map(a => {
				return {
					...a,
					audioPlaying: false
				};
			});
			setAwards(newAwards);
			setAudioLoading(true);
		} else {
			storeUserAction({ linkedinUrlId, awardId: id, actionType: "play audio" });
			const newAwards = awards.map(a => {
				if (a.id !== id) {
					return {
						...a,
						audioPlaying: false
					};
				}
				return {
					...a,
					audioLoading: true
				};
			});
			setAwards(newAwards);
			setAudioPlaying(false);
		}

		audioRefs.current.forEach((audioRef, refIndex) => {
			if (refIndex !== index) {
				audioRef.pause();
			}
		});

		if (persona) {
		  postPatch("POST", `/users/audiopersona`, { id: linkId }).then(
		    json => {
		      console.log(json);
		      const { audio_url: audioUrl } = json;
		      if (audioUrl) {
						const newAwards = awards.map(a => {
							return {
								...a,
								audioPlaying: false
							};
						});
						setAwards(newAwards);
						setAudioLoading(false)
						setAudioPlaying(true);
						const newLinkedinUrl = {
							...linkedinUrl,
							linkedinPersonas: linkedinUrl.linkedinPersonas.map(l => {
								if (l.id !== id) {
									return l;
								}
								return {
									...l,
									audioUrl
								};
							})
						};
						setLinkedinUrl(newLinkedinUrl);
						setTimeout(() => {
							const audioRef = audioRefs.current[index];
							audioRef.play();
						}, 500);
		      } else {
						stopLoadingAudio(id);
		      }
		    },
		    error => {
		    	console.log("10");
		      console.log(error);
		      stopLoadingAudio(id);
		    }
		  );
		} else {
		  postPatch("POST", `/users/audio`, { id }).then(
		    json => {
		      console.log(json);
		      const { audio_url: audioUrl } = json;
		      if (audioUrl) {
						const newAwards = awards.map(a => {
							if (a.id !== id) {
								return {
									...a,
									audioPlaying: false
								};
							}
							return {
								...a,
								audioLoading: false,
								audioPlaying: true,
								audioUrl
							};
						});
						setAwards(newAwards);
						setTimeout(() => {
							const audioRef = audioRefs.current[index];
							audioRef.play();
						}, 500);
		      } else {
						stopLoadingAudio(id);
		      }
		    },
		    error => {
		    	console.log("11");
		      console.log(error);
		      stopLoadingAudio(id);
		    }
		  );
		}
	};

	const stopLoadingAudio = () => {
		if (persona) {
			const newAwards = awards.map(a => {
				return {
					...a,
					audioPlaying: false
				};
			});
			setAwards(newAwards);
			setAudioLoading(false);
		} else {
			const newAwards = awards.map(a => {
				if (a.id !== id) {
					return {
						...a,
						audioPlaying: false
					};
				}
				return {
					...a,
					audioLoading: false,
				};
			});
			setAwards(newAwards);
		}
	};

	const onAudioEnded = () => {
		if (persona) {
			setAudioPlaying(false);
		} else {
			const newAwards = awards.map(a => {
				if (a.id !== id) {
					return a;
				}
				return {
					...a,
					audioPlaying: false,
				};
			});
			setAwards(newAwards);
		}
	};

	const showShareSheet = (audioUrl) => {
	  fetch(audioUrl)
	    .then((response) => { return response.blob(); })
	    .then(async (blob) => {
				if (persona) {
					const newLinkedinUrl = {
						...linkedinUrl,
						linkedinPersonas: linkedinUrl.linkedinPersonas.map(l => {
							if (l.id !== id) {
								return l;
							}
							return {
								...l,
								audioUrl
							};
						})
					};
					setLinkedinUrl(newLinkedinUrl);
					setShareAudioLoading(false);
		      const file = new File([blob], `Career persona - ${name}.mp3`, { type: "audio/mpeg" });
		      await navigator.share({ title: `Career persona - ${name}`, text: shareUrl, files: [file] })
		        .then(() => {
		          storeUserAction({ linkedinUrlId, actionType: "share persona success" });
		        })
		        .catch(error => {
		          storeUserAction({ linkedinUrlId, actionType: "share persona fail" });
		        });
				} else {
					const newAwards = awards.map(a => {
						if (a.id !== id) {
							return a;
						}
						return {
							...a,
							audioUrl,
							shareAudioLoading: false
						};
					});
					setAwards(newAwards);
		      const file = new File([blob], `${title}.mp3`, { type: "audio/mpeg" });
		      await navigator.share({ title, text: shareUrl, files: [file] })
		        .then(() => {
		          storeUserAction({ linkedinUrlId, awardId: id, actionType: "share audio success" });
		        })
		        .catch(error => {
		          storeUserAction({ linkedinUrlId, awardId: id, actionType: "share audio fail" });
		        });
				}
	    });
	};

  const shareAudio = async () => {
  	if (persona) {
			storeUserAction({ linkedinUrlId, actionType: "share persona" });
			// setShareAudioLoading(true);
	  	// if (audioUrl) {
	  	// 	showShareSheet(audioUrl);
	  	// } else {
			//   postPatch("POST", "/users/audiopersona", { id: linkId }).then(
			//     json => {
			//       console.log(json);
			//       const { audio_url: audioUrl } = json;
			//       if (audioUrl) {
			//       	showShareSheet(audioUrl);
			//       } else {
			//       	stopShareAudioLoading();
			//       }
			//     },
			//     error => {
			//     	console.log(error);
			//     	stopShareAudioLoading();
			//     }
			//   );
			// };
      await navigator.share({ title: `Career persona - ${name}`, text: shareUrl })
        .then(() => {
          storeUserAction({ linkedinUrlId, actionType: "share persona success" });
        })
        .catch(error => {
          storeUserAction({ linkedinUrlId, actionType: "share persona fail" });
        });
  	} else {
			storeUserAction({ linkedinUrlId, awardId: id, actionType: "share audio" });

			const newAwards = awards.map(a => {
				if (a.id !== id) {
					return a;
				}
				return {
					...a,
					shareAudioLoading: true
				};
			});
			setAwards(newAwards);

	  	if (audioUrl) {
	  		showShareSheet(audioUrl);
	  	} else {
			  postPatch("POST", "/users/audio", { id }).then(
			    json => {
			      console.log(json);
			      const { audio_url: audioUrl } = json;
			      if (audioUrl) {
			      	showShareSheet(audioUrl);
			      } else {
			      	stopShareAudioLoading();
			      }
			    },
			    error => {
			    	console.log("12");
			    	console.log(error);
			    	stopShareAudioLoading();
			    }
			  );
			};
  	}
	};

	const stopShareAudioLoading = () => {
		if (persona) {
			setShareAudioLoading(false);
		} else {
			const newAwards = awards.map(a => {
				if (a.id !== id) {
					return a;
				}
				return {
					...a,
					shareAudioLoading: false
				};
			});
			setAwards(newAwards);
		}
	};

	const getSignedUrl = () => {
    get(`/users/downloadaudio?id=${id}`).then(
      json => {
        console.log(json);
        const { signed_url: signedUrl } = json;
        if (signedUrl) {
	        const link = document.createElement("a");
	        link.href = signedUrl;
	        link.download = `${title}.mp3`;
	        document.body.appendChild(link);
	        link.click();
	        document.body.removeChild(link);
					const newAwards = awards.map(a => {
						if (a.id !== id) {
							return a;
						}
						return {
							...a,
							audioUrl,
							downloadAudioLoading: false
						};
					});
					setAwards(newAwards);
        } else {
        	stopDownloadAudioLoading(id);
        }
      },
      error => {
      	console.log("13");
        console.log(error);
        stopDownloadAudioLoading(id);
      }
    );		
	};

  const downloadAudio = () => {
		storeUserAction({ linkedinUrlId, awardId: id, actionType: "download audio" });

		const newAwards = awards.map(a => {
			if (a.id !== id) {
				return a;
			}
			return {
				...a,
				downloadAudioLoading: true
			};
		});
		setAwards(newAwards);

		if (audioUrl) {
			getSignedUrl()
		} else {
		  postPatch("POST", "/users/audio", { id }).then(
		    json => {
		      console.log(json);
		      const { audio_url: audioUrl } = json;
		      if (audioUrl) {
		      	getSignedUrl()
		      } else {
		      	stopDownloadAudioLoading();
		      }
		    },
		    error => {
		    	console.log("14");
		    	console.log(error);
		    	stopDownloadAudioLoading();
		    }
		  );
		}
  };

	const stopDownloadAudioLoading = () => {
		const newAwards = awards.map(a => {
			if (a.id !== id) {
				return a;
			}
			return {
				...a,
				downloadAudioLoading: false
			};
		});
		setAwards(newAwards);
	};

	const copyLink = () => {
	  storeUserAction({ linkedinUrlId, actionType: "copy link" });
	  copy(shareUrl);
	  updateAlert(copiedAlert, setAlert, alertTimeout);
	};

	const returnShareButton = () => {
		if (showShare) {
			return (
				<SecondaryButton
        	style={buttonStyle}
        	loading={shareAudioLoading}
        	onClick={shareAudio}
        	disabled={disabled}
        	rounded
        >
        	<ArrowUpOnSquareIcon className={iconSize} aria-hidden="true" />
        </SecondaryButton>
			);
		} else if (persona) {
			return (
				<SecondaryButton
		    	style={buttonStyle}
		    	onClick={copyLink}
		    	disabled={disabled}
		    	rounded
		    >
		    	<LinkIcon className={iconSize} aria-hidden="true" />
		    </SecondaryButton>
			);
		}
		return (
			<SecondaryButton
	    	style={buttonStyle}
	    	loading={downloadAudioLoading}
	    	onClick={downloadAudio}
	    	disabled={disabled}
	    	rounded
	    >
	    	<ArrowDownTrayIcon className={iconSize} aria-hidden="true" />
	    </SecondaryButton>
		);
	};

	return (
    <div className={`flex flex-row self-center justify-center relative z-10`}>
      {audioPlaying ? (
				<PrimaryButton
        	style={buttonStyle}
        	loading={audioLoading}
        	onClick={pauseAudio}
        	disabled={disabled}
        	rounded
        >
        	<StopIcon className={iconSize} aria-hidden="true" />
        </PrimaryButton>
      ) : (
				<PrimaryButton
        	style={buttonStyle}
        	loading={audioLoading}
        	onClick={audioUrl ? playAudio : generateAudio}
        	disabled={disabled}
        	rounded
        >
        	<PlayIcon className={iconSize} aria-hidden="true" />
        </PrimaryButton>
      )}
      {/*!home && returnShareButton()*/}
      <audio
      	ref={el => audioRefs.current[index] = el}
      	src={audioUrl}
      	onEnded={onAudioEnded}
      />
    </div>
	);
};

export default AudioButtons;
