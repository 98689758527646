import { useContext, useState, useEffect } from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import SignInButtons from "./SignInButtons";
import { LoggedInContext } from "./context/context";

const Modal = () => {
  const { modal, setModal } = useContext(LoggedInContext);
  const [description, setDescription] = useState("");
  const { open, Icon, iconColor, header, text, textArea, option, onClickOption, src, alt, footer, signIn, linkId, awardId, giftId } = modal;

  let bgColor, textColor;
  switch(iconColor) {
    case "indigo":
      bgColor = "bg-indigo-100";
      textColor = "text-indigo-600";
      break;
    case "red":
    default:
      bgColor = "bg-red-100";
      textColor = "text-red-600";
      break;
  }

  useEffect(() => {
    setDescription("");
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setModal({ ...modal, open: false })}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={`relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 ${signIn ? "w-full" : "sm:w-full"} sm:max-w-lg sm:p-6`}>
                <div className={`absolute right-0 top-0 pr-4 pt-4 ${option ? "hidden sm:block" : "block"}`}>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setModal({ ...modal, open: false })}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${bgColor} sm:mx-0 sm:h-10 sm:w-10`}>
                    {Icon && <Icon className={`h-6 w-6 ${textColor}`} aria-hidden="true" />}
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      {header}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {text}
                      </p>
                    </div>
                    {textArea && (
                      <div className="mt-4 sm:mt-3">
                        <textarea
                          rows={4}
                          name="comment"
                          id="comment"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={({ target: { value } }) => setDescription(value)}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {src && (
                  <img
                    src={src}
                    alt={alt}
                    className="mt-4 w-full bg-gray-900/5 object-cover border border-gray-200 rounded-md"
                  />
                )}
                {footer && (
                  <div className="mt-4 sm:mt-5 text-xs text-gray-500 block truncate">
                    {footer}
                  </div>
                )}
                {option && (
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => {
                        onClickOption(description);
                      	setModal({ ...modal, open: false })
                      }}
                    >
                      {option}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setModal({ ...modal, open: false })}
                    >
                      Cancel
                    </button>
                  </div>
                )}
                {signIn && <SignInButtons style="mt-4 sm:mt-5" linkId={linkId} awardId={awardId} giftId={giftId} />}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
	);
};

export default Modal;
