import { Rect, Text, Group, Circle } from "react-konva";
import { FaAward } from "react-icons/fa6";
import HeroIconImage from "./HeroIconImage";
import { backgroundImages, truncateText } from "./styles/styles";

const AwardCanvas = (props) => {
	const {
		emoji,
		awardText,
		opacity,
		bgIndex,
		condition,
		canvasWidth,
		defaultTextColor,
		defaultRectWidth,
		defaultRectStrokeWidth,
		defaultRectCornerRadius,
		backgroundOffsetHorizontal,
		contentPadding,
		defaultOffsetHorizontal,
		awardIconSize,
		awardTitleSize,
		awardTitleLineHeight,
		awardTitleOffsetHorizontal,
		awardTitleWidth,
		awardHeightAdjustment,
		awardMaxLines,
		awardTitleHeight,
		rectOffsetVertical,
		rectHeight,
		rectColor,
		rectStrokeColor,
		rectHeaderColor,
		returnLoadingText,
		setUpdateCanvas,
	} = props;

  const awardEmojiSize = 100;
  const awardEmojiOffsetHorizontal = -canvasWidth + backgroundOffsetHorizontal + (awardEmojiSize / 2);
  const awardEmojiOffsetVertical = -rectOffsetVertical+ (awardEmojiSize / 2);

  const { awardIconColor = "#d1d5db" } = backgroundImages[bgIndex]; // grey-300
  const awardIconOffsetHorizontal = -defaultOffsetHorizontal;

  const awardCircleRadius = awardIconSize * 0.25;
  const awardCircleOffsetHorizontal = awardIconOffsetHorizontal + (awardIconSize / 2);

  const awardIconEmojiSize = 50;
  const awardIconEmojiOffsetHorizontal = -awardIconOffsetHorizontal - ((awardIconSize - awardIconEmojiSize) / 2);

  const awardTitleColor = rectHeaderColor;
  
  const awardTitleOffsetVertical = -rectOffsetVertical - contentPadding - awardHeightAdjustment;
  
  // const awardDescriptionSize = awardTitleSize;
  // const awardDescriptionOffsetHorizontal = awardTitleOffsetHorizontal;
  // const awardDescriptionPaddingTop = 12;
  // const awardDescriptionOffsetVertical = awardTitleOffsetVertical - awardTitleSize - awardDescriptionPaddingTop;
  // const awardDescriptionWidth = awardTitleWidth;

  const awardIconOffsetVertical = -awardTitleOffsetVertical - ((awardIconSize - awardTitleHeight) / 2);
  const awardCircleOffsetVertical = awardIconOffsetVertical + awardIconSize * 0.37;
  const awardIconEmojiOffsetVertical = -awardIconOffsetVertical - awardIconSize * 0.15;

  const rectWidth = defaultRectWidth;
  const rectOffsetHorizontal = backgroundOffsetHorizontal;

	return (
		<Group opacity={opacity} listening={false}>
      <Rect
      	// preventDefault={false}
        x={rectOffsetHorizontal}
        y={rectOffsetVertical}
        width={rectWidth}
        height={rectHeight}
        fill={rectColor}
        stroke={rectStrokeColor}
        strokeWidth={defaultRectStrokeWidth}
        cornerRadius={defaultRectCornerRadius}
        listening={false}
      />
      {condition ? (
    		<>
		      <Text
		        // preventDefault={false}
		        text="😭"
		        fontFamily="Mona Sans"
		        fontSize={awardEmojiSize}
		        fontStyle="normal"
		        lineHeight={1}
		        fill={defaultTextColor}
		        padding={0}
		        wrap="none"
		        offsetX={awardEmojiOffsetHorizontal}
		        offsetY={awardEmojiOffsetVertical}
		        align="left"
		        listening={false}
		      />
	        <HeroIconImage 
	          // preventDefault={false}
	          icon={FaAward} 
	          color={awardIconColor}
	          size={awardIconSize}
	          x={awardIconOffsetHorizontal} 
	          y={awardIconOffsetVertical} 
	          setUpdateCanvas={setUpdateCanvas}
	          listening={false}
	        />
	        <Circle
	        	// preventDefault={false}
	        	x={awardCircleOffsetHorizontal}
	        	y={awardCircleOffsetVertical}
	        	radius={awardCircleRadius}
	        	fill={awardIconColor}
	        	listening={false}
	        />
		      <Text
		        // preventDefault={false}
		        text={emoji || "⭐️"}
		        fontFamily="Mona Sans"
		        fontSize={awardIconEmojiSize}
		        fontStyle="normal"
		        lineHeight={1}
		        fill={defaultTextColor}
		        padding={0}
		        wrap="none"
		        offsetX={awardIconEmojiOffsetHorizontal}
		        offsetY={awardIconEmojiOffsetVertical}
		        align="left"
		        listening={false}
		      />
		      <Text
		        // preventDefault={false}
		        text={truncateText(awardText, awardTitleWidth, awardTitleSize, "600", awardMaxLines)}
		        fontFamily="Mona Sans"
		        fontSize={awardTitleSize}
		        fontStyle="600"
		        lineHeight={awardTitleLineHeight}
		        fill={awardTitleColor}
		        padding={0}
		        wrap="word"
		        offsetX={awardTitleOffsetHorizontal}
		        offsetY={awardTitleOffsetVertical}
		        align="left"
		        width={awardTitleWidth}
		        listening={false}
		      />
		      {/*<Text
		        // preventDefault={false}
		        text={description}
		        fontFamily="Mona Sans"
		        fontSize={awardDescriptionSize}
		        fontStyle="normal"
		        lineHeight={1}
		        fill={defaultTextColor}
		        padding={0}
		        wrap="none"
		        offsetX={awardDescriptionOffsetHorizontal}
		        offsetY={awardDescriptionOffsetVertical}
		        align="left"
		        width={awardDescriptionWidth}
		        ellipsis={true}
		        listening={false}
		      />*/}
    		</>
    	) : returnLoadingText(6, "6. Selecting awards...", "6. Select awards", "6. Select awards failed", rectOffsetVertical, rectHeight)}
		</Group>
	);
};

export default AwardCanvas;
