import { Link } from "react-router-dom";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import Avatar from "./Avatar";
import AwardIcon from "./AwardIcon";
import Badge from "./Badge";
import { storeUserAction } from "./services/storeUserAction";

const AwardCard = ({ articleId, linkedinUrl, award, select, gift }) => {
  const { fullName, profileUrl, uploadFile } = linkedinUrl;
  const { emoji, title } = award;
  const containerClassName = `px-4 sm:px-6 w-full sm:w-auto sm:max-w-xl sm:self-center ${select ? "scale-90" : ""}`;
  const linkClassName = `relative flex items-center space-x-6 sm:space-x-8 rounded-lg ${select ? "border border-gray-100 bg-gray-50" : "border border-gray-200 bg-gray-50"} pl-3 pr-3.5 sm:pl-4 sm:pr-5`;

  const returnProfilePicture = () => {
    return (
      <div className="relative flex-shrink-0 mt-3 mb-7 sm:mt-4 sm:mb-10">
        <Avatar
          user={linkedinUrl}
          style="h-14 w-14 min-w-14 shadow-sm"
          fontSize="text-2xl"
        />
        <AwardIcon
          emoji={emoji}
          position="absolute top-8 left-8"
          iconColor="text-slate-300"
          bgColor="bg-slate-300"
          small
        />
      </div>
    );
  };
  const returnAwardInfo = () => {
    return (
      <div className="min-w-0 flex-1 my-3 sm:my-4">
        <div className="flex flex-col text-left gap-y-0.5">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="truncate text-sm sm:text-base font-medium text-gray-500 pr-4">{fullName}</p>
          <p className="text-base sm:text-lg font-medium text-gray-900 text-wrap">{title}</p>
          <Badge color="indigo" style="mt-1" text={uploadFile || select || gift ? "CEO Award" : "View on LinkedIn"} />
        </div>
      </div>
    );
  };
  if (uploadFile || select || gift) {
    return (
      <div className={containerClassName}>
        <div className={linkClassName}>
          {returnProfilePicture()}
          {returnAwardInfo()}
        </div>
      </div>
    );
  }
  return (
    <div className={containerClassName}>
      <Link 
        className={`${linkClassName} hover:border-gray-300`}
        to={profileUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => storeUserAction({ articleId, actionType: "view linkedin" })}
      >
        {returnProfilePicture()}
        {returnAwardInfo()}
        <ArrowTopRightOnSquareIcon className="absolute w-4 h-4 top-2 right-2 text-gray-400" />
      </Link>
    </div>
  );
};

export default AwardCard;
