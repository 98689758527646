import { getUserId } from "../services/getUserId";

// localStorage.setItem("change_ip", true);
let ipAddress = "192.168.0.53";
if (localStorage.getItem("change_ip")) {
  ipAddress = "192.168.0.22";
}

export const developmentEnv = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
export const baseUrl = "https://api.ceolist.org";
export const actionCableUrl = "wss://api.ceolist.org";
export const baseUrlDevelopment = `http://${ipAddress}:3000`;
export const webUrl = developmentEnv ? window.location.host : `https://${window.location.host}`;
export const actionCableUrlDevelopment = `ws://${ipAddress}:3000`;
export const apiUrl = developmentEnv ? baseUrlDevelopment : baseUrl;

const checkStatus = async (response) => {
  if (response.status >= 200 && response.status < 400) {
    return response;
  }
  // const error = new Error(response.statusText);
  // error.response = response;
  // throw error;
};

const parseJSON = (response) => {
  return response.json();
};

export const get = (path) => {
  const userId = getUserId();
  const options = {
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + userId
    }
  };
  return fetch(apiUrl + path, options)
    .then(checkStatus)
    .then(parseJSON);
};

export const getToken = (path) => {
  const token = localStorage.getItem("token");
  const options = {
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    }    
  };
  return fetch(apiUrl + path, options)
    .then(checkStatus)
    .then(parseJSON);
};

export const postPatch = (method, path, data = undefined) => {
  const userId = getUserId();
  const options = {
    method,
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + userId
    },
    body: JSON.stringify(data)  
  };
  return fetch(apiUrl + path, options)
    .then(checkStatus)
    .then(parseJSON);
};

export const postPatchToken = (method, path, data = undefined) => {
  const token = localStorage.getItem("token");
  const options = {
    method,
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
    body: JSON.stringify(data)  
  };
  return fetch(apiUrl + path, options)
    .then(checkStatus)
    .then(parseJSON);
};

export const uploadFile = (path, e, params = {}) => {
  const userId = getUserId();
  const fileData = new Blob([e.target.result], { type: "application/octet-stream" });
  const formData = new FormData();
  formData.append('file', fileData);
  Object.keys(params).forEach(key => {
    formData.append(key, params[key]);
  });
  const options = {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Authorization": "Bearer " + userId
    },
    body: formData 
  };
  return fetch(apiUrl + path, options)
    .then(checkStatus)
    .then(parseJSON);
};
