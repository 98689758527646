import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import Landing from "./Landing";
import Profile from "./Profile";
import { LoggedInContext } from "./context/context";
import { useCredentials } from "./hooks/useCredentials";
import { arrayExists, demoVideo } from "./styles/styles";
import { developmentEnv, get } from "./utils/utils";

const Home = () => {
  const { userId, loading, setLoading, awards, setAwards, linkedinUrls, setLinkedinUrls, setTotalAwards, setAwardsInitial } = useContext(LoggedInContext);
  const navigate = useNavigate();
  useCredentials();

  useEffect(() => {
    if (userId) {
      const path = developmentEnv && demoVideo ? "profilecontentdemo" : "profilecontent";
      get(`/users/${path}`).then(
        json => {
          console.log(json);
          const { articles, linkedin_urls: linkedinUrls, awards: awardsInitial, total_awards: totalAwards } = json;
          const articlesFiltered = articles.filter(a => !a.error || (new Date() - new Date(a.createdAt) < 1000 * 60 * 60 * 24));
          setAwards(articlesFiltered);
          setLinkedinUrls(linkedinUrls);
          setAwardsInitial(awardsInitial);
          setTotalAwards(totalAwards);
          setLoading(false);
        },
        error => {
          console.log("21");
          console.log(error);
          setLoading(false);
        }
      );
    }
  }, [userId]);

  const handleResponse = (json) => {
    const { path, link_id: linkId } = json;
    switch(path) {
      case "awards":
        navigate(`/aura/${linkId}`);
        break;
      case "article":
        navigate(`/a/${linkId}`)
        break;
      default: break;
    }
  };

  if (loading) {
    return <Loading />;
  } else if (arrayExists(awards) || arrayExists(linkedinUrls)) {
    return <Profile awards={awards} linkedinUrls={linkedinUrls} handleResponse={handleResponse} />;
  }
  return <Landing handleResponse={handleResponse} />;
};

export default Home;
