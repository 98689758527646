import { Rect, Text, Group } from "react-konva";
import { backgroundImages } from "./styles/styles";

const NarcissismScoreCanvas = (props) => {
	const {
		summary,
		totalScore,
		opacity,
		bgIndex,
		condition,
		canvasWidth,
		defaultTextColor,
		defaultRectWidth,
		defaultRectStrokeWidth,
		defaultRectCornerRadius,
		backgroundOffsetHorizontal,
		contentPadding,
		defaultOffsetHorizontal,
		narcissismHeaderSize,
		narcissismSubheaderPaddingTop,
		narcissismSubheaderSize,
		rectOffsetVertical,
		rectHeight,
		rectColor,
		rectStrokeColor,
		rectHeaderColor,
		returnLoadingText,
	} = props;

  const narcissismEmojiOneSize = 100;
  const narcissismEmojiOneOffsetHorizontal = -backgroundOffsetHorizontal + (narcissismEmojiOneSize * 0.5);
  const narcissismEmojiOneOffsetVertical = -rectOffsetVertical + (narcissismEmojiOneSize  * 0.5);

  const narcissismHeaderColor = rectHeaderColor;
  const narcissismHeaderOffsetHorizontal = defaultOffsetHorizontal - 10;
  const narcissismHeaderOffsetVertical = -rectOffsetVertical - contentPadding;

  const narcissismEmojiTwoSize = 300;
  const narcissismEmojiTwoOffsetHorizontal = -canvasWidth + narcissismEmojiTwoSize + contentPadding;

  const narcissismScoreSize = 80;
  const { narcissismScoreColor = defaultTextColor } = backgroundImages[bgIndex];
	const narcissismScoreOffsetHorizontal = narcissismEmojiTwoOffsetHorizontal;
	const narcissismScoreWidth = narcissismEmojiTwoSize;

  const narcissismSubheaderOffsetHorizontal = narcissismHeaderOffsetHorizontal;
  const narcissismSubheaderOffsetVertical = narcissismHeaderOffsetVertical - narcissismHeaderSize - narcissismSubheaderPaddingTop;
  const narcissismSubheaderWidth = canvasWidth + narcissismSubheaderOffsetHorizontal - narcissismEmojiTwoSize - (contentPadding / 4);

  const narcissismScoreOffsetVertical = narcissismHeaderOffsetVertical - ((narcissismHeaderSize + narcissismSubheaderPaddingTop + narcissismSubheaderSize - narcissismScoreSize) / 2);
  const narcissismEmojiTwoOffsetVertical = narcissismScoreOffsetVertical + ((narcissismEmojiTwoSize - narcissismScoreSize) / 2) - 12;

  const rectWidth = defaultRectWidth;
  const rectOffsetHorizontal = backgroundOffsetHorizontal;

	return (
		<Group opacity={opacity} listening={false}>
      <Rect
      	// preventDefault={false}
        x={rectOffsetHorizontal}
        y={rectOffsetVertical}
        width={rectWidth}
        height={rectHeight}
        fill={rectColor}
        stroke={rectStrokeColor}
        strokeWidth={defaultRectStrokeWidth}
        cornerRadius={defaultRectCornerRadius}
        listening={false}
      />
      {condition ? (
      	<>
		      <Text
		        // preventDefault={false}
		        text="😱"
		        fontFamily="Mona Sans"
		        fontSize={narcissismEmojiOneSize}
		        fontStyle="normal"
		        lineHeight={1}
		        fill={defaultTextColor}
		        padding={0}
		        wrap="none"
		        offsetX={narcissismEmojiOneOffsetHorizontal}
		        offsetY={narcissismEmojiOneOffsetVertical}
		        align="left"
		        listening={false}
		      />
		      <Text
		        // preventDefault={false}
		        text="Narcissism Score"
		        fontFamily="Mona Sans"
		        fontSize={narcissismHeaderSize}
		        fontStyle="600"
		        lineHeight={1}
		        fill={narcissismHeaderColor}
		        padding={0}
		        wrap="none"
		        offsetX={narcissismHeaderOffsetHorizontal}
		        offsetY={narcissismHeaderOffsetVertical}
		        align="left"
		        listening={false}
		      />
		      <Text
		        // preventDefault={false}
		        text={summary}
		        fontFamily="Mona Sans"
		        fontSize={narcissismSubheaderSize}
		        fontStyle="normal"
		        lineHeight={1}
		        fill={defaultTextColor}
		        padding={0}
		        wrap="none"
		        offsetX={narcissismSubheaderOffsetHorizontal}
		        offsetY={narcissismSubheaderOffsetVertical}
		        align="left"
		        width={narcissismSubheaderWidth}
		        ellipsis={true}
		        listening={false}
		      />
		      <Text
		        // preventDefault={false}
		        text="🪞"
		        fontFamily="Mona Sans"
		        fontSize={narcissismEmojiTwoSize}
		        fontStyle="normal"
		        lineHeight={1}
		        fill={defaultTextColor}
		        padding={0}
		        wrap="none"
		        offsetX={narcissismEmojiTwoOffsetHorizontal}
		        offsetY={narcissismEmojiTwoOffsetVertical}
		        align="center"
		        width={narcissismScoreWidth}
		        listening={false}
		      />
		      <Text
		        // preventDefault={false}
		        text={totalScore}
		        fontFamily="Mona Sans"
		        fontSize={narcissismScoreSize}
		        fontStyle="600"
		        lineHeight={1}
		        fill={narcissismScoreColor}
		        padding={0}
		        wrap="none"
		        offsetX={narcissismScoreOffsetHorizontal}
		        offsetY={narcissismScoreOffsetVertical}
		        align="center"
		        width={narcissismScoreWidth}
		        listening={false}
		      />
      	</>
      ) : returnLoadingText(2, "2. Assessing narcissism...", "2. Assess narcissism", "2. Assess narcissism failed", rectOffsetVertical, rectHeight)}
		</Group>
	);
};

export default NarcissismScoreCanvas;
