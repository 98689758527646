import { useGoogleLogin } from "@react-oauth/google";
import ReactPixel from "react-facebook-pixel";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import { logIn } from "./services/logIn";
import { getUserId } from "./services/getUserId";
import { returnGoogleLogo, returnLinkedinLogo, facebookPixelEnabled } from "./styles/styles";
import { developmentEnv, baseUrl } from "./utils/utils";

const SignInButtons = ({ style, linkId, awardId, giftId }) => {
	const userId = getUserId();
	const params = { user_id: userId, link_id: linkId, award_id: awardId, gift_id: giftId };
	Object.keys(params).forEach((k) => !params[k] && delete params[k]);
	const logInGoogle = useGoogleLogin({
	  flow: "auth-code",
	  ux_mode: "redirect",
	  redirect_uri: `${developmentEnv ? "http://localhost:3000" : baseUrl}/auth/google_oauth2/callback`,
	  state: new URLSearchParams(params).toString()
	  // select_account: true,
	});

	const onClickGoogle = () => {
		!developmentEnv && facebookPixelEnabled && ReactPixel.track("Lead");
		logInGoogle();
	};

	return (
	  <div className={`flex flex-col sm:flex-row justify-center items-center gap-x-6 gap-y-3 ${style || ""}`}>
	    <PrimaryButton style="w-full sm:w-auto" onClick={e => logIn(e, linkId, awardId, giftId)}>
	    	{returnLinkedinLogo({}, "mb-1 mr-1.5")}
	      <span>Sign in with LinkedIn</span>
	    </PrimaryButton>
	    <SecondaryButton style="!ring-[#747775] w-full sm:w-auto h-11 sm:h-auto" onClick={onClickGoogle}>
	    	{returnGoogleLogo("mr-1.5")}
	    	<span className="text-[#1F1F1F]">Sign in with Google</span>
	    </SecondaryButton>
	  </div>
	);
};

export default SignInButtons;
