import { useEffect } from "react";
import { Text, Image, Circle } from "react-konva";
import useImage from "use-image";
import default_picture from "./assets/default_picture.jpg";

const UserProfileCanvas = (props) => {
	const { 
		user, 
		statusRef, 
		profileImageDimension, 
		profileImageOffsetHorizontal, 
		profileImageOffsetVertical,
		nameSize,
		nameOffsetHorizontal,
		nameOffsetVertical,
		nameWidth,
		headlineSize,
		headlineOffsetHorizontal,
		headlineOffsetVertical,
		headlineWidth,
		setUpdateCanvas,
		nameColor,
		headlineColor,
	} = props;
	const { profilePictureUrl, fullName, headline } = user;
  const [profileImage, status] = useImage(profilePictureUrl, "anonymous", "origin");
  const [defaultImage] = useImage(default_picture);

  // const nameColor = "#111827"; // text-gray-900
  // const headlineColor = "#4b5563"; // text-gray-600
  const returnDefaultImage = true;

  useEffect(() => {
    if (status === "loaded") {
      statusRef.current = true;
      setUpdateCanvas && setUpdateCanvas(updateCanvas => !updateCanvas);
    }
  }, [status, setUpdateCanvas]);

  const returnProfilePicture = () => {
  	if (profilePictureUrl || returnDefaultImage) {
  		return (
		    <Image
		      // preventDefault={false}
		    	image={profileImage || defaultImage}
		      x={profileImageOffsetHorizontal}
		      y={profileImageOffsetVertical - (profileImage ? 0 : 3)}
		      width={profileImageDimension}
		      height={profileImageDimension}
		      cornerRadius={profileImageDimension / 2}
		      listening={false}
		    />
  		);
  	}
  	const radius = profileImageDimension / 2;
  	const firstInitial = fullName.trim().charAt(0).toUpperCase();
    const secondInitial = fullName.trim().split(/\s+/).length > 1 ? fullName.trim().split(/\s+/)[1].charAt(0).toUpperCase() : fullName.trim().charAt(1).toUpperCase();
  	return (
  		<>
	      <Circle
	      	// preventDefault={false}
	      	x={profileImageOffsetHorizontal + radius}
	      	y={profileImageOffsetVertical + radius}
	      	radius={radius}
          fillLinearGradientStartPoint={{ x: -radius, y: -radius }} // Top-left of the circle
          fillLinearGradientEndPoint={{ x: radius, y: radius }} // Bottom-right of the circle
          fillLinearGradientColorStops={[0, "#9ca3af", 1, "#4b5563"]} // Gradient from red to blue
	      	listening={false}
	      />
		    <Text
		      // preventDefault={false}
		      text={firstInitial + secondInitial}
		      fontFamily="Mona Sans"
		      fontSize={nameSize * 0.95}
		      fontStyle="bold"
		      lineHeight={1}
		      fill="white"
		      padding={0}
		      wrap="none"
		      offsetX={-profileImageOffsetHorizontal}
		      offsetY={-profileImageOffsetVertical - radius + (nameSize / 2)}
		      align="center"
		      width={radius * 2}
		      listening={false}
		    />
  		</>
  	);
  };

	return (
		<>
			{returnProfilePicture()}
	    <Text
	      // preventDefault={false}
	      text={fullName.replace(/[\r\n]+/g, ' ')}
	      fontFamily="Mona Sans"
	      fontSize={nameSize}
	      fontStyle="bold"
	      lineHeight={1}
	      fill={nameColor}
	      padding={0}
	      wrap="none"
	      offsetX={nameOffsetHorizontal}
	      offsetY={nameOffsetVertical}
	      align="left"
	      width={nameWidth}
	      ellipsis={true}
	      listening={false}
	    />
	    <Text
	      // preventDefault={false}
	      text={headline.replace(/[\r\n]+/g, ' ')}
	      fontFamily="Mona Sans"
	      fontSize={headlineSize}
	      fontStyle="normal"
	      lineHeight={1}
	      fill={headlineColor}
	      padding={0}
	      wrap="none"
	      offsetX={headlineOffsetHorizontal}
	      offsetY={headlineOffsetVertical}
	      align="left"
	      width={headlineWidth}
	      ellipsis={true}
	      listening={false}
	    />
		</>
	);
};

export default UserProfileCanvas;
