import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import ErrorShared from "./ErrorShared";
import { LoggedInContext } from "./context/context";
import { developmentEnv } from "./utils/utils";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(developmentEnv ? "pk_test_Kor1SvosEsdbMwJKhI2nPqD4" : "pk_live_Gf7kp6nyeh62dMOd1vgW09bj");
const loggedOutMessage = {
  header: "Something went wrong",
  subheader: "We could not find this page."
};
const loggedInMessage = {
  header: "Something went wrong",
  subheader: "Your checkout session expired."
};

const Checkout = () => {
  const { loggedIn } = useContext(LoggedInContext);
  const location = useLocation();
  const [clientSecret, setClientSecret] = useState(location?.state?.clientSecret);

  // useEffect(() => {
  //   // Create a Checkout Session as soon as the page loads
  //   fetch("/create-checkout-session", {
  //     method: "POST",
  //   })
  //     .then((res) => res.json())
  //     .then((data) => setClientSecret(data.clientSecret));
  // }, []);

  const options = { clientSecret };

  if (clientSecret) {
    return (
      <div id="checkout" className="flex flex-col justify-center w-full min-w-screen h-full min-h-screen bg-slate-100 pt-4 sm:py-10">
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={options}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    )
  }
  return <ErrorShared message={loggedIn ? loggedInMessage : loggedOutMessage} />;
};

export default Checkout;
