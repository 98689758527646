import { useState, useRef, useEffect } from "react";
// import { Buffer } from "buffer";
import * as Sentry from "@sentry/react";
import AwardCard from "./AwardCard";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import CoverCreate from "./CoverCreate";
import { notEmpty } from "./styles/styles";
import { getToken, postPatchToken } from "./utils/utils";

const SelectArticleVisual = ({ article, setArticle, setStatus, setSelectedTab, linkId }) => {
  const [loading, setLoading] = useState(false);
  const [selectedArticleVisualId, setSelectedArticleVisualId] = useState(null);
  const [message, setMessage] = useState(null);
  const stageRefs = useRef([]);

  const { articleContents, articleVisuals, linkedinUrl, award, createdAt, issueNumber } = article;
  const { fullName, profilePictureUrl } = linkedinUrl;
  const { title } = articleContents.find(a => a.selected);
  // const createdAtMonth = new Date();
  // const month = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1)
  //   .toLocaleDateString("en-US", { month: "long", year: "numeric", timeZone: "UTC" });
  const createdAtMonth = new Date(createdAt);
  const month = createdAtMonth.toLocaleDateString("en-US", { month: "long", year: "numeric", timeZone: "UTC" });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const uploadMagazineCover = () => {
    setMessage(null);
    setLoading(true);
    getToken(`/users/uploadcover?id=${linkId}`).then(
      json => {
        console.log(json);
        const { signed_url: signedUrl } = json;
        if (notEmpty(signedUrl)) {
          uploadFile(signedUrl);
        } else {
          handleError(json, "uploadMagazineCover response");
        }
      },
      error => {
        console.log("33");
        console.log(error);
        handleError(error, "uploadMagazineCover error");
      }
    );
  };

  const uploadFile = async (signedUrl) => {
    const index = articleVisuals.findIndex(a => a.id === selectedArticleVisualId);
    const ref = stageRefs.current[index];
    // const image = ref.toDataURL();
    // const file = new Buffer.from(image.replace(/^data:image\/png;base64,/, ""), "base64");
    const file = await ref.toBlob();

    const headers = { "Content-Type": "image/png", "x-amz-acl": "private" };
    fetch(signedUrl, { method: "PUT", body: file, headers }).then(
      response => {
        console.log(response);
        const { status } = response;
        if (status === 200) {
          selectArticleVisual();
        } else {
          handleError(response, "uploadFile response");
        }
      },
      error => {
        console.log("34");
        console.log(error);
        handleError(error, "uploadFile error");
      }
    );
  };

  const selectArticleVisual = () => {
    postPatchToken("PATCH", "/users/selectarticlevisual", { id: selectedArticleVisualId }).then(
      json => {
        console.log(json);
        setLoading(false);
        const articleNew = {
          ...article,
          magazineCoverSaved: true,
          articleVisuals: articleVisuals.map(v => {
            if (v.id !== selectedArticleVisualId) {
              return {
                ...v,
                selected: false
              };
            }
            return {
              ...v,
              selected: true
            };
          })
        };
        setArticle(articleNew);
        setStatus("article");
      },
      error => {
        console.log("35");
        console.log(error);
        handleError(error, "selectArticleVisual error");
      }
    )
  };

  const handleError = (error, functionName) => {
    setLoading(false);
    setMessage("Something went wrong – please try again.");
    Sentry.captureException(error, { extra: { linkId: linkId, functionName } });
  };

  const goBack = () => {
    setStatus("content");
    if (articleContents.some(a => a.selected)) {
      const index = articleContents.findIndex(a => a.selected);
      setSelectedTab(`Option ${index + 1}`);
    }
  };

  const onClickArticleVisual = (id) => {
    const prevId = selectedArticleVisualId;
    if (prevId) {
      const index = articleVisuals.findIndex(a => a.id === prevId);
      const ref = stageRefs.current[index];
      ref?.destroy();
    }
    setSelectedArticleVisualId(id);
  };

  const returnArticleVisual = ({ id, imageUrl, imageUrlMedium, backgroundImageUrl }, index) => {
    const selected = id === selectedArticleVisualId;
    return (
      <li key={id} className="relative">
        <div className={`group aspect-square block w-full overflow-hidden rounded-lg bg-gray-100 ${selected ? "ring-2 sm:ring-4 ring-indigo-500 ring-offset-4 ring-offset-white" : ""}`}>
          <img 
            src={imageUrlMedium || imageUrl} 
            alt={`option ${index + 1}`} 
            className={`pointer-events-none object-cover ${!selectedArticleVisualId ? "group-hover:opacity-75" : (selected ? "" : "opacity-75")}`} 
          />
          <button 
            type="button" 
            className="absolute inset-0 focus:outline-none"
            onClick={() => onClickArticleVisual(id)} 
          />
        </div>
        {selected && <CoverCreate
          stageRef={el => stageRefs.current[index] = el}
          imageUrl={backgroundImageUrl}
          fullName={fullName}
          title={title}
          month={month}
          issueNumber={issueNumber}
          profilePictureUrl={profilePictureUrl}
        />}
      </li>
    );
  };

  const returnArticleVisuals = () => {
    return (
      <div className="flex flex-col pt-8 pb-10 sm:py-12">
        <ul className={"grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-6 xl:gap-x-8 xl:gap-y-8"}>
          {articleVisuals.map(returnArticleVisual)}
        </ul>
      </div>
    );
  };

  return (
    <div className="lg:flex flex-col">
      <AwardCard linkedinUrl={linkedinUrl} award={award} select />
      <div className="min-w-0 flex-1 mt-8 sm:mt-10"> {/*text center*/}
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Select your cover image
        </h2>
        <p className="mt-2 text-sm sm:text-base text-gray-500">
          Our graphic design team prepared these {articleVisuals.length} image options for you.
        </p>
      </div>
      {returnArticleVisuals()}
      <div className="flex flex-col mb-10 sm:mb-12">
        <div className="flex flex-row-reverse gap-x-4">
          <PrimaryButton
            onClick={uploadMagazineCover}
            loading={loading}
            disabled={!selectedArticleVisualId}
          >
            Finish
          </PrimaryButton>
          <SecondaryButton onClick={goBack}>
            Go back
          </SecondaryButton>
        </div>
        {message && <p className="font-medium text-red-600 text-center mt-4">{message}</p>}
      </div>
    </div>
  );
};

export default SelectArticleVisual;
