import { Rect, Text, Group } from "react-konva";
import { truncateText } from "./styles/styles";

const PersonalityCanvas = (props) => {
	const {
		emoji,
		emojiPosition,
		header,
		text,
		opacity,
		condition,
		canvasWidth,
		defaultTextColor,
		defaultRectWidth,
		defaultRectStrokeWidth,
		defaultRectCornerRadius,
		backgroundOffsetHorizontal,
		contentPadding,
		headerSize,
		textPaddingTop,
		textSize,
		textLineHeight,
		textMaxLines,
		textOffsetHorizontal,
		textWidth,
		rectOffsetHorizontal,
		rectOffsetVertical,
		rectHeight,
		rectColor,
		rectStrokeColor,
		rectHeaderColor,
		returnLoadingText,
	} = props;

  const emojiSize = 100;
  const emojiOffsetHorizontal = emojiPosition === "left" ? (-backgroundOffsetHorizontal + (emojiSize / 2)) : (-canvasWidth + backgroundOffsetHorizontal + (emojiSize / 2));
  const emojiOffsetVertical = -rectOffsetVertical + (emojiSize / 2);

  const headerColor = rectHeaderColor;
  const headerOffsetHorizontal = 0;
  const headerOffsetVertical = -rectOffsetVertical - contentPadding;
  const headerWidth = canvasWidth;
  
  const textOffsetVertical = headerOffsetVertical - headerSize - textPaddingTop;

  const rectWidth = defaultRectWidth;

	return (
		<Group opacity={opacity} listening={false}>
	    <Rect
	    	// preventDefault={false}
	      x={rectOffsetHorizontal}
	      y={rectOffsetVertical}
	      width={rectWidth}
	      height={rectHeight}
	      fill={rectColor}
	      stroke={rectStrokeColor}
	      strokeWidth={defaultRectStrokeWidth}
	      cornerRadius={defaultRectCornerRadius}
	      listening={false}
	    />
	    {condition ? (
	    	<>
		      <Text
		        // preventDefault={false}
		        text={emoji}
		        fontFamily="Mona Sans"
		        fontSize={emojiSize}
		        fontStyle="normal"
		        lineHeight={1}
		        fill={defaultTextColor}
		        padding={0}
		        wrap="none"
		        offsetX={emojiOffsetHorizontal}
		        offsetY={emojiOffsetVertical}
		        align="left"
		        listening={false}
		      />
		      <Text
		        // preventDefault={false}
		        text={header}
		        fontFamily="Mona Sans"
		        fontSize={headerSize}
		        fontStyle="600"
		        lineHeight={1}
		        fill={headerColor}
		        padding={0}
		        wrap="none"
		        offsetX={headerOffsetHorizontal}
		        offsetY={headerOffsetVertical}
		        align="center"
		        width={headerWidth}
		        listening={false}
		      />
		      <Text
		        // preventDefault={false}
		        text={truncateText(text, textWidth, textSize, "normal", textMaxLines)}
		        fontFamily="Mona Sans"
		        fontSize={textSize}
		        fontStyle="normal"
		        lineHeight={textLineHeight}
		        fill={defaultTextColor}
		        padding={0}
		        wrap="word"
		        offsetX={textOffsetHorizontal}
		        offsetY={textOffsetVertical}
		        align="left"
		        width={textWidth}
		        listening={false}
		      />
	    	</>
	    ) : returnLoadingText}
		</Group>
	);
};

export default PersonalityCanvas;
