import ReactPixel from "react-facebook-pixel";
import { getUserId } from "./getUserId";
import { facebookPixelEnabled } from "../styles/styles";
import { developmentEnv, baseUrl } from "../utils/utils";

export const logIn = (e, linkId, awardId, giftId) => {
	e?.preventDefault();
	!developmentEnv && facebookPixelEnabled && ReactPixel.track("Lead");
	const userId = getUserId();
	let params = `?user_id=${userId}`
	if (linkId) {
		params += `&link_id=${linkId}`
	}
	if (awardId) {
		params += `&award_id=${awardId}`
	}
	if (giftId) {
		params += `&gift_id=${giftId}`
	}
  const logInLink = `${developmentEnv ? `http://${window.location.host.split(":")[0]}:3000` : baseUrl}/auth/linkedin${params}`;
  window.location.href = logInLink;
};

export const logOut = (e, setLoggedIn, setUser, setAwards, setLinkedinUrls, navigate) => {
	e?.preventDefault();
	localStorage.removeItem("token");
	localStorage.removeItem("userId");
	localStorage.setItem("loggedIn", false);
	setLoggedIn(false);
	setUser(null);
	setAwards(null);
	setLinkedinUrls(null);
	navigate("/");
};
