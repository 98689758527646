import { useEffect, useContext, Fragment } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react"
import { UserIcon } from "@heroicons/react/24/outline";
import Logo from "./Logo";
import TextButton from "./TextButton";
import Avatar from "./Avatar";
import { LoggedInContext } from "./context/context";
import { logOut } from "./services/logIn";
import { storeUserAction } from "./services/storeUserAction";
import { classNames, defaultModal } from "./styles/styles";
import { developmentEnv, getToken } from "./utils/utils";

const NavBar = ({ position, inverted, giftId }) => {
  const { userId, setLoading, loggedIn, setLoggedIn, user, setUser, setAwards, setLinkedinUrls, setModal } = useContext(LoggedInContext);
	const navigate = useNavigate();
	const location = useLocation();

  useEffect(() => {
    if (userId && !user && loggedIn) {
		  getToken("/users/userinfo").then(
		    json => {
		      console.log(json);
		      setUser(json);
		    },
		    error => {
		    	console.log("28");
		      console.log(error);
		      if (!developmentEnv) {
		      	logOut(null, setLoggedIn, setUser, setAwards, setLinkedinUrls, navigate);
		      }
		    }
		  );
    }
  }, [userId, user, loggedIn]);

	const onClickLogo = () => {
		if (location.pathname !== "/") {
			setLoading(true);
			navigate("/");
		}
	};

	const onClickSignIn = () => {
    const modal = {
    	...defaultModal,
      open: true,
      Icon: UserIcon,
      iconColor: "indigo",
      header: "Sign in",
      text: "Please select sign in option.",
      signIn: true,
      giftId
    };
    setModal(modal);
	};

	const returnLogInOptions = () => {
		if (loggedIn) {
			return (
	      <Menu as="div" className="relative ml-4 flex-shrink-0">
	        <div>
	          <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none h-8 sm:h-9">
	            <span className="absolute -inset-1.5" />
	            <span className="sr-only">Open user menu</span>
	            <Avatar user={user} />
	          </Menu.Button>
	        </div>
	        <Transition
	          as={Fragment}
	          enter="transition ease-out duration-100"
	          enterFrom="transform opacity-0 scale-95"
	          enterTo="transform opacity-100 scale-100"
	          leave="transition ease-in duration-75"
	          leaveFrom="transform opacity-100 scale-100"
	          leaveTo="transform opacity-0 scale-95"
	        >
	          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
	            <Menu.Item>
	              {({ active }) => (
	                <Link
	                  to="/purchases"
	                  className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700")}
	                >
	                  Purchase history
	                </Link>
	              )}
	            </Menu.Item>
	            <Menu.Item>
	              {({ active }) => (
	                <Link
	                  onClick={e => {
	                  	storeUserAction({ actionType: "log out" });
	                  	logOut(e, setLoggedIn, setUser, setAwards, setLinkedinUrls, navigate);
	                  }}
	                  className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700 cursor-pointer")}
	                >
	                  Sign out
	                </Link>
	              )}
	            </Menu.Item>
	          </Menu.Items>
	        </Transition>
	      </Menu>
			);
		}
		return (
			<TextButton dark={inverted} onClick={onClickSignIn} style="h-8 sm:h-9">
	  		Sign in <span aria-hidden="true">&rarr;</span>
	  	</TextButton>
		);
	};

	return (
	  <header className="absolute inset-x-0 top-0 z-40">
	    <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
	      <div className="flex lg:flex-1">
	        <button onClick={onClickLogo} className="-m-1.5 p-1.5">
	          {/*<span className="sr-only">CEO List</span>*/}
	          <Logo inverted={inverted} />
	        </button>
	      </div>
	      <div className="flex flex-1 justify-end">
	      	{returnLogInOptions()}
	      </div>
	    </nav>
	  </header>
	);
};

export default NavBar;
