import { useContext, useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { Stage, Layer, Image } from "react-konva";
import Konva from "konva";
import useImage from "use-image";
import copy from "copy-to-clipboard";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import TextButton from "./TextButton";
import { LoggedInContext } from "./context/context";
import { updateAlert } from "./services/updateAlert";
import { isMobile } from "./styles/styles";
import { getToken, postPatchToken, webUrl } from "./utils/utils";

Konva.pixelRatio = Math.min(2, window.devicePixelRatio);
const copiedAlert = { show: true, Icon: CheckCircleIcon, header: "Tweet copied!", subheader: "Tweet copied." };
const sampleTweet = "🌟 Exciting News! Kalyan Kanakamedala receives The Unlikely Entrepreneur Award for transforming a college project into a thriving startup, all while maintaining a minimalist LinkedIn presence. #Entrepreneurship #Innovation #StartupSuccess 🚀";

const Marketing = () => {
  const { userId, setAlert, alertTimeout } = useContext(LoggedInContext);
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState(null);
  const [generating, setGenerating] = useState(false);
  const [tweet, setTweet] = useState(null);
  const [message, setMessage] = useState(null);
  const backgroundRef = useRef(null);
  const stageRef = useRef(null);
  const scrollRef = useRef(null);
  const scrollTimeout = useRef(null);
  const { articleId } = useParams();
  const [image, status] = useImage(article && article.imageUrl, "anonymous", "origin");
  const [magazineCover] = useImage(article && article.magazineCoverUrl, "anonymous", "origin");
  const shareUrl = `${webUrl}/a/${articleId}`;;

  useEffect(() => {
    return () => {
      clearTimeout(scrollTimeout.current);
    }
  }, []);

  useEffect(() => {
    if (userId && articleId) {
      getToken(`/users/marketing?id=${articleId}`).then(
        json => {
        	console.log(json);
          setArticle(json);
          const { defaultPrompt } = json;
          setPrompt(defaultPrompt);
        },
        error => {
          // wrong article id
          console.log("26");
          console.log(error);
        }
      );
    }
  }, [userId, articleId]);

  useEffect(() => {
    if (status === "loaded") {
      backgroundRef.current.cache();
    }
  }, [status]);

  if (article) {
    const { defaultPrompt } = article;
  	const postWidth = 1080;
  	const postHeight = 1080;
  	const magazineCoverWidth = postHeight * 1080 / 1920;

    const downloadImage = () => {
      setLoading(true);
      const dataUrl = stageRef.current.toDataURL();
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "ig.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading(false);
    };

    const returnAction = () => {
      if (isMobile) {
        return (
          <p className="text-gray-900 text-center font-medium">
            Hold press on the image to save ☝️
          </p>
        );
      }
      return (
        <SecondaryButton style="sm:text-base" onClick={downloadImage} loading={loading}>
          Download
        </SecondaryButton>
      );
    };

    const generateTweet = () => {
      setGenerating(true);
      setTweet(null);
      setMessage(null);
      postPatchToken("POST", "/users/tweet", { prompt }).then(
        json => {
          console.log(json);
          setGenerating(false);
          const { tweet, message } = json;
          if (tweet) {
            setTweet(tweet);
            scrollToBottom();
          } else if (message) {
            setMessage(message);
          }
        },
        error => {
          console.log("27");
          console.log(error);
        }
      );
    };

    const scrollToBottom = () => {
      clearTimeout(scrollTimeout.current);
      scrollTimeout.current = setTimeout(() => {
        if (scrollRef?.current) {
          scrollRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 500);
    };

    const returnForm = () => {
      return (
        <form className="w-full max-w-xl">
          <div className="sm:col-span-2">
            <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
              Generate tweet
            </label>
            <div className="mt-2.5">
              <textarea
                name="prompt"
                id="prompt"
                rows={4}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={defaultPrompt}
                onChange={({ target: { value } }) => setPrompt(value)}
              />
            </div>
          </div>
          <div className="mt-4 flex flex-col items-start">
            <PrimaryButton
              loading={generating}
              onClick={generateTweet}
              style="sm:text-base"
              dark
            >
              Generate
            </PrimaryButton>
            {message && <p className="mt-4 text-red-500 font-medium">{message}</p>}
          </div>
        </form>
      );
    };

    const handleCopy = () => {
      copy(`${tweet} #ceolist ${shareUrl}`);
      updateAlert(copiedAlert, setAlert, alertTimeout);
    };

    const handleShareX = () => {
      const shareText = encodeURIComponent(`${tweet} #ceolist`);
      const url = encodeURIComponent(shareUrl);
      window.open(`https://twitter.com/intent/tweet?text=${shareText}&url=${url}`, "_blank", "noopener,noreferrer");
    };

    const returnContent = () => {
      if (tweet) {
        return (
          <div ref={scrollRef} className="w-full max-w-xl flex flex-col gap-y-5 sm:gap-y-6">
            <p className="text-gray-900 text-base">{tweet}{' '}#ceolist{' '}
              <span className="text-indigo-600 font-medium">{shareUrl}</span>
            </p>
            <div className="flex items-center justify-end gap-x-6 mt-1">
              <TextButton onClick={handleCopy} small>
                Copy
              </TextButton>
              <SecondaryButton style="hover:bg-gray-300" onClick={handleShareX}>
                Share on X
              </SecondaryButton>
            </div>
          </div>
        );
      }
      return null;
    };

    return (
      <div className={`flex flex-col justify-start items-center p-8 gap-y-8 bg-gray-100 min-h-screen`}>
			  <Stage ref={stageRef} width={postWidth} height={postHeight} listening={false}>
			    <Layer listening={false}>
			      <Image
			        x={0}
			        y={0}
			        width={postWidth}
			        height={postHeight}
			        image={image}
			        ref={backgroundRef}
			        filters={[Konva.Filters.Grayscale]}
              listening={false}
			      />
			      <Image
			        x={(postWidth - magazineCoverWidth) / 2}
			        y={0}
			        width={magazineCoverWidth}
			        height={postHeight}
			        image={magazineCover}
			        // cornerRadius={postHeight * 0.01}
              listening={false}
			      />
			    </Layer>
			  </Stage>
			  {returnAction()}
        {returnForm()}
        {returnContent()}
      </div>
    );
  }
  return null;
};

export default Marketing;
