import { returnInstagramLogo, returnThreadsLogo } from "./styles/styles";

const testimonials = [
	{
		id: 1,
		body:	"I’m still trying to figure out wtf LinkedIn Aura means and why there is a company dedicated to it in the first place",
		author: "@instacharlie",
		source: "instagram"
	},
	{
		id: 2,
		body:	"I’m having a Pets.com flashback here.",
		author: "@timd66md",
		source: "instagram"
	},
	{
		id: 3,
		body:	"It just analyzes your LinkedIn profile and gives you jokey awards and stuff. It’s like that ChatGPT insta trend but for lonely, humor-deficient workaholics.",
		author: "@emalone.libby",
		source: "instagram"
	},
	{
		id: 4,
		body:	"Yall their website is awful. It's like a self completing buzzfeed quiz lol.",
		author: "@conkyy",
		source: "instagram"
	},
	{
		id: 5,
		body:	"What the hell is a LINKEDIN PERSONALITY TEST",
		author: "@iamtex_",
		source: "instagram"
	},
	{
		id: 6,
		body:	"LinkedIn aura is something i never hope to have",
		author: "@geoffrey.loww",
		source: "instagram"
	},
	{
		id: 7,
		body:	"Man. The chat gpt roasts got deeply personal.",
		author: "@ian.engie",
		source: "instagram"
	},
	{
		id: 8,
		body: "It’s a cool site though",
		author: "@jandemoor1",
		source: "instagram"
	},
	{
		id: 9,
		body: "what the fuck is a LinkedIn personality test",
		author: "@krmgnd",
		source: "instagram"
	},
	{
		id: 10,
		body: "What the fuck is a LinkedIn personality test? 🥴🥴🥴",
		author: "@messy_mila",
		source: "instagram"
	},
	{
		id: 11,
		body: "Dying to see this LINKEDIN PERSONALITY TEST.",
		author: "@saucyshayna419",
		source: "instagram"
	},
	{
		id: 12,
		body: "If I found out I had \"LinkedIn aura\" I would walk into the ocean.",
		author: "@randomentity",
		source: "threads"
	},
	{
		id: 13,
		body: "What is LinkedIn Aura, and can I get an ointment for it.",
		author: "@northern._.monkey",
		source: "threads"
	},
	{
		id: 14,
		body: "I’m sure it’s a grift that nobody needs.",
		author: "@timtrautmann.tiff",
		source: "threads"
	},
	{
		id: 15,
		body: "Oh, absolutely. It's going to be another networking scam isn't it.",
		author: "@northern._.monkey",
		source: "threads"
	},
	{
		id: 16,
		body: "website sure is odd. I ran my LinkedIn profile through it and the first thing their little system does is roast you with a mildly insulting paragraph.",
		author: "@jmeister_design",
		source: "threads"
	},
	{
		id: 17,
		body: "CEOList sounds like Hot or Not for the C-Suite",
		author: "@heychaaahl",
		source: "threads"
	},
	{
		id: 18,
		body: "LinkedIn Personality test? Like for what reason? To find out what The Boys character I’m most like?",
		author: "@cjorgensen",
		source: "threads"
	},
	{
		id: 19,
		body: "Give yourself a treat, or a crashed browser, and check out this website on a desktop computer. Reminded me of early 2000’s pop-up viruses.",
		author: "@wheelyweb_",
		source: "threads"
	},
	{
		id: 20,
		body: "A linked in personality test 💀💀💀 what is that, like astrology for tech bros? An AI sun, Elon moon, with a NFT rising 🤣",
		author: "@theirondove",
		source: "threads"
	},
	{
		id: 21,
		body: "\"LinkedIn Aura\" is just a new Klout but for boring business types instead of fun socials.",
		author: "@whoistomcee",
		source: "threads"
	},
	{
		id: 22,
		body: "Junk \"personally tests\" are usually set up be very insecure individuals",
		author: "@the.phoebe.rose",
		source: "threads"
	},
	{
		id: 23,
		body: "What is LinkedIn Aurora? What does any of this even mean? 😅",
		author: "@jimdail",
		source: "threads"
	},
	{
		id: 24,
		body: "The world is in desperate need of more LinkedIn personality tests.",
		author: "@matthewk.rso",
		source: "threads"
	},
	{
		id: 25,
		body: "\"LinkedIn Personality Test\" is an unholy combination of words.",
		author: "@sampmuj",
		source: "threads"
	},
	// {
	// 	id: 9,
	// 	body: "We really got LinkedIn personality tests before GTA 6",
	// 	author: "ericcouu"
	// },
	// {
	// 	id: 15,
	// 	body: "if i get Louis Litt, I am renouncing linkedin for life",
	// 	author: "ericcouu"
	// },
	// {
	// 	id: 16,
	// 	body: "Yes. Immediately. Yes. That is a healthier use of ai than as a therapy.",
	// 	author: "alliekcampbell"
	// },
	// {
	// 	id: 17,
	// 	body: "I’ve actually never got an award in my life, so well this should be fun",
	// 	author: "alliekcampbell"
	// },
	// {
	// 	id: 18,
	// 	body: "This is all I needed to do to make my parents proud of me, then you know what, I’ll take it",
	// 	author: "alliekcampbell"
	// },
	// {
	// 	id: 19,
	// 	body: "This is the correct way to use Al. Lol.",
	// 	author: "tedthetoycollector"
	// },
	// {
	// 	id: 20,
	// 	body: "I literally cannot help myself. I always have to know what character i am.",
	// 	author: "tedthetoycollector"
	// },
	// {
	// 	id: 21,
	// 	body: "i’m not gonna lie. my ego’s taking a hit right now. what the heck",
	// 	author: "zoburner"
	// },
	// {
	// 	id: 22,
	// 	body: "be warned. the truth might hurt",
	// 	author: "zoburner"
	// },
	// {
	// 	id: 23,
	// 	body: "I feel called out🙈 but day=made.",
	// 	author: "chammouth"
	// }
];
const randomTestimonials = testimonials.sort(() => Math.random() - 0.5)

const SocialProof = () => {
	return (
	  <div className="bg-white">
	    <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
	      <div className="mx-auto max-w-xl text-center">
	        <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">#stawpp</h2>
	        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
	          People love us ❤️
	        </p>
	      </div>
	      <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
	        <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] xl:columns-4">
	          {randomTestimonials.map((testimonial) => (
	            <div key={testimonial.id} className="pt-8 sm:inline-block sm:w-full sm:px-4">
	              <figure className="rounded-2xl bg-white p-6 shadow-lg text-sm leading-6 ring-1 ring-gray-900/5">
	                <blockquote className="text-gray-900">
	                  <p>{testimonial.body}</p>
	                </blockquote>
	                <figcaption className="mt-2 flex items-center justify-between">
	                  <div className="text-gray-600">{testimonial.author}</div>
	                   <div className="text-gray-400">{testimonial.source === "instagram" ? returnInstagramLogo({className: "h-5 w-5"}) : returnThreadsLogo()}</div>
	                </figcaption>
	              </figure>
	            </div>
	          ))}
	        </div>
	      </div>
	    </div>
	  </div>
	);
};

export default SocialProof;
