import { v4 as uuidv4 } from "uuid";
// import { postPatch } from "../utils/utils";

export const getUserId = () => {
  const userId = localStorage.getItem("userId");
  if (userId) {
    return userId;
  } else {
    const userId = uuidv4();
    localStorage.setItem("userId", userId);
    // postPatch("POST", "/users", { user: { id: userId } }).then(
    //   json => null,
    //   error => console.log(error)
    // );
    return userId;
  }
};
