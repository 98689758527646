import { useContext, useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import SecondaryButton from "./SecondaryButton";
import ErrorShared from "./ErrorShared";
import { LoggedInContext } from "./context/context";
import useWindowDimensions from "./hooks/useWindowDimensions";
import { isMobile, userAgent } from "./styles/styles";
import { get } from "./utils/utils";

const darkBackground = false;
const defaultMessage = {
  header: "Something went wrong",
  subheader: "We couldn't find this magazine cover."
};

const Cover = () => {
  const { userId } = useContext(LoggedInContext);
  const { height: innerHeight } = useWindowDimensions();
  const [magazineCoverUrl, setMagazineCoverUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const { articleId } = useParams();
  const sizeRatio = useMemo(() => {
    // innerHeight - padding top - gap - button/text height - padding bottom
    return (innerHeight - 32 - 32 - (isMobile ? 24 : 44) - 32) / 1920;
  }, []);

  useEffect(() => {
    if (userId) {
      if (articleId) {
        get(`/users/cover?id=${articleId}&user_agent=${encodeURIComponent(userAgent)}`).then(
          json => {
            const { magazine_cover_url: magazineCoverUrl, message } = json;
            if (magazineCoverUrl) {
              setMagazineCoverUrl(magazineCoverUrl);
            } else if (message) {
              setMessage(message);
            } else {
              setDefaultErrorMessage();
            }
          },
          error => {
            // wrong article id
            console.log("19");
            console.log(error);
            setDefaultErrorMessage();
          }
        );
      } else {
        setDefaultErrorMessage();
      }
    }
  }, [userId, articleId]);

  const setDefaultErrorMessage = () => {
    setMessage(defaultMessage);
  };

  if (message) {
    return <ErrorShared message={message} />;
  } else if (magazineCoverUrl) {
    const downloadImage = () => {
      setLoading(true);
      get(`/users/downloadcover?id=${articleId}&user_agent=${encodeURIComponent(userAgent)}`).then(
        json => {
          console.log(json);
          const { signed_url: signedUrl } = json;
          const link = document.createElement("a");
          link.href = signedUrl;
          link.download = "magazine_cover.png";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setLoading(false);
        },
        error => {
          console.log("20");
          console.log(error);
        }
      );
    };

    const returnAction = () => {
      if (magazineCoverUrl) {
        if (isMobile) {
          return (
            <p className={`${darkBackground ? "text-white" : "text-gray-900"} text-center font-medium`}>
              Hold press on the image to save ☝️
            </p>
          );
        }
        return (
          <SecondaryButton style="sm:text-base" onClick={downloadImage} loading={loading}>
            Download
          </SecondaryButton>
        );
      }
      return null;
    };

    return (
      <div className={`flex flex-col justify-start items-center p-8 gap-y-8 ${darkBackground ? "bg-neutral-950" : "bg-gray-100"} min-h-screen`}>
        <img
          src={magazineCoverUrl}
          alt="magazine-cover"
          width={1080 * sizeRatio}
          height={1920 * sizeRatio}
          className={`rounded-lg ${darkBackground ? "ring-1 sm:ring-2 ring-gray-700" : "shadow-lg"}`}
        />
        {returnAction()}
      </div>
    );
  }
  return null;
};

export default Cover;
