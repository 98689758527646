import { XMarkIcon } from "@heroicons/react/24/solid";

const Notification = ({ color, style, Icon, header, subheader, options, onClickDismiss }) => {
	let bgColor, iconColor, headerColor, subheaderColor, optionColor, dismissColor;
	switch(color) {
		case "indigo":
			bgColor = "bg-indigo-50";
			iconColor = "text-indigo-400";
			headerColor = "text-indigo-800";
			subheaderColor = "text-indigo-700";
			optionColor = "bg-indigo-50 text-indigo-800 hover:bg-indigo-100";
			dismissColor = "bg-indigo-50 text-indigo-500 hover:bg-indigo-100";
			break;
		case "red":
			bgColor = "bg-red-50";
			iconColor = "text-red-400";
			headerColor = "text-red-800";
			subheaderColor = "text-red-700";
			optionColor = "bg-red-50 text-red-800 hover:bg-red-100";
			dismissColor = "bg-red-50 text-red-500 hover:bg-red-100";
			break;
		default:
			break;
	};

	return (
	  <div className={`rounded-md ${bgColor} p-4 ${style || ""}`}>
	    <div className="flex">
	      <div className="flex-shrink-0">
	        <Icon className={`h-5 w-5 ${iconColor}`} aria-hidden="true" />
	      </div>
	      <div className="ml-3">
	        <h3 className={`text-sm font-medium ${headerColor} mt-px`}>{header}</h3>
	        {subheader && <div className={`mt-2 text-sm ${subheaderColor}`}>
	          <p>{subheader}</p>
	        </div>}
	        {options && <div className="mt-4">
	          <div className="-mx-2 -my-1.5 flex">
	          	{options.map(({ id, text, onClick }) => {
	          		return (
			            <button
			            	key={id}
			              type="button"
			              className={`rounded-md ${optionColor} px-2 py-1.5 text-sm font-medium focus:outline-none`}
			              onClick={onClick}
			            >
			              {text}
			            </button>
	          		);
	          	})}
	          </div>
	        </div>}
	      </div>
	      <div className="ml-auto pl-3">
	        <div className="-mx-1.5 -my-1.5">
	          <button
	            type="button"
	            className={`inline-flex rounded-md ${dismissColor} p-1.5 focus:outline-none`}
	            onClick={onClickDismiss}
	          >
	            <span className="sr-only">Dismiss</span>
	            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
	          </button>
	        </div>
	      </div>
	    </div>
	  </div>
	);
};

export default Notification;
