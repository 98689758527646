import React, { useState, useEffect, useCallback } from "react";
import { Image, Text } from "react-konva";
import ReactDOMServer from "react-dom/server";

const HeroIconImage = ({ icon: Icon, color = 'black', size = 24, setUpdateCanvas, ...props }) => {
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);

  const createImage = useCallback(() => {
    // Render the icon to an HTML string
    const svgString = ReactDOMServer.renderToStaticMarkup(
      <Icon color={color} size={size} />
    );
    // console.log('SVG string:', svgString); // Log the SVG string

    try {
      // Replace the color in the SVG string if needed
      const coloredSvgString = svgString.replace(/currentColor/g, color);

      // Create data URL
      const dataUrl = `data:image/svg+xml;base64,${btoa(coloredSvgString)}`;
      // console.log('Data URL:', dataUrl); // Log the data URL

      const img = new window.Image();
      img.src = dataUrl;
      img.onload = () => {
        setImage(img);
        setUpdateCanvas && setUpdateCanvas(updateCanvas => !updateCanvas);
      };
    } catch (err) {
      console.error('Error creating image URL:', err);
      setError(err);
    }
  }, [Icon, color, size]);

  useEffect(() => {
    createImage();
  }, [createImage]);

  if (error) {
    return <Text text={`Error: ${error.message}`} fill="red" />;
  }

  return image ? (
    <Image
      image={image}
      width={size}
      height={size}
      {...props}
    />
  ) : null;
};

export default HeroIconImage;
