import { useState, useRef, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { createConsumer } from "@rails/actioncable";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Home from "./Home";
import Aura from "./Aura";
import StoryImage from "./StoryImage";
import ShareImage from "./ShareImage";
import Checkout from "./Checkout";
import Article from "./Article";
import Cover from "./Cover";
import FAQ from "./FAQ";
import Terms from "./Terms";
import Privacy from "./Privacy";
import Purchases from "./Purchases";
import Alert from "./Alert";
import Modal from "./Modal";
import Marketing from "./Marketing";
import { LoggedInContext } from "./context/context";
import { getUserId } from "./services/getUserId";
import { defaultAlert, defaultModal } from "./styles/styles";
import { developmentEnv, actionCableUrlDevelopment, actionCableUrl } from "./utils/utils";

if (!developmentEnv) {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const App = () => {
	const [userId, setUserId] = useState(null);
	const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("loggedIn") ? JSON.parse(localStorage.getItem("loggedIn")) : false);
  const [cable, setCable] = useState(null);
  const [user, setUser] = useState(null);
  const [awards, setAwards] = useState(null);
  const [linkedinUrls, setLinkedinUrls] = useState(null);
  const [awardsInitial, setAwardsInitial] = useState([]);
  const [totalAwards, setTotalAwards] = useState(null);
  const [alert, setAlert] = useState(defaultAlert);
  const [modal, setModal] = useState(defaultModal);
  const alertTimeout = useRef(null);

  const value = { 
  	userId,
  	loading,
  	setLoading,
  	loggedIn, 
  	setLoggedIn, 
  	cable, 
  	setCable, 
  	user, 
  	setUser, 
  	awards, 
  	setAwards, 
  	linkedinUrls, 
  	setLinkedinUrls, 
  	awardsInitial,
  	setAwardsInitial,
  	totalAwards, 
  	setTotalAwards, 
  	alert, 
  	setAlert, 
  	alertTimeout, 
  	modal, 
  	setModal 
  };

	useEffect(() => {
		const userId = getUserId();
		setUserId(userId);
		setCable(createConsumer(`${developmentEnv ? actionCableUrlDevelopment : actionCableUrl}/cable?id=${userId}`));
		Sentry.setUser({ id: userId });
	}, [loggedIn]);

  return (
  	<GoogleOAuthProvider clientId="528197377753-gvcoirbvnsstl0kdlfmkepcaa67frdtv.apps.googleusercontent.com">
	  	<LoggedInContext.Provider value={value}>
		    <Router>
		      <div>
		        <Routes>
		          <Route path="/" element={<Home />} />
		          <Route path="/aura" element={<Aura />} />
		          <Route path="/aura/:linkedinUrlId" element={<Aura />} />
		          <Route path="/awards" element={<Aura />} />
		          <Route path="/awards/:linkedinUrlId" element={<Aura />} />
		          <Route path="/story" element={<StoryImage />} />
		          <Route path="/story/:linkedinUrlId" element={<StoryImage />} />
		          <Route path="/image" element={<ShareImage />} />
		          <Route path="/image/:linkedinUrlId" element={<ShareImage />} />
		          <Route path="/checkout" element={<Checkout />} />
		          <Route path="/a" element={<Article />} />
		          <Route path="/a/:articleId" element={<Article />} />
		          <Route path="/g" element={<Article />} />
		          <Route path="/g/:giftId" element={<Article />} />
		          <Route path="/cover" element={<Cover />} />
		          <Route path="/cover/:articleId" element={<Cover />} />
		          <Route path="/faq" element={<FAQ />} />
		          <Route path="/terms" element={<Terms />} />
		          <Route path="/privacy" element={<Privacy />} />
		          <Route path="/purchases" element={<Purchases />} />
		          <Route path="/marketing/:articleId" element={<Marketing />} />
		        </Routes>
		      </div>
		    </Router>
		    <Alert />
		    <Modal />
		  </LoggedInContext.Provider>
		</GoogleOAuthProvider>
  );
};

export default App;
