import { useEffect } from "react";
import { Stage, Layer, Rect, Text } from "react-konva";
import Konva from "konva";

Konva.pixelRatio = Math.min(2, window.devicePixelRatio);

const ImageOne = ({ awards, headerIndex, headerOne, textOne, headerTwo, spacing, stageRef, updateImage, textOneRef, textOneHeight, setTextOneHeight }) => {
  useEffect(() => {
  	updateDimensions();
  	updateImage();
  }, [awards, headerIndex]);

  const updateDimensions = () => {  	
  	setTimeout(() => {
	  	if (textOneRef?.current) {
	  		const newHeight = Math.ceil(textOneRef.current.height());
	  		setTextOneHeight(Math.min(newHeight, 100 * 1.2 + 100));
	  	}
  	}, 0);
  };

	return (
    <Stage ref={stageRef} width={1080} height={1920} className="hidden" listening={false}>
      <Layer listening={false}>
        <Rect
        	// preventDefault={false}
          x={0}
          y={0}
          width={1080}
          height={1920}
          fill="black"
          opacity={0.5}
          listening={false}
        />
	      {headerOne && <Text
	        // preventDefault={false}
	        text={headerOne}
	        fontFamily="Mona Sans"
	        fontSize={50}
	        fontStyle="bold"
	        lineHeight={1}
	        fill="white"
	        padding={0}
	        wrap="word"
	        offsetY={-250}
	        width={1080}
	        align="center"
	        listening={false}
	      />}
	      {textOne && <Text
	      	ref={textOneRef}
	        // preventDefault={false}
	        text={textOne}
	        fontFamily="Mona Sans"
	        fontSize={50}
	        lineHeight={1.2}
	        fill="white"
	        padding={50}
	        wrap="word"
	        offsetY={-250 - spacing}
	        width={1080}
	        height={textOneHeight}
	        align="center"
	        listening={false}
	      />}
	      {headerTwo && <Text
	        // preventDefault={false}
	        text={headerTwo}
	        fontFamily="Mona Sans"
	        fontSize={50}
	        fontStyle="bold"
	        lineHeight={1}
	        fill="white"
	        padding={0}
	        wrap="word"
	        offsetY={-250 - (headerOne && textOne ? (50 + spacing + (textOneHeight - 100) + spacing) : 150)}
	        width={1080}
	        align="center"
	        listening={false}
	      />}
	      {awards.map(({ id, emoji, title }, index) => {
	      	return (
			      <Text
			      	key={id}
			        // preventDefault={false}
			        text={`${emoji} ${title}`}
			        fontFamily="Mona Sans"
			        fontSize={35}
			        lineHeight={1.2}
			        fill="white"
			        padding={50}
			        wrap="word"
				      offsetX={index < 5 ? 50 - (200 / 3) : (-540 - 50 + (200 / 3))}
				      offsetY={-250 - 50 - spacing - (headerOne && textOne ? (50 + spacing + (textOneHeight - 100) + spacing) : 150) + 50 - ((index < 5 ? (index) : (index - 5)) * (35 * 1.2 * 3 + 60))}
			        width={540}
			        height={35 * 1.2 * 3 + 100}
			        align="center"
			        // verticalAlign="middle"
			        listening={false}
			      />
	      	);
	      })}
	      {/*<Text
	        // preventDefault={false}
	        text="ceolist.org"
	        fontFamily="Mona Sans"
	        fontSize={35}
	        fontStyle="bold"
	        lineHeight={1}
	        fill="white"
	        padding={0}
	        wrap="word"
	        offsetY={-1920 + 35 + 70}
	        width={1080}
	        align="center"
	        listening={false}
	      />*/}
      </Layer>
    </Stage>
	);
};

export default ImageOne;
