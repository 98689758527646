const BlurLayer = ({type, style}) => {
  let blurClass;
  switch (type) {
    case "2xl": blurClass = "backdrop-blur-2xl"; break;
    case "xl": blurClass = "backdrop-blur-xl"; break;
    case "lg": blurClass = "backdrop-blur-lg"; break;
    case "md": blurClass = "backdrop-blur-md"; break;
    case "base": blurClass = "backdrop-blur"; break;
    case "sm": blurClass = "backdrop-blur-sm"; break;
    default: blurClass = "backdrop-blur-sm"; break;
  }
  return (
    <div className={`absolute left-0 top-0 w-full h-full ${blurClass} ${style || ""}`}></div>
  );
};

export default BlurLayer;
