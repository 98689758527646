import { useState, useEffect } from "react";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import logo from "./assets/logo_inverted.png";
import { storeUserAction } from "./services/storeUserAction";
import { isMobile } from "./styles/styles";
import { developmentEnv, postPatch } from "./utils/utils";

const AuraActions = ({ linkIdParent, linkedinUrlId, fullName, stageRef, bgIndex, condition, setStatus, viewFull, auraImageSaved }) => {
	const [showShare, setShowShare] = useState(false);
	const [saveImageLoading, setSaveImageLoading] = useState(false);
	const [shareImageLoading, setShareImageLoading] = useState(false);

	useEffect(() => {
    if (!developmentEnv && navigator.canShare) {
			fetch(logo)
			  .then(response => response.blob())
			  .then(blob => {
			    const file = new File([blob], "logo.png", { type: "image/png" });
			    console.log(file); // Now you have a File object
			    if (navigator.canShare({ files: [file] })) {
			    	setShowShare(true);
			    }
			  })
			  .catch(error => {
			    console.error("Error loading the file:", error);
			  });
    }
	}, []);

  const saveImage = () => {
  	storeUserAction({ linkedinUrlId, actionType: `save aura image bg${bgIndex + 1}${viewFull ? " full" : ""}` });
    setSaveImageLoading(true);
    const dataUrl = stageRef.current.toDataURL();
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = `LinkedIn Aura - ${fullName}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setSaveImageLoading(false);
  };

  const shareImage = () => {
  	storeUserAction({ linkedinUrlId, actionType: `share aura image bg${bgIndex + 1}${viewFull ? " full" : ""}` });
  	setShareImageLoading(true);
		const dataUrl = stageRef.current.toDataURL();
		fetch(dataUrl)
		  .then(res => res.blob())
		  .then(blob => {
		    const file = new File([blob], `LinkedIn Aura - ${fullName}.png`, { type: "image/png" });
		    setShareImageLoading(false);
	      navigator.share({ files: [file] })
		      .then(() => {
		      	storeUserAction({ linkedinUrlId, actionType: `share aura image bg${bgIndex + 1}${viewFull ? " full" : ""} success` });
		      })
		      .catch(error => {
		      	storeUserAction({ linkedinUrlId, actionType: `share aura image bg${bgIndex + 1}${viewFull ? " full" : ""} fail` });
		      });
		  });
  };

  const viewFullAura = () => {
  	window.scrollTo(0, 0);
  	setStatus("select");
	  postPatch("PATCH", "/users/viewfull", { id: linkIdParent }).then(
	    json => {
	      console.log(json);
	    },
	    error => {
	    	console.log("45");
	    	console.log(error);
	    }
	  );
  };

  const returnPrimaryButton = () => {
  	if (!isMobile) {
  		return (
	      <PrimaryButton onClick={saveImage} loading={saveImageLoading} disabled={!condition}>
	        Save story image
	      </PrimaryButton>
  		);
  	} else if (showShare) {
  		return (
	      <PrimaryButton onClick={shareImage} loading={shareImageLoading} disabled={!condition}>
	        Share story image
	      </PrimaryButton>
  		);
  	}
  	return null;
  };

  const returnButtonRow = () => {
  	if (!viewFull || !isMobile || showShare) {
	  	const Button = isMobile && !showShare ? PrimaryButton : SecondaryButton;
	  	return (
	    	<div className="flex flex-row justify-between items-center my-8 sm:my-10 space-x-6 sm:space-x-8">
	    		{returnPrimaryButton()}
	    		{!viewFull && (
			      <Button onClick={viewFullAura} disabled={!condition}>
			        View full aura
			      </Button>
	    		)}
	      </div>
	  	);
  	}
  	return null;
  };

  if (isMobile) {
    return (
    	<div className="flex flex-col justify-center items-center mt-8 mb-10 space-y-6">
        <p className={`${condition ? "text-gray-900 font-medium" : "text-gray-600"} text-center`}>
          Hold press on the image to save ☝️
        </p>
        {returnButtonRow()}
      </div>
    );
  }
  return returnButtonRow();
};

export default AuraActions;
