import { Rect, Text, Group } from "react-konva";
import UserProfileCanvas from "./UserProfileCanvas";
import { backgroundImages } from "./styles/styles";

const AuraHeaderCanvas = (props) => {
	const {
		linkedinUrl,
		opacity,
		bgIndex,
		condition,
		canvasWidth,
		defaultTextSize,
		defaultRectWidth,
		defaultRectStrokeWidth,
		defaultRectCornerRadius,
		backgroundOffsetHorizontal,
		contentPadding,
		profileImageDimension,
		headerPaddingVertical,
		rectOffsetVertical,
		rectHeight,
		rectColor,
		rectStrokeColor,
		rectHeaderColor,
		returnLoadingText,
		uploadFile,
		statusRef,
		setUpdateCanvas,
	} = props;

  const {
  	headerFillColor = "white",
  	headerStrokeColor = "#4f46e5", // indigo-600
  	nameColor,
  	headlineColor
  } = backgroundImages[bgIndex];

  const rectOffsetHorizontal = backgroundOffsetHorizontal;

  const headerSize = 80;
  const headerOffsetHorizontal = 0;
  const headerOffsetVertical = -rectOffsetVertical + (headerSize / 2) + 5;
  const headerWidth = canvasWidth;
  const headerStrokeWidth = 5;

  const profileImageOffsetHorizontal = backgroundOffsetHorizontal + contentPadding;
  const profileImageOffsetVertical = rectOffsetVertical + headerPaddingVertical;

  const nameSize = defaultTextSize;
  const namePaddingLeft = 20;
  const nameOffsetHorizontal = -profileImageOffsetHorizontal - profileImageDimension - namePaddingLeft;
  const namePaddingBottom = 5;
  const nameOffsetVertical = -profileImageOffsetVertical - (profileImageDimension / 2) + nameSize + namePaddingBottom;
  const nameWidth = canvasWidth + nameOffsetHorizontal - backgroundOffsetHorizontal - contentPadding;

  const headlineSize = nameSize * 0.95;
  const headlineOffsetHorizontal = nameOffsetHorizontal;
  const headlineOffsetVertical = -profileImageOffsetVertical - (profileImageDimension / 2) - namePaddingBottom;
  const headlineWidth = nameWidth;

	return (
		<>
  		<Group opacity={opacity} listening={false}>
        <Rect
        	// preventDefault={false}
          x={rectOffsetHorizontal}
          y={rectOffsetVertical}
          width={defaultRectWidth}
          height={rectHeight}
          fill={rectColor}
          stroke={rectStrokeColor}
          strokeWidth={defaultRectStrokeWidth}
          cornerRadius={defaultRectCornerRadius}
          listening={false}
        />
        {condition ? (
		      <UserProfileCanvas
		      	user={linkedinUrl}
		      	statusRef={statusRef}
		      	profileImageDimension={profileImageDimension}
		      	profileImageOffsetHorizontal={profileImageOffsetHorizontal}
		      	profileImageOffsetVertical={profileImageOffsetVertical}
		      	nameSize={nameSize}
		      	nameOffsetHorizontal={nameOffsetHorizontal}
		      	nameOffsetVertical={nameOffsetVertical}
		      	nameWidth={nameWidth}
		      	headlineSize={headlineSize}
		      	headlineOffsetHorizontal={headlineOffsetHorizontal}
		      	headlineOffsetVertical={headlineOffsetVertical}
		      	headlineWidth={headlineWidth}
		      	setUpdateCanvas={setUpdateCanvas}
		      	nameColor={nameColor}
		      	headlineColor={headlineColor}
		      />
        ) : returnLoadingText(1, "1. Reviewing profile...", "1. Review profile", "1. Review profile failed", rectOffsetVertical, rectHeight)}
	    </Group>
      <Text
        // preventDefault={false}
        text={uploadFile ? "Resume Aura" : "LinkedIn Aura"}
        fontFamily="Mona Sans Wide"
        fontSize={headerSize}
        fontStyle="italic 900"
        lineHeight={1}
        fill={headerFillColor}
        padding={0}
        wrap="none"
        offsetX={headerOffsetHorizontal}
        offsetY={headerOffsetVertical}
        align="center"
        width={headerWidth}
        stroke={headerStrokeColor}
        strokeWidth={headerStrokeWidth}
        listening={false}
      />
    </>
	);
};

export default AuraHeaderCanvas;
