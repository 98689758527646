import { useState, useRef, useEffect } from "react";
import { Stage, Layer, Text, Image, Line } from "react-konva";
import Konva from "konva";
import useImage from "use-image";
// import { sample } from "lodash";
import barcode from "./assets/barcode.png";
import { notEmpty } from "./styles/styles";

// const issueNumberArray = Array.from({length: 999}, (_, i) => (i + 1).toString().padStart(3, "0"));
// const issueNumber = sample(issueNumberArray);

Konva.pixelRatio = Math.min(2, window.devicePixelRatio);

const CoverCreate = ({ stageRef, imageUrl, fullName, title, month, issueNumber, profilePictureUrl }) => {
  const [titleHeight, setTitleHeight] = useState(0);
  const [nameHeight, setNameHeight] = useState(0);
  const backgroundRef = useRef(null);
  const nameRef = useRef(null);
  const titleRef = useRef(null);
  const [image, status] = useImage(imageUrl, "anonymous", "origin");
  // const [profileImage] = useImage(profilePictureUrl, "anonymous", "origin");
  const [barcodeImage] = useImage(barcode);

  useEffect(() => {
    if (status === "loaded") {
      backgroundRef.current.cache();
    }
  }, [status]);

  useEffect(() => {
    if (notEmpty(fullName) && notEmpty(title)) {
      setNameHeight(nameRef.current.height());
      setTitleHeight(titleRef.current.height());
    }
  }, [fullName, title]);

	return (
	  <Stage ref={stageRef} width={1080} height={1920} className="hidden" listening={false}>
	    <Layer listening={false}>
	      <Image
	        // preventDefault={false}
	        x={0}
	        y={0}
	        width={1080}
	        height={1920}
	        image={image}
	        ref={backgroundRef}
	        filters={[Konva.Filters.Brighten]}
	        brightness={-0.3}
	        crop={{ x: (1024 * (1 - (1080 / 1920))) / 2, y: 0, width: 1024 * 1080 / 1920, height: 1024 }}
	        listening={false}
	      />
	      <Text
	        // preventDefault={false}
	        text={`ISSUE ${issueNumber.toLocaleString()}`}
	        fontFamily="Mona Sans"
	        fontSize={30}
	        fontStyle="bold"
	        lineHeight={1}
	        fill="white"
	        padding={0}
	        wrap="word"
	        offsetY={-80}
	        offsetX={80}
	        width={1080}
	        align="right"
	        listening={false}
	      />
	      <Text
	        // preventDefault={false}
	        text="CEO LIST"
	        fontFamily="Mona Sans Wide"
	        fontSize={150}
	        fontStyle="bold"
	        lineHeight={1}
	        // letterSpacing={1}
	        fill="white"
	        padding={0}
	        wrap="word"
	        offsetY={-145}
	        offsetX={-110}
	        width={1080}
	        align="left"
	        listening={false}
	      />
	      <Text
	        // preventDefault={false}
	        text="MAGAZINE"
	        fontFamily="Mona Sans"
	        fontSize={40}
	        fontStyle="bold"
	        lineHeight={1}
	        fill="white"
	        padding={0}
	        wrap="word"
	        offsetY={-145 - 150 - 25}
	        offsetX={-110}
	        width={1080}
	        align="left"
	        listening={false}
	      />
	      {/*profilePictureUrl && <Image
	        preventDefault={false}
	        x={540 - 75}
	        y={1920 - 210 - titleHeight - nameHeight + 50}
	        width={150}
	        height={150}
	        image={profileImage}
	        cornerRadius={75}
	        listening={false}
	      />*/}
	      <Text
	        ref={nameRef}
	        // preventDefault={false}
	        text={fullName.toUpperCase()}
	        fontFamily="Mona Sans"
	        fontStyle="900"
	        fontSize={65}
	        lineHeight={1.4}
	        fill="white"
	        wrap="word"
	        offsetY={-(1920 - 210 - titleHeight - nameHeight + 150)}
	        width={1080}
	        align="center"
	        padding={100}
	        listening={false}
	      />
	      <Line
	        stroke="white"
	        strokeWidth={3}
	        points={[190,0,890,0]}
	        x={10}
	        y={1920 - 210 - titleHeight + 72}
	        listening={false}
	      />
	      <Text
	        ref={titleRef}
	        // preventDefault={false}
	        text={title}
	        fontFamily="Mona Sans"
	        fontSize={50}
	        fontStyle="italic bold"
	        lineHeight={1.4}
	        // fill="#def7fa"
	        fill="#cffafe"
	        // fill="#e0f2fe"
	        wrap="word"
	        offsetY={-(1920 - 210 - titleHeight)}
	        width={1080}
	        align="center"
	        padding={100}
	        listening={false}
	      />
	      <Text
	        // preventDefault={false}
	        text={month.split(" ").join("\n").toUpperCase()}
	        fontFamily="Mona Sans"
	        fontSize={30}
	        fontStyle="bold"
	        lineHeight={1}
	        fill="white"
	        padding={0}
	        wrap="word"
	        offsetY={-1750}
	        offsetX={-110}
	        width={1080}
	        align="left"
	        listening={false}
	      />
	      <Image
	        // preventDefault={false}
	        x={0}
	        y={0}
	        width={260}
	        height={54}
	        offsetY={-1752}
	        offsetX={-718}
	        image={barcodeImage}
	        listening={false}
	      />
	    </Layer>
	  </Stage>
	)
};

export default CoverCreate;
