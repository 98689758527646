const Badge = ({ color, style, text, dark }) => {
	let bgColor;
	if (dark) {
		switch(color) {
			case "indigo":
				bgColor = "bg-indigo-400/10 text-indigo-400 ring-indigo-400/30";
				break;
			default:
				break;
		}
	} else {
		switch(color) {
			case "indigo":
				bgColor = "bg-indigo-50 text-indigo-700 ring-indigo-700/10";
				break;
			default:
				break;
		}
	}

	return (
	  <span className={`${style || ""} inline-flex self-start items-center rounded-md ${bgColor} px-2 py-1 text-xs font-medium ring-1 ring-inset`}>
	    {text}
	  </span>
	);
};

export default Badge;
