import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { DocumentIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import Tabs from "./Tabs";
import AwardIcon from "./AwardIcon";
import Avatar from "./Avatar";
import award from "./assets/award.svg";
import placeholder from "./assets/placeholder.jpg";
import { storeUserAction } from "./services/storeUserAction";
import { arrayExists } from "./styles/styles";
import { get } from "./utils/utils";

const tabs = ["All", "Created", "Received", "Sent", "Viewed"];
const styleOne = false;

const ProfileContent = ({ awards, linkedinUrls, handleResponse }) => {
	const [selectedTab, setSelectedTab] = useState(tabs[0]);
	const navigate = useNavigate();

	const getRecentProfile = (linkedinUrlId, linkId) => {
		storeUserAction({ linkedinUrlId, actionType: "recent profile" });
	  get(`/users/recentprofile?id=${linkId}`).then(
	    json => {
	      console.log(json);
	      handleResponse(json);
	    },
	    error => {
	    	console.log("30");
	      console.log(error);
	    }
	  );
	};

	const returnRecentProfile = (linkedinUrl) => {
		const { id, fullName, uploadFile, linkId, paid } = linkedinUrl;
		return (
			<button
				key={id}
				className="relative flex flex-col items-center min-w-16 sm:min-w-[72px] sm:hover:opacity-75"
				onClick={() => getRecentProfile(id, linkId)}
			>
				<Avatar
					user={linkedinUrl}
					style="h-14 w-14 min-w-14 sm:h-16 sm:w-16 sm:min-w-16"
					fontSize="text-xl sm:text-2xl"
				/>
				{paid && <CheckCircleIcon className="text-green-600 h-6 w-6 absolute -top-[3px] -right-[3px] sm:-top-0.5 sm:-right-0.5" />}
				<div className="pt-2 flex flex-row justify-start items-center max-w-16 sm:max-w-[72px]">
					{uploadFile && <DocumentIcon className="w-4 h-4 mb-0.5 mr-0.5 min-w-4" />}
					<p className="text-xs truncate">{fullName.split(" ")[0]}</p>
				</div>
			</button>
		);
	};

	const returnRecentProfiles = () => {
		if (arrayExists(linkedinUrls)) {
			return (
				<div className="flex flex-col pt-8">
					<h3 className="text-base font-semibold leading-6 text-gray-900">Recent profiles</h3>
					<div className="pt-4 flex flex-row space-x-3 sm:space-x-4 overflow-scroll hide-scrollbar">
						{linkedinUrls.map(returnRecentProfile)}
					</div>
				</div>
			);
		}
		return null;
	};

	const returnAwardIcon = (emoji) => {
		// const colors = ["slate", "gray", "zinc", "neutral", "stone", "red", "orange", "amber", "yellow", "lime", "green", "emerald", "teal", "cyan", "sky", "blue", "indigo", "violet", "purple", "fuchsia", "pink", "rose"];
		const iconColors = ["text-slate-100", "text-gray-100", "text-zinc-100", "text-neutral-100", "text-stone-100", "text-red-100", "text-orange-100", "text-amber-100", "text-yellow-100", "text-lime-100", "text-green-100", "text-emerald-100", "text-teal-100", "text-cyan-100", "text-sky-100", "text-blue-100", "text-indigo-100", "text-violet-100", "text-purple-100", "text-fuchsia-100", "text-pink-100", "text-rose-100"];
		const emojiBgColors = ["bg-slate-100", "bg-gray-100", "bg-zinc-100", "bg-neutral-100", "bg-stone-100", "bg-red-100", "bg-orange-100", "bg-amber-100", "bg-yellow-100", "bg-lime-100", "bg-green-100", "bg-emerald-100", "bg-teal-100", "bg-cyan-100", "bg-sky-100", "bg-blue-100", "bg-indigo-100", "bg-violet-100", "bg-purple-100", "bg-fuchsia-100", "bg-pink-100", "bg-rose-100"];
		// const randomColor = colors[Math.floor(Math.random() * colors.length)];
		// const iconColor = iconColors.find(c => c.split("-")[1] === randomColor);
		// const emojiBgColor = emojiBgColors.find(c => c.split("-")[1] === randomColor);
		const iconColor = iconColors[0];
		const emojiBgColor = emojiBgColors[0];
		return (
	  	<AwardIcon
	  		emoji={emoji}
	  		position="absolute top-3 left-1.5 sm:top-3 sm:left-1.5"
	  		iconColor={iconColor}
	  		bgColor={emojiBgColor}
	  	/>
		);
	};

	const returnOtherIcons = (gifted, privacy, search) => {
		const icons = [
			{ id: 0, emoji: "🎁", show: gifted },
			{ id: 1, emoji: "🔒", show: privacy },
			{ id: 2, emoji: "🔍", show: search }
		];
		return (
	  	<div className="absolute top-3 right-3 flex flex-col items-center gap-y-2.5">
	  		{icons.filter(i => i.show).map(({ id, emoji }) => {
	  			return (
				  	<div key={id} className="bg-slate-100 rounded-full w-8 h-8 flex justify-center items-center">
				  		<span className="mt-0.5 text-base sm:text-lg">{emoji}</span>
				  	</div>
	  			);
	  		})}
	  	</div>
		);
	};

	const returnProfilePicture = (profilePictureUrl, uploadFile) => {
		if (profilePictureUrl) {
			return (
		    <img
		      className="h-6 w-6 min-w-6 rounded-full mr-1.5"
		      src={profilePictureUrl}
		      alt="avatar"
		    />
			);
		} else if (uploadFile) {
			return <DocumentIcon className="w-5 h-5 mb-0.5 mr-[3px] min-w-5 text-gray-500" />;
		}
		return null;
	};

	const returnArticleVariables = (articleContents, articleVisuals) => {
		let imageUrl, imageUrlMedium, articleVisualLength;
		const articleVisualSelected = articleVisuals.some(a => a.selected);
		const articleVisualSaved = articleVisuals.some(a => a.saved && !a.midjourneyOriginal);
		const articleContentSelected = articleContents.some(a => a.selected);
		if (articleVisualSelected) {
			({ imageUrl, imageUrlMedium } = articleVisuals.find(a => a.selected)); 
		} else if (articleVisualSaved) {
			({ imageUrl, imageUrlMedium } = articleVisuals.find(a => a.saved && !a.midjourneyOriginal));
			articleVisualLength = articleVisuals.filter(a => a.saved && !a.midjourneyOriginal).length;
		}
		let title;
		if (articleContentSelected) {
			({ title } = articleContents.find(a => a.selected))
		}
		return { articleVisualSelected, articleVisualSaved, articleVisualLength, imageUrl, imageUrlMedium, title };
	};

	const returnTitle = (articleVisualSelected, articleVisualSaved, articleVisualLength, error, title, awardTitle) => {
		if (articleVisualSelected) {
			return title;
		} else {
			let header, color;
			if (articleVisualSaved && articleVisualLength > 3) {
				header = "Finish your cover story";
				color = "text-green-600";
			} else if (error) {
				header = "Something went wrong";
				color = "text-red-600"
			} else {
				header = "Processing...";
				color = "text-indigo-600";
			}
			return (
				<>
		      <span className={color}>{header}</span>
		      <br/>
		      {awardTitle}
		    </>
			);
		}
	};

	const returnAwardStyleOne = ({ id, linkId, uploadFile, error, gifted, privacy, search, articleContents, articleVisuals, linkedinUrl: { fullName, profilePictureUrl }, award: { emoji, title: awardTitle } }) => {
		const { 
			articleVisualSelected, 
			articleVisualSaved, 
			articleVisualLength,
			imageUrl, 
			imageUrlMedium, 
			title 
		} = returnArticleVariables(articleContents, articleVisuals);
		return (
	    <li key={id} className="relative">
	      <div className="group aspect-square block w-full overflow-hidden rounded-lg bg-gray-100">
	        <img src={imageUrlMedium || imageUrl || placeholder} alt="cover" className={`pointer-events-none object-cover sm:group-hover:opacity-75 ${articleVisualSelected ? "" : "blur"}`} />
	        {returnAwardIcon(emoji)}
	        {returnOtherIcons(gifted, privacy, search)}
	        <button type="button" className="absolute inset-0 focus:outline-none" onClick={() => navigate(`/a/${linkId}`)}>
	          <span className="sr-only">View details for {title}</span>
	        </button>
	      </div>
	      <p className="pointer-events-none mt-2 line-clamp-2 text-sm font-semibold text-gray-900">
	      	{returnTitle(articleVisualSelected, articleVisualSaved, articleVisualLength, error, title, awardTitle)}
	      </p>
	      <div className="mt-0.5 flex flex-row justify-start items-center">
	      	{returnProfilePicture(profilePictureUrl, uploadFile)}
	      	<p className="pointer-events-none truncate text-sm font-medium text-gray-500">{fullName}</p>
	      </div>
	    </li>
		);
	};

	const returnAwardStyleTwo = ({ id, linkId, uploadFile, error, gifted, privacy, search, articleContents, articleVisuals, linkedinUrl: { fullName, profilePictureUrl }, award: { emoji, title: awardTitle } }) => {
		const { 
			articleVisualSelected, 
			articleVisualSaved, 
			articleVisualLength,
			imageUrl, 
			imageUrlMedium, 
			title 
		} = returnArticleVariables(articleContents, articleVisuals);
		return (
	    <Link
	      key={id}
	      to={`/a/${linkId}`}
	      className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
	    >
	      <div className="aspect-square bg-gray-200 sm:group-hover:opacity-75 overflow-hidden">
	        <img
	          src={imageUrlMedium || imageUrl || placeholder}
	          alt="cover"
	          className={`h-full w-full object-cover object-center sm:h-full sm:w-full ${articleVisualSelected ? "" : "blur"}`}
	        />
	        {returnAwardIcon(emoji)}
	        {returnOtherIcons(gifted, privacy, search)}
	      </div>
	      <div className="flex flex-1 flex-col space-y-2 p-4">
	      	<h3 className="text-sm font-medium text-gray-900 line-clamp-2">
	      		{returnTitle(articleVisualSelected, articleVisualSaved, articleVisualLength, error, title, awardTitle)}
	      	</h3>
	        <div className="flex flex-row justify-start items-center">
	        	{returnProfilePicture(profilePictureUrl, uploadFile)}
	        	<p className="truncate text-sm text-gray-500">{fullName}</p>
	        </div>
	      </div>
	    </Link>
		);
	};

	const returnAwards = () => {
		if (arrayExists(awards)) {
			return (
				<div className="flex flex-col pt-8 pb-40">
					<h3 className="text-base font-semibold leading-6 text-gray-900">Award gallery</h3>
					{/*<Tabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />*/}
			    <ul role="list" className={styleOne ? "pt-4 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 xl:gap-x-8" : "pt-4 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8"}>
			      {awards.map(styleOne ? returnAwardStyleOne : returnAwardStyleTwo)}
			    </ul>
				</div>
			);
		}
		return (
	    <div className={`text-center ${arrayExists(linkedinUrls) ? "pt-8" : "pt-16"} pb-40`}>
	      <img 
	      	src={award}
	      	alt="award"
	      	className="mx-auto h-12 w-12"
	      />
	      <h3 className="mt-2 text-base font-semibold text-gray-900">Award gallery</h3>
	      <p className="mt-1 text-sm text-gray-500 [text-wrap:balance]">No awards yet. Get started by submitting your credentials to The Committee.</p>
	    </div>
		);
	};

	return (
		<div className="flex flex-col">
			{returnRecentProfiles()}
			{returnAwards()}
		</div>
	);
};

export default ProfileContent;
