const Content = ({ title, sections }) => {
	return (
    <div className="px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{title}</h1>
        {sections.map(section => {
        	return (
		        <div key={section.title} className="mt-16 first:mt-10 max-w-2xl">
    					<h2 className="text-2xl font-bold tracking-tight text-gray-900">
    						{section.title}
    					</h2>
		        	{section.content.map((content, index) => {
		        		switch(content.type) {
		        			case "text":
		        				return (
		        					<p key={index} className="mt-8 first-of-type:mt-6">
		        						{content.title && <span className="font-bold">{content.title}{' '}</span>}
		        						{content.content}
		        						{content.html}
		        					</p>
		        				);
		        			case "list":
		        				return (
		        					<div key={index} className="prose mt-8">
						            <ul role="list">
						              {content.content.map((item) => <li key={item}>{item}</li>)}
						            </ul>
						          </div>
		        				);
		        			default:
		        				return null;
		        		}
		        	})}
		        </div>
        	);
        })}
      </div>
    </div>
	);
};

export default Content;
