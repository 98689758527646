import { useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import NavBar from "./NavBar";
import Hero from "./Hero";
import Story from "./Story";
import SocialProof from "./SocialProof";
import Footer from "./Footer";
import { LoggedInContext } from "./context/context";
import { storeUserAction } from "./services/storeUserAction";
import { notEmpty, facebookPixelEnabled } from "./styles/styles";
import { developmentEnv, postPatch } from "./utils/utils";

const Landing = ({ handleResponse }) => {
  const { userId } = useContext(LoggedInContext);
  const [landingVersion, setLandingVersion] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!developmentEnv && facebookPixelEnabled) {
      const advancedMatching = null;
      const pixelOptions = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init("735224225391631", advancedMatching, pixelOptions);
      ReactPixel.pageView();
    }
  }, []);

  useEffect(() => {
    if (userId) {
      storeUserAction({ actionType: "view landing" });

      const utmSource = searchParams.get("utm_source");
      if (utmSource) {
        const utmMedium = searchParams.get("utm_medium") || "";
        const utmCampaign = searchParams.get("utm_campaign") || "";
        const utmContent = searchParams.get("utm_content") || "";
        const landingVersion = searchParams.get("landing_version") || "";
        notEmpty(landingVersion) && setLandingVersion(parseInt(landingVersion));
        let newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete("utm_source");
        newSearchParams.delete("utm_medium");
        newSearchParams.delete("utm_campaign");
        newSearchParams.delete("utm_content");
        newSearchParams.delete("landing_version");
        setSearchParams(newSearchParams);

        postPatch("PATCH", "/users/referral", { 
          user: { 
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaign: utmCampaign,
            utm_content: utmContent,
            landing_version: landingVersion,
          } 
        }).then(
          json => {
            console.log(json);
          },
          error => {
            console.log("22");
            console.log(error);
          }
        );
      } else {
        const landingVersion = localStorage.getItem("landingVersion") || "";
        notEmpty(landingVersion) && setLandingVersion(parseInt(landingVersion));
      }
    }
  }, [userId]);

	return (
    <div className="bg-white min-h-screen">
    	<NavBar />
      <Hero handleResponse={handleResponse} />
      <Story />
      <SocialProof />
      <Footer />
    </div>
	);
};

export default Landing;
