import { useContext, useState, useRef } from "react";
import { LinkIcon, ArrowUpOnSquareIcon, PhotoIcon } from "@heroicons/react/24/outline";
import * as Sentry from "@sentry/react";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import AuraCreate from "./AuraCreate";
import AwardCreate from "./AwardCreate";
import { LoggedInContext } from "./context/context";
import { handleCopy, handleShareMobile, handleShareLinkedin, handleShareX } from "./services/handleShareSocial";
import { isMobile, returnLinkedinLogo, returnXLogo, notEmpty } from "./styles/styles";
import { developmentEnv, get, postPatch } from "./utils/utils";

const AuraOptions = (props) => {
  const { 
    source,
    showShare, 
    shareText, 
    shareUrl, 
    copiedAlert, 
    style, 
    small, 
    generatePersonalityImage,
    setGeneratedImages,
    linkedinUrl, 
    sectionType, 
    header, 
    emoji, 
    content, 
    hashtag,
    bold, 
    boldKey, 
    textKey, 
    randomColor,
    linkId,
    generateAwardImage,
    awards,
    setAwards,
    title,
    description,
    awardId,
    unlock,
    unlockType,
    unlockButtonSize,
    unlockButtonStyle,
    unlockAura,
    unlockLoading
  } = props;
  const { setAlert, alertTimeout } = useContext(LoggedInContext);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(null);
  const stageRef = useRef(null);
  const statusRef = useRef(null);
  const linkedinUrlId = linkedinUrl?.id;
  const sourceFooter = source === "aura page footer";

  const getSignedUrlPersonality = (name, onClick) => {
    setLoading(true);
    setActive(name);
    get(`/users/uploadpersonality?id=${linkId}&hashtag=${hashtag}`).then(
      json => {
        console.log(json);
        const { signed_url: signedUrl } = json;
        if (notEmpty(signedUrl)) {
          checkStatus(signedUrl, onClick);
        } else {
          handleError(onClick, json, "getSignedUrlPersonality response");
        }
      },
      error => {
        console.log("40");
        console.log(error);
        handleError(onClick, error, "getSignedUrlPersonality error");
      }
    );
  };

  const getSignedUrlAward = (name, onClick) => {
    setLoading(true);
    setActive(name);
    get(`/users/uploadaward?id=${awardId}`).then(
      json => {
        console.log(json);
        const { signed_url: signedUrl } = json;
        if (notEmpty(signedUrl)) {
          checkStatus(signedUrl, onClick);
        } else {
          handleError(onClick, json, "getSignedUrlPersonality response");
        }
      },
      error => {
        console.log("41");
        console.log(error);
        handleError(onClick, error, "getSignedUrlPersonality error");
      }
    );
  };

  const checkStatus = (signedUrl, onClick) => {
    const { profilePictureUrl } = linkedinUrl;
    if (!profilePictureUrl || statusRef.current) {
      uploadPersonality(signedUrl, onClick);
    } else {
      setTimeout(() => checkStatus(signedUrl, onClick), 100);
    }
  }

  const uploadPersonality = async (signedUrl, onClick) => {
    const ref = stageRef.current;
    // const image = ref.toDataURL();
    // const file = new Buffer.from(image.replace(/^data:image\/png;base64,/, ""), "base64");
    const file = await ref.toBlob();

    const headers = { "Content-Type": "image/png", "x-amz-acl": "private" };
    fetch(signedUrl, { method: "PUT", body: file, headers }).then(
      response => {
        console.log(response);
        const { status } = response;
        if (status === 200) {
          handleSuccess(onClick);
          updateGenerated();
        } else {
          handleError(onClick, response, "uploadPersonality response");
        }
      },
      error => {
        console.log("42");
        console.log(error);
        handleError(onClick, error, "uploadPersonality error");
      }
    );
  };

  const updateGenerated = () => {
    if (generatePersonalityImage) {
      postPatch("PATCH", "/users/updatepersonality", { id: linkId, hashtag }).then(
        json => {
          console.log(json);
          setGeneratedImages(generatedImages => [...generatedImages, hashtag]);
        },
        error => {
          console.log("43");
          console.log(error);
        }
      );
    } else {
      postPatch("PATCH", "/users/updateaward", { id: awardId }).then(
        json => {
          console.log(json);
          const newAwards = awards.map(a => {
            if (a.id !== awardId) {
              return a;
            }
            return {
              ...a,
              generated: true,
            };
          });
          setAwards(newAwards);
        },
        error => {
          console.log("44");
          console.log(error);
        }
      );
    }
  };

  const handleSuccess = (onClick) => {
    stageRef?.current?.destroy();
    setLoading(false);
    setActive(null);
    onClick();
  };

  const handleError = (onClick, error, functionName) => {
    handleSuccess(onClick);
    Sentry.captureException(error, { extra: { linkId: linkId, functionName } });
  };

  const returnAuraCreate = (name, onClick) => {
    if (loading) {
      if (generatePersonalityImage) {
        return (
          <AuraCreate
            stageRef={stageRef}
            linkedinUrl={linkedinUrl}
            sectionType={sectionType}
            header={header}
            emoji={emoji}
            content={content}
            bold={bold}
            boldKey={boldKey}
            textKey={textKey}
            randomColor={randomColor}
            statusRef={statusRef}
          />
        );
      } else if (generateAwardImage) {
        return (
          <AwardCreate
            stageRef={stageRef}
            linkedinUrl={linkedinUrl}
            emoji={emoji}
            title={title}
            description={description}
            randomColor={randomColor}
            statusRef={statusRef}
          />
        );
      }
    }
    return null;
  };

  const sharing = [
    {
      name: "LinkedIn",
      Icon: ({ loadingClass, ...props }) => returnLinkedinLogo(props, small ? `${loadingClass} !h-4 !w-4 mb-px` : `${loadingClass} !h-3.5 !w-3.5 mb-0.5`),
      onClick: () => handleShareLinkedin(linkedinUrlId, awardId, null, null, `share linkedin ${source}`, shareText, shareUrl),
      // onClick: () => handleShareLinkedin(linkedinUrlId, awardId, null, null, `share linkedin ${source}`, `${shareText} ${developmentEnv ? "https://google.com" : shareUrl}`),
      buttonStyle: "!bg-[#0077B5] hover:!opacity-75"
    },
    {
      name: "Twitter",
      Icon: ({ loadingClass, ...props }) => returnXLogo(props, small ? `${loadingClass} !h-4 !w-4` : `${loadingClass} !h-3.5 !w-3.5`),
      onClick: () => handleShareX(linkedinUrlId, awardId, null, null, `share twitter ${source}`, shareText, shareUrl),
      buttonStyle: "!bg-black hover:!opacity-75"
    },
    {
      name: "Copy",
      Icon: ({ loadingClass, ...props }) => <LinkIcon className={small ? `${loadingClass} h-5 w-5` : `${loadingClass} h-[18px] w-[18px]`} />,
      onClick: () => handleCopy(linkedinUrlId, awardId, null, null, `copy link ${source}`, `${shareText} ${shareUrl}`, setAlert, copiedAlert, alertTimeout),
      buttonStyle: "!pr-3"
    },
    {
      name: "Share",
      Icon: ({ loadingClass, ...props }) => <ArrowUpOnSquareIcon className={small ? `${loadingClass} h-5 w-5 mb-px` : `${loadingClass} h-5 w-5 mb-px`} />,
      onClick: () => handleShareMobile(linkedinUrlId, awardId, null, `share mobile ${source}`, shareText, shareText, shareUrl, `share mobile ${source} success`, `share mobile ${source} fail`),
      buttonStyle: "!pr-3"
    },
  ];

  let sharingOptions = ["LinkedIn", "Twitter"];
  if (isMobile && (showShare || developmentEnv)) {
    sharingOptions = [...sharingOptions, "Share"];
  } else {
    sharingOptions = [...sharingOptions, "Copy"]
  }

  const returnShareOptions = () => (
    <div className={`${style || ""} flex justify-center items-center space-x-4 sm:space-x-6 relative z-10`}>
      {sharingOptions.map(o => {
        const { name, Icon, onClick , buttonStyle} = sharing.find(s => s.name === o);
        const showLoading = loading && active === name;
        const loadingClass = showLoading ? "invisible" : "";
        if (small) {
          return (
            <button 
              key={name} 
              className="text-gray-400 hover:text-gray-500 relative flex justify-center items-center px-1 sm:px-0"
              onClick={() => {
                if (generatePersonalityImage) {
                  getSignedUrlPersonality(name, onClick);
                } else if (generateAwardImage) {
                  getSignedUrlAward(name, onClick);
                } else {
                  onClick();
                }
              }}
              disabled={showLoading}
            >
              <span className="sr-only">{name}</span>
              <Icon aria-hidden="true" loadingClass={loadingClass} />
              {showLoading && <div className="absolute border-2 border-indigo-600 border-t-transparent border-solid rounded-full w-3.5 h-3.5 animate-spin" />}
            </button>
          );
        }
        const text = ["LinkedIn", "Twitter"].includes(name) ? "Share" : name;
        const Button = ["LinkedIn", "Twitter"].includes(name) ? PrimaryButton : SecondaryButton;
        return (
          <Button 
            key={name}
            size="sm"
            onClick={onClick}
            style={`${buttonStyle} !min-w-[88px] sm:!min-w-20`}
          >
            <Icon aria-hidden="true" />
            <span className="mt-0.5">{text}</span>
          </Button>
        );
      })}
      {returnAuraCreate()}
    </div>
  );

  const returnStoryImageButton = () => {
    const Button = unlock ? SecondaryButton : PrimaryButton;
    return (
      <Button 
        size="md"
        onClick={() => window.open(`/story/${linkId}`, "_blank", "noopener,noreferrer")}
        style="!pr-3"
      >
        <PhotoIcon aria-hidden="true" className="h-5 w-5" />
        <span className="mt-0.5">{unlock ? "Image" : "Story image"}</span>
      </Button>
    );
  };

  const returnUnlockRow = () => {
    return (
      <div className={`${unlockButtonStyle || ""} flex justify-center items-center space-x-4 sm:space-x-6`}>
        <PrimaryButton
          size={unlockButtonSize || "md"}
          onClick={unlockAura}
          loading={unlockLoading}
        >
          <span>🔓</span>
          <span className="mt-0.5">Unlock Full Aura ($2.99)</span>
        </PrimaryButton>
        {returnStoryImageButton()}
      </div>
    );
  };

  if (unlock) {
    if (unlockType === "addition") {
      return (
        <div className={`flex flex-col justify-center items-center relative z-10 ${sourceFooter ? "mb-20 sm:mb-24" : ""}`}>
          {returnShareOptions()}
          {returnUnlockRow()}
        </div>
      );
    }
    return (
      <PrimaryButton
        size={unlockButtonSize || "sm"}
        onClick={unlockAura}
        style={`${unlockButtonStyle || ""} relative z-10`}
        loading={unlockLoading}
      >
        <span className="mt-0.5">Unlock</span>
        <span>🔓</span>
      </PrimaryButton>
    );
  } else if (unlockType === "addition") {
    return (
      <div className={`flex flex-col justify-center items-center relative z-10 space-y-5 ${sourceFooter ? "mb-20 sm:mb-24" : ""}`}>
        {returnShareOptions()}
        {returnStoryImageButton()}
      </div>
    );
  }
  return returnShareOptions();
};

export default AuraOptions;
