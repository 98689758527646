import copy from "copy-to-clipboard";
import { storeUserAction } from "./storeUserAction";
import { updateAlert } from "./updateAlert";
import { isMobile } from "../styles/styles";
import { developmentEnv } from "../utils/utils";


export const handleCopy = (linkedinUrlId, awardId, articleId, socialPostId, actionType, copyText, setAlert, copiedAlert, alertTimeout) => {
  storeUserAction({ linkedinUrlId, awardId, articleId, socialPostId, actionType });
  copy(copyText);
  updateAlert(copiedAlert, setAlert, alertTimeout);
};

export const handleEmail = async (linkedinUrlId, awardId, articleId, actionType, emailSubject, emailBody) => {
  storeUserAction({ linkedinUrlId, awardId, articleId, actionType });
  window.open(`mailto:?subject=${emailSubject}&body=${emailBody}`);
};

export const handleShareMobile = async (linkedinUrlId, awardId, articleId, actionType, title, shareText, shareUrl, actionTypeSuccess, actionTypeFail) => {
  storeUserAction({ linkedinUrlId, awardId, articleId, actionType });
  // doesn't work in development, only works in secure context
  await navigator.share({ title: shareText, text: shareText, url: shareUrl })
    .then(() => storeUserAction({ linkedinUrlId, awardId, articleId, actionType: actionTypeSuccess }))
    .catch(error => storeUserAction({ linkedinUrlId, awardId, articleId, actionType: actionTypeFail }));
};

export const handleShareLinkedin = (linkedinUrlId, awardId, articleId, socialPostId, actionType, text, url) => {
  storeUserAction({ linkedinUrlId, awardId, articleId, socialPostId, actionType });
  // this won't work in development because share url is not https
  const shareUrl = encodeURIComponent(developmentEnv ? "https://google.com" : url);
  if (isMobile) {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}&title=${text}&summary=${text}`, "_blank", "noopener,noreferrer");
  } else {
    // const shareText = encodeURIComponent(`${text} ${developmentEnv ? "https://google.com" : url}`);
    window.open(`https://www.linkedin.com/feed/?shareActive=true&shareUrl=${shareUrl}&text=${text}`, "_blank", "noopener,noreferrer");
  }
};

export const handleShareX = (linkedinUrlId, awardId, articleId, socialPostId, actionType, text, url) => {
  storeUserAction({ linkedinUrlId, awardId, articleId, socialPostId, actionType });
  const shareText = encodeURIComponent(text);
  const shareUrl = encodeURIComponent(url);
  window.open(`https://twitter.com/intent/tweet?text=${shareText}&url=${shareUrl}`, "_blank", "noopener,noreferrer");
};

export const handleShareFacebook = (linkedinUrlId, awardId, articleId, socialPostId, actionType, url) => {
  storeUserAction({ linkedinUrlId, awardId, articleId, socialPostId, actionType });
  // this won't work in development - says it is not a valid url
  const shareUrl = encodeURIComponent(developmentEnv ? "https://google.com" : url);
  // window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, "_blank", "noopener,noreferrer");
  window.open(`https://www.facebook.com/dialog/feed?app_id=1311545855544023&display=page&link=${shareUrl}`, "_blank", "noopener,noreferrer");
};