import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { LoggedInContext } from "../context/context";

export const useCredentials = () => {
  const { setLoggedIn } = useContext(LoggedInContext);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get("token");
    const id = searchParams.get("id");
    if (token && id) {
      localStorage.setItem("token", token);
      localStorage.setItem("userId", id);
      localStorage.setItem("loggedIn", true)
      setLoggedIn(true);
      let newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("token");
      newSearchParams.delete("id");
      setSearchParams(newSearchParams);
    }
  }, [searchParams, setSearchParams]);

  return null;
};
