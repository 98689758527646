const Logo = ({ inverted }) => {
	return (
		<p className={`text-lg sm:text-xl font-bold tracking-wide ${inverted ? "text-white" : "text-gray-900"}`}>
			<span className={`${inverted ? "bg-white text-gray-900" : "bg-gray-900 text-white"} p-2 mr-2 rounded-sm pt-2.5`}>
				CEO
			</span>
			LIST
		</p>
	);
};

export default Logo;
