import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { LoggedInContext } from "./context/context";
import { storeUserAction } from "./services/storeUserAction";
import { price, companyEmail } from "./styles/styles";

const faqs = [
  {
    question: "How long does it take to get my cover story?",
    answer: "It will take us about 5 minutes to generate your 2 article and 4 image options. We want to make sure they're perfect for you!",
  },
  {
    question: "Is my cover story unique?",
    answer: "Yes, each cover story is completely unique and personalized based on the selected award and profile. Every article and image is generated on demand, but there might be random similarities in themes and content.",
  },
  {
    question: "Can I create an award for someone else?",
    answer: "Yes, this is part of the fun! When you create an award for a friend or colleague, you can share it with them with the special gifting link. The link will be available at the cover story when it's done.",
  },
  {
    question: "How does regenerating awards work?",
    answer: "You can regenerate awards twice for each profile you submit. When you regenerate, you lose your previous awards for that profile. If you buy an award, you get a new set of awards and regenerates.",
  },
  {
    question: "How much does it cost?",
    answer: `You can generate awards for free. If you find an award you like, then the cover story costs ${price}. AI and web hosting aren't cheap!`,
  },
  {
    question: "Can I get a refund?",
    answer: "You can get a refund if we're unable to generate a cover story for you (this is rare). Otherwise, you will have 2 articles and 4 images to choose from. We can't offer a refund for successful articles because of AI costs.",
  },
  {
    question: "Is my data secure?",
    answer: "Yes, we take numerous steps to protect your data, and we don't sell your data to anyone. We anonymize your profile before processing with AI, and we only send to third parties when it's necessary to create your award.",
    linkHref: "/privacy",
    linkText: "Privacy policy",
  },
  {
    question: "How long is the cover story available?",
    answer: "Your cover story will be available on the website as long as we are operational. You can make it public or private as you wish - see the next question.",
  },
  {
    question: "Can I make my cover story private?",
    answer: "Yes, just go to your cover story, and click on the options button on the top right. You can change the privacy setting here. When you make your cover story private, no one can see it besides you.",
  },
  {
    question: "Will my cover story show up in Google?",
    answer: "No, by default, it will not show up in Google. If you want it to show up, go to your cover story, and click on the options button on the top right. You can change the search setting here.",
  },
];

const FAQ = () => {
  const { userId } = useContext(LoggedInContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (userId) {
      storeUserAction({ actionType: "view faq" });
    }
  }, [userId]);
	return (
    <div className="bg-white min-h-screen">
    	<NavBar />
      <div className="mx-auto max-w-7xl px-6 pb-24 pt-32 lg:px-8 lg:py-40">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
            <p className="mt-4 text-base leading-7 text-gray-600">
              Can’t find the answer you’re looking for? Reach out to our{' '}
              <Link to={`mailto:${companyEmail}`} className="font-semibold text-indigo-600 hover:text-indigo-500" target="_blank" rel="noreferrer">
                customer support
              </Link>{' '}
              team.
            </p>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base font-semibold leading-7 text-gray-900">{faq.question}</dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {faq.answer}
                  </dd>
                  {faq.linkHref && (
                  	<dd className="mt-2 text-base leading-7 text-gray-600">
                  		<Link to={faq.linkHref} className="font-semibold text-indigo-600 hover:text-indigo-500">
                  			{faq.linkText}
                  		</Link>
                  	</dd>
                  )}
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <Footer />
    </div>
	);
};

export default FAQ;