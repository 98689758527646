import { Stage, Layer, Image, Rect } from "react-konva";
import Konva from "konva";

Konva.pixelRatio = Math.min(2, window.devicePixelRatio);

const AuraStageCanvas = ({ image, backgroundImage, stageRef, canvasWidth, canvasHeight, children }) => {
	
  const originalAspectRatio = backgroundImage?.width / backgroundImage?.height;
  const targetAspectRatio = canvasWidth / canvasHeight;
  let cropWidth, cropHeight, cropX, cropY;
  if (originalAspectRatio > targetAspectRatio) {
    // Image is wider than target aspect ratio
    cropHeight = backgroundImage?.height;
    cropWidth = cropHeight * targetAspectRatio;
    cropX = (backgroundImage?.width - cropWidth) / 2; // Crop from the center horizontally
    cropY = 0;
  } else {
    // Image is taller than target aspect ratio
    cropWidth = backgroundImage?.width;
    cropHeight = cropWidth / targetAspectRatio;
    cropX = 0;
    cropY = (backgroundImage?.height - cropHeight) / 2; // Crop from the center vertically
  }

	return (
		  <Stage
		  	ref={stageRef}
		  	width={canvasWidth}
		  	height={canvasHeight} 
		  	className="hidden" 
		  	listening={false}
		  >
		    <Layer listening={false}>
		      <Image
		        // preventDefault={false}
		      	image={image}
		        x={0}
		        y={0}
		        width={canvasWidth}
		        height={canvasHeight}
	          crop={{
	            x: cropX,
	            y: cropY,
	            width: cropWidth,
	            height: cropHeight,
	          }}
		        listening={false}
		      />
	        <Rect
	        	// preventDefault={false}
	          x={0}
	          y={0}
	          width={canvasWidth}
	          height={canvasHeight}
	          fillLinearGradientStartPoint={{ x: 0, y: 0 }}
	          fillLinearGradientEndPoint={{ x: 0, y: canvasHeight }}
	          fillLinearGradientColorStops={[0, 'rgba(0,0,0,0)', 0.8, 'rgba(0,0,0,0)', 1, 'rgba(0,0,0,0.3)']}
	          listening={false}
	        />
	        {children}
		    </Layer>
		  </Stage>
	);
};

export default AuraStageCanvas;
