import AuraHeaderCanvas from "./AuraHeaderCanvas";
import NarcissismScoreCanvas from "./NarcissismScoreCanvas";
import PersonaCanvas from "./PersonaCanvas";
import PersonalityCanvas from "./PersonalityCanvas";
import AwardCanvas from "./AwardCanvas";
import AuraFooterCanvas from "./AuraFooterCanvas";
import AuraStageCanvas from "./AuraStageCanvas";
import AuraImage from "./AuraImage";
import { calculateLines } from "./styles/styles";

const AuraCanvasShared = (props) => {
	const {
		linkedinUrl,
		profileJsonCleaned,
		fullName,
		totalScore,
		summary,
		personaName,
		quote,
		roast,
		pickupLine,
		emoji,
		awardText,
		uploadFile,
		stepOneCondition,
		stepTwoCondition,
		stepThreeCondition,
		stepFourCondition,
		stepFiveCondition,
		stepSixCondition,
		src,
		setUpdateCanvas,
		firstLoad,
		stageRef,
		statusRef,
		bgIndex,
		backgroundImage,
		image,
		personaImage,
		logoImage,
		canvasWidth,
		defaultTextSize,
		defaultTextColor,
  	returnSectionColors,
  	returnOpacity,
  	returnLoadingText,
	} = props;

  const canvasHeight = 1920;
  const backgroundOffsetHorizontal = 90;
  const sectionGapVertical = 90;

  const contentPadding = 40;
  const defaultOffsetHorizontal = -backgroundOffsetHorizontal - contentPadding;

  const defaultRectHeight = (contentPadding * 2) + (defaultTextSize * 1.1 * 2);
  const defaultRectWidth = canvasWidth - (backgroundOffsetHorizontal * 2);
  const defaultRectStrokeWidth = 10;
  const defaultRectCornerRadius = 20;

  const profileImageDimension = 100;
  const headerPaddingVertical = 40;
  const rectOneHeight = stepOneCondition ? ((headerPaddingVertical * 2) + profileImageDimension) : defaultRectHeight;

  const narcissismHeaderSize = defaultTextSize;
  const narcissismSubheaderPaddingTop = 12;
  const narcissismSubheaderSize = narcissismHeaderSize;
  const rectTwoHeight = stepTwoCondition ? ((contentPadding * 2) + narcissismHeaderSize + narcissismSubheaderPaddingTop + narcissismSubheaderSize) : defaultRectHeight;

  const personaHeaderSize = defaultTextSize;
  const quoteTextPaddingTop = 15;
  const quoteTextSize = personaHeaderSize;
  const quoteTextLineHeight = 1.1;
  const quoteTextMaxLines = 2;
  const rectThreeHeightMax = (contentPadding * 2) + personaHeaderSize + quoteTextPaddingTop + (quoteTextMaxLines * quoteTextSize * quoteTextLineHeight);
  const personaImageAdjustment = 50;
  const scaleFactor = 0.22;
  const quoteTextPaddingLeft = 10;
  const quoteTextOffsetHorizontalMax = -backgroundOffsetHorizontal - rectThreeHeightMax - (personaImageAdjustment / 2) - contentPadding - (2 * (86 * scaleFactor)) - quoteTextPaddingLeft;
  const quoteTextWidth = canvasWidth + quoteTextOffsetHorizontalMax - backgroundOffsetHorizontal - contentPadding;
  const rectThreeHeight = stepThreeCondition ? ((contentPadding * 2) + personaHeaderSize + quoteTextPaddingTop + (Math.min(calculateLines(quote, quoteTextWidth, quoteTextSize, "normal"), quoteTextMaxLines) * quoteTextSize * quoteTextLineHeight)) : defaultRectHeight;

  const personalityHeaderSize = defaultTextSize;
  const personalityTextPaddingTop = 20;
  const personalityTextSize = personalityHeaderSize;
  const personalityTextLineHeight = 1.1;
  const personalityTextOffsetHorizontal = defaultOffsetHorizontal;
  const personalityTextWidth = canvasWidth + (personalityTextOffsetHorizontal * 2);

  const roastTextMaxLines = 3;
  const rectFourOffsetHorizontal = backgroundOffsetHorizontal;
  const rectFourHeight = stepFourCondition ? ((contentPadding * 2) + personalityHeaderSize + personalityTextPaddingTop + (Math.min(calculateLines(roast, personalityTextWidth, personalityTextSize, "normal"), roastTextMaxLines) * personalityTextSize * personalityTextLineHeight)) : defaultRectHeight;

  const pickupLinesTextMaxLines = 3;
  const rectFiveOffsetHorizontal = backgroundOffsetHorizontal;
  const rectFiveHeight = stepFiveCondition ? ((contentPadding * 2) + personalityHeaderSize + personalityTextPaddingTop + (Math.min(calculateLines(pickupLine, personalityTextWidth, personalityTextSize, "normal"), pickupLinesTextMaxLines) * personalityTextSize * personalityTextLineHeight)) : defaultRectHeight;

  const awardIconSize = 100;
  const awardTitleSize = defaultTextSize;
  const awardTitleLineHeight = 1.1;
  const awardTitlePaddingLeft = 20;
  const awardTitleOffsetHorizontal = defaultOffsetHorizontal - awardIconSize - awardTitlePaddingLeft;
  const awardTitleWidth = canvasWidth + awardTitleOffsetHorizontal - backgroundOffsetHorizontal - contentPadding;
  const awardLines = calculateLines(awardText, awardTitleWidth, awardTitleSize, "600");
  const awardHeightAdjustment = awardLines === 1 ? 10 : 0;
  const awardMaxLines = 2;
  const awardTitleHeight = Math.min(awardLines, awardMaxLines) * awardTitleSize * awardTitleLineHeight;
  const rectSixHeight = stepSixCondition ? (((contentPadding + awardHeightAdjustment) * 2) + awardTitleHeight) : defaultRectHeight;

  const logoHeight = 60;
  const logoOffsetHorizontal = 50;

  let backgroundOffsetVertical = 90;
  if (stepSixCondition) {
  	backgroundOffsetVertical = Math.max((canvasHeight - (rectOneHeight + rectTwoHeight + rectThreeHeight + rectFourHeight + rectFiveHeight + rectSixHeight + (sectionGapVertical * 5) + logoHeight + logoOffsetHorizontal)) / 2, 90);
  }
  const rectOneOffsetVertical = backgroundOffsetVertical;
  const rectTwoOffsetVertical = rectOneOffsetVertical + rectOneHeight + sectionGapVertical;
  const rectThreeOffsetVertical = rectTwoOffsetVertical + rectTwoHeight + sectionGapVertical;
  const rectFourOffsetVertical = rectThreeOffsetVertical + rectThreeHeight + sectionGapVertical;
  const rectFiveOffsetVertical = rectFourOffsetVertical + rectFourHeight + sectionGapVertical;
  const rectSixOffsetVertical = rectFiveOffsetVertical + rectFiveHeight + sectionGapVertical;

  const returnAuraHeader = () => {
  	const { rectColor: rectOneColor, strokeColor: rectOneStrokeColor, headerColor: rectOneHeaderColor } = returnSectionColors(0);
  	return (
  		<AuraHeaderCanvas
  			linkedinUrl={linkedinUrl}
  			opacity={returnOpacity(1)}
  			bgIndex={bgIndex}
  			condition={stepOneCondition}
  			canvasWidth={canvasWidth}
  			defaultTextSize={defaultTextSize}
  			defaultRectHeight={defaultRectHeight}
  			defaultRectWidth={defaultRectWidth}
				defaultRectStrokeWidth={defaultRectStrokeWidth}
				defaultRectCornerRadius={defaultRectCornerRadius}
  			backgroundOffsetHorizontal={backgroundOffsetHorizontal}
  			contentPadding={contentPadding}
				profileImageDimension={profileImageDimension}
				headerPaddingVertical={headerPaddingVertical}
				rectOffsetVertical={rectOneOffsetVertical}
				rectHeight={rectOneHeight}
				rectColor={rectOneColor}
				rectStrokeColor={rectOneStrokeColor}
				rectHeaderColor={rectOneHeaderColor}
				returnLoadingText={returnLoadingText}
				uploadFile={uploadFile}
				statusRef={statusRef}
				setUpdateCanvas={setUpdateCanvas}
  		/>
  	);
  };

  const returnNarcissismScore = () => {
  	const { rectColor: rectTwoColor, strokeColor: rectTwoStrokeColor, headerColor: rectTwoHeaderColor } = returnSectionColors(1);
  	return (
  		<NarcissismScoreCanvas
	  		summary={summary}
	  		totalScore={totalScore}
  			opacity={returnOpacity(2)}
  			bgIndex={bgIndex}
  			condition={stepTwoCondition}
  			canvasWidth={canvasWidth}
  			defaultTextColor={defaultTextColor}
  			defaultRectWidth={defaultRectWidth}
				defaultRectStrokeWidth={defaultRectStrokeWidth}
				defaultRectCornerRadius={defaultRectCornerRadius}
  			backgroundOffsetHorizontal={backgroundOffsetHorizontal}
  			contentPadding={contentPadding}
  			defaultOffsetHorizontal={defaultOffsetHorizontal}
  			narcissismHeaderSize={narcissismHeaderSize}
  			narcissismSubheaderPaddingTop={narcissismSubheaderPaddingTop}
  			narcissismSubheaderSize={narcissismSubheaderSize}
  			rectOffsetVertical={rectTwoOffsetVertical}
  			rectHeight={rectTwoHeight}
				rectColor={rectTwoColor}
				rectStrokeColor={rectTwoStrokeColor}
				rectHeaderColor={rectTwoHeaderColor}
				returnLoadingText={returnLoadingText}
  		/>
  	);
  };

  const returnPersonas = () => {
  	const { rectColor: rectThreeColor, strokeColor: rectThreeStrokeColor, headerColor: rectThreeHeaderColor } = returnSectionColors(2);
  	return (
  		<PersonaCanvas
  			personaImage={personaImage}
  			personaName={personaName}
  			quote={quote}
  			opacity={returnOpacity(3)}
  			bgIndex={bgIndex}
  			condition={stepThreeCondition}
  			canvasWidth={canvasWidth}
  			defaultTextColor={defaultTextColor}
  			defaultRectWidth={defaultRectWidth}
				defaultRectStrokeWidth={defaultRectStrokeWidth}
				defaultRectCornerRadius={defaultRectCornerRadius}
  			backgroundOffsetHorizontal={backgroundOffsetHorizontal}
  			contentPadding={contentPadding}
  			personaHeaderSize={personaHeaderSize}
  			quoteTextPaddingTop={quoteTextPaddingTop}
  			quoteTextSize={quoteTextSize}
  			quoteTextLineHeight={quoteTextLineHeight}
  			quoteTextMaxLines={quoteTextMaxLines}
  			personaImageAdjustment={personaImageAdjustment}
  			scaleFactor={scaleFactor}
  			quoteTextPaddingLeft={quoteTextPaddingLeft}
  			quoteTextWidth={quoteTextWidth}
  			rectOffsetVertical={rectThreeOffsetVertical}
  			rectHeight={rectThreeHeight}
				rectColor={rectThreeColor}
				rectStrokeColor={rectThreeStrokeColor}
				rectHeaderColor={rectThreeHeaderColor}
				returnLoadingText={returnLoadingText}
  		/>
  	);
  };

  const returnRoast = () => {
  	const { rectColor: rectFourColor, strokeColor: rectFourStrokeColor, headerColor: rectFourHeaderColor } = returnSectionColors(3);
  	return (
  		<PersonalityCanvas
  			emoji="🔥"
  			emojiPosition="right"
  			header="Roast"
  			text={roast}
  			opacity={returnOpacity(4)}
  			condition={stepFourCondition}
  			canvasWidth={canvasWidth}
  			defaultTextColor={defaultTextColor}
  			defaultRectWidth={defaultRectWidth}
				defaultRectStrokeWidth={defaultRectStrokeWidth}
				defaultRectCornerRadius={defaultRectCornerRadius}
  			backgroundOffsetHorizontal={backgroundOffsetHorizontal}
  			contentPadding={contentPadding}
  			headerSize={personalityHeaderSize}
  			textPaddingTop={personalityTextPaddingTop}
  			textSize={personalityTextSize}
  			textLineHeight={personalityTextLineHeight}
  			textMaxLines={roastTextMaxLines}
  			textOffsetHorizontal={personalityTextOffsetHorizontal}
  			textWidth={personalityTextWidth}
  			rectOffsetHorizontal={rectFourOffsetHorizontal}
  			rectOffsetVertical={rectFourOffsetVertical}
  			rectHeight={rectFourHeight}
				rectColor={rectFourColor}
				rectStrokeColor={rectFourStrokeColor}
				rectHeaderColor={rectFourHeaderColor}
				returnLoadingText={returnLoadingText(4, "4. Analyzing career...", "4. Analyze career", "4. Analyze career failed", rectFourOffsetVertical, rectFourHeight)}
  		/>
  	);
  };

  const returnPickupLines = () => {
  	const { rectColor: rectFiveColor, strokeColor: rectFiveStrokeColor, headerColor: rectFiveHeaderColor } = returnSectionColors(4);
  	return (
  		<PersonalityCanvas
  			emoji="😘"
  			emojiPosition="left"
  			header="Pickup Lines"
  			text={pickupLine}
  			opacity={returnOpacity(5)}
  			condition={stepFiveCondition}
  			canvasWidth={canvasWidth}
  			defaultTextColor={defaultTextColor}
  			defaultRectWidth={defaultRectWidth}
				defaultRectStrokeWidth={defaultRectStrokeWidth}
				defaultRectCornerRadius={defaultRectCornerRadius}
  			backgroundOffsetHorizontal={backgroundOffsetHorizontal}
  			contentPadding={contentPadding}
  			headerSize={personalityHeaderSize}
  			textPaddingTop={personalityTextPaddingTop}
  			textSize={personalityTextSize}
  			textLineHeight={personalityTextLineHeight}
  			textMaxLines={roastTextMaxLines}
  			textOffsetHorizontal={personalityTextOffsetHorizontal}
  			textWidth={personalityTextWidth}
  			rectOffsetHorizontal={rectFiveOffsetHorizontal}
  			rectOffsetVertical={rectFiveOffsetVertical}
  			rectHeight={rectFiveHeight}
				rectColor={rectFiveColor}
				rectStrokeColor={rectFiveStrokeColor}
				rectHeaderColor={rectFiveHeaderColor}
				returnLoadingText={returnLoadingText(5, "5. Analyzing personal life...", "5. Analyze personal life", "5. Analyze personal life failed", rectFiveOffsetVertical, rectFiveHeight)}
  		/>
  	);
  };

  const returnAwards = () => {
  	const { rectColor: rectSixColor, strokeColor: rectSixStrokeColor, headerColor: rectSixHeaderColor } = returnSectionColors(5);
  	return (
  		<AwardCanvas
  			emoji={emoji}
  			awardText={awardText}
  			opacity={returnOpacity(6)}
  			bgIndex={bgIndex}
  			condition={stepSixCondition}
  			canvasWidth={canvasWidth}
  			defaultTextColor={defaultTextColor}
  			defaultRectWidth={defaultRectWidth}
				defaultRectStrokeWidth={defaultRectStrokeWidth}
				defaultRectCornerRadius={defaultRectCornerRadius}
  			backgroundOffsetHorizontal={backgroundOffsetHorizontal}
  			contentPadding={contentPadding}
  			defaultOffsetHorizontal={defaultOffsetHorizontal}
  			awardIconSize={awardIconSize}
  			awardTitleSize={awardTitleSize}
  			awardTitleLineHeight={awardTitleLineHeight}
  			awardTitleOffsetHorizontal={awardTitleOffsetHorizontal}
  			awardTitleWidth={awardTitleWidth}
  			awardHeightAdjustment={awardHeightAdjustment}
  			awardMaxLines={awardMaxLines}
  			awardTitleHeight={awardTitleHeight}
  			rectOffsetVertical={rectSixOffsetVertical}
  			rectHeight={rectSixHeight}
				rectColor={rectSixColor}
				rectStrokeColor={rectSixStrokeColor}
				rectHeaderColor={rectSixHeaderColor}
				returnLoadingText={returnLoadingText}
				setUpdateCanvas={setUpdateCanvas}
  		/>
  	);
  };

  const returnFooter = () => {
  	return (
  		<AuraFooterCanvas
  			logoImage={logoImage}
  			canvasWidth={canvasWidth}
  			canvasHeight={canvasHeight}
  			defaultTextSize={defaultTextSize}
  			logoHeight={logoHeight}
  			logoOffsetHorizontal={logoOffsetHorizontal}
  		/>
  	);
  };

  const returnCanvas = () => {
  	return (
		  <AuraStageCanvas
		  	image={image}
		  	backgroundImage={backgroundImage}
		  	stageRef={stageRef}
		  	canvasWidth={canvasWidth}
		  	canvasHeight={canvasHeight} 
		  >
        {returnAuraHeader()}
        {returnNarcissismScore()}
        {returnPersonas()}
        {returnRoast()}
        {returnPickupLines()}
        {returnAwards()}
        {returnFooter()}
		  </AuraStageCanvas>
  	);
  };

  const returnImage = () => {
  	return <AuraImage src={src} firstLoad={firstLoad} />;
  };

	return (
		<>
			{returnImage()}
			{returnCanvas()}
		</>
	);
};

export default AuraCanvasShared;
