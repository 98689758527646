import { CheckCircleIcon } from "@heroicons/react/24/solid";
import AwardIcon from "./AwardIcon";
import AudioButtons from "./AudioButtons";
import AuraOptions from "./AuraOptions";
import BlurLayer from "./BlurLayer";
import { headerColors, awardBgColors, awardIconColors, uuidToBase64 } from "./styles/styles";

const Award = (props) => {
	const { 
		award, 
		index, 
		selectedAwardId, 
		awards, 
		setAwards, 
		linkedinUrl, 
		audioRefs, 
		setAudioLoading, 
		setAudioPlaying, 
		setShareAudioLoading, 
		setDownloadAudioLoading, 
		audioDisabled, 
		onClick,
		bgColor,
		home,
		showShare, 
		shareUrl, 
		copiedAlert,
		randomColor,
		paid,
		unlockAura,
		unlockLoading
	} = props;
	const { id, title, description, emoji, audioLoading, audioPlaying, shareAudioLoading, downloadAudioLoading, audioUrl, generated } = award;
	const awardBgColor = awardBgColors.find(c => c.split("-")[1] === randomColor);
	const awardIconColor = awardIconColors.find(c => c.split("-")[1] === randomColor);
	// const awardHeaderColor = headerColors.find(c => c.split("-")[1] === randomColor);
	const selected = id === selectedAwardId;
	const display = index === 0 || paid;
	const selectedStyle = selected ? "bg-white border border-indigo-600 ring-2 ring-indigo-600" : "border border-gray-200";
	const containerStyle = home ? `bg-gradient-to-br from-white ${bgColor} sm:absolute sm:-bottom-24 sm:-right-16 sm:w-96 z-30 animate-wiggle2` : `${bgColor || "bg-gray-50"} ${display ? "hover:bg-white" : ""}`;
	
	const returnAudioButtons = () => {
		if (home || display) {
			return (
	      <AudioButtons 
	      	id={id}
	      	indexAward={index}
	      	title={title}
	      	audioUrl={audioUrl}
	      	awards={awards}
	      	setAwards={setAwards}
	      	linkedinUrl={linkedinUrl}
	      	audioRefs={audioRefs}
	      	audioLoading={audioLoading}
	      	setAudioLoading={setAudioLoading}
	      	audioPlaying={audioPlaying}
	      	setAudioPlaying={setAudioPlaying}
	      	shareAudioLoading={shareAudioLoading}
	      	setShareAudioLoading={setShareAudioLoading}
	      	downloadAudioLoading={downloadAudioLoading}
	      	setDownloadAudioLoading={setDownloadAudioLoading}
	      	disabled={audioDisabled}
	      	home={home}
	      />
			);
		}
		return null;
	};

	const returnOptions = () => {
		if (!home) {
			return (
				<AuraOptions
        	source="award"
        	showShare={showShare}
        	shareText={`I got a CEO Award for ${title}. Check out my LinkedIn Aura!`}
        	shareUrl={`${shareUrl}/?a=${uuidToBase64(id)}`}
        	copiedAlert={copiedAlert}
        	style=""
        	small
        	generateAwardImage={!generated}
        	awards={awards}
        	setAwards={setAwards}
        	linkedinUrl={linkedinUrl}
        	emoji={emoji}
        	title={title}
        	description={description}
        	randomColor={randomColor}
        	awardId={id}
        	unlock={!display}
        	unlockType="replace"
        	unlockButtonStyle="my-2"
        	unlockAura={() => unlockAura("award")}
        	unlockLoading={unlockLoading}
        />
			);
		}
		return null;
	}

	return (
    <li key={id} className={`${containerStyle} relative col-span-1 flex flex-col rounded-lg text-center shadow-lg ${selectedStyle}`}>
      {!home && <CheckCircleIcon
        className={`absolute top-2.5 right-2.5 h-7 w-7 text-indigo-600 ${selected ? "" : "invisible"}`}
        aria-hidden="true"
      />}
      <div className="flex flex-1 flex-col p-8 pb-6 justify-center relative">
      	<AwardIcon
      		emoji={emoji}
      		position="relative"
      		iconColor={awardIconColor || "text-slate-300"}
      		bgColor={awardBgColor || "bg-slate-300"}
      	/>
        <h3 className="mt-6 font-medium text-gray-900">{title}</h3>
        <p className="mt-1.5 text-sm text-gray-500">{description}</p>
        <div className={`flex flex-row justify-center items-center self-center space-x-3 sm:space-x-4 relative z-10 pt-4 ${home ? "" : "pr-2"}`}>
        	{returnAudioButtons()}
	        {returnOptions()}
	      </div>
	      {}
      </div>
      {!home && display && <button className="absolute inset-0" onClick={() => onClick(id)} />}
      {!home && !display && <BlurLayer />}
    </li>
	);
};

export default Award;
