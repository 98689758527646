import { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "./Loading";
import NavBar from "./NavBar";
import LoadingShared from "./LoadingShared";
import ErrorShared from "./ErrorShared";
import SelectArticleContent from "./SelectArticleContent";
import SelectArticleVisual from "./SelectArticleVisual";
import ArticleDisplay from "./ArticleDisplay";
import Footer from "./Footer";
import sample from "./assets/sample.jpeg";
import { LoggedInContext } from "./context/context";
import { useCredentials } from "./hooks/useCredentials";
import { notEmpty, arrayExists, fallbackEmoji, sampleArticleId, sampleArticleIdDevelopment } from "./styles/styles";
import { developmentEnv, get, getToken } from "./utils/utils";

const currentDescriptionArray = [
  "Don't rush genius 🎨", 
  "Can't rush art 🎨", 
  "Hard at work 🎨", 
  "Artists at work 🎨", 
  "Painting a Picasso 🎨", 
  "Hitting the angles 🎨"
];
const currentDescriptionStepTwo = currentDescriptionArray[Math.floor(Math.random() * currentDescriptionArray.length)];
const defaultMessage = {
  header: "Something went wrong",
  subheader: "We couldn't find this article."
};
const tabs = ["Option 1", "Option 2"];

const Article = () => {
  const { userId, loggedIn } = useContext(LoggedInContext);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [articleStatus, setArticleStatus] = useState(null);
  const [message, setMessage] = useState(null);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [article, setArticle] = useState(null);
  const { articleId, giftId } = useParams();
  const navigate = useNavigate();
  useCredentials();

  useEffect(() => {
    if (userId) {
      const method = loggedIn ? getToken : get;
      if (articleId) {
        setLoading(true);
        const path = loggedIn ? "article" : "articlepublic";
        method(`/users/${path}?id=${articleId}`).then(
          json => {
            handleResponse(json);
            setLoading(false);
          },
          error => {
            // wrong article id
            console.log("1");
            console.log(error);
            setDefaultErrorMessage();
          }
        );
      } else if (giftId) {
        setLoading(true);
        const path = loggedIn ? "gift" : "giftpublic";
        method(`/users/${path}?id=${giftId}`).then(
          json => {
            handleResponse(json);
            setLoading(false);
          },
          error => {
            // wrong article id
            console.log("2");
            console.log(error);
            setDefaultErrorMessage();
          }
        );
      } else {
        setDefaultErrorMessage();
      }
    }
  }, [userId, articleId, giftId, loggedIn]);

  const handleResponse = (json) => {
    console.log(json);
    const { status, message, article, link_id: linkId, gift_message: giftMessage } = json;
    setStatus(status);
    switch(status) {
      case "gift":
        navigate(`/a/${linkId}`);
        break;
      case "gift error":
        navigate(`/a/${linkId}?gift_message=${giftMessage}`);
        break;
      case "processing":
        const { article_status: articleStatus } = json;
        setArticleStatus(articleStatus);
        break;
      case "error":
        setMessage(message);
        break;
      case "content":
      case "visual":
      case "article":
        const { articleContents, articleVisuals } = article;
        const articleContentsModified = articleContents
        	.filter(a => notEmpty(a.title) && arrayExists(a.body) && notEmpty(a.location))
        	.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        const articleVisualsModified = articleVisuals
        	.filter(a => a.saved && !a.midjourneyOriginal)
        	.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        const articleModified = {
        	...article,
        	articleContents: articleContentsModified,
        	articleVisuals: articleVisualsModified
        };
        setArticle(articleModified);
        break;
      default:
        break;
    }
  };

  const setDefaultErrorMessage = () => {
    setStatus("error");
    setMessage(defaultMessage);
    setLoading(false);
  };

  const returnLoading = () => {
    if (articleStatus) {
      const { awardTitle, awardEmoji, written, email, contactEmail, error, errorMessage, queued, queuePosition, loadingSessionId  } = articleStatus;
      const title = `${awardEmoji || fallbackEmoji} ${awardTitle}`;
      const steps = [
        { 
          id: 0, 
          currentName: "Writing articles", 
          timeEstimate: "2m",
          completedName: "Articles written",
          upcomingName: null,
          currentDescription: title,
          completedDescription: title,
          errorDescription: title,
          completedSrc: null,
          status: written ? "complete" : "current",
          // status: "current",
          // status: "complete",
          defaultErrorMessage: {
            header: "Something went wrong",
            subheader: "We were unable to write the articles. You were automatically refunded."
          }
        },
        { 
          id: 1, 
          currentName: "Creating images", 
          timeEstimate: "5m",
          completedName: null,
          upcomingName: "Create images",
          currentDescription: currentDescriptionStepTwo,
          completedDescription: null,
          errorDescription: null,
          status: written ? "current" : "upcoming",
          // status: "upcoming",
          // status: "current",
          defaultErrorMessage: {
            header: "Something went wrong",
            subheader: "We were unable to create images. You were automatically refunded."
          }
        },
      ];
      return (
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8 pt-24">
          <LoadingShared 
            linkIdParent={articleId}
            steps={steps} 
            email={email}
            contactEmail={contactEmail}
            readyText="when your cover story options are ready." 
            // readyText="when your cover story options are ready. You can also use the award gallery on your home page to come back." 
            error={error}
            errorMessage={errorMessage}
            queued={queued}
            queuePosition={queuePosition}
            handleResponse={handleResponse}
            loadingSessionId={loadingSessionId}
          />
        </div>
      );
    }
    return null;
  };

  const returnError = () => {
    if (message) {
      return <ErrorShared message={message} />;
    }
    return null;
  };

  const returnArticleContents = () => {
  	if (article) {
  		const { articleContents } = article;
  		if (arrayExists(articleContents)) {
  			return (
		      <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8 pt-24">
		        <SelectArticleContent
		        	article={article}
		        	setArticle={setArticle}
		          tabs={tabs}
		          selectedTab={selectedTab}
		          setSelectedTab={setSelectedTab}
		          setStatus={setStatus}
		        />
		      </div>
  			);
  		}
  	}
  };

  const returnArticleVisuals = () => {
  	if (article) {
  		const { articleContents, articleVisuals } = article;
  		if (arrayExists(articleContents) && arrayExists(articleVisuals)) {
  			return (
		      <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8 pt-24">
		        <SelectArticleVisual
		          article={article}
		          setArticle={setArticle}
		          setStatus={setStatus}
		          setSelectedTab={setSelectedTab}
              linkId={articleId}
		        />
		      </div>
  			);
  		}
  	}
  };

  const returnSampleSticker = () => {
    if (articleId === (developmentEnv ? sampleArticleIdDevelopment : sampleArticleId)) {
      return (
        <div className="w-full flex flex-col items-center">
          <img
            src={sample}
            alt="sample cover story" 
            className="h-20 mb-4 mx-4 sm:mx-6 lg:absolute lg:top-2.5 lg:z-50 grayscale rotate-3" 
          />
        </div>
      );
    }
  };

  const returnArticle = () => {
  	if (article) {
	    return (
	      <div className="mx-auto px-0 sm:px-6 lg:px-8 pt-24">
          {returnSampleSticker()}
	        <ArticleDisplay 
	          linkId={articleId}
	          article={article}
	          setArticle={setArticle}
            giftIdParent={giftId}
	        />
	      </div>
	    );
  	}
  };

  const returnContent = () => {
    switch(status) {
      case "processing":
        return returnLoading();
      case "error":
        return returnError();
      case "content":
        return returnArticleContents();
      case "visual":
        return returnArticleVisuals();
      case "article":
        return returnArticle();
      default:
        return;
    }
  };

  if (loading) {
    return <Loading />
  }
  return (
    <div className="bg-white min-h-screen">
      <NavBar inverted={status === "error"} giftId={giftId} />
      {returnContent()}
      {!["processing", "error"].includes(status) && <Footer />}
    </div>
  );
};

export default Article;
