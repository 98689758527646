import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import Content from "./Content";
import Footer from "./Footer";
import { LoggedInContext } from "./context/context";
import { storeUserAction } from "./services/storeUserAction";
import { companyEmail, websiteName } from "./styles/styles";

const sections = [
	{
		title: "1. Introduction",
		content: [
			{
				type: "text",
				content: `Welcome to ${websiteName}. We are committed to respecting the privacy of our users and ensuring the protection of their personal information. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of information through our website, which utilizes artificial intelligence (AI) to generate personalized awards and cover stories.`
			}
		]
	},
	{
		title: "2. Information Collection",
		content: [
			{
				type: "text",
				title: "2.1 Personal Information.",
				content: ` While using ${websiteName}, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. This may include, but is not limited to, your name, email address, and phone number. We also ask for your LinkedIn profile and/or resume data to provide website services.`
			},
			{
				type: "text",
				title: "2.2 Usage Data.",
				content: "We collect information on how the website is accessed and used. This Usage Data may include information such as your computer's Internet Protocol address (IP address), browser type, browser version, the pages of our website that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data."
			},
			{
				type: "text",
				title: "2.3  AI-Generated Content Data.",
				content: "We also collect data related to the AI-generated awards and cover stories, including your inputs to the AI and the content it produces."
			}
		]
	},
	{
		title: "3. Use of Data",
		content: [
			{
				type: "text",
				content: `${websiteName} uses the collected data for various purposes, ensuring the enhancement of your experience while respecting your privacy. The following outlines how we use the data we collect:`
			},
			{
				type: "text",
				title: "3.1 To Provide and Maintain our Service.",
				content: "We use your information to operate and maintain the functionality of our website, including the AI-driven content generation features. This includes using personal information and usage data to troubleshoot, prevent, and address technical issues."
			},
			{
				type: "text",
				title: "3.2 To Personalize User Experience.",
				content: "We use the information to understand how our users as a group use the services and resources provided on our website. This helps us in tailoring our content and functionality to better meet your needs and preferences."
			},
			{
				type: "text",
				title: "3.3 To Improve our Website.",
				content: "Usage data and feedback you provide enables us to improve our website offerings and design. We continually strive to improve our website offerings based on the information and feedback we receive from you."
			},
			{
				type: "text",
				title: "3.4 To Communicate with You.",
				content: "We use personal information such as email addresses to contact users in response to their inquiries, questions, and other requests. We may also use your information to send periodic emails that may include company news, updates, related product or service information, etc."
			},
			{
				type: "text",
				title: "3.5 To Generate AI Content.",
				content: "The inputs you provide for AI-generated content are used specifically for creating the materials requested. We ensure that this data is processed in a way that maintains the integrity and purpose for which it was collected."
			},
			{
				type: "text",
				title: "3.6 For Analytics and Market Research.",
				content: "We may use data for internal analytics and market research. We may share aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above."
			},
			{
				type: "text",
				title: "3.7 For Legal Compliance.",
				content: "We may use the personal information to comply with legal obligations, such as responding to lawful requests by public authorities."
			},
			{
				type: "text",
				title: "3.8 For Other Purposes.",
				content: "We may use your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and to evaluate and improve our service, products, marketing, and your experience."
			},
			{
				type: "text",
				content: "We will not use or share your information with anyone except as described in this Privacy Policy. The collected data is used solely for the purposes stated here and will not be used for any unauthorized purpose."
			}
		]
	},
	{
		title: "4. Sharing of Data",
		content: [
			{
				type: "text",
				content: `${websiteName} values your privacy. We share and disclose your information only in the following circumstances:`
			},
			{
				type: "text",
				title: "4.1 With Service Providers.",
				content: "We may share your personal information with service providers who perform services on our behalf. We do our best to anonymize your data before sharing it. These service providers are given access to your personal information as necessary to perform these tasks and are obligated not to disclose or use it for any other purpose."
			},
			{
				type: "text",
				title: "4.2 For Business Transfers.",
				content: "In the event that we are involved in a merger, acquisition, bankruptcy, dissolution, reorganization, sale of some or all of our assets, financing, acquisition of all or a portion of our business, or similar transaction, your information may be transferred or disclosed in connection with such transaction."
			},
			{
				type: "text",
				title: "4.3 For Legal Requirements.",
				content: "We may disclose your personal information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency). This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction."
			},
			{
				type: "text",
				title: "4.4 With Your Consent.",
				content: "We may disclose personal information for any other purpose with your consent."
			},
			{
				type: "text",
				title: "4.5 Aggregated or Anonymized Data.",
				content: "We may share aggregated or anonymized information, which cannot reasonably be used to identify you. For instance, we may share aggregated user statistics to describe our services to current and prospective business partners or to other third parties for other lawful purposes."
			},
			{
				type: "text",
				title: "4.6 Marketing and Advertising Partners.",
				content: "We may share your information with advertising and marketing partners for the purpose of providing you with marketing communications, or to conduct targeted advertising. We require all third parties to respect the security of your personal data and to treat it in accordance with the law."
			},
			{
				type: "text",
				title: "4.7 Compliance with Policies and User Safety.",
				content: `We may disclose your information to enforce our policies, or to protect the rights, property, or safety of ${websiteName}, our users, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and spam/malware prevention.`
			},
			{
				type: "text",
				title: "4.8 With Affiliates.",
				content: "If we share your information with our affiliates, we will ensure that they comply with this Privacy Policy and use your personal information only for the purposes for which it was collected."
			},
			{
				type: "text",
				content: "We do not sell your personal information to third parties. Your personal data will only be shared in accordance with this policy, and where necessary to provide you with the services available on our website."
			}
		]
	},
	{
		title: "5. Data Security",
		content: [
			{
				type: "text",
				content: `At ${websiteName}, we take the security of your personal information seriously and implement a variety of security measures to maintain the safety of your personal data. However, it's important to note that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.`
			},
			{
				type: "text",
				title: "5.1 Security Measures.",
				content: "We use appropriate technical and organizational measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction. These measures may include, but are not limited to, encryption, access controls, secure software development practices, and regular security training for our staff."
			},
			{
				type: "text",
				title: "5.2 Data Breach Procedures.",
				content: "In the event of a data breach, we will take immediate steps to mitigate the breach's impact and will notify you and any applicable regulators when we are legally required to do so."
			},
			{
				type: "text",
				title: "5.3 Employee Access.",
				content: "Only a limited number of employees who have special access rights to our systems are allowed to access personal information, and they are required to keep the information confidential."
			},
			{
				type: "text",
				title: "5.4 Third-Party Service Providers.",
				content: "We ensure that our third-party service providers who have access to personal data are compliant with strict data protection standards."
			},
			{
				type: "text",
				title: "5.5 Regular Updates.",
				content: "Our security practices are regularly reviewed and updated in light of new risks and the evolution of technology."
			},
			{
				type: "text",
				title: "5.6 User Responsibility.",
				content: "We also advise you to take steps to protect yourself against unauthorized access to your password and to your computer. Be sure to sign off when finished using a shared computer."
			},
			{
				type: "text",
				title: "5.7 Payment Security.",
				content: "If we collect payment information, we use secure payment systems to ensure the security of your financial data."
			},
			{
				type: "text",
				title: "5.8 Communication Security.",
				content: "We advise caution when communicating personal information via email as it is not a secure method of communication. Please do not send us sensitive information via email."
			},
			{
				type: "text",
				html: (
					<span>
						By using our website, you acknowledge that you understand and agree to assume these risks. If you have any questions about security on our website, you can contact us at{' '}
						<Link to={`mailto:${companyEmail}`} className="font-semibold text-indigo-600 hover:text-indigo-500" target="_blank" rel="noreferrer">{companyEmail}</Link>.
					</span>
				)
			}
		]
	},
	{
		title: "6. Data Protection Rights",
		content: [
			{
				type: "text",
				content: `You have certain data protection rights. ${websiteName} aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your personal information.`
			}
		]
	},
	{
		title: "7. Changes to This Privacy Policy",
		content: [
			{
				type: "text",
				content: "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Your continued use of our website after any modification to our Privacy Policy will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy."
			}
		]
	},
	{
		title: "8. Contact Information",
		content: [
			{
				type: "text",
				html: (
					<span>
						If you have any questions about this Privacy Policy, please email us at{' '}
						<Link href={`mailto:${companyEmail}`} className="font-semibold text-indigo-600 hover:text-indigo-500" target="_blank" rel="noreferrer">{companyEmail}</Link>.
					</span>
				)
			}
		]
	},
	{
		title: "9. Effective Date",
		content: [
			{
				type: "text",
				content: "This Privacy Policy is effective as of January 4, 2024. By accessing, browsing, or otherwise using our website from this date onward, you acknowledge and agree to the terms and conditions set forth in this document."
			}
		]
	},
];

const Privacy = () => {
	const { userId } = useContext(LoggedInContext);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
  useEffect(() => {
    if (userId) {
      storeUserAction({ actionType: "view privacy" });
    }
  }, [userId]);
	return (
    <div className="bg-white min-h-screen">
    	<NavBar />
	    <Content title="Privacy" sections={sections} />
      <Footer />
    </div>
	);
};

export default Privacy;
