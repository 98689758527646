import { useContext, useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "./Loading";
import AuraHeader from "./AuraHeader";
import AuraCanvasShared from "./AuraCanvasShared";
import AuraActions from "./AuraActions";
import useImage from "use-image";
import logo from "./assets/logo_inverted.png";
import { LoggedInContext } from "./context/context";
import { storeUserAction } from "./services/storeUserAction";
import { filterNarcScores, filterLinkedinPersonas, filterPersonalitiesOne, filterPersonalitiesTwo, backgroundImages } from "./styles/styles";
import { get } from "./utils/utils";

const defaultMessage = {
	header: "Something went wrong",
	subheader: "We couldn't find your story."
};

const StoryImage = () => {
	const { userId } = useContext(LoggedInContext);
	const [loading, setLoading] = useState(true);
	const [linkedinUrl, setLinkedinUrl] = useState(null);
	const [message, setMessage] = useState(null);
	const [src, setSrc] = useState(null);
	const [updateCanvas, setUpdateCanvas] = useState(false);
	const [firstLoad, setFirstLoad] = useState(false);
	const stageRef = useRef(null);
	const statusRef = useRef(null);
	const [bgIndex, setBgIndex] = useState(0);
	const [backgroundImage, setBackgroundImage] = useState(backgroundImages[0]);
	const [image, status] = useImage(backgroundImage?.image);
	const [logoImage, logoStatus] = useImage(logo);
	const { linkedinUrlId } = useParams();

	let id, profileJsonCleaned, fullName, totalScore, summary, personaImageUrl, personaName, quote, roast, pickupLine, emoji, awardText, uploadFile

	if (linkedinUrl) {
		({ id, uploadFile, profileJsonCleaned, fullName } = linkedinUrl);
		const { narcScores, linkedinPersonas, personalities, awards, error } = linkedinUrl;

		const scoresFiltered = narcScores.filter(filterNarcScores).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
		({ totalScore, summary } = scoresFiltered[0]);

		const linkedinPersonasFiltered = linkedinPersonas.filter(filterLinkedinPersonas).sort((a, b) => (b.percentage - a.percentage) || (b.createdAt - a.createdAt));
		const linkedinPersona = linkedinPersonasFiltered[0];
		({ imageUrl: personaImageUrl, name: personaName, quote } = linkedinPersona);

		const personalitiesOneFiltered = personalities.filter(filterPersonalitiesOne).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
		({ roast } = personalitiesOneFiltered[0]);

		const personalitiesTwoFiltered = personalitiesOneFiltered.filter(filterPersonalitiesTwo);
		({ pickupLines: [pickupLine] } = personalitiesTwoFiltered[0]);

		({ emoji } = awards[0]);
		const { title, description } = awards[0];
		awardText = `CEO Awards: ${title}`;
	}

	const [personaImage, personaStatus] = useImage(personaImageUrl, "anonymous", "origin");

	useEffect(() => {
	  const fontPromises = [
	    document.fonts.load("10pt 'Mona Sans'"), // First font
	    document.fonts.load("10pt 'Mona Sans Wide'"), // Second font (e.g., Roboto)
	  ];
		Promise.all(fontPromises).then(() => {
		  console.log('Font is available');
		  // Now that the font is available, re-render the canvas or the component that uses it.
		}).catch(error => {
		  console.error('Font loading failed:', error);
		});
	}, []);

	useEffect(() => {
		if (userId) {
			if (linkedinUrlId) {
				setLoading(true);
		    get(`/users/awards?id=${linkedinUrlId}`).then(
		      json => {
		      	const { linkedin_url: linkedinUrl } = json;
		        setLinkedinUrl(linkedinUrl);
		        setLoading(false);

		        const { id } = linkedinUrl;
		        storeUserAction({ linkedinUrlId: id, actionType: "view story image" });
		      },
		      error => {
		      	// wrong linkedin url id or not logged in
		      	console.log("15");
		      	console.log(error);
		      	setDefaultErrorMessage();
		      }
		    );
			} else {
				setDefaultErrorMessage();
			}
		}
	}, [userId, linkedinUrlId]);

  useEffect(() => {
  	if (linkedinUrl) {
	    if (status === "loaded") {
	    	setFirstLoad(true);
	    }

	    if (stageRef.current) {
	      const dataURL = stageRef.current.toDataURL();
	      setSrc(dataURL);
	    }
  	}
  }, [updateCanvas, status, personaStatus, logoStatus, linkedinUrl]);

	const setDefaultErrorMessage = () => {
		setMessage(defaultMessage);
		setLoading(false);
	};

  const canvasWidth = 1080;
  const defaultTextSize = 35;

  const { 
  	defaultTextColor = "#111827", // gray-900
  	defaultRectFillColor = "white", 
  	defaultRectStrokeColor, // = "#e5e7eb", // gray-200
  } = backgroundImages[bgIndex];

  const returnHeader = () => {
  	let headerText = "Share your LinkedIn Aura story!";
  	return (
  		<AuraHeader
  			linkedinUrlId={id}
				headerText={headerText}
				stepSixCondition={true}
				bgIndex={bgIndex}
				setBgIndex={setBgIndex}
				setBackgroundImage={setBackgroundImage}
				viewFull
  		/>
  	);
  };

  const returnCanvasShared = () => {
  	if (linkedinUrl) {
	  	return (
	  		<AuraCanvasShared
	  			linkedinUrl={linkedinUrl}
	  			profileJsonCleaned={profileJsonCleaned}
	  			fullName={fullName}
	  			totalScore={totalScore}
	  			summary={summary}
	  			personaName={personaName}
	  			quote={quote}
	  			roast={roast}
	  			pickupLine={pickupLine}
	  			emoji={emoji}
	  			awardText={awardText}
	  			uploadFile={uploadFile}
	  			stepOneCondition={true}
	  			stepTwoCondition={true}
	  			stepThreeCondition={true}
	  			stepFourCondition={true}
	  			stepFiveCondition={true}
	  			stepSixCondition={true}
					src={src}
					setUpdateCanvas={setUpdateCanvas}
					firstLoad={firstLoad}
					stageRef={stageRef}
					statusRef={statusRef}
					bgIndex={bgIndex}
					backgroundImage={backgroundImage}
					image={image}
					personaImage={personaImage}
					logoImage={logoImage}
	  			canvasWidth={canvasWidth}
	  			defaultTextSize={defaultTextSize}
	  			defaultTextColor={defaultTextColor}
	  			returnSectionColors={() => { return { rectColor: defaultRectFillColor, strokeColor: defaultRectStrokeColor, headerColor: defaultTextColor }; }}
	  			returnOpacity={() => 1}
	  			returnLoadingText={() => null}
	  		/>
	  	);  		
  	}
  	return null;
  };

  const returnActions = () => {
  	return (
  		<AuraActions
  			linkIdParent={linkedinUrlId}
  			linkedinUrlId={id}
  			fullName={fullName}
  			stageRef={stageRef}
  			bgIndex={bgIndex}
  			condition={true}
  			viewFull
  		/>
  	);
  };

	if (loading) {
		return <Loading />;
	}
	return (
		<div className="bg-white min-h-screen">
	    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
	    	<div className="mx-auto max-w-4xl">
					<div className="flex flex-col justify-center items-center">
						{returnHeader()}
						{returnCanvasShared()}
						{returnActions()}
				  </div>
	    	</div>
	    </div>
		</div>
	);
};

export default StoryImage;
