import { Link } from "react-router-dom";
import error from "./assets/error.jpeg";

const ErrorShared = ({ message: { header, subheader } }) => {
	return (
	  <main className="absolute isolate h-full min-h-screen w-full min-w-screen inset-0">
	    <img
	      src={error}
	      alt="error"
	      className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
	    />
	    <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
	    	<div className="mx-auto max-w-3xl">
		      <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-4xl">{header}</h1>
		      <p className="mt-4 text-base text-white/70 sm:mt-6 sm:text-lg">{subheader}</p>
		      <div className="mt-10 flex justify-center">
		        <Link to="/" className="text-sm font-semibold leading-7 text-white sm:text-base">
		          <span aria-hidden="true">&larr;</span> Back to home
		        </Link>
		      </div>
		     </div>
	    </div>
	  </main>
	);
};

export default ErrorShared;
