import { FaAward } from "react-icons/fa6";
import { fallbackEmoji } from "./styles/styles";

const AwardIcon = ({ emoji, position, iconColor, bgColor, small }) => {
	return (
    <div className={`${position} flex justify-center items-center`}>
    	<FaAward className={`${small ? "w-10 h-10 sm:w-12 sm:h-12" : "w-14 h-14"} ${iconColor}`} />
    	<p className={`absolute ${small ? "top-[5px] sm:top-1" : "top-[7px]"} ${small ? "text-sm sm:text-lg" : "text-xl"} ${bgColor} rounded-full`}>{emoji || fallbackEmoji}</p>
    </div>
	);
};

export default AwardIcon;
