import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import NavBar from "./NavBar";
import Footer from "./Footer";
import AwardIcon from "./AwardIcon";
import ErrorShared from "./ErrorShared";
import { LoggedInContext } from "./context/context";
import { storeUserAction } from "./services/storeUserAction";
import { arrayExists, logInMessage } from "./styles/styles";
import { getToken } from "./utils/utils";

const Purchases = () => {
  const { userId } = useContext(LoggedInContext);
  const [loading, setLoading] = useState(true);
  const [purchases, setPurchases] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userId) {
      storeUserAction({ actionType: "view purchases" });
      getToken("/users/purchases").then(
        json => {
          console.log(json);
          setPurchases(json);
          setLoading(false);
        },
        error => {
          console.log("31");
          console.log(error);
          setMessage(logInMessage);
          setLoading(false);
        }
      );
    }
  }, [userId]);

  const returnPrice = (price, amountTotal) => {
    const priceFormatted = (price / 100).toLocaleString("en-US", { style: "currency", currency: "USD" });
    if (price !== amountTotal) {
      const amountTotalFormatted = (amountTotal / 100).toLocaleString("en-US", { style: "currency", currency: "USD" });
      return <><span className="line-through">{priceFormatted}</span>&nbsp;&nbsp;{amountTotalFormatted}</>;
    }
    return priceFormatted;
  };

  const returnPurchase = ({ id, name, emoji, price, amountTotal, createdAt, status, linkId, type, articleVisuals }, index) => {
    const dateFormatted = new Date(createdAt).toLocaleDateString("en-US", { dateStyle: "medium" });
    let imageUrl, imageUrlSmall;
    if (articleVisuals.some(a => a.saved && !a.midjourneyOriginal)) {
      ({ imageUrl, imageUrlSmall } = articleVisuals.find(a => a.saved && !a.midjourneyOriginal));
    }
    const link = type === "award" ? `/a/${linkId}` : `/aura/${linkId}`;
    const returnVisual = () => {
      if (imageUrlSmall || imageUrl) {
        return (
          <img
            src={imageUrlSmall || imageUrl}
            alt="award"
            className="mr-6 h-16 w-16 rounded object-cover object-center"
          />
        );
      } else if (type === "aura") {
        return (
          <img
            src="https://ceolist.org/aura_image.png"
            alt="award"
            className="mr-6 h-16 w-16 rounded object-cover object-center"
          />
        )
      }
      return (
        <AwardIcon
          emoji={emoji}
          position="relative ml-1 mr-7"
          iconColor="text-slate-300"
          bgColor="bg-slate-300"
        />
      );
    };
    return (
      <tr key={id} className="relative hover:opacity-75">
        <td className="py-6 pr-6 sm:pr-8">
          <button type="button" className="absolute inset-0 focus:outline-none" onClick={() => navigate(link)} />
          <div className="flex items-center">
            {returnVisual()}
            <div>
              <div className="font-medium text-gray-900">{name}</div>
              <div className="mt-1 sm:hidden">{returnPrice(price, amountTotal)}</div>
              <div className="mt-1 sm:hidden">{dateFormatted}</div>
            </div>
          </div>
        </td>
        <td className="hidden py-6 pr-8 sm:table-cell">{returnPrice(price, amountTotal)}</td>
        <td className="hidden py-6 pr-8 sm:table-cell">{dateFormatted}</td>
        <td className="whitespace-nowrap py-6 text-right font-medium">
          {status}
          {/*<a href={award.href} className="text-indigo-600">
            View<span className="hidden lg:inline"> Award</span>
            <span className="sr-only">, {award.name}</span>
          </a>*/}
        </td>
      </tr>
    );
  };

  const returnPurchases = () => {
    if (arrayExists(purchases)) {
      return purchases.map(returnPurchase);
    }
  };

  if (loading) {
    return <Loading />;
  } else if (message) {
    return <ErrorShared message={message} />;
  }
	return (
    <div className="bg-white min-h-screen">
    	<NavBar />
      <div className="mx-auto max-w-7xl px-4 py-32 sm:px-6 lg:px-8 lg:pb-24">
        <div className="max-w-xl">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Purchase history</h1>
          <p className="mt-2 text-sm text-gray-500">
            Check the status of auras & awards you've purchased.
          </p>
        </div>

        <div className="mt-16">
          {arrayExists(purchases) ? (
            <table className="mt-4 w-full text-gray-500 sm:mt-6">
              <caption className="sr-only">Awards</caption>
              <thead className="text-left text-sm text-gray-500">
                <tr>
                  <th scope="col" className="py-3 pr-8 font-normal w-1/2">
                    Aura/Award
                  </th>
                  <th scope="col" className="hidden py-3 pr-8 font-normal w-1/6 sm:table-cell">
                    Price
                  </th>
                  <th scope="col" className="hidden py-3 pr-8 font-normal w-1/6 sm:table-cell">
                    Date
                  </th>
                  <th scope="col" className="py-3 text-right font-normal w-1/6">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 border-b border-gray-200 text-sm border-t">
                {returnPurchases()}
              </tbody>
            </table>
          ) : (
            <p className="font-medium text-center">
              No purchases yet.
            </p>
          )}
        </div>
      </div>
      <Footer />
    </div>
	);
};

export default Purchases;
