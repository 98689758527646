import AwardIcon from "./AwardIcon";

const StackAward = ({ award, style }) => {
	const { id, title, description, emoji } = award;
	return (
    <li key={id} className={`${style} bg-white absolute top-0 sm:top-auto sm:-bottom-24 sm:-right-16 sm:w-96 border border-gray-200 col-span-1 flex flex-col rounded-lg text-center shadow-lg`}>
      <div className="flex flex-1 flex-col p-8 pb-6 justify-center">
      	<AwardIcon
      		emoji={emoji}
      		position="relative"
      		iconColor="text-slate-300"
      		bgColor="bg-slate-300"
      	/>
        <h3 className="mt-6 font-medium text-gray-900">{title}</h3>
        <p className="mt-1 text-sm text-gray-500">{description}</p>
        <div className="h-14 mt-3" />
      </div>
    </li>
	);
};

export default StackAward;
